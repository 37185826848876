import { combineReducers } from 'redux'
import { configureStore } from '@reduxjs/toolkit';

import { authReducer } from './auth.slice';
import { cartReducer } from './cart.slice';

import storage from 'redux-persist/lib/storage';
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist'

const persistConfig = {
  key: 'root',
  storage,
}

const reducers = combineReducers({
    cart: persistReducer(
        persistConfig,
        cartReducer
    ),
    auth: authReducer
})

export * from './auth.slice';
export * from './cart.slice';

export const store = configureStore({
    reducer: reducers,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: {
          ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
        },
      })
  })
  
  export const persistor = persistStore(store)