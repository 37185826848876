import { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import demoGraphic from '../_assets/graphic.png';

export { PriceCard };

function PriceCard({
    icon = demoGraphic,
    title,
    body,
    price,
    graphic = true,
    toggleCallback = null,
    learnMoreCallback = null,
    selected = false,
    ignoreToggle = false,
    iconDisplay = 'square'
}) {
    let [isSelected, setIsSelected] = useState(selected);

    if (parseInt(price) === 0) {
        price = 'Free';
    } else {
        price = '+£' + price;
    }

    function selectItem() {
        toggleCallback(!isSelected);
        if (ignoreToggle) {
            setIsSelected(true);
        } else {
            setIsSelected(!isSelected);
        }
    }

    return (
        <div className="mt-4 flex w-full items-center rounded-3xl border border-nw-offBlack bg-white py-4 px-4">
            {graphic && (
                <img
                    className={
                        'relative mr-5 mt-2 mb-2 hidden hover:cursor-pointer md:block' +
                        (iconDisplay === 'square' ? ' h-[40px] w-[65px]' : ' h-[20px] w-auto')
                    }
                    src={icon}
                    alt="Paper copy of results"
                    onClick={() => {
                        selectItem();
                    }}
                />
            )}
            <div className="w-full">
                <p
                    className="text-nw-offBlack hover:cursor-pointer"
                    onClick={() => {
                        selectItem();
                    }}
                >
                    {title}
                </p>
                <p className="pr-2 text-sm font-light text-nw-offBlack lg:pr-5">{body}</p>
                {learnMoreCallback !== null && (
                    <p
                        className="mt-2 inline-block text-sm font-medium text-nw-offBlack underline hover:cursor-pointer"
                        onClick={learnMoreCallback}
                    >
                        Learn more
                    </p>
                )}
            </div>
            <div
                className={
                    'relative flex min-w-[80px] rounded-xl align-middle hover:cursor-pointer' +
                    (isSelected ? ' bg-black text-white' : '')
                }
                onClick={() => {
                    selectItem();
                }}
            >
                <p className="min-w-[50px] text-right">{price}</p>
                <div className="absolute right-0 h-6 w-6 rounded-[50%] border-2 border-black bg-white">
                    {isSelected && (
                        <FontAwesomeIcon icon={faCheck} className="relative mb-[3px] ml-1 h-3 w-3 text-black" />
                    )}
                </div>
            </div>
        </div>
    );
}
