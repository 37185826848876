import { useState, useEffect } from 'react';
import { useSearchParams, Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { cartActions } from '_store';
import { checkNested, products, history } from '_helpers';
import { PriceCard, Popup } from '_components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleXmark } from '@fortawesome/free-regular-svg-icons';

import boneHealthIcon from '../../../_assets/personalise/add-on-bone-health.svg';
import cancerScreeningIcon from '../../../_assets/personalise/add-on-cancer-screening.svg';
import fullBloodCountIcon from '../../../_assets/personalise/add-on-full-blood-count.svg';
import hormoneHealthIcon from '../../../_assets/personalise/add-on-hormone-health.svg';
import immuneHealthIcon from '../../../_assets/personalise/add-on-immune-health.svg';
import ironStatusIcon from '../../../_assets/personalise/add-on-iron-status.svg';
import kidneyHealthIcon from '../../../_assets/personalise/add-on-kidney-health.svg';
import liverHealthIcon from '../../../_assets/personalise/add-on-liver-health.svg';
import muscleJointHealthIcon from '../../../_assets/personalise/add-on-muscle-joint-health.svg';
import nutritionHealthIcon from '../../../_assets/personalise/add-on-nutrition-health.svg';
import thyroidHealthIcon from '../../../_assets/personalise/add-on-thyroid-health.svg';
import diabetesHealthIcon from '../../../_assets/personalise/add-on-diabetes-health.svg';
import digestiveHealthIcon from '../../../_assets/personalise/add-on-digestive-health.svg';
import generalHealthIcon from '../../../_assets/personalise/add-on-general-health.svg';
import heartHealthIcon from '../../../_assets/personalise/add-on-heart-health.svg';
import pancreasHealthIcon from '../../../_assets/personalise/add-on-pancreas-health.svg';
import sexualHealthIcon from '../../../_assets/personalise/add-on-sexual-health.svg';
import weightLossIcon from '../../../_assets/personalise/add-on-weight-loss.svg';
import bioniqIcon from '../../../_assets/personalise/add-on-bioniq-supplements.png';
import testosteroneIcon from '../../../_assets/personalise/add-on-male.svg';
import trucheckIcon from '../../../_assets/personalise/add-on-trucheck.png';
import oncontextIcon from '../../../_assets/personalise/add-on-onconext.png';
import prenatalIcon from '../../../_assets/personalise/add-on-prenatal_safe.png';

export { Personalise };

function Personalise() {
    const randoxBiomarkers = {
        'General Health': [
            {
                title: 'Full Blood Count',
                body: 'A comprehensive analysis of your full blood count including cells related to your immune system.',
                price: 40,
                learnMore: (
                    <>
                        <div className="text-center">
                            <p className="mt-4 text-center text-lg text-nw-offBlack">Markers tested include:</p>
                            <ul className="mt-2 grid list-disc place-items-center px-4 text-nw-darkGrey">
                                <li>Haemoglobin</li>
                                <li>Haematocrit</li>
                                <li>Mean Cell Haemoglobin</li>
                                <li>Mean Cell Haemoglobin Concentration (MCHC)</li>
                                <li>Mean Cell Volume</li>
                                <li>Red Blood Cell Count</li>
                                <li>Basophil Count</li>
                                <li>Lymphocyte Count</li>
                                <li>Eosinophil Count</li>
                                <li>Monocyte Count</li>
                                <li>Neutrophil Count</li>
                                <li>White Blood Cell Count</li>
                                <li>Platelet Count</li>
                            </ul>
                        </div>
                    </>
                ),
                codes: ['HSC1']
            },
            {
                title: 'HPV DNA Screen',
                body: 'A comprehensive analysis of HPV antibodies to full out possible HPV infections within the body.',
                price: 100,
                learnMore: (
                    <>
                        <div className="text-center">
                            <p className="mt-2 list-disc place-items-center px-4 text-nw-darkGrey">
                                Screens for HPV viral DNA within the blood. Includes High risk HPV groups
                                16,18,45,31,33,35,39,51,52,56,58,59,66,88.
                            </p>
                        </div>
                    </>
                ),
                codes: ['HPV']
            },
            {
                title: 'Full Anaemia Profile',
                body: 'A comprehensive analysis of blood biomarkers relating to possible causes of aneamia.',
                price: 100,
                learnMore: (
                    <>
                        <div className="text-center">
                            <p className="mt-4 text-center text-lg text-nw-offBlack">Markers tested include:</p>
                            <p className="mt-4 px-10 text-center text-xl font-medium text-nw-offBlack">
                                Full Blood Count
                            </p>
                            <ul className="mx-auto mt-2 grid list-disc place-items-center px-10 text-nw-darkGrey">
                                <li>Haemoglobin</li>
                                <li>Haematocrit</li>
                                <li>Mean Cell Haemoglobin</li>
                                <li>Mean Cell Haemoglobin</li>
                                <li>Concentration (MCHC)</li>
                                <li>Mean Cell Volume</li>
                                <li>Red Blood Cell Count</li>
                                <li>Basophil Count</li>
                                <li>Lymphocyte Count</li>
                                <li>Eosinophil Count</li>
                                <li>Monocyte Count</li>
                                <li>Neutrophil Count</li>
                                <li>White Blood Cell Count</li>
                                <li>Platelet Count</li>
                            </ul>
                            <p className="mt-4 px-10 text-center text-xl font-medium text-nw-offBlack">Iron Status</p>
                            <ul className="mx-auto mt-2 grid list-disc place-items-center px-10 text-nw-darkGrey">
                                <li>Iron</li>
                                <li>Ferritin</li>
                                <li>Total Iron Binding Capacity</li>
                                <li>Transferrin</li>
                                <li>Transferrin Saturation</li>
                            </ul>
                            <p className="mt-4 px-10 text-center text-xl font-medium text-nw-offBlack">
                                Nutritional Health
                            </p>
                            <ul className="mx-auto mt-2 grid list-disc place-items-center px-10 text-nw-darkGrey">
                                <li>Folic Acid</li>
                                <li>Vitamin B12</li>
                            </ul>
                            <p className="mt-4 px-10 text-center text-xl font-medium text-nw-offBlack">
                                Infection & Inflammation
                            </p>
                            <ul className="mx-auto mt-2 grid list-disc place-items-center px-10 text-nw-darkGrey">
                                <li>High Sensitivity</li>
                                <li>C-Reactive Protein</li>
                            </ul>
                        </div>
                    </>
                ),
                codes: ['RP21']
            },
            {
                title: 'Blood Group',
                body: 'An analysis of your blood to determine your blood group.',
                price: 45,
                learnMore: <></>,
                codes: ['BLOOD_GROUP']
            },
            {
                title: 'Heart, Diabetes and Advanced Metabolic Health',
                body: 'A comprehensive analysis of your metabolic health including heart and diabetes health.',
                price: 110,
                learnMore: (
                    <>
                        <div className="text-center">
                            <p className="mt-4 text-center text-lg text-nw-offBlack">Markers tested include:</p>
                            <p className="mt-4 px-10 text-center text-xl font-medium text-nw-offBlack">Heart Health</p>
                            <ul className="mx-auto mt-2 grid list-disc place-items-center px-10 text-nw-darkGrey">
                                <li>Total Cholesterol</li>
                                <li>LDL Cholesterol</li>
                                <li>HDL Cholesterol</li>
                                <li>Total Cholesterol / HDL</li>
                                <li>Cholesterol Ratio</li>
                                <li>Triglycerides</li>
                                <li>Apolipoprotein A-I</li>
                                <li>Apolipoprotein B</li>
                                <li>Apolipoprotein B / A-I Ratio</li>
                                <li>Apolipoprotein CII</li>
                                <li>Apolipoprotein CIII</li>
                                <li>Apolipoprotein E</li>
                                <li>Small LDL Cholesterol</li>
                                <li>Lipoprotein (a)</li>
                                <li>High Sensitivity</li>
                                <li>C-Reactive Protein</li>
                            </ul>
                            <p className="mt-4 px-10 text-center text-xl font-medium text-nw-offBlack">
                                Metabolic Health
                            </p>
                            <ul className="mx-auto mt-2 grid list-disc place-items-center px-10 text-nw-darkGrey">
                                <li>Glucose</li>
                                <li>HDL Cholesterol</li>
                                <li>Triglycerides</li>
                                <li>HbA1c</li>
                                <li>Insulin</li>
                                <li>C-peptide</li>
                                <li>Leptin</li>
                                <li>Adiponectin</li>
                                <li>Resistin</li>
                                <li>High Sensitivity</li>
                                <li>C-Reactive Protein</li>
                            </ul>
                            <p className="mt-4 px-10 text-center text-xl font-medium text-nw-offBlack">
                                Diabetes Health
                            </p>
                            <ul className="mx-auto mt-2 grid list-disc place-items-center px-10 text-nw-darkGrey">
                                <li>Glucose</li>
                                <li>HbA1c</li>
                                <li>Insulin</li>
                                <li>C-peptide</li>
                            </ul>
                        </div>
                    </>
                ),
                codes: ['RP3']
            },
            {
                title: 'Stress Markers',
                body: 'A comprehensive analysis of stress biomarkers.',
                price: 55,
                learnMore: (
                    <>
                        <div className="text-center">
                            <p className="mt-4 text-center text-lg text-nw-offBlack">Markers tested include:</p>
                            <ul className="mt-2 grid list-disc place-items-center px-4 text-nw-darkGrey">
                                <li>Cortisol</li>
                                <li>DHEA-S</li>
                            </ul>
                        </div>
                    </>
                ),
                codes: ['RP8']
            },
            {
                title: 'Diabetes Status',
                body: 'A comprehensive analysis of blood biomarkers relating to diabetes.',
                price: 55,
                learnMore: (
                    <>
                        <div className="text-center">
                            <p className="mt-4 text-center text-lg text-nw-offBlack">Markers tested include:</p>
                            <ul className="mt-2 grid list-disc place-items-center px-4 text-nw-darkGrey">
                                <li>Glucose</li>
                                <li>HbA1c</li>
                                <li>Insulin</li>
                            </ul>
                        </div>
                    </>
                ),
                codes: ['RP6']
            },
            {
                title: 'Lipid Profile',
                body: 'A comprehensive analysis of blood lipids and cholesterol.',
                price: 45,
                learnMore: (
                    <>
                        <div className="text-center">
                            <p className="mt-4 text-center text-lg text-nw-offBlack">Markers tested include:</p>
                            <ul className="mt-2 grid list-disc place-items-center px-4 text-nw-darkGrey">
                                <li>Total Cholesterol</li>
                                <li>LDL Cholesterol</li>
                                <li>HDL Cholesterol</li>
                                <li>Total Cholesterol/HDL Cholesterol Ratio</li>
                                <li>Triglycerides</li>
                            </ul>
                        </div>
                    </>
                ),
                codes: ['XLIPIDS']
            },
            {
                title: 'Digestive Health (Stomach Cancer Risk & Coeliac Disease)',
                body: 'Test for indicators of stomach cancer risk and coeliac disease with H.Pylori and anti TTG antibodies.',
                price: 85,
                learnMore: (
                    <>
                        <div className="text-center">
                            <p className="mt-4 text-center text-lg text-nw-offBlack">Markers tested include:</p>
                            <ul className="mt-2 grid list-disc place-items-center px-4 text-nw-darkGrey">
                                <li>H.Pylori</li>
                                <li>Anti Tissue Transglutaminase Antibodies</li>
                            </ul>
                        </div>
                    </>
                ),
                codes: ['RP16']
            },
            {
                title: 'Advanced Thyroid Health',
                body: 'A more advanced analysis of blood biomarkers relating to thyroid health.',
                price: 55,
                learnMore: (
                    <>
                        <div className="text-center">
                            <p className="mt-4 text-center text-lg text-nw-offBlack">Markers tested include:</p>
                            <ul className="mt-2 grid list-disc place-items-center px-4 text-nw-darkGrey">
                                <li>Thyroid Stimulating Hormone</li>
                                <li>Free Tri-iodothyronine</li>
                                <li>Free Thyroxine</li>
                                <li>Anti-Thyroglobulin Antibody</li>
                                <li>Anti-Thyroid Peroxidase Antibody</li>
                            </ul>
                        </div>
                    </>
                ),
                codes: ['RP4']
            }
        ],
        'Kidney Health': [
            {
                title: 'Basic Kidney Health',
                body: 'A comprehensive analysis of blood biomarkers relating to kidney health.',
                price: 40,
                learnMore: (
                    <>
                        <div className="text-center">
                            <p className="mt-4 text-center text-lg text-nw-offBlack">Markers tested include:</p>
                            <ul className="mt-2 grid list-disc place-items-center px-4 text-nw-darkGrey">
                                <li>Creatinine</li>
                                <li>Estimated Glomerular Filtration Rate</li>
                                <li>Calcium (Adjusted)</li>
                                <li>Chloride</li>
                                <li>Magnesium</li>
                                <li>Phosphate</li>
                                <li>Potassium*</li>
                                <li>Sodium</li>
                                <li>Urea</li>
                            </ul>
                        </div>
                    </>
                ),
                codes: ['RP20']
            },
            {
                title: 'Advanced Kidney Health',
                body: 'A more advanced analysis of blood biomarkers relating to kidney health.',
                price: 85,
                learnMore: (
                    <>
                        <div className="text-center">
                            <p className="mt-4 text-center text-lg text-nw-offBlack">Markers tested include:</p>
                            <ul className="mt-2 grid list-disc place-items-center px-4 text-nw-darkGrey">
                                <li>Creatinine</li>
                                <li>Estimated Glomerular Filtration Rate</li>
                                <li>Cystatin C</li>
                                <li>Calcium (Adjusted)</li>
                                <li>Chloride</li>
                                <li>Magnesium</li>
                                <li>Phosphate</li>
                                <li>Potassium*</li>
                                <li>Sodium</li>
                                <li>Urea</li>
                                <li>Uric Acid</li>
                            </ul>
                        </div>
                    </>
                ),
                codes: ['RP12']
            },
            {
                title: 'Urinalysis',
                body: 'A comprehensive analysis of your urine in order to help monitor kidney health.',
                price: 25,
                learnMore: (
                    <>
                        <div className="text-center">
                            <p className="mt-4 text-center text-lg text-nw-offBlack">Markers tested include:</p>
                            <ul className="mt-2 grid list-disc place-items-center px-4 text-nw-darkGrey">
                                <li>Bilirubin</li>
                                <li>Glucose</li>
                                <li>Ketones</li>
                                <li>Nitrite</li>
                                <li>pH</li>
                                <li>Protein</li>
                                <li>Red Blood Cells</li>
                                <li>Urobilinogen</li>
                                <li>White Blood Cells</li>
                            </ul>
                        </div>
                    </>
                ),
                codes: ['RP13']
            }
        ],
        'Muscle & Joint Health': [
            {
                title: 'Muscle & Joint Health',
                body: 'A comprehensive analysis of muscle and joint health.',
                price: 45,
                learnMore: (
                    <>
                        <div className="text-center">
                            <p className="mt-4 text-center text-lg text-nw-offBlack">Markers tested include:</p>
                            <ul className="mt-2 grid list-disc place-items-center px-4 text-nw-darkGrey">
                                <li>Creatine Kinase</li>
                                <li>Uric Acid</li>
                                <li>Rheumatoid Factor</li>
                            </ul>
                        </div>
                    </>
                ),
                codes: ['RP19']
            },
            {
                title: 'Muscle & Rhuematology',
                body: 'A comprehensive analysis of rhuematological biomarkers and joint health. A good assessment for conditions such as rhuematoid disease or rhuematoid arthritis.',
                price: 120,
                learnMore: (
                    <>
                        <div className="text-center">
                            <p className="mt-4 text-center text-lg text-nw-offBlack">Markers tested include:</p>
                            <p className="mt-4 px-10 text-center text-xl font-medium text-nw-offBlack">
                                Nutritional Health
                            </p>
                            <ul className="mx-auto mt-2 grid list-disc place-items-center px-10 text-nw-darkGrey">
                                <li>Calcium (Adjusted)</li>
                                <li>Albumin</li>
                                <li>Vitamin D</li>
                            </ul>
                            <p className="mt-4 px-10 text-center text-xl font-medium text-nw-offBlack">Liver Health</p>
                            <ul className="mx-auto mt-2 grid list-disc place-items-center px-10 text-nw-darkGrey">
                                <li>Alanine Aminotransferase</li>
                                <li>Alkaline Phosphatase</li>
                                <li>Aspartate Aminotransferase</li>
                                <li>Gamma- glutamyltransferase</li>
                                <li>Total Bilirubin</li>
                                <li>Albumin</li>
                                <li>Ferritin</li>
                            </ul>
                            <p className="mt-4 px-10 text-center text-xl font-medium text-nw-offBlack">Kidney Health</p>
                            <ul className="mx-auto mt-2 grid list-disc place-items-center px-10 text-nw-darkGrey">
                                <li>Creatinine</li>
                                <li>Estimated Glomerular</li>
                                <li>Filtration Rate</li>
                                <li>Cystatin C</li>
                                <li>Calcium (Adjusted)</li>
                                <li>Chloride</li>
                                <li>Magnesium</li>
                                <li>Phosphate</li>
                                <li>Potassium*</li>
                                <li>Sodium</li>
                                <li>Urea</li>
                                <li>Uric Acid</li>
                            </ul>
                            <p className="mt-4 px-10 text-center text-xl font-medium text-nw-offBlack">
                                Muscle & Joint Health
                            </p>
                            <ul className="mx-auto mt-2 grid list-disc place-items-center px-10 text-nw-darkGrey">
                                <li>Creatine Kinase</li>
                                <li>Uric Acid</li>
                                <li>Rheumatoid Factor</li>
                            </ul>
                            <p className="mt-4 px-10 text-center text-xl font-medium text-nw-offBlack">Bone Health</p>
                            <ul className="mx-auto mt-2 grid list-disc place-items-center px-10 text-nw-darkGrey">
                                <li>Alkaline Phosphatase</li>
                                <li>Calcium (Adjusted)</li>
                                <li>Phosphate</li>
                                <li>Vitamin D</li>
                            </ul>
                            <p className="mt-4 px-10 text-center text-xl font-medium text-nw-offBlack">Iron Status</p>
                            <ul className="mx-auto mt-2 grid list-disc place-items-center px-10 text-nw-darkGrey">
                                <li>Iron</li>
                                <li>Ferritin</li>
                                <li>Total Iron Binding Capacity</li>
                                <li>Transferrin</li>
                                <li>Transferrin Saturation</li>
                            </ul>
                            <p className="mt-4 px-10 text-center text-xl font-medium text-nw-offBlack">
                                Infection & Inflammation
                            </p>
                            <ul className="mx-auto mt-2 grid list-disc place-items-center px-10 text-nw-darkGrey">
                                <li>C-Reactive Protein</li>
                                <li>Rheumatoid Factor</li>
                                <li>Albumin</li>
                                <li>Complement Component 3</li>
                                <li>Complement Component 4</li>
                                <li>Ferritin</li>
                                <li>Immunoglobulin A</li>
                                <li>Immunoglobulin G</li>
                                <li>Immunoglobulin M</li>
                            </ul>
                        </div>
                    </>
                ),
                codes: ['RP2']
            },
            {
                title: 'Rheumatoid Arthritis Risk (Rhuematoid Factor)',
                body: 'Rhuematoid Factor.',
                price: 35,
                learnMore: (
                    <>
                        <div className="text-center">
                            <p className="mt-4 list-disc place-items-center px-4 text-nw-darkGrey">
                                Rhuematoid factor is increased in people with rhuematological conditions such as
                                rhuematoid arthritis
                            </p>
                        </div>
                    </>
                ),
                codes: ['RF']
            },
            {
                title: 'Bone Health',
                body: 'A comprehensive analysis of blood biomarkers relating to bone health.',
                price: 95,
                learnMore: (
                    <>
                        <div className="text-center">
                            <p className="mt-4 text-center text-lg text-nw-offBlack">Markers tested include:</p>
                            <ul className="mt-2 grid list-disc place-items-center px-4 text-nw-darkGrey">
                                <li>Alkaline Phosphatase</li>
                                <li>Calcium (Adjusted)</li>
                                <li>Phosphate</li>
                                <li>Vitamin D</li>
                                <li>Parathyroid Hormone </li>
                            </ul>
                        </div>
                    </>
                ),
                codes: ['RP18']
            }
        ],
        'Hormone Health': [
            {
                title: 'Hormone Health',
                body: 'A comprehensive analysis of your full hormone profile.',
                price: 75,
                learnMore: (
                    <>
                        <div className="text-center">
                            <p className="mt-4 text-center text-lg text-nw-offBlack">Markers tested include:</p>
                            <ul className="mt-2 grid list-disc place-items-center px-4 text-nw-darkGrey">
                                <li>Oestradiol </li>
                                <li>Follicle Stimulating Hormone</li>
                                <li>Luteinising Hormone</li>
                                <li>Progesterone</li>
                                <li>Prolactin</li>
                                <li>Testosterone</li>
                                <li>Sex Hormone Binding Globulin</li>
                                <li>Free Androgen Index</li>
                            </ul>
                        </div>
                    </>
                ),
                codes: ['RP7']
            },
            {
                title: 'Testosterone Monitoring',
                body: 'A good test to monitor the levels of testosterone in your blood.',
                price: 55,
                learnMore: (
                    <>
                        <div className="text-center">
                            <p className="mt-4 text-center text-lg text-nw-offBlack">Markers tested include:</p>
                            <ul className="mt-2 grid list-disc place-items-center px-4 text-nw-darkGrey">
                                <li>Testosterone</li>
                                <li>Sex Hormone Binding Globulin</li>
                                <li>Free Androgen Index</li>
                                <li>Free Testosterone</li>
                                <li>Albumin</li>
                            </ul>
                        </div>
                    </>
                ),
                codes: ['RP17']
            },
            {
                title: 'Stress Markers',
                body: 'A comprehensive analysis of stress biomarkers.',
                price: 55,
                learnMore: (
                    <>
                        <div className="text-center">
                            <p className="mt-4 text-center text-lg text-nw-offBlack">Markers tested include:</p>
                            <ul className="mt-2 grid list-disc place-items-center px-4 text-nw-darkGrey">
                                <li>Cortisol</li>
                                <li>DHEA-S</li>
                            </ul>
                        </div>
                    </>
                ),
                codes: ['RP8']
            },
            {
                title: 'Diabetes Status',
                body: 'A comprehensive analysis of blood biomarkers relating to diabetes.',
                price: 55,
                learnMore: (
                    <>
                        <div className="text-center">
                            <p className="mt-4 text-center text-lg text-nw-offBlack">Markers tested include:</p>
                            <ul className="mt-2 grid list-disc place-items-center px-4 text-nw-darkGrey">
                                <li>Glucose</li>
                                <li>HbA1c</li>
                                <li>Insulin</li>
                            </ul>
                        </div>
                    </>
                ),
                codes: ['RP6']
            },
            {
                title: 'Advanced Thyroid & Hormone Health',
                body: 'A more advanced analysis of blood biomarkers relating to thyroid and hormone health.',
                price: 110,
                learnMore: (
                    <>
                        <div className="text-center">
                            <p className="mt-4 text-center text-lg text-nw-offBlack">Markers tested include:</p>
                            <ul className="mt-2 grid list-disc place-items-center px-4 text-nw-darkGrey">
                                <li>Thyroid Stimulating Hormone</li>
                                <li>Free Tri-iodothyronine</li>
                                <li>Free Thyroxine</li>
                                <li>Anti-Thyroglobulin Antibody</li>
                                <li>Anti-Thyroid Peroxidase Antibody</li>
                                <li>Oestradiol </li>
                                <li>Follicle Stimulating Hormone</li>
                                <li>Luteinising Hormone</li>
                                <li>Progesterone</li>
                                <li>Prolactin</li>
                                <li>Testosterone</li>
                                <li>Sex Hormone Binding Globulin</li>
                                <li>Free Androgen Index</li>
                            </ul>
                        </div>
                    </>
                ),
                codes: ['RP22']
            }
        ],
        'Diabetes Health': [
            {
                title: 'Diabetes Status',
                body: 'A comprehensive analysis of blood biomarkers relating to diabetes.',
                price: 55,
                learnMore: (
                    <>
                        <div className="text-center">
                            <p className="mt-4 text-center text-lg text-nw-offBlack">Markers tested include:</p>
                            <ul className="mt-2 grid list-disc place-items-center px-4 text-nw-darkGrey">
                                <li>Glucose</li>
                                <li>HbA1c</li>
                                <li>Insulin</li>
                            </ul>
                        </div>
                    </>
                ),
                codes: ['RP6']
            }
        ],
        'Nutrition Health': [
            {
                title: 'Vitamins & Minerals',
                body: 'A comprehensive analysis of blood biomarkers relating vitamin and mineral status.',
                price: 85,
                learnMore: (
                    <>
                        <div className="text-center">
                            <p className="mt-4 text-center text-lg text-nw-offBlack">Markers tested include:</p>
                            <ul className="mt-2 grid list-disc place-items-center px-4 text-nw-darkGrey">
                                <li>Iron</li>
                                <li>Ferritin</li>
                                <li>Total Iron Binding Capacity</li>
                                <li>Transferrin</li>
                                <li>Transferrin Saturation</li>
                                <li>Albumin</li>
                                <li>Calcium (Adjusted)</li>
                                <li>Magnesium</li>
                                <li>Iron</li>
                                <li>Folic acid</li>
                                <li>Vitamin B12</li>
                                <li>Zinc</li>
                                <li>Vitamin D</li>
                            </ul>
                        </div>
                    </>
                ),
                codes: ['RP5']
            },
            {
                title: 'Lipid Profile',
                body: 'A comprehensive analysis of blood lipids and cholesterol.',
                price: 45,
                learnMore: (
                    <>
                        <div className="text-center">
                            <p className="mt-4 text-center text-lg text-nw-offBlack">Markers tested include:</p>
                            <ul className="mt-2 grid list-disc place-items-center px-4 text-nw-darkGrey">
                                <li>Total Cholesterol</li>
                                <li>LDL Cholesterol</li>
                                <li>HDL Cholesterol</li>
                                <li>Total Cholesterol/HDL Cholesterol Ratio</li>
                                <li>Triglycerides</li>
                            </ul>
                        </div>
                    </>
                ),
                codes: ['XLIPIDS']
            },
            {
                title: 'Digestive Health (Stomach Cancer Risk & Coeliac Disease)',
                body: 'Test for indicators of stomach cancer risk and coeliac disease with H.Pylori and anti TTG antibodies.',
                price: 85,
                learnMore: (
                    <>
                        <div className="text-center">
                            <p className="mt-4 text-center text-lg text-nw-offBlack">Markers tested include:</p>
                            <ul className="mt-2 grid list-disc place-items-center px-4 text-nw-darkGrey">
                                <li>H.Pylori</li>
                                <li>Anti Tissue Transglutaminase Antibodies</li>
                            </ul>
                        </div>
                    </>
                ),
                codes: ['RP16']
            }
        ],
        'Digestive Health': [
            {
                title: 'Digestive Health (Stomach Cancer Risk & Coeliac Disease)',
                body: 'Test for indicators of stomach cancer risk and coeliac disease with H.Pylori and anti TTG antibodies.',
                price: 85,
                learnMore: (
                    <>
                        <div className="text-center">
                            <p className="mt-4 text-center text-lg text-nw-offBlack">Markers tested include:</p>
                            <ul className="mt-2 grid list-disc place-items-center px-4 text-nw-darkGrey">
                                <li>H.Pylori</li>
                                <li>Anti Tissue Transglutaminase Antibodies</li>
                            </ul>
                        </div>
                    </>
                ),
                codes: ['RP16']
            }
        ],
        'Thyroid Health': [
            {
                title: 'Advanced Thyroid Health',
                body: 'A more advanced analysis of blood biomarkers relating to thyroid health.',
                price: 55,
                learnMore: (
                    <>
                        <div className="text-center">
                            <p className="mt-4 text-center text-lg text-nw-offBlack">Markers tested include:</p>
                            <ul className="mt-2 grid list-disc place-items-center px-4 text-nw-darkGrey">
                                <li>Thyroid Stimulating Hormone</li>
                                <li>Free Tri-iodothyronine</li>
                                <li>Free Thyroxine</li>
                                <li>Anti-Thyroglobulin Antibody</li>
                                <li>Anti-Thyroid Peroxidase Antibody</li>
                            </ul>
                        </div>
                    </>
                ),
                codes: ['RP4']
            },
            {
                title: 'Advanced Thyroid & Hormone Health',
                body: 'A more advanced analysis of blood biomarkers relating to thyroid and hormone health.',
                price: 110,
                learnMore: (
                    <>
                        <div className="text-center">
                            <p className="mt-4 text-center text-lg text-nw-offBlack">Markers tested include:</p>
                            <ul className="mt-2 grid list-disc place-items-center px-4 text-nw-darkGrey">
                                <li>Thyroid Stimulating Hormone</li>
                                <li>Free Tri-iodothyronine</li>
                                <li>Free Thyroxine</li>
                                <li>Anti-Thyroglobulin Antibody</li>
                                <li>Anti-Thyroid Peroxidase Antibody</li>
                                <li>Oestradiol </li>
                                <li>Follicle Stimulating Hormone</li>
                                <li>Luteinising Hormone</li>
                                <li>Progesterone</li>
                                <li>Prolactin</li>
                                <li>Testosterone</li>
                                <li>Sex Hormone Binding Globulin</li>
                                <li>Free Androgen Index</li>
                            </ul>
                        </div>
                    </>
                ),
                codes: ['RP22']
            }
        ],
        'Heart Health': [
            {
                title: 'Advanced Heart Health',
                body: 'A more advanced analysis of blood biomarkers relating to heart health.',
                price: 85,
                learnMore: (
                    <>
                        <div className="text-center">
                            <p className="mt-4 text-center text-lg text-nw-offBlack">Markers tested include:</p>
                            <ul className="mt-2 grid list-disc place-items-center px-4 text-nw-darkGrey">
                                <li>Total Cholesterol</li>
                                <li>LDL Cholesterol</li>
                                <li>HDL Cholesterol</li>
                                <li>Total Cholesterol / HDL Cholesterol Ratio</li>
                                <li>Triglycerides</li>
                                <li>Apolipoprotein A-I</li>
                                <li>Apolipoprotein B</li>
                                <li>Apolipoprotein B / A-I Ratio</li>
                                <li>Apolipoprotein CII</li>
                                <li>Apolipoprotein CIII</li>
                                <li>Apolipoprotein E</li>
                                <li>Small LDL Cholesterol</li>
                                <li>Lipoprotein (a)</li>
                                <li>High Sensitivity C-Reactive Protein</li>
                            </ul>
                        </div>
                    </>
                ),
                codes: ['RP10']
            },
            {
                title: 'Heart, Diabetes and Advanced Metabolic Health',
                body: 'A comprehensive analysis of your metabolic health including heart and diabetes health.',
                price: 110,
                learnMore: (
                    <>
                        <div className="text-center">
                            <p className="mt-4 text-center text-lg text-nw-offBlack">Markers tested include:</p>
                            <p className="mt-4 px-10 text-center text-xl font-medium text-nw-offBlack">Heart Health</p>
                            <ul className="mx-auto mt-2 grid list-disc place-items-center px-10 text-nw-darkGrey">
                                <li>Total Cholesterol</li>
                                <li>LDL Cholesterol</li>
                                <li>HDL Cholesterol</li>
                                <li>Total Cholesterol / HDL</li>
                                <li>Cholesterol Ratio</li>
                                <li>Triglycerides</li>
                                <li>Apolipoprotein A-I</li>
                                <li>Apolipoprotein B</li>
                                <li>Apolipoprotein B / A-I Ratio</li>
                                <li>Apolipoprotein CII</li>
                                <li>Apolipoprotein CIII</li>
                                <li>Apolipoprotein E</li>
                                <li>Small LDL Cholesterol</li>
                                <li>Lipoprotein (a)</li>
                                <li>High Sensitivity</li>
                                <li>C-Reactive Protein</li>
                            </ul>
                            <p className="mt-4 px-10 text-center text-xl font-medium text-nw-offBlack">
                                Metabolic Health
                            </p>
                            <ul className="mx-auto mt-2 grid list-disc place-items-center px-10 text-nw-darkGrey">
                                <li>Glucose</li>
                                <li>HDL Cholesterol</li>
                                <li>Triglycerides</li>
                                <li>HbA1c</li>
                                <li>Insulin</li>
                                <li>C-peptide</li>
                                <li>Leptin</li>
                                <li>Adiponectin</li>
                                <li>Resistin</li>
                                <li>High Sensitivity</li>
                                <li>C-Reactive Protein</li>
                            </ul>
                            <p className="mt-4 px-10 text-center text-xl font-medium text-nw-offBlack">
                                Diabetes Health
                            </p>
                            <ul className="mx-auto mt-2 grid list-disc place-items-center px-10 text-nw-darkGrey">
                                <li>Glucose</li>
                                <li>HbA1c</li>
                                <li>Insulin</li>
                                <li>C-peptide</li>
                            </ul>
                        </div>
                    </>
                ),
                codes: ['RP3']
            }
        ],
        'Iron Status': [
            {
                title: 'Iron Status',
                body: 'A comprehensive analysis of blood iron status.',
                price: 45,
                learnMore: (
                    <>
                        <div className="text-center">
                            <p className="mt-4 text-center text-lg text-nw-offBlack">Markers tested include:</p>
                            <ul className="mt-2 grid list-disc place-items-center px-4 text-nw-darkGrey">
                                <li>Iron</li>
                                <li>Ferritin</li>
                                <li>Total Iron Binding Capacity</li>
                                <li>Transferrin</li>
                                <li>Transferrin Saturation</li>
                            </ul>
                        </div>
                    </>
                ),
                codes: ['RP9']
            }
        ],
        'Liver Health': [
            {
                title: 'Liver Health',
                body: 'A comprehensive analysis of liver function.',
                price: 35,
                learnMore: (
                    <>
                        <div className="text-center">
                            <p className="mt-4 text-center text-lg text-nw-offBlack">Markers tested include:</p>
                            <ul className="mt-2 grid list-disc place-items-center px-4 text-nw-darkGrey">
                                <li>Alanine Aminotransferase</li>
                                <li>Alkaline Phosphatase</li>
                                <li>Aspartate Aminotransferase</li>
                                <li>Gamma- glutamyltransferase</li>
                                <li>Total Bilirubin</li>
                                <li>Albumin</li>
                                <li>Ferritin</li>
                            </ul>
                        </div>
                    </>
                ),
                codes: ['RP14']
            }
        ],
        'Pancreatic Health': [
            {
                title: 'Pancreatic Health',
                body: 'A comprehensive analysis of blood biomarkers relating to pancreatic health.',
                price: 50,
                learnMore: (
                    <>
                        <div className="text-center">
                            <p className="mt-4 text-center text-lg text-nw-offBlack">Markers tested include:</p>
                            <ul className="mt-2 grid list-disc place-items-center px-4 text-nw-darkGrey">
                                <li>Pancreatic Amylase</li>
                                <li>Lipase</li>
                            </ul>
                        </div>
                    </>
                ),
                codes: ['RP15']
            }
        ],
        'Sexual Health Screening': [
            {
                title: 'STI 10',
                body: 'Simultaneously detect 10 viral, bacterial and protozoan STIs from either a urine or lesion-based swab.',
                price: 95,
                learnMore: (
                    <>
                        <div className="text-center">
                            <p className="mt-4 text-center text-lg text-nw-offBlack">Markers tested include:</p>
                            <ul className="mt-2 grid list-disc place-items-center px-4 text-nw-darkGrey">
                                <li>Chlamydia trachomatis</li>
                                <li>Neisseria gonorrhoeae</li>
                                <li>Syphilis (Presence in lesion or urine)</li>
                                <li>Herpes Simplex I</li>
                                <li>Herpes Simplex II</li>
                                <li>Trichomonas Vaginalis</li>
                                <li>Mycoplasma Hominis</li>
                                <li>Mycoplasma Genitalium</li>
                                <li>Ureaplasma Urelyticum</li>
                                <li>Chancroid</li>
                            </ul>
                        </div>
                    </>
                ),
                codes: ['STI_10PLEX_Q_U']
            },
            {
                title: 'Chlamydia + Gonorrhoeae',
                body: 'PCR-based Screen for two of the most common STIs; Chlamydia trachomatis and Neisseria gonorrhoeae.',
                price: 65,
                learnMore: (
                    <>
                        <div className="text-center">
                            <p className="mt-4 text-center text-lg text-nw-offBlack">Markers tested include:</p>
                            <ul className="mt-2 grid list-disc place-items-center px-4 text-nw-darkGrey">
                                <li>Chlamydia trachomatis</li>
                                <li>Neisseria gonorrhoeae</li>
                            </ul>
                        </div>
                    </>
                ),
                codes: ['STI_CTNG_U']
            },
            {
                title: 'STI Blood Screen',
                body: 'Screen for multiple blood borne pathogens with our STI blood profile.',
                price: 85,
                learnMore: (
                    <>
                        <div className="text-center">
                            <p className="mt-4 text-center text-lg text-nw-offBlack">Markers tested include:</p>
                            <ul className="mt-2 grid list-disc place-items-center px-4 text-nw-darkGrey">
                                <li>HIV1&2/p24Ag</li>
                                <li>Hepatitis B Surface Antigen</li>
                                <li>Hepatitis C Antibodies</li>
                                <li>Syphilis Antibodies (IgG/IgM)</li>
                            </ul>
                        </div>
                    </>
                ),
                codes: ['STI_BLOOD']
            },
            {
                title: 'STI Complete',
                body: "A complete screen of Neuwell's entire 14 pathogen STI portfolio, comprising 10 urine or swab pathogens and 4 blood-borne viruses.",
                price: 135,
                learnMore: (
                    <>
                        <div className="text-center">
                            <p className="mt-4 text-center text-lg text-nw-offBlack">Markers tested include:</p>
                            <ul className="mt-2 grid list-disc place-items-center px-4 text-nw-darkGrey">
                                <li>Chlamydia trachomatis</li>
                                <li>Neisseria gonorrhoeae</li>
                                <li>Syphilis (Presence in lesion or urine)</li>
                                <li>Herpes Simplex I</li>
                                <li>Herpes Simplex II</li>
                                <li>Trichomonas Vaginalis</li>
                                <li>Mycoplasma Hominis</li>
                                <li>Mycoplasma Genitalium</li>
                                <li>Ureaplasma Urelyticum</li>
                                <li>Chancroid</li>
                                <li>HIV1&2/p24Ag</li>
                                <li>Hepatitis B Surface Antigen</li>
                                <li>Hepatitis C Antibodies</li>
                                <li>Syphilis Antibodies (IgG/IgM)</li>
                            </ul>
                        </div>
                    </>
                ),
                codes: ['STI_COMPLETE_U']
            },
            {
                title: 'HIV Test',
                body: 'An HIV Blood test.',
                price: 35,
                learnMore: (
                    <>
                        <div className="text-center">
                            <p className="mt-4 text-center text-lg text-nw-offBlack">Markers tested include:</p>
                            <ul className="mt-2 grid list-disc place-items-center px-4 text-nw-darkGrey">
                                <li>HIV1&2/p24Ag</li>
                            </ul>
                        </div>
                    </>
                ),
                codes: ['HIVDUO']
            },
            {
                title: 'STI Comprehensive',
                body: 'A comprehensive panel simultaneously detecting 6 sexually transmitted pathogens by serology and urine/swab.',
                price: 100,
                learnMore: (
                    <>
                        <div className="text-center">
                            <p className="mt-4 text-center text-lg text-nw-offBlack">Markers tested include:</p>
                            <ul className="mt-2 grid list-disc place-items-center px-4 text-nw-darkGrey">
                                <li>Chlamydia trachomatis</li>
                                <li>Neisseria gonorrhoeae</li>
                                <li>HIV1&2/p24Ag</li>
                                <li>Hepatitis B Surface Antigen</li>
                                <li>Hepatitis C Antibodies</li>
                                <li>Syphilis Antibodies (IgG/IgM)</li>
                            </ul>
                        </div>
                    </>
                ),
                codes: ['STI_COMPREHESIVE_U']
            }
        ],
        'Cancer Screening': [
            {
                title: 'Ovarian Cancer Screen (Cancer Antigen - 125)',
                body: 'Cancer Antigen - 125',
                price: 50,
                learnMore: (
                    <>
                        <div className="text-center">
                            <p className="mt-4 px-4 text-nw-darkGrey">
                                CA 125, or cancer antigen 125, is a protein that is often found at elevated levels in
                                the blood of individuals with certain types of cancers, particularly ovarian cancer.
                            </p>
                        </div>
                    </>
                ),
                codes: ['CA_125']
            },
            {
                title: 'Breast Cancer Screening (Cancer Antigen 15-3)',
                body: 'Cancer Antigen 15-3',
                price: 50,
                learnMore: (
                    <>
                        <div className="text-center">
                            <p className="mt-4 px-4 text-nw-darkGrey">
                                CA 15.3, or cancer antigen 15-3, is a protein that is often found at elevated levels in
                                the blood of individuals with certain types of cancers, particularly Breast Cancer.
                            </p>
                        </div>
                    </>
                ),
                codes: ['CA_15_3']
            },
            {
                title: 'Pancreatic Cancer Screening (Cancer Antigen 19-9)',
                body: 'Cancer Antigen 19-9',
                price: 60,
                learnMore: (
                    <>
                        <div className="text-center">
                            <p className="mt-4 px-4 text-nw-darkGrey">
                                CA 19.9, or cancer antigen 19-9, is a protein that is often found at elevated levels in
                                the blood of individuals with certain types of cancers, particularly Pancreatic Cancer.
                            </p>
                        </div>
                    </>
                ),
                codes: ['CA_19_9']
            },
            {
                title: 'Bowel Cancer Screening (qFIT)',
                body: 'Quantitative Faecal Immunochemical Test (qFIT)',
                price: 45,
                learnMore: (
                    <>
                        <div className="text-center">
                            <p className="mt-4 px-4 text-nw-darkGrey">
                                qFIT testing test for miscroscopic levels of blood within your stools. The presence of
                                this can indicate a risk of bowel cancer.
                            </p>
                        </div>
                    </>
                ),
                codes: ['FOB_FIT_STOOL']
            },
            {
                title: 'Stomach Cancer Risk (H-Pylori)',
                body: 'H-Pylori Antibodies',
                price: 45,
                learnMore: (
                    <>
                        <div className="text-center">
                            <p className="mt-4 px-4 text-nw-darkGrey">
                                H.Pylori antibodies indictate the presence of H.pylori within the stomach which is
                                associated with an incresed risk of stomach cancer.
                            </p>
                        </div>
                    </>
                ),
                codes: ['HPYL']
            },
            {
                title: 'Prostate Cancer Screen (PSA)',
                body: 'Prostate Specific Antigen',
                price: 45,
                learnMore: (
                    <>
                        <div className="text-center">
                            <p className="mt-4 px-4 text-nw-darkGrey">
                                PSA testing is commonly used as a screening tool for prostate cancer. The PSA test
                                measures the level of prostate-specific antigen in the blood. Elevated levels of PSA can
                                be associated with various conditions, including Prostate Cancer.
                            </p>
                        </div>
                    </>
                ),
                codes: ['TPSA']
            }
        ],
        'Prescription Weight Loss': [
            {
                title: 'Initial Prescription Weight Loss Programme Consultation',
                body: 'Combine your health check with a Presciption Weightloss consultation.',
                price: 0,
                learnMore: (
                    <>
                        <div className="text-center">
                            <p className="mt-4 list-disc place-items-center px-4 text-nw-darkGrey">
                                To get started with our prescription weight loss programme you need an initial
                                consultation with one of our doctors. You can combine with your health check free of
                                charge to see if you are a suitable candidate.
                            </p>
                        </div>
                    </>
                ),
                codes: []
            }
        ],
        'Bioniq Supplements': [
            {
                title: 'Bioniq (Male)',
                body: 'Initial Blood Test + 3 Months Personalised Supplement Supply (Male)',
                price: 600,
                learnMore: <></>,
                codes: []
            },
            {
                title: 'Bioniq (Female)',
                body: 'Initial Blood Test + 3 Months Personalised Supplement Supply (Female)',
                price: 600,
                learnMore: <></>,
                codes: []
            }
        ],
        'TRT Consultation': [
            {
                title: 'Initial Testosterone Replacement Blood Test + Consultation',
                body: 'Combine your health check with an initial testosterone replacement therapy consultation and blood test.',
                price: 35,
                learnMore: (
                    <>
                        <div className="text-center">
                            <p className="mt-4 list-disc place-items-center px-4 text-nw-darkGrey">
                                To get started with testosterone replacement therapy you require an initial blood test
                                for levels of testoterone in your blood including total testosterone, free testoterone,
                                SHGB & Albumin.
                            </p>
                        </div>
                    </>
                ),
                codes: ['RP17']
            }
        ],
        'TruCheck™': [
            {
                title: 'TruCheck™ Bowel Cancer Screening',
                body: 'TruCheck™ Bowel Cancer screening test is a revolutionary new blood test for the early detection of bowel cancer, even before it is visible on scans and x-rays.',
                price: 449,
                learnMore: (
                    <>
                        <div className="text-center">
                            <ul className="mt-2 grid list-disc place-items-center px-4 text-nw-darkGrey">
                                <li>Screen for possible cancer years in advance</li>
                                <li>In clinic medical consultation & doctors report</li>
                                <li>Option for home blood collection</li>
                            </ul>
                        </div>
                    </>
                ),
                codes: []
            },
            {
                title: 'TruCheck™ Prostate Cancer Screening',
                body: 'TruCheck™ Prostate Cancer screening test is a revolutionary new blood test for the early detection of prostate cancer, even before it is visible on scans and x-rays.',
                price: 449,
                learnMore: (
                    <>
                        <div className="text-center">
                            <ul className="mt-2 grid list-disc place-items-center px-4 text-nw-darkGrey">
                                <li>Screen for possible cancer years in advance</li>
                                <li>In clinic medical consultation & doctors report</li>
                                <li>Option for home blood collection</li>
                            </ul>
                        </div>
                    </>
                ),
                codes: []
            },
            {
                title: 'TruCheck™ Breast Cancer Screening',
                body: 'TruCheck™ Breast Cancer screening test is a revolutionary new blood test for the early detection of breast cancer, even before it is visible on scans and x-rays.',
                price: 449,
                learnMore: (
                    <>
                        <div className="text-center">
                            <ul className="mt-2 grid list-disc place-items-center px-4 text-nw-darkGrey">
                                <li>Screen for possible cancer years in advance</li>
                                <li>In clinic medical consultation & doctors report</li>
                                <li>Option for home blood collection</li>
                            </ul>
                        </div>
                    </>
                ),
                codes: []
            },
            {
                title: 'TruCheck™ Intelli Cancer Screening',
                body: 'TruCheck™ is the most effective & convenient diagnostic blood test available to distinguish up to 70 types of solid cancer tumours.',
                price: 1199,
                learnMore: (
                    <>
                        <div className="text-center">
                            <ul className="mt-2 grid list-disc place-items-center px-4 text-nw-darkGrey">
                                <li>Screen for possible cancer years in advance</li>
                                <li>In clinic medical consultation & doctors report</li>
                                <li>Option for home blood collection</li>
                            </ul>
                        </div>
                    </>
                ),
                codes: []
            },
            {
                title: 'TruCheck™ Female Cancer Screening',
                body: 'TruCheck™ is the most effective & convenient diagnostic blood test available to distinguish several types of solid cancer tumours which commonly affect females.',
                price: 779,
                learnMore: (
                    <>
                        <div className="text-center">
                            <ul className="mt-2 grid list-disc place-items-center px-4 text-nw-darkGrey">
                                <li>Screen for possible cancer years in advance</li>
                                <li>In clinic medical consultation & doctors report</li>
                                <li>Option for home blood collection</li>
                            </ul>
                        </div>
                    </>
                ),
                codes: []
            }
        ],
        'Prenatal Safe': [
            {
                title: 'Gender Reveal Test',
                body: 'Prenatal gender determination with non invasive blood test.',
                price: 165,
                learnMore: (
                    <>
                        <div className="text-center">
                            <ul className="mt-2 grid list-disc place-items-center px-4 text-nw-darkGrey">
                                <li>Suitable from 10 week gestation</li>
                                <li>In clinic medical consultation & doctors report</li>
                            </ul>
                        </div>
                    </>
                ),
                codes: []
            },
            {
                title: 'Prenatal SAFE 3 +/- Gender',
                body: 'Prenantal SAFE 3 is a non invasive test that enables the analysis of Fetal genetic material from a routine blood sample.',
                price: 315,
                learnMore: (
                    <>
                        <div className="text-center">
                            <ul className="mt-2 grid list-disc place-items-center px-4 text-nw-darkGrey">
                                <li>Fetal sex</li>
                                <li>Trisomy 21 (Downs Syndrome)</li>
                                <li>Trisomy 18 (Edwards Syndrome)</li>
                                <li>Trisomy 13 (Patau Syndrome)</li>
                            </ul>
                        </div>
                    </>
                ),
                codes: []
            },
            {
                title: 'Prenatal SAFE 5 +/- Gender',
                body: 'Prenantal SAFE 5 is a non invasive test that enables the analysis of Fetal genetic material from a routine blood sample.',
                price: 375,
                learnMore: (
                    <>
                        <div className="text-center">
                            <ul className="mt-2 grid list-disc place-items-center px-4 text-nw-darkGrey">
                                <li>Fetal sex</li>
                                <li>Trisomy 21 (Downs Syndrome)</li>
                                <li>Trisomy 18 (Edwards Syndrome)</li>
                                <li>Trisomy 13 (Patau Syndrome)</li>
                                <li>Sex Chromosome Anueploidies</li>
                            </ul>
                        </div>
                    </>
                ),
                codes: []
            },
            {
                title: 'Prenatal SAFE Karyo',
                body: 'Prenantal SAFE Karyo is a non invasive test that enables the analysis of Fetal genetic material from a routine blood sample.',
                price: 645,
                learnMore: (
                    <>
                        <div className="text-center">
                            <ul className="mt-2 grid list-disc place-items-center px-4 text-nw-darkGrey">
                                <li>Fetal sex</li>
                                <li>Trisomy 21 (Downs Syndrome)</li>
                                <li>Trisomy 18 (Edwards Syndrome)</li>
                                <li>Trisomy 13 (Patau Syndrome)</li>
                                <li>Sex Chromosome Anueploidies</li>
                                <li>Rare Autosomal Aneuploidies</li>
                                <li>Deletions & Duplications</li>
                            </ul>
                        </div>
                    </>
                ),
                codes: []
            },
            {
                title: 'Prenatal SAFE Complete Plus',
                body: 'Prenantal SAFE complete plus is a non invasive test that enables the analysis of Fetal genetic material from a routine blood sample.',
                price: 1545,
                learnMore: (
                    <>
                        <div className="text-center">
                            <ul className="mt-2 grid list-disc place-items-center px-4 text-nw-darkGrey">
                                <li>Fetal sex</li>
                                <li>Trisomy 21 (Downs Syndrome)</li>
                                <li>Trisomy 18 (Edwards Syndrome)</li>
                                <li>Trisomy 13 (Patau Syndrome)</li>
                                <li>Sex Chromosome Anueploidies</li>
                                <li>Rare Autosomal Aneuploidies</li>
                                <li>Deletions & Duplications</li>
                                <li>Microdeletions</li>
                                <li>Inherited Genetic Diseases</li>
                                <li>De novo Genetic Diseases</li>
                            </ul>
                        </div>
                    </>
                ),
                codes: []
            }
        ],
        'Onconext Risk®': [
            {
                title: 'Onconext Risk® BRCA',
                body: 'Onconext testing analyses multiple genes for the detection of possible predisposition to hereditary cancers.',
                price: 749,
                learnMore: <></>,
                codes: []
            },
            {
                title: 'Onconext Risk® Breast',
                body: 'Onconext testing analyses multiple genes for the detection of possible predisposition to hereditary cancers.',
                price: 799,
                learnMore: <></>,
                codes: []
            },
            {
                title: 'Onconext Risk® Ovarian',
                body: 'Onconext testing analyses multiple genes for the detection of possible predisposition to hereditary cancers.',
                price: 799,
                learnMore: <></>,
                codes: []
            },
            {
                title: 'Onconext Risk® Colon',
                body: 'Onconext testing analyses multiple genes for the detection of possible predisposition to hereditary cancers.',
                price: 799,
                learnMore: <></>,
                codes: []
            },
            {
                title: 'Onconext Risk® Cerebral',
                body: 'Onconext testing analyses multiple genes for the detection of possible predisposition to hereditary cancers.',
                price: 799,
                learnMore: <></>,
                codes: []
            },
            {
                title: 'Onconext Risk® Prostate',
                body: 'Onconext testing analyses multiple genes for the detection of possible predisposition to hereditary cancers.',
                price: 799,
                learnMore: <></>,
                codes: []
            },
            {
                title: 'Onconext Risk® Renal',
                body: 'Onconext testing analyses multiple genes for the detection of possible predisposition to hereditary cancers.',
                price: 799,
                learnMore: <></>,
                codes: []
            },
            {
                title: 'Onconext Risk® Melanoma',
                body: 'Onconext testing analyses multiple genes for the detection of possible predisposition to hereditary cancers.',
                price: 799,
                learnMore: <></>,
                codes: []
            },
            {
                title: 'Onconext Risk® Gastric',
                body: 'Onconext testing analyses multiple genes for the detection of possible predisposition to hereditary cancers.',
                price: 799,
                learnMore: <></>,
                codes: []
            },
            {
                title: 'Onconext Risk® Pancreas',
                body: 'Onconext testing analyses multiple genes for the detection of possible predisposition to hereditary cancers.',
                price: 799,
                learnMore: <></>,
                codes: []
            },
            {
                title: 'Onconext Risk® Oncoscreening Complete',
                body: 'Onconext testing analyses multiple genes for the detection of possible predisposition to hereditary cancers.',
                price: 1199,
                learnMore: <></>,
                codes: []
            }
        ]
    };

    const inuviBiomarkers = {
        'Full Blood Count': [
            {
                title: 'FBC',
                body: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. In magna dui, dignissim sit amet est ac, suscipit efficitur ex.',
                price: 15,
                learnMore: (
                    <>
                        <div className="text-center">
                            <p className="text-2xl text-nw-offBlack">FBC</p>
                            <p className="mt-4 text-nw-offBlack">
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit. In magna dui, dignissim sit
                                amet est ac, suscipit efficitur ex. Aliquam convallis sodales justo, commodo congue urna
                                tristique vitae. In eu pellentesque nibh. Vestibulum et euismod tellus. Duis sit amet
                                lectus lacus. Fusce mi urna, aliquam sed ultrices sed, ornare a lorem. Suspendisse
                                gravida elementum sem eu imperdiet. <br></br>
                                <br></br>Ut auctor elit in augue hendrerit iaculis. Sed posuere hendrerit nisi, a
                                fermentum nibh facilisis id. Suspendisse nisl purus, ultricies vitae sem eget, interdum
                                bibendum tellus. Nulla ut nulla non neque eleifend pellentesque.
                            </p>
                        </div>
                    </>
                )
            }
        ],
        'Nutrition Health': [
            {
                title: 'Active B12',
                body: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
                price: 12,
                learnMore: (
                    <>
                        <div className="text-center">
                            <p className="text-2xl text-nw-offBlack">Active B12</p>
                            <p className="mt-4 text-nw-offBlack">
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit. In magna dui, dignissim sit
                                amet est ac, suscipit efficitur ex. Aliquam convallis sodales justo, commodo congue urna
                                tristique vitae. In eu pellentesque nibh. Vestibulum et euismod tellus. Duis sit amet
                                lectus lacus. Fusce mi urna, aliquam sed ultrices sed, ornare a lorem. Suspendisse
                                gravida elementum sem eu imperdiet. <br></br>
                                <br></br>Ut auctor elit in augue hendrerit iaculis. Sed posuere hendrerit nisi, a
                                fermentum nibh facilisis id. Suspendisse nisl purus, ultricies vitae sem eget, interdum
                                bibendum tellus. Nulla ut nulla non neque eleifend pellentesque.
                            </p>
                        </div>
                    </>
                )
            },
            {
                title: 'Albumin',
                body: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
                price: 6,
                learnMore: (
                    <>
                        <div className="text-center">
                            <p className="text-2xl text-nw-offBlack">Albumin</p>
                            <p className="mt-4 text-nw-offBlack">
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit. In magna dui, dignissim sit
                                amet est ac, suscipit efficitur ex. Aliquam convallis sodales justo, commodo congue urna
                                tristique vitae. In eu pellentesque nibh. Vestibulum et euismod tellus. Duis sit amet
                                lectus lacus. Fusce mi urna, aliquam sed ultrices sed, ornare a lorem. Suspendisse
                                gravida elementum sem eu imperdiet. <br></br>
                                <br></br>Ut auctor elit in augue hendrerit iaculis. Sed posuere hendrerit nisi, a
                                fermentum nibh facilisis id. Suspendisse nisl purus, ultricies vitae sem eget, interdum
                                bibendum tellus. Nulla ut nulla non neque eleifend pellentesque.
                            </p>
                        </div>
                    </>
                )
            },
            {
                title: 'Folate',
                body: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
                price: 6,
                learnMore: (
                    <>
                        <div className="text-center">
                            <p className="text-2xl text-nw-offBlack">Folate</p>
                            <p className="mt-4 text-nw-offBlack">
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit. In magna dui, dignissim sit
                                amet est ac, suscipit efficitur ex. Aliquam convallis sodales justo, commodo congue urna
                                tristique vitae. In eu pellentesque nibh. Vestibulum et euismod tellus. Duis sit amet
                                lectus lacus. Fusce mi urna, aliquam sed ultrices sed, ornare a lorem. Suspendisse
                                gravida elementum sem eu imperdiet. <br></br>
                                <br></br>Ut auctor elit in augue hendrerit iaculis. Sed posuere hendrerit nisi, a
                                fermentum nibh facilisis id. Suspendisse nisl purus, ultricies vitae sem eget, interdum
                                bibendum tellus. Nulla ut nulla non neque eleifend pellentesque.
                            </p>
                        </div>
                    </>
                )
            },
            {
                title: 'Magnesium',
                body: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
                price: 6,
                learnMore: (
                    <>
                        <div className="text-center">
                            <p className="text-2xl text-nw-offBlack">Magnesium</p>
                            <p className="mt-4 text-nw-offBlack">
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit. In magna dui, dignissim sit
                                amet est ac, suscipit efficitur ex. Aliquam convallis sodales justo, commodo congue urna
                                tristique vitae. In eu pellentesque nibh. Vestibulum et euismod tellus. Duis sit amet
                                lectus lacus. Fusce mi urna, aliquam sed ultrices sed, ornare a lorem. Suspendisse
                                gravida elementum sem eu imperdiet. <br></br>
                                <br></br>Ut auctor elit in augue hendrerit iaculis. Sed posuere hendrerit nisi, a
                                fermentum nibh facilisis id. Suspendisse nisl purus, ultricies vitae sem eget, interdum
                                bibendum tellus. Nulla ut nulla non neque eleifend pellentesque.
                            </p>
                        </div>
                    </>
                )
            },
            {
                title: 'Omega 3 & 6',
                body: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
                price: 70,
                learnMore: (
                    <>
                        <div className="text-center">
                            <p className="text-2xl text-nw-offBlack">Omega 3 & 6</p>
                            <p className="mt-4 text-nw-offBlack">
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit. In magna dui, dignissim sit
                                amet est ac, suscipit efficitur ex. Aliquam convallis sodales justo, commodo congue urna
                                tristique vitae. In eu pellentesque nibh. Vestibulum et euismod tellus. Duis sit amet
                                lectus lacus. Fusce mi urna, aliquam sed ultrices sed, ornare a lorem. Suspendisse
                                gravida elementum sem eu imperdiet. <br></br>
                                <br></br>Ut auctor elit in augue hendrerit iaculis. Sed posuere hendrerit nisi, a
                                fermentum nibh facilisis id. Suspendisse nisl purus, ultricies vitae sem eget, interdum
                                bibendum tellus. Nulla ut nulla non neque eleifend pellentesque.
                            </p>
                        </div>
                    </>
                )
            },
            {
                title: 'Vitamin D',
                body: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
                price: 12,
                learnMore: (
                    <>
                        <div className="text-center">
                            <p className="text-2xl text-nw-offBlack">Vitamin D</p>
                            <p className="mt-4 text-nw-offBlack">
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit. In magna dui, dignissim sit
                                amet est ac, suscipit efficitur ex. Aliquam convallis sodales justo, commodo congue urna
                                tristique vitae. In eu pellentesque nibh. Vestibulum et euismod tellus. Duis sit amet
                                lectus lacus. Fusce mi urna, aliquam sed ultrices sed, ornare a lorem. Suspendisse
                                gravida elementum sem eu imperdiet. <br></br>
                                <br></br>Ut auctor elit in augue hendrerit iaculis. Sed posuere hendrerit nisi, a
                                fermentum nibh facilisis id. Suspendisse nisl purus, ultricies vitae sem eget, interdum
                                bibendum tellus. Nulla ut nulla non neque eleifend pellentesque.
                            </p>
                        </div>
                    </>
                )
            }
        ],
        'Bone Health': [
            {
                title: 'Calcium',
                body: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. In magna dui.',
                price: 5,
                learnMore: (
                    <>
                        <div className="text-center">
                            <p className="text-2xl text-nw-offBlack">Calcium</p>
                            <p className="mt-4 text-nw-offBlack">
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit. In magna dui, dignissim sit
                                amet est ac, suscipit efficitur ex. Aliquam convallis sodales justo, commodo congue urna
                                tristique vitae. In eu pellentesque nibh. Vestibulum et euismod tellus. Duis sit amet
                                lectus lacus. Fusce mi urna, aliquam sed ultrices sed, ornare a lorem. Suspendisse
                                gravida elementum sem eu imperdiet. <br></br>
                                <br></br>Ut auctor elit in augue hendrerit iaculis. Sed posuere hendrerit nisi, a
                                fermentum nibh facilisis id. Suspendisse nisl purus, ultricies vitae sem eget, interdum
                                bibendum tellus. Nulla ut nulla non neque eleifend pellentesque.
                            </p>
                        </div>
                    </>
                )
            }
        ],
        'Cancer Screening': [
            {
                title: 'PSA',
                body: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. In magna dui.',
                price: 5,
                learnMore: (
                    <>
                        <div className="text-center">
                            <p className="text-2xl text-nw-offBlack">PSA</p>
                            <p className="mt-4 text-nw-offBlack">
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit. In magna dui, dignissim sit
                                amet est ac, suscipit efficitur ex. Aliquam convallis sodales justo, commodo congue urna
                                tristique vitae. In eu pellentesque nibh. Vestibulum et euismod tellus. Duis sit amet
                                lectus lacus. Fusce mi urna, aliquam sed ultrices sed, ornare a lorem. Suspendisse
                                gravida elementum sem eu imperdiet. <br></br>
                                <br></br>Ut auctor elit in augue hendrerit iaculis. Sed posuere hendrerit nisi, a
                                fermentum nibh facilisis id. Suspendisse nisl purus, ultricies vitae sem eget, interdum
                                bibendum tellus. Nulla ut nulla non neque eleifend pellentesque.
                            </p>
                        </div>
                    </>
                )
            }
        ],
        'Hormone Health': [
            {
                title: 'Female Hormone - FSH, LH, Oestradiol, prolactin',
                body: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. In magna dui.',
                price: 20,
                learnMore: (
                    <>
                        <div className="text-center">
                            <p className="text-2xl text-nw-offBlack">Female Hormone - FSH, LH, Oestradiol, prolactin</p>
                            <p className="mt-4 text-nw-offBlack">
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit. In magna dui, dignissim sit
                                amet est ac, suscipit efficitur ex. Aliquam convallis sodales justo, commodo congue urna
                                tristique vitae. In eu pellentesque nibh. Vestibulum et euismod tellus. Duis sit amet
                                lectus lacus. Fusce mi urna, aliquam sed ultrices sed, ornare a lorem. Suspendisse
                                gravida elementum sem eu imperdiet. <br></br>
                                <br></br>Ut auctor elit in augue hendrerit iaculis. Sed posuere hendrerit nisi, a
                                fermentum nibh facilisis id. Suspendisse nisl purus, ultricies vitae sem eget, interdum
                                bibendum tellus. Nulla ut nulla non neque eleifend pellentesque.
                            </p>
                        </div>
                    </>
                )
            },
            {
                title: 'Cortisol (9am)',
                body: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. In magna dui.',
                price: 6,
                learnMore: (
                    <>
                        <div className="text-center">
                            <p className="text-2xl text-nw-offBlack">Cortisol (9am)</p>
                            <p className="mt-4 text-nw-offBlack">
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit. In magna dui, dignissim sit
                                amet est ac, suscipit efficitur ex. Aliquam convallis sodales justo, commodo congue urna
                                tristique vitae. In eu pellentesque nibh. Vestibulum et euismod tellus. Duis sit amet
                                lectus lacus. Fusce mi urna, aliquam sed ultrices sed, ornare a lorem. Suspendisse
                                gravida elementum sem eu imperdiet. <br></br>
                                <br></br>Ut auctor elit in augue hendrerit iaculis. Sed posuere hendrerit nisi, a
                                fermentum nibh facilisis id. Suspendisse nisl purus, ultricies vitae sem eget, interdum
                                bibendum tellus. Nulla ut nulla non neque eleifend pellentesque.
                            </p>
                        </div>
                    </>
                )
            },
            {
                title: 'Free Androgen Index',
                body: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. In magna dui.',
                price: 12,
                learnMore: (
                    <>
                        <div className="text-center">
                            <p className="text-2xl text-nw-offBlack">Free Androgen Index</p>
                            <p className="mt-4 text-nw-offBlack">
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit. In magna dui, dignissim sit
                                amet est ac, suscipit efficitur ex. Aliquam convallis sodales justo, commodo congue urna
                                tristique vitae. In eu pellentesque nibh. Vestibulum et euismod tellus. Duis sit amet
                                lectus lacus. Fusce mi urna, aliquam sed ultrices sed, ornare a lorem. Suspendisse
                                gravida elementum sem eu imperdiet. <br></br>
                                <br></br>Ut auctor elit in augue hendrerit iaculis. Sed posuere hendrerit nisi, a
                                fermentum nibh facilisis id. Suspendisse nisl purus, ultricies vitae sem eget, interdum
                                bibendum tellus. Nulla ut nulla non neque eleifend pellentesque.
                            </p>
                        </div>
                    </>
                )
            },
            {
                title: 'Free testosterone',
                body: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. In magna dui.',
                price: 15,
                learnMore: (
                    <>
                        <div className="text-center">
                            <p className="text-2xl text-nw-offBlack">Free testosterone</p>
                            <p className="mt-4 text-nw-offBlack">
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit. In magna dui, dignissim sit
                                amet est ac, suscipit efficitur ex. Aliquam convallis sodales justo, commodo congue urna
                                tristique vitae. In eu pellentesque nibh. Vestibulum et euismod tellus. Duis sit amet
                                lectus lacus. Fusce mi urna, aliquam sed ultrices sed, ornare a lorem. Suspendisse
                                gravida elementum sem eu imperdiet. <br></br>
                                <br></br>Ut auctor elit in augue hendrerit iaculis. Sed posuere hendrerit nisi, a
                                fermentum nibh facilisis id. Suspendisse nisl purus, ultricies vitae sem eget, interdum
                                bibendum tellus. Nulla ut nulla non neque eleifend pellentesque.
                            </p>
                        </div>
                    </>
                )
            },
            {
                title: 'FSH',
                body: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. In magna dui.',
                price: 6,
                learnMore: (
                    <>
                        <div className="text-center">
                            <p className="text-2xl text-nw-offBlack">FSH</p>
                            <p className="mt-4 text-nw-offBlack">
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit. In magna dui, dignissim sit
                                amet est ac, suscipit efficitur ex. Aliquam convallis sodales justo, commodo congue urna
                                tristique vitae. In eu pellentesque nibh. Vestibulum et euismod tellus. Duis sit amet
                                lectus lacus. Fusce mi urna, aliquam sed ultrices sed, ornare a lorem. Suspendisse
                                gravida elementum sem eu imperdiet. <br></br>
                                <br></br>Ut auctor elit in augue hendrerit iaculis. Sed posuere hendrerit nisi, a
                                fermentum nibh facilisis id. Suspendisse nisl purus, ultricies vitae sem eget, interdum
                                bibendum tellus. Nulla ut nulla non neque eleifend pellentesque.
                            </p>
                        </div>
                    </>
                )
            },
            {
                title: 'LH',
                body: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. In magna dui.',
                price: 6,
                learnMore: (
                    <>
                        <div className="text-center">
                            <p className="text-2xl text-nw-offBlack">LH</p>
                            <p className="mt-4 text-nw-offBlack">
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit. In magna dui, dignissim sit
                                amet est ac, suscipit efficitur ex. Aliquam convallis sodales justo, commodo congue urna
                                tristique vitae. In eu pellentesque nibh. Vestibulum et euismod tellus. Duis sit amet
                                lectus lacus. Fusce mi urna, aliquam sed ultrices sed, ornare a lorem. Suspendisse
                                gravida elementum sem eu imperdiet. <br></br>
                                <br></br>Ut auctor elit in augue hendrerit iaculis. Sed posuere hendrerit nisi, a
                                fermentum nibh facilisis id. Suspendisse nisl purus, ultricies vitae sem eget, interdum
                                bibendum tellus. Nulla ut nulla non neque eleifend pellentesque.
                            </p>
                        </div>
                    </>
                )
            },
            {
                title: 'Oestradiol',
                body: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. In magna dui.',
                price: 6,
                learnMore: (
                    <>
                        <div className="text-center">
                            <p className="text-2xl text-nw-offBlack">Oestradiol</p>
                            <p className="mt-4 text-nw-offBlack">
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit. In magna dui, dignissim sit
                                amet est ac, suscipit efficitur ex. Aliquam convallis sodales justo, commodo congue urna
                                tristique vitae. In eu pellentesque nibh. Vestibulum et euismod tellus. Duis sit amet
                                lectus lacus. Fusce mi urna, aliquam sed ultrices sed, ornare a lorem. Suspendisse
                                gravida elementum sem eu imperdiet. <br></br>
                                <br></br>Ut auctor elit in augue hendrerit iaculis. Sed posuere hendrerit nisi, a
                                fermentum nibh facilisis id. Suspendisse nisl purus, ultricies vitae sem eget, interdum
                                bibendum tellus. Nulla ut nulla non neque eleifend pellentesque.
                            </p>
                        </div>
                    </>
                )
            },
            {
                title: 'Progesterone',
                body: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. In magna dui.',
                price: 10,
                learnMore: (
                    <>
                        <div className="text-center">
                            <p className="text-2xl text-nw-offBlack">Progesterone</p>
                            <p className="mt-4 text-nw-offBlack">
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit. In magna dui, dignissim sit
                                amet est ac, suscipit efficitur ex. Aliquam convallis sodales justo, commodo congue urna
                                tristique vitae. In eu pellentesque nibh. Vestibulum et euismod tellus. Duis sit amet
                                lectus lacus. Fusce mi urna, aliquam sed ultrices sed, ornare a lorem. Suspendisse
                                gravida elementum sem eu imperdiet. <br></br>
                                <br></br>Ut auctor elit in augue hendrerit iaculis. Sed posuere hendrerit nisi, a
                                fermentum nibh facilisis id. Suspendisse nisl purus, ultricies vitae sem eget, interdum
                                bibendum tellus. Nulla ut nulla non neque eleifend pellentesque.
                            </p>
                        </div>
                    </>
                )
            },
            {
                title: 'Prolactin',
                body: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. In magna dui.',
                price: 6,
                learnMore: (
                    <>
                        <div className="text-center">
                            <p className="text-2xl text-nw-offBlack">Prolactin</p>
                            <p className="mt-4 text-nw-offBlack">
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit. In magna dui, dignissim sit
                                amet est ac, suscipit efficitur ex. Aliquam convallis sodales justo, commodo congue urna
                                tristique vitae. In eu pellentesque nibh. Vestibulum et euismod tellus. Duis sit amet
                                lectus lacus. Fusce mi urna, aliquam sed ultrices sed, ornare a lorem. Suspendisse
                                gravida elementum sem eu imperdiet. <br></br>
                                <br></br>Ut auctor elit in augue hendrerit iaculis. Sed posuere hendrerit nisi, a
                                fermentum nibh facilisis id. Suspendisse nisl purus, ultricies vitae sem eget, interdum
                                bibendum tellus. Nulla ut nulla non neque eleifend pellentesque.
                            </p>
                        </div>
                    </>
                )
            },
            {
                title: 'Total Testosterone',
                body: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. In magna dui.',
                price: 5,
                learnMore: (
                    <>
                        <div className="text-center">
                            <p className="text-2xl text-nw-offBlack">Total Testosterone</p>
                            <p className="mt-4 text-nw-offBlack">
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit. In magna dui, dignissim sit
                                amet est ac, suscipit efficitur ex. Aliquam convallis sodales justo, commodo congue urna
                                tristique vitae. In eu pellentesque nibh. Vestibulum et euismod tellus. Duis sit amet
                                lectus lacus. Fusce mi urna, aliquam sed ultrices sed, ornare a lorem. Suspendisse
                                gravida elementum sem eu imperdiet. <br></br>
                                <br></br>Ut auctor elit in augue hendrerit iaculis. Sed posuere hendrerit nisi, a
                                fermentum nibh facilisis id. Suspendisse nisl purus, ultricies vitae sem eget, interdum
                                bibendum tellus. Nulla ut nulla non neque eleifend pellentesque.
                            </p>
                        </div>
                    </>
                )
            }
        ],
        'Immune Health': [
            {
                title: 'HS - CRP',
                body: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. In magna dui.',
                price: 5,
                learnMore: (
                    <>
                        <div className="text-center">
                            <p className="text-2xl text-nw-offBlack">HS - CRP</p>
                            <p className="mt-4 text-nw-offBlack">
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit. In magna dui, dignissim sit
                                amet est ac, suscipit efficitur ex. Aliquam convallis sodales justo, commodo congue urna
                                tristique vitae. In eu pellentesque nibh. Vestibulum et euismod tellus. Duis sit amet
                                lectus lacus. Fusce mi urna, aliquam sed ultrices sed, ornare a lorem. Suspendisse
                                gravida elementum sem eu imperdiet. <br></br>
                                <br></br>Ut auctor elit in augue hendrerit iaculis. Sed posuere hendrerit nisi, a
                                fermentum nibh facilisis id. Suspendisse nisl purus, ultricies vitae sem eget, interdum
                                bibendum tellus. Nulla ut nulla non neque eleifend pellentesque.
                            </p>
                        </div>
                    </>
                )
            }
        ],
        'Iron Status': [
            {
                title: 'Ferritin',
                body: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. In magna dui.',
                price: 6,
                learnMore: (
                    <>
                        <div className="text-center">
                            <p className="text-2xl text-nw-offBlack">Ferritin</p>
                            <p className="mt-4 text-nw-offBlack">
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit. In magna dui, dignissim sit
                                amet est ac, suscipit efficitur ex. Aliquam convallis sodales justo, commodo congue urna
                                tristique vitae. In eu pellentesque nibh. Vestibulum et euismod tellus. Duis sit amet
                                lectus lacus. Fusce mi urna, aliquam sed ultrices sed, ornare a lorem. Suspendisse
                                gravida elementum sem eu imperdiet. <br></br>
                                <br></br>Ut auctor elit in augue hendrerit iaculis. Sed posuere hendrerit nisi, a
                                fermentum nibh facilisis id. Suspendisse nisl purus, ultricies vitae sem eget, interdum
                                bibendum tellus. Nulla ut nulla non neque eleifend pellentesque.
                            </p>
                        </div>
                    </>
                )
            }
        ],
        'Kidney Health': [
            {
                title: 'Kidney function',
                body: 'Creatinine, eGFR, Urea, Uric Acid',
                price: 6,
                learnMore: (
                    <>
                        <div className="text-center">
                            <p className="text-2xl text-nw-offBlack">Kidney function</p>
                            <p className="mt-4 text-nw-offBlack">
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit. In magna dui, dignissim sit
                                amet est ac, suscipit efficitur ex. Aliquam convallis sodales justo, commodo congue urna
                                tristique vitae. In eu pellentesque nibh. Vestibulum et euismod tellus. Duis sit amet
                                lectus lacus. Fusce mi urna, aliquam sed ultrices sed, ornare a lorem. Suspendisse
                                gravida elementum sem eu imperdiet. <br></br>
                                <br></br>Ut auctor elit in augue hendrerit iaculis. Sed posuere hendrerit nisi, a
                                fermentum nibh facilisis id. Suspendisse nisl purus, ultricies vitae sem eget, interdum
                                bibendum tellus. Nulla ut nulla non neque eleifend pellentesque.
                            </p>
                        </div>
                    </>
                )
            }
        ],
        'Liver Health': [
            {
                title: 'Lipid Panel',
                body: 'HDL, HDL ratio, LDL, Total cholesterol, Triglycerides',
                price: 6,
                learnMore: (
                    <>
                        <div className="text-center">
                            <p className="text-2xl text-nw-offBlack">Lipid Panel</p>
                            <p className="mt-4 text-nw-offBlack">
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit. In magna dui, dignissim sit
                                amet est ac, suscipit efficitur ex. Aliquam convallis sodales justo, commodo congue urna
                                tristique vitae. In eu pellentesque nibh. Vestibulum et euismod tellus. Duis sit amet
                                lectus lacus. Fusce mi urna, aliquam sed ultrices sed, ornare a lorem. Suspendisse
                                gravida elementum sem eu imperdiet. <br></br>
                                <br></br>Ut auctor elit in augue hendrerit iaculis. Sed posuere hendrerit nisi, a
                                fermentum nibh facilisis id. Suspendisse nisl purus, ultricies vitae sem eget, interdum
                                bibendum tellus. Nulla ut nulla non neque eleifend pellentesque.
                            </p>
                        </div>
                    </>
                )
            },
            {
                title: 'Liver Function',
                body: 'ALT, albumin, ALP, Gamma G, Globulin, Total protein ',
                price: 6,
                learnMore: (
                    <>
                        <div className="text-center">
                            <p className="text-2xl text-nw-offBlack">Liver Function</p>
                            <p className="mt-4 text-nw-offBlack">
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit. In magna dui, dignissim sit
                                amet est ac, suscipit efficitur ex. Aliquam convallis sodales justo, commodo congue urna
                                tristique vitae. In eu pellentesque nibh. Vestibulum et euismod tellus. Duis sit amet
                                lectus lacus. Fusce mi urna, aliquam sed ultrices sed, ornare a lorem. Suspendisse
                                gravida elementum sem eu imperdiet. <br></br>
                                <br></br>Ut auctor elit in augue hendrerit iaculis. Sed posuere hendrerit nisi, a
                                fermentum nibh facilisis id. Suspendisse nisl purus, ultricies vitae sem eget, interdum
                                bibendum tellus. Nulla ut nulla non neque eleifend pellentesque.
                            </p>
                        </div>
                    </>
                )
            }
        ],
        'Muscle & Joint Health': [
            {
                title: 'CK',
                body: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. In magna dui.',
                price: 6,
                learnMore: (
                    <>
                        <div className="text-center">
                            <p className="text-2xl text-nw-offBlack">CK</p>
                            <p className="mt-4 text-nw-offBlack">
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit. In magna dui, dignissim sit
                                amet est ac, suscipit efficitur ex. Aliquam convallis sodales justo, commodo congue urna
                                tristique vitae. In eu pellentesque nibh. Vestibulum et euismod tellus. Duis sit amet
                                lectus lacus. Fusce mi urna, aliquam sed ultrices sed, ornare a lorem. Suspendisse
                                gravida elementum sem eu imperdiet. <br></br>
                                <br></br>Ut auctor elit in augue hendrerit iaculis. Sed posuere hendrerit nisi, a
                                fermentum nibh facilisis id. Suspendisse nisl purus, ultricies vitae sem eget, interdum
                                bibendum tellus. Nulla ut nulla non neque eleifend pellentesque.
                            </p>
                        </div>
                    </>
                )
            }
        ],
        'Thyroid Health': [
            {
                title: 'Thyroglobulin Antibodies',
                body: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. In magna dui.',
                price: 10,
                learnMore: (
                    <>
                        <div className="text-center">
                            <p className="text-2xl text-nw-offBlack">Thyroglobulin Antibodies</p>
                            <p className="mt-4 text-nw-offBlack">
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit. In magna dui, dignissim sit
                                amet est ac, suscipit efficitur ex. Aliquam convallis sodales justo, commodo congue urna
                                tristique vitae. In eu pellentesque nibh. Vestibulum et euismod tellus. Duis sit amet
                                lectus lacus. Fusce mi urna, aliquam sed ultrices sed, ornare a lorem. Suspendisse
                                gravida elementum sem eu imperdiet. <br></br>
                                <br></br>Ut auctor elit in augue hendrerit iaculis. Sed posuere hendrerit nisi, a
                                fermentum nibh facilisis id. Suspendisse nisl purus, ultricies vitae sem eget, interdum
                                bibendum tellus. Nulla ut nulla non neque eleifend pellentesque.
                            </p>
                        </div>
                    </>
                )
            },
            {
                title: 'Thyroid Function - TSH, T4, T3',
                body: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. In magna dui.',
                price: 15,
                learnMore: (
                    <>
                        <div className="text-center">
                            <p className="text-2xl text-nw-offBlack">Thyroid Function - TSH, T4, T3</p>
                            <p className="mt-4 text-nw-offBlack">
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit. In magna dui, dignissim sit
                                amet est ac, suscipit efficitur ex. Aliquam convallis sodales justo, commodo congue urna
                                tristique vitae. In eu pellentesque nibh. Vestibulum et euismod tellus. Duis sit amet
                                lectus lacus. Fusce mi urna, aliquam sed ultrices sed, ornare a lorem. Suspendisse
                                gravida elementum sem eu imperdiet. <br></br>
                                <br></br>Ut auctor elit in augue hendrerit iaculis. Sed posuere hendrerit nisi, a
                                fermentum nibh facilisis id. Suspendisse nisl purus, ultricies vitae sem eget, interdum
                                bibendum tellus. Nulla ut nulla non neque eleifend pellentesque.
                            </p>
                        </div>
                    </>
                )
            },
            {
                title: 'Thyroid peroxidase antibodies',
                body: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. In magna dui.',
                price: 10,
                learnMore: (
                    <>
                        <div className="text-center">
                            <p className="text-2xl text-nw-offBlack">Thyroid peroxidase antibodies</p>
                            <p className="mt-4 text-nw-offBlack">
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit. In magna dui, dignissim sit
                                amet est ac, suscipit efficitur ex. Aliquam convallis sodales justo, commodo congue urna
                                tristique vitae. In eu pellentesque nibh. Vestibulum et euismod tellus. Duis sit amet
                                lectus lacus. Fusce mi urna, aliquam sed ultrices sed, ornare a lorem. Suspendisse
                                gravida elementum sem eu imperdiet. <br></br>
                                <br></br>Ut auctor elit in augue hendrerit iaculis. Sed posuere hendrerit nisi, a
                                fermentum nibh facilisis id. Suspendisse nisl purus, ultricies vitae sem eget, interdum
                                bibendum tellus. Nulla ut nulla non neque eleifend pellentesque.
                            </p>
                        </div>
                    </>
                )
            },
            {
                title: 'Free T4',
                body: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. In magna dui.',
                price: 6,
                learnMore: (
                    <>
                        <div className="text-center">
                            <p className="text-2xl text-nw-offBlack">Free T4</p>
                            <p className="mt-4 text-nw-offBlack">
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit. In magna dui, dignissim sit
                                amet est ac, suscipit efficitur ex. Aliquam convallis sodales justo, commodo congue urna
                                tristique vitae. In eu pellentesque nibh. Vestibulum et euismod tellus. Duis sit amet
                                lectus lacus. Fusce mi urna, aliquam sed ultrices sed, ornare a lorem. Suspendisse
                                gravida elementum sem eu imperdiet. <br></br>
                                <br></br>Ut auctor elit in augue hendrerit iaculis. Sed posuere hendrerit nisi, a
                                fermentum nibh facilisis id. Suspendisse nisl purus, ultricies vitae sem eget, interdum
                                bibendum tellus. Nulla ut nulla non neque eleifend pellentesque.
                            </p>
                        </div>
                    </>
                )
            },
            {
                title: 'Free T3',
                body: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. In magna dui.',
                price: 6,
                learnMore: (
                    <>
                        <div className="text-center">
                            <p className="text-2xl text-nw-offBlack">Free T3</p>
                            <p className="mt-4 text-nw-offBlack">
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit. In magna dui, dignissim sit
                                amet est ac, suscipit efficitur ex. Aliquam convallis sodales justo, commodo congue urna
                                tristique vitae. In eu pellentesque nibh. Vestibulum et euismod tellus. Duis sit amet
                                lectus lacus. Fusce mi urna, aliquam sed ultrices sed, ornare a lorem. Suspendisse
                                gravida elementum sem eu imperdiet. <br></br>
                                <br></br>Ut auctor elit in augue hendrerit iaculis. Sed posuere hendrerit nisi, a
                                fermentum nibh facilisis id. Suspendisse nisl purus, ultricies vitae sem eget, interdum
                                bibendum tellus. Nulla ut nulla non neque eleifend pellentesque.
                            </p>
                        </div>
                    </>
                )
            },
            {
                title: 'TSH',
                body: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. In magna dui.',
                price: 6,
                learnMore: (
                    <>
                        <div className="text-center">
                            <p className="text-2xl text-nw-offBlack">TSH</p>
                            <p className="mt-4 text-nw-offBlack">
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit. In magna dui, dignissim sit
                                amet est ac, suscipit efficitur ex. Aliquam convallis sodales justo, commodo congue urna
                                tristique vitae. In eu pellentesque nibh. Vestibulum et euismod tellus. Duis sit amet
                                lectus lacus. Fusce mi urna, aliquam sed ultrices sed, ornare a lorem. Suspendisse
                                gravida elementum sem eu imperdiet. <br></br>
                                <br></br>Ut auctor elit in augue hendrerit iaculis. Sed posuere hendrerit nisi, a
                                fermentum nibh facilisis id. Suspendisse nisl purus, ultricies vitae sem eget, interdum
                                bibendum tellus. Nulla ut nulla non neque eleifend pellentesque.
                            </p>
                        </div>
                    </>
                )
            }
        ]
    };

    const icons = {
        'Full Blood Count': fullBloodCountIcon,
        'Nutrition Health': nutritionHealthIcon,
        'Bone Health': boneHealthIcon,
        'Cancer Screening': cancerScreeningIcon,
        'Hormone Health': hormoneHealthIcon,
        'Immune Health': immuneHealthIcon,
        'Iron Status': ironStatusIcon,
        'Kidney Health': kidneyHealthIcon,
        'Liver Health': liverHealthIcon,
        'Muscle & Joint Health': muscleJointHealthIcon,
        'Thyroid Health': thyroidHealthIcon,
        'Diabetes Health': diabetesHealthIcon,
        'Digestive Health': digestiveHealthIcon,
        'General Health': generalHealthIcon,
        'Heart Health': heartHealthIcon,
        'Pancreatic Health': pancreasHealthIcon,
        'Sexual Health Screening': sexualHealthIcon,
        'Prescription Weight Loss': weightLossIcon,
        'Bioniq Supplements': bioniqIcon,
        'TRT Consultation': testosteroneIcon,
        'TruCheck™': trucheckIcon,
        'Prenatal Safe': prenatalIcon,
        'Onconext Risk®': oncontextIcon
    };

    const cart = useSelector((state) => state.cart);
    const dispatch = useDispatch();
    const [group, setGroup] = useState('General Health');
    const [activeBiomarkers, setActiveBiomarkers] = useState(null);
    const [popupContent, setPopupContent] = useState(null);
    const [error, setError] = useState(null);
    // eslint-disable-next-line no-unused-vars
    const [searchParams, setSearchParams] = useSearchParams();
    const UID = parseInt(searchParams.get('uid'));
    const cartItem = cart.cart.find((item) => item.UID === UID);

    function setBiomarkers(name) {
        const group = products[cartItem.productID].personalise;

        if (group === 'RANDOX') {
            setActiveBiomarkers(randoxBiomarkers[name]);
        } else {
            setActiveBiomarkers(inuviBiomarkers[name]);
        }
    }

    function CategoryTab({ name, showName = true }) {
        let markerCount = 0;
        if (cartItem['additionalBiomarkers'] !== undefined) {
            if (cartItem['additionalBiomarkers'][name] !== undefined) {
                markerCount = Object.values(cartItem['additionalBiomarkers'][name]).reduce(
                    (a, { value }) => a + value,
                    0
                );
            }
        }

        return (
            <div
                className={
                    'h-full min-h-[100px] rounded-2xl p-[3px] transition-colors hover:cursor-pointer' +
                    (group === name ? ' bg-gradient-to-r from-[#B9DEE9] to-[#79B3C3]' : ' hover:bg-nw-blue')
                }
                onClick={() => {
                    setGroup(name);
                    setBiomarkers(name);
                }}
            >
                <div className="relative flex h-full rounded-xl bg-white p-1 md:p-4">
                    <div className="m-auto">
                        <div className="flex justify-center">
                            <img
                                className={
                                    'relative max-w-[80px] sm:max-w-[120px]' +
                                    (showName ? ' mb-2 h-[35px]' : ' xl:max-w-[150px]')
                                }
                                src={icons[name]}
                                alt={`Personalise - ${name}`}
                            />
                        </div>
                        {showName && (
                            <p className="text-center text-xs text-nw-offBlack sm:text-sm md:text-base">{name}</p>
                        )}
                    </div>
                    {markerCount > 0 && (
                        <div className="absolute top-2 right-2 h-5 w-5 rounded-[50%] bg-black pt-0.5 text-center text-xs text-white">
                            {markerCount}
                        </div>
                    )}
                </div>
            </div>
        );
    }

    function BiomarkerCard({ eCategory, eTitle, eBody, ePrice, eLearnMore, eCodes }) {
        return (
            <PriceCard
                icon={icons[eCategory]}
                title={eTitle}
                body={eBody}
                price={ePrice}
                iconDisplay={
                    eCategory === 'TruCheck™' ||
                    eCategory === 'Prenatal Safe' ||
                    eCategory === 'Onconext Risk®' ||
                    eCategory === 'Bioniq Supplements'
                        ? 'rectangle'
                        : 'square'
                }
                toggleCallback={(val) => {
                    dispatch(
                        cartActions.setAdditionalBiomarker({
                            UID: UID,
                            category: eCategory,
                            biomarker: eTitle,
                            price: ePrice,
                            value: val,
                            codes: eCodes
                        })
                    );
                }}
                selected={
                    checkNested(cartItem, 'additionalBiomarkers', eCategory, eTitle)
                        ? cartItem['additionalBiomarkers'][eCategory][eTitle].value
                        : false
                }
                learnMoreCallback={() => {
                    setPopupContent(
                        <>
                            <p className="mt-2 mb-4 text-center text-2xl font-medium text-nw-offBlack">{eTitle}</p>
                            <p className="mt-4 text-center text-nw-offBlack">{eBody}</p>
                            {eLearnMore}
                        </>
                    );
                }}
            />
        );
    }

    useEffect(() => {
        if (cartItem === undefined || products[cartItem.productID].personalise === null) {
            history.navigate('/checkout');
        } else {
            setBiomarkers('General Health');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        setError(cart.personalisationError);

        if (cart.personalisationError !== null) {
            const timer = setTimeout(() => {
                dispatch(cartActions.setPersonalisationError(null));
            }, 5000);
            return () => clearTimeout(timer);
        }
    }, [cart.personalisationError, dispatch]);

    return cartItem !== undefined && activeBiomarkers !== null ? (
        <>
            {popupContent && (
                <Popup
                    children={popupContent}
                    onClose={() => {
                        setPopupContent(null);
                    }}
                />
            )}
            {error !== null && (
                <div className="mb-4 rounded-2xl border-4 border-red-300 bg-red-200 p-5 text-center font-medium text-nw-offBlack">
                    {error}
                </div>
            )}
            <div className="relative rounded-3xl bg-nw-blue px-2 py-6 md:px-6">
                <p className="text-md text-center font-light text-nw-offBlack">{cartItem.title}</p>
                <p className="mx-12 text-center text-2xl text-nw-offBlack">Personalise Your Health Check</p>
                <Link to="/checkout">
                    <FontAwesomeIcon
                        icon={faCircleXmark}
                        className="absolute top-6 right-4 mr-1 h-10 w-10 text-nw-grey transition-colors hover:text-nw-darkGrey md:top-8 md:right-9"
                    />
                </Link>

                <div className="mt-8 rounded-2xl bg-[#E2F0F4] p-3">
                    <div className="grid grid-cols-2 gap-2 gap-y-2 md:grid-cols-4 md:gap-4">
                        <CategoryTab name={'General Health'} />
                        <CategoryTab name={'Kidney Health'} />
                        <CategoryTab name={'Muscle & Joint Health'} />
                        <CategoryTab name={'Hormone Health'} />
                        <CategoryTab name={'Diabetes Health'} />
                        <CategoryTab name={'Nutrition Health'} />
                        <CategoryTab name={'Digestive Health'} />
                        <CategoryTab name={'Thyroid Health'} />
                        <CategoryTab name={'Heart Health'} />
                        <CategoryTab name={'Iron Status'} />
                        <CategoryTab name={'Liver Health'} />
                        <CategoryTab name={'Pancreatic Health'} />
                        <CategoryTab name={'Sexual Health Screening'} />
                        <CategoryTab name={'Cancer Screening'} />
                        <CategoryTab name={'Prescription Weight Loss'} />
                        <CategoryTab name={'TRT Consultation'} />
                        <CategoryTab name={'Bioniq Supplements'} showName={false} />
                        <CategoryTab name={'TruCheck™'} showName={false} />
                        <CategoryTab name={'Prenatal Safe'} showName={false} />
                        <CategoryTab name={'Onconext Risk®'} showName={false} />
                    </div>
                </div>

                <p className="mt-8 text-center text-xl font-light text-nw-offBlack">{group}</p>
                <div className="mt-4 h-0.5 w-full rounded-3xl bg-nw-lightGrey"></div>
                {activeBiomarkers.map((biomarker, index) => (
                    <BiomarkerCard
                        key={group + '-' + biomarker.title}
                        eCategory={group}
                        eTitle={biomarker.title}
                        eBody={biomarker.body}
                        ePrice={biomarker.price}
                        eLearnMore={biomarker.learnMore}
                        eCodes={biomarker.codes}
                    />
                ))}
            </div>
        </>
    ) : (
        ''
    );
}
