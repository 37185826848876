import { useState, useEffect } from 'react';

export { Select };

function Select({ width, options, callback, selected = 0, updateValue = null }) {
    const [inputValue, setValue] = useState(selected);

    function handleChange(e) {
        setValue(e.target.value);
        callback(e);
    }

    useEffect(() => {
        if (updateValue !== null) {
            setValue(updateValue);
        }
    }, [updateValue]);

    return (
        <select
            name="select"
            className="inline-select rounded-2xl border border-nw-lightGrey pt-1.5 pb-1.5 pl-4 pr-9 font-light outline-none drop-shadow-md transition-colors placeholder:text-nw-grey hover:cursor-pointer hover:bg-[#f9f9f9] focus:outline-none"
            style={{ maxWidth: width }}
            onChange={handleChange}
            value={inputValue}
        >
            {options.map((option, index) => (
                <option key={index} value={index}>
                    {option}
                </option>
            ))}
        </select>
    );
}
