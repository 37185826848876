import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { useJsApiLoader } from '@react-google-maps/api';
import GooglePlacesAutocomplete, { geocodeByPlaceId } from 'react-google-places-autocomplete';
import { Button, TextInput } from '_components';
import { googleAPILibraries, fetchWrapper } from '_helpers';
import { authActions } from '_store';

export { ManageShipping };

function ManageShipping() {
    const [msg, setMsg] = useState('');
    // const [newUser, setNewUser] = useState({});
    const { user: authUser } = useSelector((x) => x.auth);
    const [addressResult, setAddressResult] = useState(null);
    const [activeAddress, setActiveAddress] = useState({
        // fullName: authUser?.user?.shippingFullName ? authUser.user.shippingFullName : '',
        address1: authUser?.user?.shippingAddress1 ? authUser.user.shippingAddress1 : '',
        address2: authUser?.user?.shippingAddress2 ? authUser.user.shippingAddress2 : '',
        city: authUser?.user?.shippingCity ? authUser.user.shippingCity : '',
        postcode: authUser?.user?.shippingPostcode ? authUser.user.shippingPostcode : ''
    });
    const dispatch = useDispatch();

    const postcodeRegex =
        /([Gg][Ii][Rr] 0[Aa]{2})|((([A-Za-z][0-9]{1,2})|(([A-Za-z][A-Ha-hJ-Yj-y][0-9]{1,2})|(([A-Za-z][0-9][A-Za-z])|([A-Za-z][A-Ha-hJ-Yj-y][0-9][A-Za-z]?))))\s?[0-9][A-Za-z]{2})/;

    let validationSchema = Yup.object().shape({
        // fullName: Yup.string().required('Name is required'),
        address1: Yup.string().required('Address Line 1 is required').max(100, 'Max 100 characters'),
        city: Yup.string().required('City is required').max(100, 'Max 100 characters'),
        postcode: Yup.string().required('Postcode is required').matches(postcodeRegex, 'Postcode is not valid')
    });

    const formOptions = { resolver: yupResolver(validationSchema) };
    const { register, handleSubmit, formState, reset, setValue } = useForm(formOptions);
    const { errors, isSubmitting } = formState;

    const getFullAddress = async (placeId) => {
        if (placeId) {
            const [place] = await geocodeByPlaceId(placeId);

            const { long_name: streetNumber = '' } =
                place.address_components.find((c) => c.types.includes('street_number')) || {};
            const { long_name: streetAddress = '' } =
                place.address_components.find((c) => c.types.includes('route')) || {};
            const { long_name: postalTown = '' } =
                place.address_components.find((c) => c.types.includes('postal_town')) || {};
            const { long_name: postalCode = '' } =
                place.address_components.find((c) => c.types.includes('postal_code')) || {};
            return {
                address1: streetNumber + (streetAddress ? ' ' + streetAddress : ''),
                city: postalTown,
                postcode: postalCode
            };
        }
    };

    async function updateStripeCustomer(stripeAddress, fullName, stripeCustomerId) {
        if (
            authUser.user.shippingPostcode !== stripeAddress.postal_code ||
            authUser.user.shippingFullName !== fullName
        ) {
            if (authUser.user.stripeCustomerId !== undefined) {
                await fetchWrapper.post(`${process.env.REACT_APP_API_URL}/checkout/update-stripe-customer`, {
                    customerId: stripeCustomerId,
                    name: fullName,
                    address: stripeAddress
                });
            }
        }
    }

    useEffect(() => {
        //Prefill fields from address finder results
        if (addressResult) {
            const placeId = addressResult?.value['place_id'];
            getFullAddress(placeId).then((json) => {
                json.fullName = activeAddress.fullName;
                setActiveAddress(json);
                setValue('address1', json.address1);
                setValue('city', json.city);
                setValue('postcode', json.postcode);
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [addressResult]);

    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY,
        libraries: googleAPILibraries
    });

    async function updateAccount({ address1, address2, city, postcode }) {
        setMsg('');

        const fullName = `${authUser.user.firstName} ${authUser.user.lastName}`;

        let newUser = {
            ...authUser.user,
            shippingFullName: fullName,
            shippingAddress1: address1,
            shippingAddress2: address2,
            shippingCity: city,
            shippingPostcode: postcode
        };

        const stripeAddress = { country: 'GB', city, line1: address1, postal_code: postcode };

        await updateStripeCustomer(stripeAddress, fullName, authUser.user.stripeCustomerId);

        return dispatch(
            authActions.updateAccount({
                newUser
            })
        ).then((res) => {
            if (res.meta.requestStatus !== 'fulfilled') {
                setMsg('Error please try again later.');
            } else {
                setMsg('Shipping details updated successfully.');
                reset({
                    currentPassword: '',
                    password: '',
                    confirmPassword: ''
                });
            }
        });
    }

    return (
        <>
            <p className="mt-8 text-center text-2xl text-nw-offBlack">Edit Shipping details</p>
            {authUser.user.stripeCustomerId !== undefined ? (
                <form className="mt-6" onSubmit={handleSubmit(updateAccount)}>
                    {/* <div className="pt-4">
                        <TextInput
                            name="fullName"
                            label="Full name"
                            width="100%"
                            props={register('fullName')}
                            value={activeAddress['fullName']}
                        />
                        <div className="ml-5 mt-1 text-sm text-red-700">{errors.fullName?.message}</div>
                    </div> */}
                    <p className="ml-4 mt-4 text-sm font-medium text-nw-grey">Find your address:</p>
                    {isLoaded && (
                        <div className="googleAutoComplete mt-2">
                            <GooglePlacesAutocomplete
                                autocompletionRequest={{
                                    componentRestrictions: {
                                        country: ['uk']
                                    },
                                    types: ['address']
                                }}
                                selectProps={{
                                    placeholder: 'Start typing address...',
                                    addressResult,
                                    onChange: setAddressResult
                                }}
                            />
                        </div>
                    )}
                    <div className="mt-6">
                        <TextInput
                            name="address1"
                            label="Address Line 1"
                            width="100%"
                            props={register('address1')}
                            value={activeAddress['address1']}
                            callback={(e) => setActiveAddress({ ...activeAddress, address1: e.target.value })}
                        />
                        <div className="ml-5 mt-1 text-sm text-red-700">{errors.address1?.message}</div>
                    </div>
                    <div className="mt-6">
                        <TextInput
                            name="address2"
                            label="Address Line 2 (optional)"
                            width="100%"
                            props={register('address2')}
                        />
                    </div>
                    <div className="mt-6">
                        <TextInput
                            name="city"
                            label="City"
                            width="100%"
                            value={activeAddress['city']}
                            props={register('city')}
                            callback={(e) => setActiveAddress({ ...activeAddress, city: e.target.value })}
                        />
                        <div className="ml-5 mt-1 text-sm text-red-700">{errors.city?.message}</div>
                    </div>
                    <div className="mt-6">
                        <TextInput
                            name="postcode"
                            label="Postcode"
                            width="100%"
                            value={activeAddress['postcode']}
                            props={register('postcode')}
                            callback={(e) => setActiveAddress({ ...activeAddress, postcode: e.target.value })}
                        />
                        <div className="ml-5 mt-1 text-sm text-red-700">{errors.postcode?.message}</div>
                    </div>
                    <p className="mx-4 mt-8 text-center text-sm font-medium text-nw-grey md:mx-20">
                        Important: Clicking save will modify your shipping details for your current active Neuwell
                        subscriptions.
                    </p>
                    <div className="mt-4">
                        <Button text="Save" shadow={true} fullWidth={true} icon={false} disabled={isSubmitting} />
                    </div>
                    {msg && (
                        <div className="my-3 mb-0 rounded-2xl bg-white p-2 text-center text-sm text-nw-grey">{msg}</div>
                    )}
                    <div className="mt-6 h-[1px] w-full rounded-3xl bg-nw-lightGrey"></div>
                </form>
            ) : (
                <>
                    <div className="mt-8"></div>
                    <p className="text-center font-medium text-nw-grey">You have no saved shipping details.</p>
                    <div className="mt-6 h-[1px] w-full rounded-3xl bg-nw-lightGrey"></div>
                </>
            )}
        </>
    );
}
