import React, { useState, useEffect } from 'react';
import { Link, Navigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { useSelector, useDispatch } from 'react-redux';
import { useJsApiLoader } from '@react-google-maps/api';
import GooglePlacesAutocomplete, { geocodeByPlaceId } from 'react-google-places-autocomplete';
import { customAlphabet } from 'nanoid';
import { Button, TextInput, PriceCard } from '_components';
import { fetchWrapper, history, googleAPILibraries, getItemPriceBreakdown } from '_helpers';
import { authActions } from '_store';

export { CartShipping };

function CartShipping() {
    const authUser = useSelector((x) => x.auth.user);
    const cart = useSelector((state) => state.cart);
    const nanoid = customAlphabet('1234567890abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ', 16);
    const [isLoading, setIsLoading] = useState(false);
    const [addressResult, setAddressResult] = useState(null);
    const [error, setError] = useState('');
    const [showShipping, setShowShipping] = useState(true);
    const [activeAddress, setActiveAddress] = useState({
        // fullName: authUser?.user?.shippingFullName ? authUser.user.shippingFullName : '',
        address1: authUser?.user?.shippingAddress1 ? authUser.user.shippingAddress1 : '',
        address2: authUser?.user?.shippingAddress2 ? authUser.user.shippingAddress2 : '',
        city: authUser?.user?.shippingCity ? authUser.user.shippingCity : '',
        postcode: authUser?.user?.shippingPostcode ? authUser.user.shippingPostcode : ''
    });
    const dispatch = useDispatch();

    const postcodeRegex =
        /([Gg][Ii][Rr] 0[Aa]{2})|((([A-Za-z][0-9]{1,2})|(([A-Za-z][A-Ha-hJ-Yj-y][0-9]{1,2})|(([A-Za-z][0-9][A-Za-z])|([A-Za-z][A-Ha-hJ-Yj-y][0-9][A-Za-z]?))))\s?[0-9][A-Za-z]{2})/;

    let validationSchema = Yup.object().shape({
        // fullName: Yup.string().required('Name is required'),
        address1: Yup.string().required('Address Line 1 is required').max(100, 'Max 100 characters'),
        city: Yup.string().required('City is required').max(100, 'Max 100 characters'),
        postcode: Yup.string().required('Postcode is required').matches(postcodeRegex, 'Postcode is not valid')
    });

    const formOptions = { resolver: yupResolver(validationSchema) };
    const { register, handleSubmit, formState, setValue } = useForm(formOptions);
    const { errors } = formState;

    const getFullAddress = async (placeId) => {
        if (placeId) {
            const [place] = await geocodeByPlaceId(placeId);

            const { long_name: streetNumber = '' } =
                place.address_components.find((c) => c.types.includes('street_number')) || {};
            const { long_name: streetAddress = '' } =
                place.address_components.find((c) => c.types.includes('route')) || {};
            const { long_name: postalTown = '' } =
                place.address_components.find((c) => c.types.includes('postal_town')) || {};
            const { long_name: postalCode = '' } =
                place.address_components.find((c) => c.types.includes('postal_code')) || {};
            return {
                address1: streetNumber + (streetAddress ? ' ' + streetAddress : ''),
                city: postalTown,
                postcode: postalCode
            };
        }
    };

    useEffect(() => {
        //Prefill fields from address finder results
        if (addressResult) {
            const placeId = addressResult?.value['place_id'];
            getFullAddress(placeId).then((json) => {
                json.fullName = activeAddress.fullName;
                setActiveAddress(json);
                setValue('address1', json.address1);
                setValue('city', json.city);
                setValue('postcode', json.postcode);
            });
        }
    }, [addressResult, activeAddress.fullName, setValue]);

    useEffect(() => {
        const shippingItem = cart.cart.find(
            (item) => item.display === 'FINGERPRICK' || item.display === 'FINGERPRICK-STI'
        );

        if (!shippingItem) {
            setShowShipping(false);
        }
    }, [cart.cart]);

    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY,
        libraries: googleAPILibraries
    });

    async function createStripeCustomer(email, name, address) {
        if (authUser.user.stripeCustomerId && authUser.user.stripeCustomerId !== null) {
            return authUser.user.stripeCustomerId;
        }

        const response = await fetchWrapper.post(`${process.env.REACT_APP_API_URL}/checkout/create-stripe-customer`, {
            email: email,
            name: name,
            address: address,
            phone: authUser.user.phoneNumber
        });

        const { customerId } = response;
        return customerId;
    }

    async function updateStripeCustomer(authUser, stripeAddress, fullName, stripeCustomerId, postcode) {
        const { user } = authUser;

        if (!user.stripeCustomerId) {
            dispatch(authActions.storeStripeCustomerId({ id: stripeCustomerId }));
            return true;
        } else if (user.shippingPostcode !== postcode || user.shippingFullName !== fullName) {
            await fetchWrapper.post(`${process.env.REACT_APP_API_URL}/checkout/update-stripe-customer`, {
                customerId: stripeCustomerId,
                name: fullName,
                address: stripeAddress
            });
            return true;
        }
        return false;
    }

    async function updatePatientDetails(
        updateContentful,
        authUser,
        stripeCustomerId,
        fullName,
        address1,
        address2,
        city,
        postcode
    ) {
        if (updateContentful) {
            let cartTotal = 0;

            const basketData = cart.cart.map((item) => {
                const total = Math.round(getItemPriceBreakdown(item).total);
                cartTotal += total;

                return {
                    id: item.productID,
                    name: item.title,
                    category: item.productID,
                    quantity: 1,
                    price: total
                };
            });

            window.dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.

            window.dataLayer.push({
                event: 'add_shipping_info',
                ecommerce: {
                    currency: 'GBP',
                    value: cartTotal,
                    items: basketData
                }
            });

            await fetchWrapper.post(`${process.env.REACT_APP_API_URL}/users/update-shipping-details`, {
                entryID: authUser.user.sys.id,
                stripeCustomerId: stripeCustomerId,
                fullName: fullName,
                address1: address1,
                address2: address2,
                city: city,
                postcode: postcode
            });
        }
    }

    async function storeBasketData(orderID, address1, address2, city, postcode) {
        let totalCost = 0;

        const userData = {
            patientID: authUser.user.sys.id,
            title: authUser.user.title,
            firstName: authUser.user.firstName,
            lastName: authUser.user.lastName,
            gender: authUser.user.gender,
            dateOfBirth: authUser.user.dateOfBirth,
            addressLine1: address1,
            addressLine2: address2,
            city: city,
            postcode: postcode,
            phoneNumber: authUser.user.phoneNumber
        };

        const basketData = cart.cart.map((item) => {
            const itemCost = Math.round(getItemPriceBreakdown(item).total * 100); //amount in pence
            totalCost += itemCost;

            return {
                cost: itemCost, //amount in pence
                productTitle: item.title,
                productID: item.productID,
                frequency: item.frequency,
                testType: item.testType?.title !== undefined ? item.testType.title : null,
                testCodes: item.testCodes.length > 0 ? JSON.stringify(item.testCodes) : null,
                extras:
                    item.extras !== undefined
                        ? JSON.stringify(Object.values(item.extras).filter((key) => key.value === true))
                        : null,
                additionalBiomarkers:
                    item.encodedAdditionalBiomarkers !== undefined ? item.encodedAdditionalBiomarkers : null,
                type: item.type,
                display: item.display,
                booking: item.booking !== undefined ? JSON.stringify(item.booking) : null
            };
        });

        let storeBasketData = await fetchWrapper.post(`${process.env.REACT_APP_API_URL}/checkout/store-basket-data`, {
            orderID: orderID,
            email: authUser.user.email,
            basketData: { totalCost, userData, basketData: basketData }
        });

        storeBasketData.totalCost = totalCost;

        return storeBasketData;
    }

    async function createStripePaymentIntent(authUser, stripeCustomerId, address1, address2, city, postcode) {
        const orderID = nanoid();
        const hasSubscription = cart.cart.find((item) => item.frequency > 0) !== undefined ? true : false;
        const basketData = await storeBasketData(orderID, address1, address2, city, postcode);

        if (basketData.success === true) {
            const metadata = {
                orderID,
                basketDataID: basketData.contentfulID,
                env: process.env.REACT_APP_ENV
            };

            const intent = await fetchWrapper.post(
                `${process.env.REACT_APP_API_URL}/checkout/create-stripe-payment-intent`,
                {
                    amount: basketData.totalCost,
                    customer_id: stripeCustomerId,
                    shipping: {
                        name: `${authUser.user.firstName} ${authUser.user.lastName}`,
                        address: {
                            line1: address1,
                            line2: address2,
                            city: city,
                            country: 'GB',
                            postal_code: postcode
                        }
                    },
                    hasSubscription,
                    metadata: metadata
                }
            );

            dispatch(authActions.storeStripeClientSecret({ clientSecret: intent.clientSecret }));
            history.navigate(`/checkout/payment?id=${orderID}`);
        } else {
            setIsLoading(false);
            setError('There was an error proceeding with your order. Please try again later.');
        }
    }

    async function processUserDetails({ address1, address2, city, postcode }) {
        try {
            setIsLoading(true);
            setError('');

            const stripeAddress = { country: 'GB', city, line1: address1, postal_code: postcode };
            const fullName = `${authUser.user.firstName} ${authUser.user.lastName}`;

            const stripeCustomerId = await createStripeCustomer(authUser.user.email, fullName, stripeAddress);
            const updateContentful = await updateStripeCustomer(
                authUser,
                stripeAddress,
                fullName,
                stripeCustomerId,
                postcode
            );

            dispatch(authActions.storeShippingDetails({ fullName, address1, address2, city, postcode }));

            await updatePatientDetails(
                updateContentful,
                authUser,
                stripeCustomerId,
                fullName,
                address1,
                address2,
                city,
                postcode
            );

            await createStripePaymentIntent(authUser, stripeCustomerId, address1, address2, city, postcode);
        } catch (error) {
            setIsLoading(false);
            setError('Issue processing shipping details, please try again later.');
            console.log(error);
        }
    }

    if (!cart?.cart?.length > 0) {
        return <Navigate to="/checkout" state={{ from: history.location }} />;
    }

    if (!authUser) {
        return <Navigate to="/checkout/account" state={{ from: history.location }} />;
    }

    return (
        <div className="right-10 mb-8 lg:absolute lg:w-[70%] 2xl:w-[55%]">
            <div className="flow-root text-sm text-nw-offBlack">
                <p className="float-left text-nw-offBlack">
                    <Link to={'/checkout/account'}>
                        <span className="mr-2">Account</span>
                    </Link>{' '}
                    {'>'} <span className="ml-2 mr-2 font-medium">Shipping</span> {'>'}{' '}
                    <span className="ml-2">Payment</span>
                </p>
                <Link to={'/checkout'}>
                    <p className="float-right mb-4 text-nw-grey underline hover:cursor-pointer">{'<'} Back to basket</p>
                </Link>
            </div>
            <p className="mt-4 text-2xl text-nw-offBlack">Shipping Information</p>
            <p className="mt-2 text-sm font-light text-nw-offBlack">
                Shipping country: <span className="font-medium">United Kingdom</span>
            </p>
            <div className="relative mt-8 rounded-3xl bg-nw-blue py-6 px-6 lg:mb-20">
                <form onSubmit={handleSubmit(processUserDetails)}>
                    {/* <div className="">
                        <TextInput
                            name="fullName"
                            label="Full name"
                            width="100%"
                            props={register('fullName')}
                            value={activeAddress['fullName']}
                        />
                        <div className="ml-5 mt-1 text-sm text-red-700">{errors.fullName?.message}</div>
                    </div> */}
                    <p className="ml-4 text-sm font-medium text-nw-grey">Find your address:</p>
                    {isLoaded && (
                        <div className="googleAutoComplete mt-2">
                            <GooglePlacesAutocomplete
                                autocompletionRequest={{
                                    componentRestrictions: {
                                        country: ['uk']
                                    },
                                    types: ['address']
                                }}
                                selectProps={{
                                    placeholder: 'Start typing address...',
                                    addressResult,
                                    onChange: setAddressResult
                                }}
                            />
                        </div>
                    )}
                    <div className="mt-6">
                        <TextInput
                            name="address1"
                            label="Address Line 1"
                            width="100%"
                            props={register('address1')}
                            value={activeAddress['address1']}
                            callback={(e) => setActiveAddress({ ...activeAddress, address1: e.target.value })}
                        />
                        <div className="ml-5 mt-1 text-sm text-red-700">{errors.address1?.message}</div>
                    </div>
                    <div className="mt-6">
                        <TextInput
                            name="address2"
                            label="Address Line 2 (optional)"
                            width="100%"
                            props={register('address2')}
                        />
                    </div>
                    <div className="mt-6">
                        <TextInput
                            name="city"
                            label="City"
                            width="100%"
                            value={activeAddress['city']}
                            props={register('city')}
                            callback={(e) => setActiveAddress({ ...activeAddress, city: e.target.value })}
                        />
                        <div className="ml-5 mt-1 text-sm text-red-700">{errors.city?.message}</div>
                    </div>
                    <div className="mt-6">
                        <TextInput
                            name="postcode"
                            label="Postcode"
                            width="100%"
                            value={activeAddress['postcode']}
                            props={register('postcode')}
                            callback={(e) => setActiveAddress({ ...activeAddress, postcode: e.target.value })}
                        />
                        <div className="ml-5 mt-1 text-sm text-red-700">{errors.postcode?.message}</div>
                    </div>
                    {showShipping && (
                        <>
                            <p className="ml-4 mt-8 text-sm font-medium text-nw-offBlack">Shipping Method:</p>
                            <div className="">
                                <PriceCard
                                    title={'Free Shipping'}
                                    body={
                                        'Tracked shipping, arrives in 3 - 5 business days with free return postage to laboratory'
                                    }
                                    price={'0'}
                                    selected={true}
                                    graphic={false}
                                />
                            </div>
                        </>
                    )}
                    <div className="mt-6">
                        <Button text="Checkout" shadow={true} fullWidth={true} icon={true} disabled={isLoading} />
                    </div>
                </form>
                {error && (
                    <div className="mt-6 mb-0 rounded-2xl bg-white p-2 py-3 text-center text-sm text-nw-grey">
                        {error}
                    </div>
                )}
            </div>
        </div>
    );
}
