import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Navigate, Link, useSearchParams } from 'react-router-dom';
import { cartActions } from '_store';
import { Button } from '_components';

export { PaymentSuccess };

function PaymentSuccess({ history }) {
    const authUser = useSelector((x) => x.auth.user);
    const cart = useSelector((state) => state.cart);
    const dispatch = useDispatch();
    // eslint-disable-next-line no-unused-vars
    const [searchParams, setSearchParams] = useSearchParams();
    const id = searchParams.get('id');

    useEffect(() => {
        if (cart?.cart?.length > 0) {
            dispatch(cartActions.clearCart());
        }
    }, [cart, dispatch]);

    if (!authUser) {
        return <Navigate to="/checkout" />;
    }

    return (
        <div className="my-10 min-h-[80vh] text-center lg:my-20">
            <p className="text-3xl text-nw-offBlack">Order Placed</p>
            {id && (
                <p className="mx-auto mt-6 font-light text-nw-offBlack md:max-w-[650px]">
                    <span className="font-medium">Order ID:</span> {id}
                </p>
            )}
            <p className="mx-auto mt-6 font-light text-nw-offBlack md:max-w-[650px]">
                Thank you for your order. Please check your emails for a copy of your receipt along with instructions on
                accessing your Neuwell Dashboard.
            </p>
            <p className="mx-auto mt-6 font-light text-nw-offBlack md:max-w-[825px]">
                If you have scheduled an in-clinic health check, your personal account manager will contact you shortly
                to discuss your appointment and answer any further questions you may have.
            </p>
            <div className="mx-auto mt-4 max-w-[300px] lg:mt-12">
                <Link to={'../'}>
                    <Button text="Visit your dashboard" shadow={true} fullWidth={true} icon={false} />
                </Link>
            </div>
            <p className="mt-10 text-2xl text-nw-offBlack">Health Hub</p>
            <p className="mt-6 font-light text-nw-offBlack">
                Visit our health hub to find more resources regarding health topics and learn more about how we can help
                you live a longer, healthier happy life!
            </p>
            <div className="mt-10 grid grid-cols-1 gap-4 lg:grid-cols-3">
                <a href="https://neuwell.co.uk/health-hub">
                    <div className="relative mb-10 flex justify-center rounded-2xl bg-gradient-to-b from-[#8C2EAD] to-[#C87CCA] py-20 align-middle lg:mb-0 xl:py-24">
                        <div className="absolute top-[-20px] z-10 h-[20px] w-full bg-white lg:top-[-35px] lg:h-[35px]"></div>
                        <div className="absolute top-[-5%] h-[110%] w-[90%] overflow-hidden rounded-[50%] bg-[#C87CCA] bg-clip-content transition-colors md:w-[55%] lg:w-[90%] xl:top-[-12.5%] xl:h-[120%] xl:w-[65%]"></div>
                        <p className="z-30 px-8 text-xl font-medium text-white">
                            What causes<br></br> vitamin D deficiency?
                        </p>
                        <div className="absolute bottom-[-20px] z-10 h-[20px] w-full bg-white lg:bottom-[-35px] lg:h-[35px]"></div>
                    </div>
                </a>
                <a href="https://neuwell.co.uk/health-hub">
                    <div className="relative mb-10 flex justify-center rounded-2xl bg-gradient-to-b from-[#E2F0F4] to-[#AAC9D1] py-20 align-middle lg:mb-0 xl:py-24">
                        <div className="absolute top-[-20px] z-10 h-[20px] w-full bg-white lg:top-[-35px] lg:h-[35px]"></div>{' '}
                        <div className="absolute top-[-5%] h-[110%] w-[90%] overflow-hidden rounded-[50%] bg-[#AAC9D1] bg-clip-content transition-colors md:w-[55%] lg:w-[90%] xl:top-[-12.5%] xl:h-[120%] xl:w-[65%]"></div>
                        <p className="z-30 px-8 text-xl font-medium text-white">
                            How to support<br></br> your immune system
                        </p>
                        <div className="absolute bottom-[-20px] z-10 h-[20px] w-full bg-white lg:bottom-[-35px] lg:h-[35px]"></div>
                    </div>
                </a>
                <a href="https://neuwell.co.uk/health-hub">
                    <div className="relative mb-10 flex justify-center rounded-2xl bg-gradient-to-b from-[#EF6464] to-[#B64949] py-20 align-middle lg:mb-0 xl:py-24">
                        <div className="absolute top-[-20px] z-10 h-[20px] w-full bg-white lg:top-[-35px] lg:h-[35px]"></div>{' '}
                        <div className="absolute top-[-5%] h-[110%] w-[90%] overflow-hidden rounded-[50%] bg-[#B64949] bg-clip-content transition-colors md:w-[55%] lg:w-[90%] xl:top-[-12.5%] xl:h-[120%] xl:w-[65%]"></div>
                        <p className="z-30 px-8 text-xl font-medium text-white">
                            What are the signs<br></br> of high cortisol?
                        </p>
                        <div className="absolute bottom-[-20px] z-10 h-[20px] w-full bg-white lg:bottom-[-35px] lg:h-[35px]"></div>
                    </div>
                </a>
            </div>
        </div>
    );
}
