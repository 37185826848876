import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Link, useSearchParams, Navigate } from 'react-router-dom';
import * as Yup from 'yup';
import { useSelector } from 'react-redux';

import { history, fetchFromAPI } from '_helpers';

import { Button, TextInput } from '_components';

import logo from '../../../_assets/neuwell-logo.gif';

export { ResetPassword };

function ResetPassword() {
    const authUser = useSelector((x) => x.auth.user);
    const [msg, setMsg] = useState(null);
    // eslint-disable-next-line no-unused-vars
    const [searchParams, setSearchParams] = useSearchParams();
    const email = searchParams.get('email');
    const token = searchParams.get('token');

    useEffect(() => {
        document.title = `Neuwell® - Password Reset`;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [authUser]);

    // form validation rules
    const validationSchema = Yup.object().shape({
        password: Yup.string().min(8, 'Password must be a minimum of 8 characters').max(100, 'Max 100 characters'),
        confirmPassword: Yup.string().oneOf([Yup.ref('password'), null], 'Passwords must match')
    });
    const formOptions = { resolver: yupResolver(validationSchema) };

    // get functions to build form with useForm() hook
    const { register, handleSubmit, formState } = useForm(formOptions);

    const { errors, isSubmitting } = formState;

    function onSubmit({ password, confirmPassword }) {
        setMsg(null);

        return fetchFromAPI('users/reset-password', {
            body: {
                token: token,
                password: password
            }
        })
            .then((res) => {
                if (res === 'success') {
                    history.navigate('/login?reset=true');
                } else {
                    throw res;
                }
            })
            .catch((error) => {
                console.log(error);
                setMsg('Password reset link invalid, please return to forgot my password and try again');
            });
    }

    if (authUser) {
        // already logged in, redirect to dashboard
        return <Navigate to="/" />;
    }

    if (!email || !token) {
        return <Navigate to="/login" />;
    }

    return (
        <div className="flex h-screen">
            <div className="card relative m-auto inline-block rounded-xl bg-nw-blue pt-10 pb-10 pl-4 pr-4 drop-shadow-md md:pt-[75px] md:pb-[75px] md:pl-[175px] md:pr-[175px]">
                <img className="mx-auto mb-1 w-[200px]" src={logo} alt="neuwell logo" />
                <div className="card-body max-w-[280px]">
                    <p className="text-md mb-6 text-center font-light text-nw-grey">PASSWORD RESET</p>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="mb-4">
                            <TextInput
                                name="password"
                                type="password"
                                label="New password"
                                props={register('password')}
                                width="280px"
                            />
                            <div className="ml-5 mt-1 text-sm text-red-700">{errors.password?.message}</div>
                        </div>
                        <div className="mb-5">
                            <TextInput
                                name="confirmPassword"
                                type="password"
                                label="Confirm password"
                                props={register('confirmPassword')}
                                width="280px"
                            />
                            <div className="ml-5 mt-1 text-sm text-red-700">{errors.confirmPassword?.message}</div>
                        </div>
                        <div className="mt-5">
                            <Button text="Reset password" disabled={isSubmitting} formHeight={true} fullWidth={true} />
                        </div>
                    </form>
                    <Link to="../login">
                        <span className="mt-2 block text-center text-xs text-nw-grey underline">Back to login</span>
                    </Link>
                    {msg && (
                        <div className="mt-4 mb-0 max-w-[280px] rounded-2xl bg-white p-2 text-center text-sm text-nw-grey">
                            {msg}
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}
