import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import moment from 'moment';
import { Button, TextInput, TextSelect } from '_components';
import { authActions } from '_store';

export { ManageAccount };

function ManageAccount() {
    const [msg, setMsg] = useState('');
    const [password, setPassword] = useState('');
    // const [newUser, setNewUser] = useState({});
    const { user: authUser } = useSelector((x) => x.auth);
    const dispatch = useDispatch();
    const dateOfBirth = authUser.user.dateOfBirth;
    const dobDay = moment(dateOfBirth).format('DD');
    const dobMonth = moment(dateOfBirth).format('MMMM');
    const dobYear = moment(dateOfBirth).format('YYYY');

    const dobYears = Array.from({ length: 110 }, (_, i) => moment().year() - i);
    const phoneRegExp = /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/;

    // form validation rules
    let validationSchema;

    validationSchema = Yup.object().shape({
        title: Yup.string().required('Title is required').max(100, 'Max 100 characters'),
        firstName: Yup.string().required('First name is required').max(100, 'Max 100 characters'),
        lastName: Yup.string().required('Last name is required').max(100, 'Max 100 characters'),
        phoneNumber: Yup.string().min(4, 'Phone number is required').matches(phoneRegExp, 'Phone number is not valid'),
        gender: Yup.string().required('Gender is required').max(100, 'Max 100 characters'),
        ethnicity: Yup.string().required('Ethnicity is required').max(100, 'Max 100 characters'),
        dobDay: Yup.string().required('Day is required').max(100, 'Max 100 characters'),
        dobMonth: Yup.string().required('Month is required').max(100, 'Max 100 characters'),
        dobYear: Yup.string().required('Year is required').max(100, 'Max 100 characters'),
        password:
            password !== ''
                ? Yup.string().min(8, 'Password must be a minimum of 8 characters').max(100, 'Max 100 characters')
                : '',
        confirmPassword: Yup.string().oneOf([Yup.ref('password'), null], 'Passwords must match')
    });

    const formOptions = { resolver: yupResolver(validationSchema) };

    // get functions to build form with useForm() hook
    const { register, handleSubmit, reset, formState } = useForm(formOptions);

    const { errors, isSubmitting } = formState;

    function capitalizeFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
    }

    function updateAccount({
        title,
        firstName,
        lastName,
        phoneNumber,
        gender,
        ethnicity,
        dobDay,
        dobMonth,
        dobYear,
        currentPassword,
        password,
        confirmPassword
    }) {
        setMsg('');

        const dateOfBirth = moment(dobDay + ' ' + dobMonth + ' ' + dobYear, 'DD MMMM YYYY').format();
        firstName = capitalizeFirstLetter(firstName);
        lastName = capitalizeFirstLetter(lastName);

        let newUser = {
            ...authUser.user,
            title: title,
            firstName: firstName,
            lastName: lastName,
            phoneNumber: phoneNumber,
            gender: gender,
            ethnicity: ethnicity,
            dateOfBirth: dateOfBirth
        };

        return dispatch(
            authActions.updateAccount({
                newUser,
                currentPassword,
                password
            })
        )
            .then((res) => {
                if (res.meta.requestStatus !== 'fulfilled') {
                    setMsg('Password incorrect.');
                } else {
                    setMsg('Account updated successfully.');
                    reset({
                        currentPassword: '',
                        password: '',
                        confirmPassword: ''
                    });
                }
            })
            .catch((res) => setMsg('Password incorrect.'));
    }

    return (
        <>
            <p className="mt-8 text-center text-2xl text-nw-offBlack">Edit Profile</p>
            <form className="mt-6" onSubmit={handleSubmit(updateAccount)}>
                <div className="grid grid-flow-row gap-4 pt-4 sm:grid-flow-col">
                    <div className="">
                        <TextSelect
                            name="title"
                            options={['Mr', 'Mrs', 'Miss', 'Ms']}
                            label="Title"
                            width="100%"
                            props={register('title')}
                            value={authUser.user.title !== undefined ? authUser.user.title : ''}
                        />
                        <div className="ml-5 mt-1 text-sm text-red-700">{errors.title?.message}</div>
                    </div>
                    <div className="">
                        <TextInput
                            name="firstName"
                            label="First name"
                            width="100%"
                            props={register('firstName')}
                            value={authUser.user.firstName !== undefined ? authUser.user.firstName : ''}
                        />
                        <div className="ml-5 mt-1 text-sm text-red-700">{errors.firstName?.message}</div>
                    </div>
                    <div className="">
                        <TextInput
                            name="lastName"
                            label="Last name"
                            width="100%"
                            props={register('lastName')}
                            value={authUser.user.lastName !== undefined ? authUser.user.lastName : ''}
                        />
                        <div className="ml-5 mt-1 text-sm text-red-700">{errors.lastName?.message}</div>
                    </div>
                </div>
                <div className="pt-6">
                    <TextInput
                        name="phoneNumber"
                        label="Phone number"
                        width="100%"
                        props={register('phoneNumber')}
                        value={authUser.user.phoneNumber !== undefined ? authUser.user.phoneNumber : ''}
                    />
                    <div className="ml-5 mt-1 text-sm text-red-700">{errors.phoneNumber?.message}</div>
                    <p className="mt-1 ml-4 text-sm font-light text-nw-grey">
                        In case we need to contact you in an emergency
                    </p>
                </div>
                <div className="mt-4 grid grid-flow-row gap-4 sm:grid-flow-col sm:grid-cols-2">
                    <div className="">
                        <TextSelect
                            name="gender"
                            options={['Male', 'Female']}
                            label="Gender at birth"
                            width="100%"
                            props={register('gender')}
                            value={authUser.user.gender !== undefined ? authUser.user.gender : ''}
                        />
                        <div className="ml-5 mt-1 text-sm text-red-700">{errors.gender?.message}</div>
                    </div>
                    <div className="">
                        <TextSelect
                            name="ethnicity"
                            options={[
                                'White English / Welsh / Scottish / Northern Irish / British',
                                'White Irish',
                                'White Gypsy or Irish Traveller',
                                'White and Black Caribbean',
                                'White and Black African',
                                'White and Asian',
                                'Indian',
                                'Pakistani',
                                'Bangladeshi',
                                'Chinese',
                                'African',
                                'Caribbean',
                                'Arab',
                                'Other'
                            ]}
                            label="Ethnicity"
                            width="100%"
                            props={register('ethnicity')}
                            value={authUser.user.ethnicity !== undefined ? authUser.user.ethnicity : ''}
                        />
                        <div className="ml-5 mt-1 text-sm text-red-700">{errors.ethnicity?.message}</div>
                    </div>
                </div>
                <p className="ml-4 mt-4 text-sm text-nw-grey">Date of birth:</p>
                <div className="mt-2 grid grid-flow-row gap-4 sm:grid-flow-col sm:grid-cols-3">
                    <div className="">
                        <TextSelect
                            name="dobDay"
                            options={[
                                1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24,
                                25, 26, 27, 28, 29, 30, 31
                            ]}
                            label="Day"
                            width="100%"
                            props={register('dobDay')}
                            value={dobDay}
                        />
                        <div className="ml-5 mt-1 text-sm text-red-700">{errors.dobDay?.message}</div>
                    </div>
                    <div className="">
                        <TextSelect
                            name="dobMonth"
                            options={[
                                'January',
                                'February',
                                'March',
                                'April',
                                'May',
                                'June',
                                'July',
                                'August',
                                'September',
                                'October',
                                'November',
                                'December'
                            ]}
                            label="Month"
                            width="100%"
                            props={register('dobMonth')}
                            value={dobMonth}
                        />
                        <div className="ml-5 mt-1 text-sm text-red-700">{errors.dobMonth?.message}</div>
                    </div>
                    <div className="">
                        <TextSelect
                            name="dobYear"
                            options={dobYears}
                            label="Year"
                            width="100%"
                            props={register('dobYear')}
                            value={dobYear}
                        />
                        <div className="ml-5 mt-1 text-sm text-red-700">{errors.dobYear?.message}</div>
                    </div>
                </div>
                <div className="mt-6 h-[1px] w-full rounded-3xl bg-nw-lightGrey"></div>
                <p className="my-6 text-center font-medium text-nw-grey">Update your password</p>
                <div className="mt-4">
                    <TextInput
                        name="currentPassword"
                        label="Current password"
                        type="password"
                        width="100%"
                        props={register('currentPassword')}
                        callback={(e) => {
                            setPassword(e.target.value);
                        }}
                    />
                    <div className="ml-5 mt-1 text-sm text-red-700">{errors.currentPassword?.message}</div>
                </div>
                <div className="mt-6">
                    <TextInput
                        name="password"
                        label="New password"
                        type="password"
                        width="100%"
                        props={register('password')}
                    />
                    <div className="ml-5 mt-1 text-sm text-red-700">{errors.password?.message}</div>
                </div>
                <div className="mt-6">
                    <TextInput
                        name="confirmPassword"
                        label="Confirm password"
                        type="password"
                        width="100%"
                        props={register('confirmPassword')}
                    />
                    <div className="ml-5 mt-1 text-sm text-red-700">{errors.confirmPassword?.message}</div>
                </div>
                <div className="mt-6">
                    <Button text="Save" disabled={isSubmitting} shadow={true} fullWidth={true} icon={false} />
                </div>
                {msg && (
                    <div className="my-3 mb-0 rounded-2xl bg-white p-2 text-center text-sm text-nw-grey">{msg}</div>
                )}
                <div className="mt-6 h-[1px] w-full rounded-3xl bg-nw-lightGrey"></div>
            </form>
        </>
    );
}
