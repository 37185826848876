import logo from '../_assets/neuwell-logo.gif';

export { PublicNav };

function PublicNav({ heading }) {
    return (
        <nav className="relative z-50 py-2 shadow-md">
            <div className="container mx-auto flex flex-wrap items-center justify-between px-4">
                <a href="https://neuwell.co.uk/">
                    <img
                        className="relative left-[-9px] w-[175px] lg:mb-1 lg:w-[200px]"
                        src={logo}
                        alt="Neuwell logo"
                    />
                </a>
                <div className="hidden w-full text-xl font-light text-nw-darkGrey md:block md:w-auto">{heading}</div>
                <div className="w-auto py-4 text-right lg:w-[200px]">
                    <a href="https://neuwell.co.uk">
                        <button className="btn text-xs font-light text-nw-darkGrey underline hover:text-black md:text-base">
                            CONTINUE SHOPPING
                        </button>
                    </a>
                </div>
            </div>
        </nav>
    );
}
