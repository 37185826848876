import { useEffect } from 'react';
import { PublicNav } from './PublicNav';

export { PublicRoute };

function PublicRoute({ title, heading, children }) {
    useEffect(() => {
        document.title = `Neuwell® - ${title}`;
    }, [title]);

    return (
        <>
            <PublicNav heading={heading} />
            <div className="container px-4">{children}</div>
        </>
    );
}
