import { Link } from 'react-router-dom';
import { Checkout } from './Checkout';
import { Button } from '_components';
import { products } from '_helpers';

export { ProductTester };

function ProductTester() {
    return (
        <>
            <Checkout
                children={
                    <>
                        <p className="mb-4 text-xl font-medium underline">Product Catalogue</p>
                        <div className="grid grid-cols-2">
                            {Object.keys(products).map((key) => {
                                return products[key].type === 'BOOKING' ? (
                                    <div key={key}>
                                        <Link to={'/booking?id=' + key}>
                                            <div className="mt-6 mb-2 underline hover:cursor-pointer">
                                                {products[key].title} (One-time)
                                            </div>
                                        </Link>
                                        <Link to={'/booking?id=' + key + '&frequency=1'}>
                                            <div className="mb-4 underline hover:cursor-pointer">
                                                {products[key].title} (3 Month reoccurring)
                                            </div>
                                        </Link>
                                    </div>
                                ) : (
                                    <div key={key}>
                                        <Link to={'/add-to-basket?id=' + key}>
                                            <div className="mt-6 mb-2 underline hover:cursor-pointer">
                                                {products[key].title} (One-time)
                                            </div>
                                        </Link>
                                        <Link to={'/add-to-basket?id=' + key + '&frequency=1'}>
                                            <div className="mb-4 underline hover:cursor-pointer">
                                                {products[key].title} (3 Month reoccurring)
                                            </div>
                                        </Link>
                                    </div>
                                );
                            })}
                        </div>
                    </>
                }
            />
        </>
    );
}
