import { useState, useEffect } from 'react';

export { Loading };

function Loading({ loading = false, triggerStop = false, delay = 750 }) {
    const [isLoading, setIsLoading] = useState(loading);
    const [fadeOut, setFadeOut] = useState(false);

    useEffect(() => {
        if (isLoading) {
            setTimeout(() => {
                setFadeOut(true);
            }, delay - 300);
            setTimeout(() => {
                setIsLoading(false);
            }, delay);
        }
    }, [triggerStop, isLoading, delay]);

    return (
        <>
            {isLoading && (
                <div
                    className={
                        'loading-screen fixed top-0 left-0 z-20 h-full w-full bg-white' + (fadeOut ? ' fadeOut' : '')
                    }
                >
                    <div className="flex h-screen justify-center">
                        <div className="lds-grid my-auto">
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}
