import React, { useState, useEffect } from 'react';
import { authActions, cartActions } from '_store';
import { useSelector, useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { GoogleMap, MarkerF, useJsApiLoader } from '@react-google-maps/api';
import {
    Button,
    Product,
    BookingProduct,
    STIProduct,
    ONCONTEXTProduct,
    PRENATALSAFEProduct,
    TRUCHECKProduct
} from '_components';
import { googleAPILibraries, products } from '_helpers';
import packaging from '../../../_assets/packaging.png';
import oncontextPackaging from '../../../_assets/onconext.svg';
import trucheckPackaging from '../../../_assets/trucheck.svg';
import prenatalPackaging from '../../../_assets/prenatal_safe.svg';
import markerIcon from '../../../_assets/location-marker.png';
import { Popup, TextInput } from '_components';

export { Products };

function Products() {
    const dispatch = useDispatch();
    const [postcodeInput, setPostcode] = useState('');
    const [clinics, setClinics] = useState(null);
    const [partnerClinics, setPartnerClinics] = useState(null);
    const [truCheckClinics, setTruCheckClinics] = useState(null);
    const [displayClinics, setDisplayClinics] = useState(null);
    const [showPartnerClinics, setShowPartnerClinics] = useState(false);
    const [isSubmittingPostcode, setSubmittingPostcode] = useState(false);
    const [showProducts, setShowProducts] = useState(null);
    const [additionalBiomarkers, setAdditionalBiomarkers] = useState(null);

    const containerStyle = {
        width: '100%',
        height: '100%',
        borderRadius: '1.5rem'
    };

    const center = {
        lat: 54.15,
        lng: -3.5
    };

    const cart = useSelector((state) => state.cart);

    function LocationCard({ name, displayName, address }) {
        return (
            <div className="relative mb-6 rounded-3xl border border-nw-lightGrey p-6">
                <p className="text-xl font-medium text-nw-offBlack">{displayName}</p>
                {address && (
                    <>
                        <p className="mt-4 font-medium text-nw-offBlack">Address:</p>
                        <p className="text-nw-offBlack">{address}</p>
                    </>
                )}
            </div>
        );
    }

    function findClosestClinics(originLat, originLng) {
        let results = [];

        for (let i = 0; i < clinics.length; i++) {
            let destLat = clinics[i].location.lat;
            let destLng = clinics[i].location.lon;

            results.push({
                clinicIndex: i,
                distance: getHaversineDist(originLat, originLng, destLat, destLng)
            });
        }

        results = results
            // eslint-disable-next-line array-callback-return
            .sort((a, b) => {
                if (a.distance < b.distance) {
                    return -1;
                }
            })
            .slice(0, 3); // return 3 closest clinics

        let closestClinics = [];

        for (let i = 0; i < results.length; i++) {
            let clinic = clinics[results[i].clinicIndex];
            clinic['distance'] = results[i].distance;
            closestClinics.push(clinics[results[i].clinicIndex]);
        }

        setDisplayClinics(closestClinics);
    }

    function getHaversineDist(originLat, originLon, destLat, destLon) {
        const R = 6371e3; // metres
        const φ1 = (originLat * Math.PI) / 180; // φ, λ in radians
        const φ2 = (destLat * Math.PI) / 180;
        const Δφ = ((destLat - originLat) * Math.PI) / 180;
        const Δλ = ((destLon - originLon) * Math.PI) / 180;

        const a =
            Math.sin(Δφ / 2) * Math.sin(Δφ / 2) + Math.cos(φ1) * Math.cos(φ2) * Math.sin(Δλ / 2) * Math.sin(Δλ / 2);
        const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

        const d = R * c; // in metres

        return Math.round(d * 0.000621); // return in miles
    }

    async function getCoords(postcode) {
        await window
            .fetch(
                `https://maps.googleapis.com/maps/api/geocode/json?address=${postcode}&key=${process.env.REACT_APP_GOOGLE_API_KEY}`,
                {
                    method: 'POST'
                }
            )
            .then((response) => response.json())
            .then((json) => {
                //ZERO_RESULTS appears if no results.
                if (json.status === 'OK') {
                    findClosestClinics(json.results[0].geometry.location.lat, json.results[0].geometry.location.lng);
                }
                setSubmittingPostcode(false);
            });
    }

    // form validation rules
    const validationSchema = Yup.object().shape({
        postcode: Yup.string()
            .required('Postcode is required')
            .matches(/^[a-z]{1,2}\d[a-z\d]?\s*\d[a-z]{2}$/i, 'Please enter a valid UK postcode')
    });
    const formOptions = { resolver: yupResolver(validationSchema) };

    // get functions to build form with useForm() hook
    const { register, handleSubmit, formState } = useForm(formOptions);

    const { errors } = formState;

    function onSubmit({ postcode }) {
        setSubmittingPostcode(true);
        getCoords(postcode);
    }

    function handlePostcodeChange(e) {
        setPostcode(e.target.value);
    }

    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY,
        libraries: googleAPILibraries
    });

    const query = `
    query {
        partnerClinicCollection(order:sys_firstPublishedAt_ASC) {
            items {
                name
                displayAddress
                location {
                    lon
                    lat
                }
            }
        }

        truCheckClinicCollection(order:sys_firstPublishedAt_ASC) {
            items {
                name
                displayAddress
                location {
                    lon
                    lat
                }
            }
        }
    }
    `;

    function setActiveClinics(clinics) {
        setDisplayClinics(null);

        if (clinics === 'partner') {
            setClinics(partnerClinics);
        } else {
            setClinics(truCheckClinics);
        }

        setShowPartnerClinics(true);
    }

    useEffect(() => {
        window
            .fetch(
                `https://graphql.contentful.com/content/v1/spaces/${process.env.REACT_APP_CONTENTFUL_SPACE}/environments/${process.env.REACT_APP_CONTENTFUL_ENV}/?access_token=${process.env.REACT_APP_CONTENTFUL_GRAPHQL_TOKEN}`,
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({ query })
                }
            )
            .then((response) => response.json())
            .then((json) => {
                // setClinics(json.data.partnerClinicCollection.items);
                setPartnerClinics(json.data.partnerClinicCollection.items);
                setTruCheckClinics(json.data.partnerClinicCollection.items);
            });
    }, [query]);

    useEffect(() => {
        let activeProducts = [];
        let extraBiomarkers = {};

        //Reset stripeClientSecret to avoid payment errors
        dispatch(authActions.storeStripeClientSecret({ clientSecret: null }));

        cart.cart.forEach((item) => {
            if (products[item.productID] !== undefined) {
                activeProducts.push(item);

                if (item['additionalBiomarkers'] !== undefined) {
                    let total = 0;
                    Object.keys(item['additionalBiomarkers']).forEach(function (key) {
                        total += Object.values(item['additionalBiomarkers'][key]).reduce(
                            (a, { value }) => a + value,
                            0
                        );
                    });
                    extraBiomarkers[item.UID] = total;
                }
            }
        });

        setShowProducts(activeProducts);
        setAdditionalBiomarkers(extraBiomarkers);
    }, [cart.cart, dispatch]);

    return showProducts !== null && showProducts.length > 0 ? (
        <>
            {showPartnerClinics && (
                <Popup
                    children={
                        <>
                            <p className="mx-auto mt-10 max-w-[200px] text-center text-2xl text-nw-offBlack sm:max-w-none md:mt-0">
                                Find your nearest clinic
                            </p>
                            <p className="mt-4 text-center font-light">
                                Enter your postcode below and we'll show you our closest partner clinics.
                            </p>
                            <div className="mt-6 lg:mt-2 lg:flex">
                                <div className="lg:mr-[3%] lg:w-[45%]">
                                    <form onSubmit={handleSubmit(onSubmit)}>
                                        <div className="mt-5 mb-6 flex flex-col sm:flex-row md:px-10 lg:px-0">
                                            <div className="flex sm:w-[55%]">
                                                <TextInput
                                                    name="postcode"
                                                    label="Enter your postcode"
                                                    width="100%"
                                                    value={postcodeInput}
                                                    props={register('postcode')}
                                                    callback={handlePostcodeChange}
                                                />
                                            </div>
                                            <div className="mt-3 flex sm:mt-0 sm:ml-[5%] sm:w-[40%]">
                                                <Button
                                                    text="Find"
                                                    shadow={true}
                                                    disabled={isSubmittingPostcode}
                                                    fullWidth={true}
                                                />
                                            </div>
                                        </div>
                                    </form>

                                    <div className="relative bottom-4 ml-5 mb-1 text-sm text-red-700">
                                        {errors.postcode?.message}
                                    </div>

                                    {displayClinics === null &&
                                        clinics !== null &&
                                        clinics
                                            .slice(0, 3)
                                            .map((clinic, index) => (
                                                <LocationCard
                                                    key={clinic.name}
                                                    name={clinic.name}
                                                    displayName={index + 1 + '. ' + clinic.name}
                                                    address={clinic.displayAddress}
                                                />
                                            ))}

                                    {displayClinics !== null &&
                                        displayClinics.map((clinic, index) => (
                                            <LocationCard
                                                key={clinic.name}
                                                name={clinic.name}
                                                displayName={
                                                    index + 1 + '. ' + clinic.name + ' (' + clinic.distance + ' miles)'
                                                }
                                                address={clinic.displayAddress}
                                            />
                                        ))}
                                </div>
                                <div className="lg:w-[52%]">
                                    <div className="relative mt-10 hidden h-[500px] w-full rounded-3xl md:h-[550px] lg:mt-5 lg:block lg:h-[615px]">
                                        {isLoaded && (
                                            <GoogleMap
                                                mapContainerStyle={containerStyle}
                                                center={center}
                                                zoom={6}
                                                options={{
                                                    mapId: '3700b719bf2b505b',
                                                    fullscreenControl: false,
                                                    mapTypeControl: false,
                                                    streetViewControl: false
                                                }}
                                            >
                                                {clinics !== null &&
                                                    clinics.map((clinic, index) => (
                                                        <MarkerF
                                                            key={'Marker: ' + clinic.name}
                                                            name={clinic.name}
                                                            position={{
                                                                lat: clinic.location.lat,
                                                                lng: clinic.location.lon
                                                            }}
                                                            icon={markerIcon}
                                                        />
                                                    ))}
                                            </GoogleMap>
                                            // <div>Google map</div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </>
                    }
                    onClose={() => setShowPartnerClinics(false)}
                />
            )}
            <div>
                {cart.error !== null && (
                    <div className="mb-4 rounded-2xl border-4 border-red-300 bg-red-200 p-5 text-center font-medium text-nw-offBlack">
                        {cart.error}
                    </div>
                )}
                {showProducts
                    .slice(0)
                    .reverse()
                    .map((item, index) => {
                        return products[item.productID] !== undefined ? (
                            products[item.productID].display === 'INCLINIC' ? (
                                <BookingProduct
                                    item={item}
                                    personalise={products[item.productID].personalise !== null}
                                    additionalBiomarkers={additionalBiomarkers[item.UID]}
                                    key={item.title + '-' + index}
                                />
                            ) : products[item.productID].display === 'FINGERPRICK-STI' ? (
                                <STIProduct
                                    item={item}
                                    additionalBiomarkers={additionalBiomarkers[item.UID]}
                                    productImage={packaging}
                                    key={item.title + '-' + index}
                                />
                            ) : products[item.productID].display === 'ONCONTEXT' ? (
                                <ONCONTEXTProduct
                                    item={item}
                                    personalise={products[item.productID].personalise !== null}
                                    additionalBiomarkers={additionalBiomarkers[item.UID]}
                                    productImage={oncontextPackaging}
                                    key={item.title + '-' + index}
                                    partnerClinicCallback={() => setActiveClinics('partner')}
                                />
                            ) : products[item.productID].display === 'PRENATALSAFE' ? (
                                <PRENATALSAFEProduct
                                    item={item}
                                    personalise={products[item.productID].personalise !== null}
                                    additionalBiomarkers={additionalBiomarkers[item.UID]}
                                    productImage={prenatalPackaging}
                                    key={item.title + '-' + index}
                                    partnerClinicCallback={() => setActiveClinics('partner')}
                                />
                            ) : products[item.productID].display === 'TRUCHECK' ? (
                                <TRUCHECKProduct
                                    item={item}
                                    personalise={products[item.productID].personalise !== null}
                                    additionalBiomarkers={additionalBiomarkers[item.UID]}
                                    productImage={trucheckPackaging}
                                    key={item.title + '-' + index}
                                    partnerClinicCallback={() => setActiveClinics('trucheck')}
                                />
                            ) : (
                                <Product
                                    item={item}
                                    personalise={products[item.productID].personalise !== null}
                                    additionalBiomarkers={additionalBiomarkers[item.UID]}
                                    productImage={packaging}
                                    key={item.title + '-' + index}
                                    partnerClinicCallback={() => setActiveClinics('partner')}
                                />
                            )
                        ) : (
                            <></>
                        );
                    })}
            </div>
        </>
    ) : cart.cart.length === 0 ? (
        <div>
            <p className="text-lg text-nw-offBlack">Your shopping cart is empty...</p>
            <a href="https://neuwell.co.uk">
                <div className="mt-6 w-[300px]">
                    <Button text="Back to shop" shadow={true} fullWidth={true} icon={true} formHeight={false} />
                </div>
            </a>
        </div>
    ) : (
        ''
    );
}
