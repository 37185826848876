import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import pako from 'pako';
import { Buffer } from 'buffer';
import { fetchWrapper, products } from '_helpers';

const name = 'cart';
const initialState = createInitialState();
const reducers = createReducers();
const extraActions = createExtraActions();
const extraReducers = createExtraReducers();
const slice = createSlice({ name, initialState, reducers, extraReducers });

// exports

export const cartActions = { ...slice.actions, ...extraActions };
export const cartReducer = slice.reducer;

// implementation

function createInitialState() {
    return {
        cart: [],
        itemCount: 0,
        error: null,
        personalisationError: null
    };
}

function createReducers() {
    return {
        updateBooking,
        addToCart,
        setProductFrequency,
        setProductExtra,
        setProductTestType,
        setAdditionalBiomarker,
        setPersonalisationError,
        removeItem,
        clearCart,
        setCartError
    };

    function updateBooking(state, action) {
        const itemInBasket = state.cart.find((item) => item.UID === action.payload.UID);

        if (itemInBasket && products[itemInBasket.productID].type === 'BOOKING') {
            itemInBasket.booking = action.payload.booking;
            state.error = null;
        }
    }

    function addToCart(state, action) {
        const item = state.cart.find((item) => item.UID === action.payload.UID);

        if (!item && state.cart.length < 10) {
            let pid = action.payload.productID;

            const bookingExists = state.cart.find((item) => item.type === 'BOOKING');

            if (bookingExists !== undefined && products[pid].type === 'BOOKING') {
                state.error =
                    'A maximum of one in clinic booking is allowed per checkout. Please remove your existing booking.';
            } else {
                let name = action.payload.name;
                let testType = {};

                if (products[pid].display === 'FINGERPRICK') {
                    testType = {
                        title: 'Finger prick kit',
                        price: '0'
                    };
                } else if (
                    products[pid].display === 'ONCONTEXT' ||
                    products[pid].display === 'TRUCHECK' ||
                    products[pid].display === 'PRENATALSAFE'
                ) {
                    testType = {
                        title: 'In Clinic Appointment',
                        price: '0'
                    };
                }

                state.cart.push({
                    ...action.payload,
                    UID: state.itemCount,
                    title: name,
                    productID: pid,
                    type: products[pid].type,
                    testType,
                    testCodes: products[pid].testCodes,
                    display: products[pid].display
                });
                state.itemCount = state.itemCount + 1;
                state.error = null;
            }
        }
    }

    function setProductFrequency(state, action) {
        const item = state.cart.find((item) => item.UID === action.payload.UID);
        item.frequency = action.payload.frequency;
    }

    function setProductExtra(state, action) {
        const item = state.cart.find((item) => item.UID === action.payload.UID);
        if (item['extras'] === undefined) {
            item.extras = {};
        }
        item.extras[action.payload.extra] = {
            title: action.payload.extra,
            value: action.payload.value,
            price: action.payload.price
        };
    }

    function setProductTestType(state, action) {
        const item = state.cart.find((item) => item.UID === action.payload.UID);
        if (item['testType'] === undefined) {
            item.testType = {};
        }

        item.testType.title = action.payload.testType;
        item.testType.price = action.payload.price;
    }

    function setAdditionalBiomarker(state, action) {
        const item = state.cart.find((item) => item.UID === action.payload.UID);
        if (item['additionalBiomarkers'] === undefined) {
            item.additionalBiomarkers = {};
        }
        if (item.additionalBiomarkers[action.payload.category] === undefined) {
            item.additionalBiomarkers[action.payload.category] = {};
        }
        item.additionalBiomarkers[action.payload.category][action.payload.biomarker] = {
            title: action.payload.biomarker,
            value: action.payload.value,
            price: action.payload.price,
            codes: action.payload.codes
        };

        let tempShortAdditionalBiomarkers = {};

        // Encode additionalBiomarkers to allow for more tests due to stripe metadata limit
        Object.values(item.additionalBiomarkers).forEach((category) => {
            Object.values(category).forEach((biomarker) => {
                if (biomarker.value) {
                    tempShortAdditionalBiomarkers[biomarker.title] = {
                        p: biomarker.price,
                        c: biomarker.codes === undefined ? [] : [...biomarker.codes]
                    };
                }
            });
        });

        const biomarkerCount = Object.keys(tempShortAdditionalBiomarkers).length;

        if (biomarkerCount > 0) {
            const minifiedJson = JSON.stringify(tempShortAdditionalBiomarkers);
            const compressed = pako.gzip(minifiedJson);

            // If adding an additionalBiomarker and length is more than 500, remove bioMarker to adhear to stripe metadata limit.
            if (biomarkerCount < 31) {
                item.encodedAdditionalBiomarkers = Buffer.from(compressed).toString('base64');
                state.personalisationError = null;
            } else {
                item.additionalBiomarkers[action.payload.category][action.payload.biomarker].value = false;
                state.personalisationError = 'You have reached the maximum number of add-ons.';
            }
        } else {
            item.encodedAdditionalBiomarkers = '';
        }
    }

    function setPersonalisationError(state, action) {
        state.personalisationError = action.payload;
    }

    function removeItem(state, action) {
        const removeItem = state.cart.filter((item) => item.UID !== action.payload);
        state.cart = removeItem;
        state.error = null;
    }

    function clearCart(state) {
        state.cart = [];
        state.error = null;
    }

    function setCartError(state, action) {
        state.error = action.payload;
    }
}

function createExtraActions() {
    const baseUrl = `${process.env.REACT_APP_API_URL}/checkout`;

    return {
        addClinicRequest: addClinicRequest()
    };

    function addClinicRequest() {
        return createAsyncThunk(
            `${name}/addClinicRequest`,
            async ({ email, phone, postcode }) =>
                await fetchWrapper.post(`${baseUrl}/add-clinic-request`, { email, phone, postcode })
        );
    }
}

function createExtraReducers() {
    return {
        ...addClinicRequest()
    };

    function addClinicRequest() {
        var { pending, fulfilled, rejected } = extraActions.addClinicRequest;
        return {
            [pending]: (state) => {
                state.error = null;
            },
            [fulfilled]: (state, action) => {},
            [rejected]: (state, action) => {
                state.error = action.error;
            }
        };
    }
}
