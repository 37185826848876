import { useState, useEffect } from 'react';

export { TextInput };

function TextInput({ name, type = 'text', label, props, width = 'auto', value = '', callback = (e) => null }) {
    const [inputValue, setValue] = useState(value);

    function handleChange(e) {
        setValue(e.target.value);
        callback(e);
    }

    useEffect(() => {
        setValue(value);
    }, [value]);

    return (
        <div className="input-container relative flex flex-col" style={{ width: width }}>
            <input
                className={'rounded-3xl border border-nw-lightGrey font-light drop-shadow-md focus:border-nw-grey'}
                name={name}
                type={type}
                {...props}
                value={inputValue}
                onChange={handleChange}
            />
            <label className={'absolute font-light text-nw-grey' + (inputValue && ' filled')}>{label}</label>
        </div>
    );
}
