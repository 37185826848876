import { useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { cartActions } from '_store';
import { Loading } from '_components';
import { history, products, fetchFromAPI } from '_helpers';

export { AddToCart };

function AddToCart() {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(true);
    // eslint-disable-next-line no-unused-vars
    const [searchParams, setSearchParams] = useSearchParams();
    const clamp = (num, min, max) => Math.min(Math.max(num, min), max);

    useEffect(() => {
        const ID = searchParams.get('id');

        if (products[ID] === undefined) {
            history.navigate('/checkout', { replace: true });
        } else {
            let frequency = parseInt(searchParams.get('frequency'));

            if (isNaN(frequency)) {
                frequency = 0;
            }

            frequency = clamp(frequency, 0, 3);

            fetchFromAPI('checkout/get-stripe-product', {
                body: {
                    stripeProductId: ID
                }
            })
                .then((res) => {
                    const name = res.name;
                    const price = res.default_price.unit_amount / 100;
                    const freq = isNaN(frequency) ? 0 : frequency;
                    setLoading(false);

                    if (products[ID].type !== 'BOOKING') {
                        window.dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.

                        window.dataLayer.push({
                            event: 'add_to_cart',
                            ecommerce: {
                                currency: 'GBP',
                                value: price,
                                items: [
                                    {
                                        id: ID,
                                        name: name,
                                        category: products[ID].display,
                                        quantity: 1,
                                        price: price
                                    }
                                ]
                            }
                        });

                        dispatch(
                            cartActions.addToCart({
                                name: name,
                                basePrice: price,
                                productID: ID,
                                frequency: freq
                            })
                        );

                        history.navigate('/checkout', { replace: true });
                    } else {
                        let bookingJSON = JSON.parse(searchParams.get('booking'));

                        if (bookingJSON === null) {
                            history.navigate('/booking?id=' + ID);
                        } else {
                            window.dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.

                            window.dataLayer.push({
                                event: 'add_to_cart',
                                ecommerce: {
                                    currency: 'GBP',
                                    value: price,
                                    items: [
                                        {
                                            id: ID,
                                            name: name,
                                            category: products[ID].display,
                                            quantity: 1,
                                            price: price
                                        }
                                    ]
                                }
                            });

                            dispatch(
                                cartActions.addToCart({
                                    name: name,
                                    basePrice: price,
                                    productID: ID,
                                    frequency: freq,
                                    booking: bookingJSON
                                })
                            );

                            history.navigate('/checkout', { replace: true });
                        }
                    }
                })
                .catch((error) => {
                    console.log(error);
                    history.navigate('/checkout', { replace: true });
                });
        }
    }, [searchParams, dispatch]);

    return <Loading loading={true} triggerStop={!loading} delay={500} />;
}
