import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { cartActions } from '_store';
import { PriceCard, Select, Popup } from '_components';
import graphic from '../../_assets/graphic.png';
import paperCopyIcon from '../../_assets/paper-copy-of-results.svg';
import { checkNested, getItemPriceBreakdown, products } from '_helpers';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt } from '@fortawesome/free-regular-svg-icons';
import { faVial, faChevronRight } from '@fortawesome/free-solid-svg-icons';

export { STIProduct };

function STIProduct({ item, productImage }) {
    const [popupContent, setPopupContent] = useState(null);
    const dispatch = useDispatch();
    const [popup, setPopup] = useState(false);
    const [totalPrice, setTotalPrice] = useState(0);
    const [frequency, setFrequency] = useState(item.frequency !== undefined ? parseInt(item.frequency) : 0);
    const freqMap = {
        1: '3 months',
        2: '6 months',
        3: 'year'
    };

    function ExtrasCard({ eIcon, eTitle, eBody, ePrice, eItem }) {
        return (
            <PriceCard
                icon={eIcon}
                title={eTitle}
                body={eBody}
                price={ePrice}
                toggleCallback={(val) => {
                    dispatch(
                        cartActions.setProductExtra({
                            UID: eItem.UID,
                            extra: eTitle,
                            price: ePrice,
                            value: val
                        })
                    );
                }}
                selected={checkNested(eItem, 'extras', eTitle) ? eItem['extras'][eTitle].value : false}
            />
        );
    }

    useEffect(() => {
        const breakdown = getItemPriceBreakdown(item);
        setTotalPrice(breakdown.total);
    }, [item]);

    return (
        <>
            {popupContent && (
                <Popup
                    children={popupContent}
                    onClose={() => {
                        setPopupContent(null);
                    }}
                />
            )}
            <div className="mb-8 rounded-[27px] bg-[#E2F0F4] p-1">
                {popup === true && (
                    <Popup
                        children={
                            <>
                                <div className="py-4 text-center">
                                    <p className="text-2xl text-nw-offBlack">Prescription included</p>
                                    <p className="mt-6 text-nw-offBlack">
                                        Our doctors will provide a private prescription for antibiotics should any of
                                        your results come back positive. You can either purchase your antibiotics
                                        directly through us with discrete next day delivery or from any pharmacy using
                                        your private prescription. Our team will contact you directly via phone and
                                        email notifying you of your results and to arrange next steps.
                                    </p>
                                </div>
                            </>
                        }
                        onClose={() => setPopup(false)}
                    />
                )}
                <div className="relative rounded-3xl bg-nw-blue py-6 px-6">
                    <div className="my-2 flex-col md:flex xl:flex-row">
                        <div className="hidden xl:mr-[2%] xl:block xl:w-[28%]">
                            <img className="relative hidden w-full sm:block" src={productImage} alt="Product" />
                        </div>
                        <div className="w-full xl:mr-[2%] xl:w-[50%]">
                            <div className="md:flex xl:block">
                                <img className="hidden w-[200px] md:block xl:hidden" src={productImage} alt="Product" />
                                <div className="w-full md:mt-2 md:pl-4 xl:mt-0 xl:pl-0">
                                    <p className="text-2xl text-nw-offBlack md:mt-2">{item.title}</p>
                                    <div
                                        className="relative mt-2 flex w-full justify-center rounded-2xl bg-black py-5 text-center text-white hover:cursor-pointer"
                                        onClick={() => setPopup(true)}
                                    >
                                        <img
                                            className="relative mr-3 mt-2 hidden h-[36px] w-[60px] sm:block"
                                            src={graphic}
                                            alt="Free prescription"
                                        />
                                        <div className="px-4 text-left md:max-w-[200px] md:px-0">
                                            <div className="relative mr-0 font-semibold md:px-0">
                                                Free prescription included for STI checks
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div
                                className="relative mt-4 flex hover:cursor-pointer"
                                onClick={() =>
                                    setPopupContent(
                                        <>
                                            <p className="text-center text-lg text-nw-offBlack">{item.name}</p>
                                            <p className="text-center text-2xl text-nw-offBlack">Markers Tested</p>
                                            {products[item.productID].markersTested}
                                        </>
                                    )
                                }
                            >
                                <FontAwesomeIcon
                                    icon={faVial}
                                    style={{ color: '#7A7A7A' }}
                                    className="relative h-7 w-7 text-nw-grey"
                                />
                                <p className="ml-2 text-lg text-nw-darkGrey">Markers Tested</p>
                                <FontAwesomeIcon
                                    icon={faChevronRight}
                                    style={{ color: '#7A7A7A' }}
                                    className="absolute top-1 right-0 h-5 w-5 text-nw-grey"
                                />
                            </div>
                            <div className="mt-2 h-[1px] w-full rounded-3xl bg-nw-lightGrey"></div>
                        </div>
                        <div className="w-full xl:w-[41%]">
                            <div className="mt-5 flex">
                                <div className="mr-8 w-[150px]">
                                    <p className="mb-1 ml-2 text-nw-offBlack">Frequency</p>
                                    <Select
                                        name="frequency"
                                        options={['One-time', '3 Months', '6 Months', '1 Year']}
                                        label="Frequency"
                                        width="100%"
                                        selected={item.frequency !== undefined ? item.frequency : 0}
                                        callback={(e) => {
                                            setFrequency(parseInt(e.target.value));
                                            dispatch(
                                                cartActions.setProductFrequency({
                                                    UID: item.UID,
                                                    frequency: parseInt(e.target.value)
                                                })
                                            );
                                        }}
                                    />
                                </div>
                                <div>
                                    <p className="mb-1 ml-2 text-nw-offBlack">Quantity</p>
                                    <div
                                        className="my-1 flex rounded-2xl border border-nw-lightGrey bg-white font-light drop-shadow-md"
                                        onClick={() => dispatch(cartActions.removeItem(item.UID))}
                                    >
                                        <div className="rounded-2xl border-r border-nw-lightGrey px-3 py-1.5 transition-colors hover:cursor-pointer hover:bg-[#f9f9f9]">
                                            <FontAwesomeIcon
                                                icon={faTrashAlt}
                                                style={{ color: '#7A7A7A' }}
                                                className="relative top-0.5 h-5 w-5 text-nw-grey"
                                            />
                                        </div>
                                        <div className="ml-5 mr-6 mt-2 text-nw-offBlack">1</div>
                                    </div>
                                </div>
                            </div>
                            {frequency === 0 && (
                                <div className="mt-4 text-sm font-light text-nw-grey">
                                    Your {item.title} will be charged one-time at{' '}
                                    <span className="font-medium">£{totalPrice}</span>. You can view this order at any
                                    time using your dashboard.
                                </div>
                            )}
                            {frequency > 0 && (
                                <div className="mt-4 text-sm font-light text-nw-grey">
                                    Your {item.title} will be charged at{' '}
                                    <span className="font-medium">£{totalPrice}</span> then{' '}
                                    <span className="font-medium">£{totalPrice}</span> every{' '}
                                    <span className="font-medium">{freqMap[frequency]}</span>. You can cancel or change
                                    the frequency of your test at any time using your dashboard.
                                </div>
                            )}
                        </div>
                    </div>

                    <div className="mt-4 rounded-3xl pt-2">
                        <div className="text-center text-xl font-light text-nw-offBlack">
                            <div className="rounded-2xl px-8 py-1">Extras:</div>
                        </div>
                        <ExtrasCard
                            eIcon={paperCopyIcon}
                            eTitle={'Get a Personalised Results Brochure'}
                            eBody={'Get a result brochure sent to your address along with your doctors report.'}
                            ePrice={10}
                            eItem={item}
                        />
                    </div>
                </div>
            </div>
        </>
    );
}
