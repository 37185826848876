import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { history, fetchWrapper } from '_helpers';

const name = 'auth';
const initialState = createInitialState();
const reducers = createReducers();
const extraActions = createExtraActions();
const extraReducers = createExtraReducers();
const slice = createSlice({ name, initialState, reducers, extraReducers });

// exports

export const authActions = { ...slice.actions, ...extraActions };
export const authReducer = slice.reducer;

// implementation

function createInitialState() {
    return {
        // initialize state from local storage to enable user to stay logged in
        user: JSON.parse(localStorage.getItem('user')),
        error: null
    };
}

function createReducers() {
    return {
        logout,
        noRedirectlogout,
        storeShippingDetails,
        storeStripeCustomerId,
        storeStripeClientSecret
    };

    function logout(state) {
        localStorage.removeItem('user');
        state.user = null;
        history.navigate('/login');
    }

    function noRedirectlogout(state) {
        localStorage.removeItem('user');
        state.user = null;
    }

    function storeShippingDetails(state, action) {
        let user = state.user;

        user.user.shippingFullName = action.payload.fullName;
        user.user.shippingAddress1 = action.payload.address1;
        user.user.shippingAddress2 = action.payload.address2;
        user.user.shippingCity = action.payload.city;
        user.user.shippingPostcode = action.payload.postcode;

        localStorage.setItem('user', JSON.stringify(user));
        state.user = user;
    }

    function storeStripeCustomerId(state, action) {
        let user = state.user;

        user.user.stripeCustomerId = action.payload.id;

        localStorage.setItem('user', JSON.stringify(user));
        state.user = user;
    }

    function storeStripeClientSecret(state, action) {
        let user = state.user;

        if (user?.user !== undefined) {
            user.user.stripeClientSecret = action.payload.clientSecret;

            localStorage.setItem('user', JSON.stringify(user));
            state.user = user;
        }
    }
}

function createExtraActions() {
    const baseUrl = `${process.env.REACT_APP_API_URL}/users`;

    return {
        login: login(),
        loginNoRedirect: loginNoRedirect(),
        createAccount: createAccount(),
        updateAccount: updateAccount(),
        updatePaymentMethod: updatePaymentMethod()
    };

    function login() {
        return createAsyncThunk(
            `${name}/login`,
            async ({ username, password }) => await fetchWrapper.post(`${baseUrl}/authenticate`, { username, password })
        );
    }

    function loginNoRedirect() {
        return createAsyncThunk(
            `${name}/loginNoRedirect`,
            async ({ username, password }) => await fetchWrapper.post(`${baseUrl}/authenticate`, { username, password })
        );
    }

    function createAccount() {
        return createAsyncThunk(
            `${name}/createAccount`,
            async ({ username, title, firstName, lastName, phoneNumber, gender, ethnicity, dateOfBirth, password }) =>
                await fetchWrapper.post(`${baseUrl}/create-account`, {
                    username,
                    title,
                    firstName,
                    lastName,
                    phoneNumber,
                    gender,
                    ethnicity,
                    dateOfBirth,
                    password
                })
        );
    }

    function updateAccount() {
        return createAsyncThunk(
            `${name}/updateAccount`,
            async ({ newUser, currentPassword, password }) =>
                await fetchWrapper.post(`${baseUrl}/update-account`, { newUser, currentPassword, password })
        );
    }

    function updatePaymentMethod() {
        return createAsyncThunk(
            `${name}/updatePaymentMethod`,
            async ({ entryID, paymentMethodID }) =>
                await fetchWrapper.post(`${baseUrl}/update-payment-method`, { entryID, paymentMethodID })
        );
    }
}

function createExtraReducers() {
    return {
        ...login(),
        ...loginNoRedirect(),
        ...createAccount(),
        ...updateAccount(),
        ...updatePaymentMethod()
    };

    function login() {
        var { pending, fulfilled, rejected } = extraActions.login;
        return {
            [pending]: (state) => {
                state.error = null;
            },
            [fulfilled]: (state, action) => {
                const user = action.payload;

                // store user details and jwt token in local storage to keep user logged in between page refreshes
                localStorage.setItem('user', JSON.stringify(user));
                state.user = user;

                // get return url from location state or default to results page
                const { from } = history.location.state || { from: { pathname: '/' } };
                history.navigate(from);
            },
            [rejected]: (state, action) => {
                state.error = action.error;
            }
        };
    }

    function loginNoRedirect() {
        var { pending, fulfilled, rejected } = extraActions.loginNoRedirect;
        return {
            [pending]: (state) => {
                state.error = null;
            },
            [fulfilled]: (state, action) => {
                const user = action.payload;

                // store user details and jwt token in local storage to keep user logged in between page refreshes
                localStorage.setItem('user', JSON.stringify(user));
                state.user = user;
            },
            [rejected]: (state, action) => {
                state.error = action.error;
            }
        };
    }

    function createAccount() {
        var { pending, fulfilled, rejected } = extraActions.createAccount;
        return {
            [pending]: (state) => {
                state.error = null;
            },
            [fulfilled]: (state, action) => {
                const user = action.payload;

                // store user details and jwt token in local storage to keep user logged in between page refreshes
                localStorage.setItem('user', JSON.stringify(user));
                state.user = user;
            },
            [rejected]: (state, action) => {
                state.error = action.error;
            }
        };
    }

    function updateAccount() {
        var { pending, fulfilled, rejected } = extraActions.updateAccount;
        return {
            [pending]: (state) => {
                state.error = null;
            },
            [fulfilled]: (state, action) => {
                const user = action.payload;
                // store user details and jwt token in local storage to keep user logged in between page refreshes
                localStorage.setItem('user', JSON.stringify(user));
                state.user = user;
            },
            [rejected]: (state, action) => {
                state.error = action.error;
            }
        };
    }

    function updatePaymentMethod() {
        var { pending, fulfilled, rejected } = extraActions.updatePaymentMethod;
        return {
            [pending]: (state) => {
                state.error = null;
            },
            [fulfilled]: (state, action) => {
                let user = state.user;

                user.user.stripePaymentMethod = action.payload.stripePaymentMethod;

                localStorage.setItem('user', JSON.stringify(user));
                state.user = user;
            },
            [rejected]: (state, action) => {
                state.error = action.error;
            }
        };
    }
}
