import React, { useState } from 'react';
import { Link, Navigate, useSearchParams, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Button } from '_components';
import { useStripe, useElements, PaymentElement } from '@stripe/react-stripe-js';
import { authActions } from '_store';

export { AddPayment };

function AddPayment() {
    const stripe = useStripe();
    const elements = useElements();
    // eslint-disable-next-line no-unused-vars
    const [searchParams, setSearchParams] = useSearchParams();
    const secret = searchParams.get('id');
    const { user: authUser } = useSelector((x) => x.auth);
    let navigate = useNavigate();
    const dispatch = useDispatch();

    const [stripeError, setStripeError] = useState('');
    const [stripeProcessing, setStripeProcessing] = useState(false);

    const handleSubmit = async (event) => {
        event.preventDefault();

        if (!stripe || !elements) {
            return null;
        }

        setStripeProcessing(true);

        const { error, setupIntent } = await stripe.confirmSetup({
            elements,
            confirmParams: {
                return_url: window.location.href + '/my-account/payment'
            },
            redirect: 'if_required'
        });

        if (error) {
            setStripeError(error.message);
            console.log(error.message);
            setStripeProcessing(false);
        } else if (setupIntent && setupIntent.status === 'succeeded') {
            dispatch(
                authActions.updatePaymentMethod({
                    entryID: authUser?.user.sys.id,
                    paymentMethodID: setupIntent.payment_method
                })
            )
                .then((res) => {
                    const routeChange = () => {
                        let path = `/my-account/payment`;
                        navigate(path);
                    };
                    //Save new details to contentful and redirect
                    routeChange();
                })
                .catch((res) => console.log("can't update payment method."));
        }
    };

    if (authUser?.user?.stripeClientSecret !== secret) {
        return <Navigate to="/my-account/payment" />;
    }

    return (
        <div className="min-h-[70vh]">
            <p className="mt-8 text-center text-2xl text-nw-offBlack">Update Payment method</p>
            <div className="mt-6 pt-4"></div>

            <form onSubmit={handleSubmit}>
                <PaymentElement />
                <div className="mt-6">
                    <Button
                        text="Save"
                        shadow={true}
                        fullWidth={true}
                        icon={false}
                        disabled={!stripe || stripeProcessing}
                    />
                </div>
            </form>

            <div className="mt-4">
                <Link to={'/my-account/payment'}>
                    <Button text="Back" shadow={true} fullWidth={true} icon={false} disabled={false} red={true} />
                </Link>
            </div>

            {stripeError && (
                <div className="mt-6 mb-0 rounded-3xl bg-nw-grey p-2 py-3 text-center text-sm text-white">
                    {stripeError}
                </div>
            )}

            <div className="mt-6 h-[1px] w-full rounded-3xl bg-nw-lightGrey"></div>
        </div>
    );
}
