import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Button, Select, PopupChoice, Loading, Popup } from '_components';
import { fetchWrapper, products } from '_helpers';
import moment from 'moment';
import { Link } from 'react-router-dom';
import packaging from '../../../_assets/packaging.png';
import oncontextIcon from '../../../_assets/onconext.svg';
import prenatalIcon from '../../../_assets/prenatal_safe.svg';
import trucheckIcon from '../../../_assets/trucheck.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faVial, faChevronRight } from '@fortawesome/free-solid-svg-icons';

export { Orders };

function Orders() {
    const [loading, setLoading] = useState(false);
    const [freqPopup, setFreqPopup] = useState(false);
    const [markersTestedPopup, setMarkersTestedPopup] = useState(null);
    const [cancelPopup, setCancelPopup] = useState(false);
    const [cancelOrder, setCancelOrder] = useState(null);
    const [lastFreq, setLastFreq] = useState(null);
    const [subscriptionUpdating, setSubscriptionUpdating] = useState(false);
    const [newPaymentDate, setNewPaymentDate] = useState({});
    const [orderData, setOrderData] = useState(null);
    const [subscriptionData, setSubscriptionData] = useState(null);
    const { user: authUser } = useSelector((x) => x.auth);

    const freqTbl = {
        '3 Months': 0,
        '6 Months': 1,
        '1 Year': 2
    };

    const revFreqTbl = {
        0: '3 Months',
        1: '6 Months',
        2: '1 Year'
    };

    const freqMonthTbl = {
        0: '3',
        1: '6',
        2: '12'
    };

    const query = `
        query {
            orderCollection(where: { patient: { patientRef: "${authUser?.user.patientRef}" } } ) {
                items {
                    testId
                    orderId
                    productTitle
                    frequency
                    orderDate
                    display
                    stripeProductId
                    activeSubscription
                    triggerDate
                    cost
                }
            }
        }
    `;

    useEffect(() => {
        setLoading(true);

        window
            .fetch(
                `https://graphql.contentful.com/content/v1/spaces/${process.env.REACT_APP_CONTENTFUL_SPACE}/environments/${process.env.REACT_APP_CONTENTFUL_ENV}/?access_token=${process.env.REACT_APP_CONTENTFUL_GRAPHQL_TOKEN}`,
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({ query })
                }
            )
            .then((response) => response.json())
            .then((json) => {
                const dataset = json.data.orderCollection.items;

                if (dataset.length > 0) {
                    const oneTimeItems = dataset.filter((item) => {
                        return item.frequency === 'One-time';
                    });

                    if (oneTimeItems.length > 0) {
                        setOrderData(oneTimeItems);
                    }

                    const recurringItems = dataset.filter((item) => {
                        return item.activeSubscription === true;
                    });

                    if (recurringItems.length > 0) {
                        setSubscriptionData(recurringItems);
                    }
                }

                setLoading(false);
            });
    }, [query]);

    async function updateSubscription(testId, frequency = null, triggerDate = null, cancel = false) {
        setSubscriptionUpdating(true);

        await fetchWrapper
            .post(`${process.env.REACT_APP_API_URL}/orders/update-subscription`, {
                testId: testId,
                frequency: frequency !== null ? frequency + 1 : null,
                triggerDate: triggerDate,
                cancel: cancel
            })
            .then(() => {
                setSubscriptionUpdating(false);
                setFreqPopup(false);
                setCancelPopup(false);
                window.location.reload(false);
            })
            .catch((error) => {
                console.log(error);
                setSubscriptionUpdating(false);
                setFreqPopup(false);
                setCancelPopup(false);
            });
    }

    function renderFreqPopup(order, frequency) {
        if (freqTbl[order.frequency] !== frequency) {
            setLastFreq(null);
            const curDate = Date.now();
            const mDate = moment(curDate);
            let newNextPaymentDate = moment(mDate).add(freqMonthTbl[frequency], 'month').format();
            setNewPaymentDate({ order: order, triggerDate: newNextPaymentDate, frequency: frequency });
            setFreqPopup(true);
        }
        //const newTriggerDate = moment(curDate).add(freqMonthTbl[item.frequency], 'month').format();
    }

    return (
        <>
            <Loading loading={true} triggerStop={!loading} delay={650} />

            {markersTestedPopup && (
                <Popup
                    children={markersTestedPopup}
                    onClose={() => {
                        setMarkersTestedPopup(null);
                    }}
                />
            )}

            {freqPopup && (
                <PopupChoice
                    title={`Update ${newPaymentDate.order.productTitle}`}
                    body={
                        <p className="mt-6 text-nw-offBlack">
                            Are you sure you want to upgrade this subscription's frequency to{' '}
                            <span className="font-medium">{revFreqTbl[newPaymentDate.frequency]}</span> Your new next
                            payment date will be{' '}
                            <span className="font-medium">
                                {moment(newPaymentDate.triggerDate).format('DD/MM/YYYY')}
                            </span>
                            .
                        </p>
                    }
                    yesText={'Yes'}
                    noText={'No'}
                    onClose={() => {
                        if (!subscriptionUpdating) {
                            setLastFreq(freqTbl[newPaymentDate.order.frequency]);
                            setFreqPopup(false);
                        }
                    }}
                    onYes={() =>
                        updateSubscription(
                            newPaymentDate.order.testId,
                            newPaymentDate.frequency,
                            newPaymentDate.triggerDate,
                            false
                        )
                    }
                    onNo={() => {
                        if (!subscriptionUpdating) {
                            setLastFreq(freqTbl[newPaymentDate.order.frequency]);
                            setFreqPopup(false);
                        }
                    }}
                    submitting={subscriptionUpdating}
                />
            )}

            {cancelPopup && (
                <PopupChoice
                    title={`Cancel ${cancelOrder.productTitle}`}
                    body={
                        <p className="mt-6 text-nw-offBlack">
                            Are you sure you wan't to cancel this subscription? Please note all subscription related
                            test data will be kept upon unsubscription.
                        </p>
                    }
                    yesText={'Yes'}
                    noText={'No'}
                    onClose={() => {
                        if (!subscriptionUpdating) {
                            setCancelPopup(false);
                        }
                    }}
                    onYes={() => updateSubscription(cancelOrder.testId, null, null, true)}
                    onNo={() => {
                        if (!subscriptionUpdating) {
                            setCancelPopup(false);
                        }
                    }}
                    submitting={subscriptionUpdating}
                />
            )}

            <div className="mx-auto mb-10 min-h-[110vh] max-w-[1240px] md:mb-20">
                <h1 className="mt-10 mb-4 text-center text-3xl text-nw-offBlack lg:mt-20">Orders</h1>
                {orderData !== null && (
                    <>
                        <p className="mt-4 mb-2 text-center text-nw-darkGrey">
                            Here is a list of your previous one time purchases and bookings.
                        </p>

                        {orderData.map((order, index) => (
                            <div className="mt-10" key={order.productTitle + '-' + index}>
                                <div className="flex flex-col justify-center gap-8 lg:gap-8">
                                    <div className="flex gap-8 sm:mx-auto lg:min-h-[200px] lg:gap-14">
                                        {order.display !== 'INCLINIC' && (
                                            <img
                                                className="relative hidden w-[100px] object-contain sm:block lg:w-[220px]"
                                                src={
                                                    order.display === 'ONCONTEXT'
                                                        ? oncontextIcon
                                                        : order.display === 'PRENATALSAFE'
                                                        ? prenatalIcon
                                                        : order.display === 'TRUCHECK'
                                                        ? trucheckIcon
                                                        : packaging
                                                }
                                                alt="Product"
                                            />
                                        )}
                                        <div className="my-auto">
                                            <p className="text-2xl text-nw-offBlack">{order.productTitle}</p>
                                            <div
                                                className="w-[300px] min-w-[200px] md:min-w-[300px]"
                                                onClick={() => {
                                                    if (products[order.stripeProductId] !== undefined) {
                                                        setMarkersTestedPopup(
                                                            <>
                                                                <p className="text-center text-lg text-nw-offBlack">
                                                                    {order.productTitle}
                                                                </p>
                                                                <p className="text-center text-2xl text-nw-offBlack">
                                                                    Markers Tested
                                                                </p>
                                                                {products[order.stripeProductId].markersTested}
                                                            </>
                                                        );
                                                    }
                                                }}
                                            >
                                                <div className="relative mt-4 flex hover:cursor-pointer">
                                                    <FontAwesomeIcon
                                                        icon={faVial}
                                                        style={{ color: '#7A7A7A' }}
                                                        className="relative h-7 w-7 text-nw-grey"
                                                    />
                                                    <p className="ml-2 text-lg text-nw-darkGrey">Markers Tested</p>
                                                    <FontAwesomeIcon
                                                        icon={faChevronRight}
                                                        style={{ color: '#7A7A7A' }}
                                                        className="absolute top-1 right-0 h-5 w-5 text-nw-grey"
                                                    />
                                                </div>
                                                <div className="mt-2 h-[1px] w-full rounded-3xl bg-nw-lightGrey"></div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="flex flex-col gap-4 sm:mx-auto sm:flex-row sm:gap-8 lg:ml-auto lg:mt-[-50px] lg:gap-14">
                                        <div className="my-auto">
                                            <p className="font-medium text-nw-offBlack underline">Order date</p>
                                            <p className="mt-2 text-nw-offBlack sm:mt-4">
                                                {moment(order.orderDate).format('DD/MM/YYYY')}
                                            </p>
                                        </div>
                                        <div className="my-auto">
                                            <p className="font-medium text-nw-offBlack underline">Order ID</p>
                                            <p className="mt-2 text-nw-offBlack sm:mt-4 md:max-w-[130px]">
                                                {order.orderId}
                                            </p>
                                        </div>
                                        <div className="my-auto">
                                            <p className="font-medium text-nw-offBlack underline">Charge</p>
                                            <p className="mt-2 text-nw-offBlack sm:mt-4">
                                                {order.cost === null || order.cost === 0 ? `Free` : `£${order.cost}`}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </>
                )}
                {orderData === null && <p className="mt-4 mb-4 text-center text-nw-grey">No orders currently exist.</p>}
                <div className="mt-8 h-0.5 w-full rounded-3xl bg-nw-lightGrey lg:mt-16"></div>

                <h1 className="mt-10 mb-4 text-center text-3xl text-nw-offBlack lg:mt-20">Subscriptions</h1>

                {subscriptionData !== null && (
                    <>
                        <p className="mx-auto mt-4 mb-4 max-w-[775px] text-center text-nw-darkGrey">
                            Here you can manage your existing subscriptions. Please note, by changing a test's
                            frequency, you allow Neuwell to charge your card for future payments in accordance with
                            their terms.
                        </p>
                        <div className="mt-10 flex flex-col justify-center gap-6 sm:flex-row">
                            <div className="mx-auto w-[250px] sm:mx-0">
                                <Link to={'/my-account/shipping'}>
                                    <Button
                                        text="Update Shipping details"
                                        shadow={false}
                                        active={false}
                                        fullWidth={true}
                                    />
                                </Link>
                            </div>

                            <div className="mx-auto w-[250px] sm:mx-0">
                                <Link to={'/my-account/payment'}>
                                    <Button
                                        text="Update Payment details"
                                        shadow={false}
                                        active={false}
                                        fullWidth={true}
                                    />
                                </Link>
                            </div>
                        </div>
                        <div className="mt-16 lg:mt-20">
                            {subscriptionData.map((order, index) => (
                                <div
                                    className="mb-10 flex flex-col justify-center gap-8 md:flex-row lg:mb-20 lg:gap-14"
                                    key={order.productTitle + '-sub' + order.triggerDate}
                                >
                                    <div className="flex gap-8 lg:gap-14">
                                        {order.display !== 'INCLINIC' && (
                                            <img
                                                className="relative hidden w-[100px] object-contain sm:block sm:h-[100px] lg:h-auto lg:w-[200px]"
                                                src={
                                                    order.display === 'ONCONTEXT'
                                                        ? oncontextIcon
                                                        : order.display === 'PRENATALSAFE'
                                                        ? prenatalIcon
                                                        : order.display === 'TRUCHECK'
                                                        ? trucheckIcon
                                                        : packaging
                                                }
                                                alt="Product"
                                            />
                                        )}
                                        <div className="my-auto w-full">
                                            <p className="text-2xl text-nw-offBlack">{order.productTitle}</p>
                                            <div className="md:w-[300px]">
                                                <div className="relative mt-4 flex hover:cursor-pointer">
                                                    <FontAwesomeIcon
                                                        icon={faVial}
                                                        style={{ color: '#7A7A7A' }}
                                                        className="relative h-7 w-7 text-nw-grey"
                                                    />
                                                    <p className="ml-2 text-lg text-nw-darkGrey">Markers Tested</p>
                                                    <FontAwesomeIcon
                                                        icon={faChevronRight}
                                                        style={{ color: '#7A7A7A' }}
                                                        className="absolute top-1 right-0 h-5 w-5 text-nw-grey"
                                                    />
                                                </div>
                                                <div className="mt-2 h-[1px] w-full rounded-3xl bg-nw-lightGrey"></div>
                                                <p className="mt-4 mb-1 ml-2 font-medium text-nw-offBlack">
                                                    Frequency:
                                                </p>
                                                <div className="flex gap-4">
                                                    <Select
                                                        name="frequency"
                                                        options={['3 Months', '6 Months', '1 Year']}
                                                        label="Frequency"
                                                        width="100%"
                                                        selected={freqTbl[order.frequency]}
                                                        callback={
                                                            (e) => {
                                                                renderFreqPopup(order, parseInt(e.target.value));
                                                            }
                                                            // setOrderUpdateFrequencies(...orderUpdateFrequencies, {
                                                            //     index: e.target.value
                                                            // })
                                                        }
                                                        updateValue={lastFreq !== null ? lastFreq : null}
                                                    />
                                                    <div
                                                        className="w-full"
                                                        onClick={() => {
                                                            setCancelOrder(order);
                                                            setCancelPopup(true);
                                                        }}
                                                    >
                                                        <Button
                                                            text="Cancel"
                                                            shadow={true}
                                                            fullWidth={true}
                                                            formHeight={false}
                                                            red={true}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="my-auto grid grid-flow-row sm:ml-[130px] md:ml-0">
                                        <div className="grid grid-flow-col grid-cols-2 gap-8 lg:gap-12">
                                            <div className="">
                                                <p className="font-medium text-nw-offBlack underline">Last payment</p>
                                                <p className="mt-2 text-nw-offBlack sm:mt-4">
                                                    {moment(order.orderDate).format('DD/MM/YYYY')}
                                                </p>
                                            </div>
                                            <div className="">
                                                <p className="font-medium text-nw-offBlack underline">Next payment</p>
                                                <p className="mt-2 text-nw-offBlack sm:mt-4">
                                                    {moment(order.triggerDate).format('DD/MM/YYYY')}
                                                </p>
                                            </div>
                                        </div>
                                        <div className="mt-4 grid grid-flow-col grid-cols-2 gap-8 lg:gap-12">
                                            <div className="">
                                                {/* <p className="font-medium text-nw-offBlack underline">Order number</p>
                                                <p className="mt-2 text-nw-offBlack sm:mt-4">{order.orderId}</p> */}
                                                <p className="font-medium text-nw-offBlack underline">Order ID</p>
                                                <p className="mt-2 text-nw-offBlack sm:mt-4 md:max-w-[130px] md:text-xs">
                                                    {order.orderId}
                                                </p>
                                            </div>
                                            <div className="">
                                                <p className="font-medium text-nw-offBlack underline">Charge</p>
                                                <p className="mt-2 text-nw-offBlack sm:mt-4">£{order.cost}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </>
                )}
                {subscriptionData === null && (
                    <p className="mt-4 mb-4 text-center text-nw-grey">You have no active subscriptions.</p>
                )}
                <div className="mt-8 h-0.5 w-full rounded-3xl bg-nw-lightGrey lg:mt-16"></div>
            </div>
        </>
    );
}
