import React, { useEffect } from 'react';
import { Routes, Route, Navigate, useNavigate, useLocation } from 'react-router-dom';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { useSelector } from 'react-redux';
import { useIntercom } from 'react-use-intercom';
import { history } from '_helpers';
import { PublicRoute, PrivateRoute } from '_components';
import {
    Login,
    ForgotPassword,
    ResetPassword,
    Results,
    Account,
    Orders,
    ManageAccount,
    ManageShipping,
    ManagePayment,
    AddPayment
} from '_views/app';
import {
    Booking,
    Checkout,
    Products,
    Personalise,
    CartAccount,
    CartShipping,
    CartPayment,
    PaymentSuccess,
    AddToCart,
    ProductTester
} from '_views/shop';
// import ReactGA from 'react-ga4';
import TagManager from 'react-gtm-module';

export { App };

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

// ReactGA.initialize('G-2K6498YYEZ');

const tagManagerArgs = {
    gtmId: 'GTM-PKT5JHSX'
};

TagManager.initialize(tagManagerArgs);

function App() {
    // init custom history object to allow navigation from
    // anywhere in the react app (inside or outside components)
    history.navigate = useNavigate();
    history.location = useLocation();

    const authUser = useSelector((x) => x.auth.user);
    const { boot } = useIntercom();

    let location = useLocation();

    useEffect(() => {
        // Only send production site pageviews to Google Analytics
        //if (process.env.REACT_APP_ENV === 'main') {
        // ReactGA.send({ hitType: 'pageview', page: location.pathname });
        //}

        window.dataLayer.push({
            event: 'pageview',
            page: {
                path: location.pathname
            }
        });
    }, [location]);

    const options = {
        // passing the client secret obtained from the server
        clientSecret: authUser?.user?.stripeClientSecret
    };

    useEffect(() => {
        boot();
    }, [boot]);

    return (
        <div className="app-container">
            <Routes>
                <Route
                    path="/"
                    element={
                        <PrivateRoute title="Results dashboard">
                            <Results />
                        </PrivateRoute>
                    }
                />
                <Route path="/login" element={<Login />} />
                <Route path="/login/forgot-password" element={<ForgotPassword />} />
                <Route path="/login/reset-password" element={<ResetPassword />} />
                <Route path="/my-account" element={<Navigate to={'/my-account/profile'} />} />
                <Route
                    path="/my-account/profile"
                    element={
                        <PrivateRoute title="My account | Profile">
                            <Account page="profile" children={<ManageAccount />} />
                        </PrivateRoute>
                    }
                />
                <Route
                    path="/my-account/shipping"
                    element={
                        <PrivateRoute title="My account | Shipping">
                            <Account page="shipping" children={<ManageShipping />} />
                        </PrivateRoute>
                    }
                />
                <Route
                    path="/my-account/payment"
                    element={
                        <PrivateRoute title="My account | Payment">
                            <Account page="payment" children={<ManagePayment />} />
                        </PrivateRoute>
                    }
                />
                <Route
                    path="/my-account/payment/add"
                    element={
                        stripePromise && options.clientSecret ? (
                            <Elements stripe={stripePromise} options={options}>
                                <PrivateRoute title="My account | Update payment">
                                    <Account page="payment" children={<AddPayment />} />
                                </PrivateRoute>
                            </Elements>
                        ) : (
                            <Navigate to="/my-account/payment" />
                        )
                    }
                />
                <Route
                    path="/orders"
                    element={
                        <PrivateRoute title="My orders">
                            <Orders />
                        </PrivateRoute>
                    }
                />
                <Route
                    path="/booking"
                    element={
                        <PublicRoute title="Health Check Booking" heading="HEALTH CHECK BOOKING">
                            <Booking />
                        </PublicRoute>
                    }
                />
                <Route
                    path="/checkout"
                    element={
                        <PublicRoute title="Checkout" heading="SHOPPING BASKET">
                            <Checkout children={<Products />} />
                        </PublicRoute>
                    }
                />
                <Route
                    path="/checkout/personalise-test"
                    element={
                        <PublicRoute title="Checkout | Personalise test" heading="SHOPPING BASKET">
                            <Checkout children={<Personalise />} />
                        </PublicRoute>
                    }
                />
                <Route
                    path="/checkout/account"
                    element={
                        <PublicRoute title="Checkout | Account" heading="SHOPPING BASKET">
                            <Checkout children={<CartAccount />} hideButton={true} />
                        </PublicRoute>
                    }
                />
                <Route
                    path="/checkout/shipping"
                    element={
                        <PublicRoute title="Checkout | Shipping" heading="SHOPPING BASKET">
                            <Checkout children={<CartShipping />} hideButton={true} />
                        </PublicRoute>
                    }
                />
                <Route
                    path="/checkout/payment"
                    element={
                        stripePromise && options.clientSecret ? (
                            <Elements stripe={stripePromise} options={options}>
                                <PublicRoute title="Checkout | Payment" heading="SHOPPING BASKET">
                                    <Checkout children={<CartPayment />} hideButton={true} />
                                </PublicRoute>
                            </Elements>
                        ) : (
                            <Navigate to="/checkout/shipping" />
                        )
                    }
                />
                <Route
                    path="/checkout/payment/success"
                    element={
                        <PublicRoute title="Order Successful" heading="SHOPPING BASKET">
                            <PaymentSuccess />
                        </PublicRoute>
                    }
                />
                <Route
                    path="/add-to-basket"
                    element={
                        <PublicRoute title="Add to basket" heading="SHOPPING BASKET">
                            <AddToCart />
                        </PublicRoute>
                    }
                />
                <Route
                    path="/product-tester"
                    element={
                        <PublicRoute title="Product tester" heading="SHOPPING BASKET">
                            <ProductTester />
                        </PublicRoute>
                    }
                />
                <Route path="*" element={<Navigate to="/" />} />
            </Routes>
        </div>
    );
}
