import React, { Fragment, useEffect, useState } from 'react';
import { Loading, Button } from '_components';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { BLOCKS, INLINES } from '@contentful/rich-text-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
} from 'chart.js';
import { Line } from 'react-chartjs-2';

import {
    faCircle,
    faCirclePlus,
    faCircleMinus,
    faNotesMedical,
    faUserDoctor,
    faChevronDown
} from '@fortawesome/free-solid-svg-icons';

import { Select } from '_components';

export { Results };

function Results() {
    const { user: authUser } = useSelector((x) => x.auth);
    const [loading, setLoading] = useState(false);
    const [hasLoaded, setHasLoaded] = useState(false);
    const [resultData, setResultData] = useState(null);
    const [activeData, setActiveData] = useState(null);
    const [activeResults, setActiveResults] = useState([]);
    const [resultNames, setResultNames] = useState([]);
    const [rangeMarkers, setRangeMarkers] = useState([]);
    const [gpAccordion, setGpAccordion] = useState(false);
    const [gpHasAccordion, setGpHasAccordion] = useState(false);
    const [biomarkers, setBiomarkers] = useState({});
    const [activeBiomarkerDesc, setActiveBiomarkerDesc] = useState(null);

    ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

    const options = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                display: false,
                position: 'top'
            },
            tooltip: {
                displayColors: false,
                callbacks: {
                    label: (text) => {
                        return `Result: ${text.raw}`;
                    }
                }
            }
        },
        elements: {
            line: {
                tension: 0.5
            }
        },
        scales: {
            y: {
                min: 10,
                max: 45,

                ticks: {
                    stepSize: 5,
                    // Include a dollar sign in the ticks
                    callback: function (value, index, ticks) {
                        return '';
                    }
                },

                grid: {
                    display: false
                }
            },
            x: {
                grid: {
                    display: false
                },
                ticks: {
                    font: {
                        family: 'Poppins', // Add your font here to change the font of your x axis
                        size: 12,
                        weight: '400'
                    },
                    maxRotation: 0,
                    color: '#7A7A7A'
                }
            }
        }
    };

    const labels = ['13th Dec 22', '13th Jan 23', '13th Feb 23', '13th Mar 23'];

    const data = {
        labels,
        datasets: [
            {
                label: 'Result',
                data: [16, 17.2, 22, 28],
                borderColor: 'rgb(150, 150, 150)',
                backgroundColor: 'rgba(0, 0, 0, 1)'
            }
        ]
    };

    //Graph relative state values.
    const [activeGraph, setActiveGraph] = useState(null);
    const [graphType, setGraphType] = useState(0);
    const [graphBlocks, setGraphBlocks] = useState([]);

    const query = `
        query {
            resultCollection(where: { patient: { patientRef: "${authUser?.user.patientRef}" }, completed: true }, order: testDate_DESC) {
                items {
                    order {
                        testId
                        productTitle
                        type
                    }
                    testDate
                    doctorsReport {
                        json
                    }
                    ecg {
                        url
                    }
                    data
                }
            }

            biomarkerCollection {
                items {
                        title
                    description {
                        json
                    }
                }
            }
        }
    `;

    const renderDocument = (document) => {
        const Text = ({ children }) => <p>{children}</p>;

        const options = {
            renderNode: {
                [BLOCKS.PARAGRAPH]: (node, children) => <Text>{children}</Text>,
                [INLINES.HYPERLINK]: (node) => {
                    return (
                        <a href={node.data.uri} target="_blank" rel="noopener noreferrer">
                            {node.content[0].value}
                        </a>
                    );
                }
            },
            renderText: (text) => text.split('\n').flatMap((text, i) => [i > 0 && <br />, text])
        };

        return documentToReactComponents(document, options);
    };

    function formatResultNames(data) {
        let ar = [];

        for (let i = 0; i < data.length; i++) {
            ar.push(data[i].order.productTitle + ' - ' + moment(data[i].testDate).format('DD/MM/YYYY'));
        }

        setResultNames(ar);
    }

    function validateResults(data) {
        let green = 0,
            amber = 0,
            red = 0;
        let tempAr = data;

        for (let i = 0; i < data.length; i++) {
            let res = data[i];
            let range = res['Reference Range'];
            let r = parseFloat(res.Result);
            let indicator;

            //Remove anomalies.
            if (res.Result === 'NSR (No Sample Received)' || res.Result === 'insufficient' || range === undefined) {
                tempAr.splice(i, 1);
                i--;
            } else {
                //Less than case.
                if (range.charAt(0) === '<') {
                    let max = parseFloat(range.substring(1));
                    let highNorm = Math.round(max + max / 2);
                    let highRed = Math.round(max * 2);

                    if (parseFloat(res.Result) > highRed) {
                        highRed = parseFloat(res.Result);
                    }

                    if (r < max) {
                        indicator = 0;
                    } else if (r >= max && r <= highNorm) {
                        indicator = 1;
                    } else {
                        indicator = 2;
                    }

                    if (res.Result.charAt(0) === '<') {
                        tempAr[i]['refMarker'] = '#1F8722';
                        green++;
                    } else {
                        if (r < max) {
                            tempAr[i]['refMarker'] = '#1F8722';
                            green++;
                        } else if (r >= max && r <= highNorm) {
                            tempAr[i]['refMarker'] = '#F6C872';
                            amber++;
                        } else {
                            tempAr[i]['refMarker'] = '#EE8189';
                            red++;
                        }
                    }

                    tempAr[i]['graph'] = {
                        format: 'lessthan',
                        optimal: [max],
                        highNorm: highNorm,
                        highRed: highRed,
                        indicator: indicator
                    };
                }
                //More than case.
                else if (range.charAt(0) === '>') {
                    let min = parseFloat(range.substring(1));

                    if (res.Result.charAt(0) === '>') {
                        tempAr[i]['refMarker'] = '#1F8722';
                        green++;

                        tempAr[i]['graph'] = {
                            format: 'morethan',
                            optimal: [min]
                        };
                    } else {
                        let highOpt = Math.round(min + min / 2);
                        let highNorm = Math.round(min - min / 2);
                        let highRed = Math.round(min - min * 2);

                        if (r > min) {
                            indicator = 2;
                            tempAr[i]['refMarker'] = '#1F8722';
                            green++;
                        } else if (r <= min && r >= highNorm) {
                            indicator = 1;
                            tempAr[i]['refMarker'] = '#F6C872';
                            amber++;
                        } else {
                            indicator = 0;
                            tempAr[i]['refMarker'] = '#EE8189';
                            red++;
                        }

                        tempAr[i]['graph'] = {
                            format: 'morethan',
                            optimal: [min],
                            highOpt: highOpt,
                            highNorm: highNorm,
                            highRed: highRed,
                            indicator: indicator
                        };
                    }
                } else {
                    let str = range.replace(/\s/g, '');
                    let hyphenPos = str.indexOf('-', 0);

                    //Reference range case.
                    if (hyphenPos > -1) {
                        let optMin = parseFloat(str.substring(0, hyphenPos));
                        let optMax = parseFloat(str.substring(hyphenPos + 1));
                        let diff = optMax - optMin;
                        let lowNorm = optMin - diff * 0.3;
                        let highNorm = optMax + diff * 0.3;
                        let highRed = highNorm + diff * 0.5;

                        if (r < lowNorm) {
                            indicator = 0;
                            tempAr[i]['refMarker'] = '#EE8189';
                            red++;
                        } else if (r >= lowNorm && r < optMin) {
                            indicator = 1;
                            tempAr[i]['refMarker'] = '#F6C872';
                            amber++;
                        } else if (r >= optMin && r <= optMax) {
                            indicator = 2;
                            tempAr[i]['refMarker'] = '#1F8722';
                            green++;
                        } else if (r > optMax && r <= highNorm) {
                            indicator = 3;
                            tempAr[i]['refMarker'] = '#F6C872';
                            amber++;
                        } else {
                            indicator = 4;
                            tempAr[i]['refMarker'] = '#EE8189';
                            red++;
                        }

                        tempAr[i]['graph'] = {
                            format: 'range',
                            optimal: [optMin, optMax],
                            lowNorm: lowNorm,
                            highNorm: highNorm,
                            highRed: highRed,
                            indicator: indicator
                        };
                    } else {
                        //Invalid result, remove from dataset.
                        tempAr.splice(i, 1);
                        i--;
                    }
                }
            }
        }

        setActiveGraph(null);
        setRangeMarkers([green, amber, red]);
        setActiveResults(tempAr);
    }

    function validateSTIResults(data) {
        let green = 0,
            red = 0;
        let tempAr = data;

        for (let i = 0; i < data.length; i++) {
            let res = data[i];
            if (res.Result === 'not detected') {
                tempAr[i]['refMarker'] = '#1F8722';
                green++;
            } else {
                tempAr[i]['refMarker'] = '#EE8189';
                red++;
            }
        }

        setRangeMarkers([green, 0, red]);
        setActiveResults(tempAr);
    }

    function setResultsGraph(index) {
        let set = activeResults[index]['graph'];
        let result = activeResults[index].Result;
        let blocks = [];

        const clamp = (num, min, max) => Math.min(Math.max(num, min), max);
        const round = (num) => Math.round((num + Number.EPSILON) * 100) / 100;

        if (set.format === 'range') {
            let optMin = set.optimal[0];
            let optMax = set.optimal[1];
            let lowNorm = set.lowNorm;
            let highNorm = set.highNorm;
            let highRed = set.highRed;
            let indicator = set.indicator;

            let diff = optMin < 1 ? 0.01 : 0.1;

            //Lower red range
            blocks.push({
                text: '< ' + round(lowNorm),
                colour: '#EE8189', //Red
                width: 15,
                indicatorPos: indicator === 0 ? clamp((result * 100) / lowNorm, 5, 100) : ''
            });

            //Lower orange range
            blocks.push({
                text: round(lowNorm) + ' - ' + round(optMin - diff),
                colour: '#F6C872', //Orange
                width: 20,
                indicatorPos:
                    indicator === 1 ? clamp(((result - lowNorm) * 100) / (optMin - diff - lowNorm), 0, 100) : ''
            });

            //Optimal green range
            blocks.push({
                text: round(optMin) + ' - ' + round(optMax),
                colour: '#1F8722', //Green
                width: 30,
                indicatorPos: indicator === 2 ? clamp(((result - optMin) * 100) / (optMax - optMin), 0, 100) : ''
            });

            //Upper orange range
            blocks.push({
                text: round(optMax + diff) + ' - ' + round(highNorm),
                colour: '#F6C872', //Orange
                width: 20,
                indicatorPos:
                    indicator === 3
                        ? clamp(((result - optMax + diff) * 100) / (highNorm - diff - (result - optMax + diff)), 0, 100)
                        : ''
            });

            //Upper red range
            blocks.push({
                text: '> ' + round(highNorm),
                colour: '#EE8189', //Red
                width: 15,
                indicatorPos: indicator === 4 ? clamp(((result - highNorm) * 100) / (highRed - highNorm), 0, 95) : ''
            });
        } else if (set.format === 'lessthan') {
            let lowNorm = set.optimal[0];
            let highNorm = set.highNorm;
            let highRed = set.highRed;
            let indicator = set.indicator;

            //Optimal green range
            blocks.push({
                text: '<' + round(lowNorm),
                colour: '#1F8722', //Green
                width: 50,
                indicatorPos: indicator === 0 ? clamp((result * 100) / lowNorm, 5, 100) : ''
            });

            //Upper orange range
            blocks.push({
                text: round(lowNorm) + ' - ' + round(highNorm),
                colour: '#F6C872', //Orange
                width: 30,
                indicatorPos: indicator === 1 ? clamp(((result - lowNorm) * 100) / (highNorm - lowNorm), 0, 100) : ''
            });

            //Upper red range
            blocks.push({
                text: '> ' + round(highNorm),
                colour: '#EE8189', //Red
                width: 20,
                indicatorPos: indicator === 2 ? clamp(((result - highNorm) * 100) / (highRed - highNorm), 0, 95) : ''
            });
        } else if (set.format === 'morethan' && set.indicator !== undefined) {
            let lowNorm = set.optimal[0];
            let highOpt = set.highOpt;
            let highNorm = set.highNorm;
            let indicator = set.indicator;

            //Upper red range
            blocks.push({
                text: '< ' + round(highNorm),
                colour: '#EE8189', //Red
                width: 20,
                indicatorPos: indicator === 0 ? clamp((result * 100) / highNorm, 5, 100) : ''
            });

            //Upper orange range
            blocks.push({
                text: round(highNorm) + ' - ' + round(lowNorm),
                colour: '#F6C872', //Orange
                width: 30,
                indicatorPos: indicator === 1 ? clamp(((result - lowNorm) * 100) / (highNorm - lowNorm), 0, 100) : ''
            });

            //Optimal green range
            blocks.push({
                text: '>' + round(lowNorm),
                colour: '#1F8722', //Green
                width: 50,
                indicatorPos: indicator === 2 ? clamp((result * 100) / highOpt, 0, 95) : ''
            });
        }

        setGraphBlocks(blocks);
    }

    function updateActiveResults(data) {
        setActiveData(data);
        setGpAccordion(false);

        if (data.doctorsReport?.json) {
            setGpHasAccordion(JSON.stringify(data.doctorsReport.json).length > 300 ? true : false);
        }

        if (data.data.Results.length > 0) {
            validateResults(data.data.Results);
        } else {
            validateSTIResults(data.data.STIResults);
        }
    }

    function handleResultSelect(e) {
        let data = resultData[e.target.value];

        if (data !== undefined) {
            updateActiveResults(data);
        }
    }

    function handleResultClick(index) {
        if (index === activeGraph) {
            setActiveGraph(null);
        } else {
            setResultsGraph(index);
            setActiveGraph(index);

            const biomarker = biomarkers.find((marker) => marker.title === activeResults[index].Code);

            if (biomarker !== undefined) {
                setActiveBiomarkerDesc(biomarker.description.json);
            } else {
                setActiveBiomarkerDesc(null);
            }
        }
    }

    const downloadECGFile = (url, date) => {
        fetch(url).then((response) => {
            response.blob().then((blob) => {
                const fileURL = window.URL.createObjectURL(blob);
                let alink = document.createElement('a');
                alink.href = fileURL;
                alink.download = `Neuwell-ECG-Report-${date}.pdf`;
                alink.click();
            });
        });
    };

    useEffect(() => {
        window
            .fetch(
                `https://graphql.contentful.com/content/v1/spaces/${process.env.REACT_APP_CONTENTFUL_SPACE}/environments/${process.env.REACT_APP_CONTENTFUL_ENV}/?access_token=${process.env.REACT_APP_CONTENTFUL_GRAPHQL_TOKEN}`,
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({ query })
                }
            )
            .then((response) => response.json())
            .then((json) => {
                const dataset = json.data?.resultCollection?.items;
                const biomarkerDescriptions = json.data?.biomarkerCollection?.items;

                if (dataset !== undefined && dataset.length > 0) {
                    setResultData(dataset);
                    formatResultNames(dataset);
                    updateActiveResults(dataset[0]);
                }

                if (biomarkerDescriptions !== undefined) {
                    setBiomarkers(biomarkerDescriptions);
                }

                setHasLoaded(true);
                setLoading(false);
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [query]);

    return (
        <>
            <Loading loading={true} triggerStop={!loading} delay={500} />
            <div className="min-h-[100vh]">
                {hasLoaded && activeData !== null && (
                    <>
                        <div className="text-center">
                            <h1 className="mt-10 mb-4 text-3xl text-nw-offBlack lg:mt-20 lg:text-5xl">
                                Hi {authUser?.user.firstName},
                            </h1>
                            <p className="text-nw-darkGrey md:mx-20 xl:mx-10 2xl:mx-0 2xl:pr-[350px] 2xl:pl-[350px]">
                                Welcome to your Neuwell dashboard, where your lastest results can be seen below. Click
                                the plus icon on each result to further understand each test and see your trend in
                                results.
                            </p>
                            <h2 className="mt-8 mb-4 text-2xl text-nw-offBlack lg:mb-4 lg:text-3xl">
                                <FontAwesomeIcon
                                    icon={faNotesMedical}
                                    style={{ color: '#76E9FB' }}
                                    className="relative top-[2px] mr-2 h-[25px] md:h-[30px]"
                                />
                                Results
                            </h2>
                            <Select
                                options={resultNames}
                                callback={handleResultSelect}
                                width="350px"
                                className="mb-10 md:mb-8"
                            />
                            <div className="my-6 lg:my-6">
                                <p className="mb-1 font-medium text-nw-offBlack">
                                    <span className="font-light">Test:</span> {activeData.order.productTitle}
                                </p>
                                <p className="mb-1 font-medium text-nw-offBlack">
                                    <span className="font-light">Test date:</span>{' '}
                                    {moment(activeData.testDate).format('Do MMMM YYYY')}
                                </p>
                                <p className="mb-1 font-medium text-nw-offBlack">
                                    <span className="font-light">Test ID:</span> {activeData.order.testId}
                                </p>
                            </div>

                            {activeData.data.Results.length > 0 ? (
                                <div className="flex flex-col justify-center text-center md:flex-row md:text-left">
                                    <div className="mx-3 my-1 font-light">
                                        <FontAwesomeIcon
                                            icon={faCircle}
                                            style={{ color: '#1F8722' }}
                                            className="relative top-[2px] mr-2 h-[20px]"
                                        />
                                        {rangeMarkers[0]} Optimal
                                    </div>
                                    <div className="mx-3 my-1 font-light">
                                        {' '}
                                        <FontAwesomeIcon
                                            icon={faCircle}
                                            style={{ color: '#F6C872' }}
                                            className="relative top-[2px] mr-2 h-[20px]"
                                        />
                                        {rangeMarkers[1]} Normal
                                    </div>
                                    <div className="mx-3 my-1 font-light">
                                        {' '}
                                        <FontAwesomeIcon
                                            icon={faCircle}
                                            style={{ color: '#EE8189' }}
                                            className="relative top-[2px] mr-2 h-[20px]"
                                        />
                                        {rangeMarkers[2]} Abnormal
                                    </div>
                                </div>
                            ) : (
                                <>
                                    {/* {rangeMarkers[2] === 0 && (
                                        <p className="pt-2 text-center text-lg font-medium">
                                            Your results are all clear! You tested negative for the following STI's:
                                        </p>
                                    )} */}

                                    {/* {rangeMarkers[2] > 0 && ( */}
                                    <div className="flex flex-col justify-center text-center md:flex-row md:text-left">
                                        <div className="mx-3 my-1 font-light">
                                            <FontAwesomeIcon
                                                icon={faCircle}
                                                style={{ color: '#1F8722' }}
                                                className="relative top-[2px] mr-2 h-[20px]"
                                            />
                                            {rangeMarkers[0]} Negative
                                        </div>
                                        <div className="mx-3 my-1 font-light">
                                            {' '}
                                            <FontAwesomeIcon
                                                icon={faCircle}
                                                style={{ color: '#EE8189' }}
                                                className="relative top-[2px] mr-2 h-[20px]"
                                            />
                                            {rangeMarkers[2]} Positive
                                        </div>
                                    </div>
                                    {/* )} */}
                                </>
                            )}

                            {activeData.doctorsReport?.json && (
                                <div className="relative mx-auto mt-12 mb-14 rounded-xl border border-nw-offBlack 2xl:max-w-[1000px]">
                                    <div className="absolute top-[-17px] right-1/2 flex translate-x-1/2 bg-white px-4">
                                        <FontAwesomeIcon
                                            icon={faUserDoctor}
                                            style={{ color: '#54595F' }}
                                            className="relative top-[2px] mr-3 h-[25px] md:h-[30px]"
                                        />
                                        <div className="whitespace-pre text-2xl text-nw-offBlack">Doctors Report:</div>
                                    </div>
                                    <div className="relative md:mx-2">
                                        <div
                                            className={
                                                'rich-text-format mt-1 overflow-hidden px-6 py-6 text-nw-darkGrey' +
                                                (gpAccordion || !gpHasAccordion ? '' : ' max-h-[165px]')
                                            }
                                        >
                                            {renderDocument(activeData.doctorsReport.json)}

                                            <div className="mx-auto mt-6 max-w-[650px] font-medium">
                                                If you would like to discuss your results in more detail with one of our
                                                doctors, you can book an appointment below:
                                            </div>

                                            <div className="mt-4 inline-block">
                                                <a
                                                    href="https://book.stripe.com/3cs4iT72ZfHsdtS6oo"
                                                    target="_blank"
                                                    rel="noreferrer"
                                                >
                                                    <Button
                                                        text="Book appointment"
                                                        icon={false}
                                                        formHeight={true}
                                                        fullWidth={false}
                                                        shadow={false}
                                                    />
                                                </a>
                                            </div>
                                        </div>
                                        <div
                                            className={
                                                'accordion-gradient absolute bottom-0 mx-6 h-[100px] w-[96%]' +
                                                (gpAccordion || !gpHasAccordion ? ' hidden' : '')
                                            }
                                        ></div>

                                        {gpHasAccordion && (
                                            <div
                                                className="absolute bottom-[-15px] right-1/2 flex translate-x-1/2 bg-white px-4 hover:cursor-pointer"
                                                onClick={() => {
                                                    setGpAccordion(!gpAccordion);
                                                }}
                                            >
                                                <div className="whitespace-pre text-lg text-nw-offBlack">
                                                    {gpAccordion ? 'Show less' : 'Read more'}
                                                </div>
                                                <FontAwesomeIcon
                                                    icon={faChevronDown}
                                                    style={{ color: '#54595F' }}
                                                    className={
                                                        'relative top-[1px] ml-2 h-[20px] md:h-[25px]' +
                                                        (gpAccordion ? ' rotate-180' : '')
                                                    }
                                                />
                                            </div>
                                        )}
                                    </div>
                                </div>
                            )}
                        </div>

                        {activeData.ecg !== null && (
                            <div className="my-[-20px]">
                                <div
                                    onClick={() => {
                                        downloadECGFile(
                                            activeData.ecg.url,
                                            moment(activeData.testDate).format('Do-MMMM-YYYY')
                                        );
                                    }}
                                >
                                    <Button
                                        text="Download ECG Report"
                                        icon={false}
                                        formHeight={true}
                                        fullWidth={false}
                                        shadow={false}
                                    />
                                </div>
                            </div>
                        )}

                        {activeData.data.Results.length > 0 ? (
                            <table className="my-7 w-full table-auto text-center md:my-14">
                                <thead>
                                    <tr className="bg-[#EFEFEF]">
                                        <th className="w-[30px] py-2 font-medium md:w-[60px]"> </th>
                                        <th className="w-[50%] py-2 font-medium md:w-auto">Test</th>
                                        <th className="hidden py-2 font-medium md:block">Optimal result</th>
                                        <th className="py-2 font-medium">Result</th>
                                        <th className="w-[30px] py-2 font-medium md:w-[60px]"> </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {activeResults.map((result, index) => (
                                        <React.Fragment key={`${result.Description}-${index}`}>
                                            <tr
                                                className={
                                                    index % 2 === 0
                                                        ? (index === activeGraph ? 'bg-[#F3F3F3]' : 'bg-[#F9F9F9]') +
                                                          ' hover:cursor-pointer hover:bg-[#F3F3F3]'
                                                        : (index === activeGraph ? 'bg-[#F3F3F3]' : '') +
                                                          ' hover:cursor-pointer hover:bg-[#F3F3F3]'
                                                }
                                                onClick={() => {
                                                    handleResultClick(index);
                                                }}
                                            >
                                                <td className="py-2 text-sm">
                                                    <FontAwesomeIcon
                                                        icon={faCircle}
                                                        style={{ color: result['refMarker'] }}
                                                        className="relative top-[2px] ml-2 h-[17px] md:h-[20px]"
                                                    />
                                                </td>
                                                <td className="py-2 text-sm md:text-base">
                                                    <div className="">{result.Description}</div>
                                                </td>
                                                <td className="hidden py-2 md:block">
                                                    {result['Reference Range']}{' '}
                                                    <span className="font-medium text-nw-darkGrey">{result.Units}</span>
                                                </td>
                                                <td className="py-2 text-sm md:text-base">
                                                    {result.Result}{' '}
                                                    <span className="font-medium text-nw-darkGrey">{result.Units}</span>
                                                </td>
                                                <td className="pt-1">
                                                    {index === activeGraph && (
                                                        <FontAwesomeIcon
                                                            icon={faCircleMinus}
                                                            style={{ color: '#54595f' }}
                                                            className="h-[17px] md:h-[20px]"
                                                        />
                                                    )}
                                                    {index !== activeGraph && (
                                                        <FontAwesomeIcon
                                                            icon={faCirclePlus}
                                                            style={{ color: '#54595f' }}
                                                            className="h-[17px] md:h-[20px]"
                                                        />
                                                    )}
                                                </td>
                                            </tr>
                                            {index === activeGraph && (
                                                <tr className="bg-[#F3F3F3]" key={result.Description + '-' + index}>
                                                    <td colSpan="5" className="pb-12 align-top">
                                                        <div className="mt-4 flex justify-center">
                                                            <button
                                                                className={
                                                                    'relative left-[13px] rounded-2xl px-14 py-1 ' +
                                                                    (graphType === 0
                                                                        ? 'z-10 bg-nw-offBlack font-medium text-white'
                                                                        : 'bg-[#E2E2E2] text-nw-offBlack transition-colors hover:bg-nw-lightGrey')
                                                                }
                                                                onClick={() => {
                                                                    setGraphType(0);
                                                                }}
                                                            >
                                                                Result
                                                            </button>
                                                            <button
                                                                className={
                                                                    'relative right-[13px] rounded-2xl px-14 py-1 ' +
                                                                    (graphType === 1
                                                                        ? 'z-10 bg-nw-offBlack font-medium text-white'
                                                                        : 'bg-[#E2E2E2] text-nw-offBlack transition-colors hover:bg-nw-lightGrey')
                                                                }
                                                                onClick={() => {
                                                                    setGraphType(1);
                                                                }}
                                                            >
                                                                Trend
                                                            </button>
                                                        </div>

                                                        {graphType === 0 && (
                                                            <>
                                                                <div className="mt-6 flex justify-center text-2xl font-semibold">
                                                                    <FontAwesomeIcon
                                                                        icon={faCircle}
                                                                        style={{ color: result['refMarker'] }}
                                                                        className="relative top-[3px] mr-2 h-[25px]"
                                                                    />
                                                                    {result.Result} {result.Units}
                                                                </div>
                                                                <div className="mt-6 flex justify-center">
                                                                    <div className="w-[90%] lg:w-[60%] xl:w-[40%]">
                                                                        {graphBlocks.map((block, index) => (
                                                                            <div
                                                                                className="relative inline-block"
                                                                                style={{
                                                                                    width: block['width'] + '%'
                                                                                }}
                                                                                key={`block-${index}`}
                                                                            >
                                                                                {block['indicatorPos'] !== '' && (
                                                                                    <div
                                                                                        className="absolute top-[-4px] z-10 h-[22px] w-[4px] bg-black"
                                                                                        style={{
                                                                                            left:
                                                                                                block['indicatorPos'] +
                                                                                                '%'
                                                                                        }}
                                                                                    ></div>
                                                                                )}
                                                                                <div
                                                                                    className={
                                                                                        'h-[15px]' +
                                                                                        (index === 0
                                                                                            ? ' rounded-l-2xl'
                                                                                            : index ===
                                                                                              graphBlocks.length - 1
                                                                                            ? ' rounded-r-2xl'
                                                                                            : '')
                                                                                    }
                                                                                    style={{
                                                                                        backgroundColor: block['colour']
                                                                                    }}
                                                                                ></div>
                                                                                <span className="inline-flex text-sm text-nw-grey">
                                                                                    {block['text']}
                                                                                </span>
                                                                            </div>
                                                                        ))}
                                                                    </div>
                                                                </div>

                                                                {activeBiomarkerDesc !== null && (
                                                                    <div className="rich-text-format mt-8 ml-5 mr-5 text-sm text-nw-grey lg:ml-[300px] lg:mr-[300px]">
                                                                        {renderDocument(activeBiomarkerDesc)}
                                                                    </div>
                                                                )}
                                                            </>
                                                        )}

                                                        {graphType === 1 && (
                                                            <>
                                                                <div className="mt-10 mb-8 flex justify-center text-lg text-nw-grey">
                                                                    We need to gather more data in order to see your
                                                                    trend in results.
                                                                </div>

                                                                {/* <div className="relative mx-auto mt-6 h-[395px] w-[95%] overflow-x-hidden rounded-2xl bg-white py-5 pl-[30px] pr-5 md:w-[600px] md:pl-[70px] lg:w-[800px]">
                                                                    <div className="absolute top-[30px] left-[45px] h-[318px] md:left-[80px]">
                                                                        {graphBlocks
                                                                            .slice(0)
                                                                            .reverse()
                                                                            .map((block, index) => (
                                                                                <div
                                                                                    className="relative flex"
                                                                                    style={{
                                                                                        height: block['width'] + '%'
                                                                                    }}
                                                                                    key={`block2-${index}`}
                                                                                >
                                                                                    <div
                                                                                        className={
                                                                                            'absolute z-10 h-full w-[15px]' +
                                                                                            (index === 0
                                                                                                ? ' rounded-t-2xl'
                                                                                                : index ===
                                                                                                  graphBlocks.length - 1
                                                                                                ? ' rounded-b-2xl'
                                                                                                : '')
                                                                                        }
                                                                                        style={{
                                                                                            backgroundColor:
                                                                                                block['colour']
                                                                                        }}
                                                                                    ></div>
                                                                                    <span className="relative left-[-0px] top-0 my-auto w-[35px] translate-x-[-40px] text-right text-xs text-nw-grey md:left-[-60px] md:w-[90px]">
                                                                                        {block['text']}
                                                                                    </span>
                                                                                    {index > 0 && (
                                                                                        <div className="absolute left-[25px] z-0 h-[1px] w-[500px] bg-[rgb(200,200,200)] md:w-[440px] lg:w-[640px]"></div>
                                                                                    )}
                                                                                </div>
                                                                            ))}
                                                                    </div>
                                                                    <div className="relative z-20 h-full">
                                                                        <Line
                                                                            className="h-full w-full"
                                                                            options={options}
                                                                            data={data}
                                                                        />
                                                                    </div>
                                                                </div> */}
                                                                {activeBiomarkerDesc !== null && (
                                                                    <div className="rich-text-format mt-8 ml-5 mr-5 text-sm text-nw-grey lg:ml-[300px] lg:mr-[300px]">
                                                                        {renderDocument(activeBiomarkerDesc)}
                                                                    </div>
                                                                )}
                                                            </>
                                                        )}
                                                    </td>
                                                </tr>
                                            )}
                                        </React.Fragment>
                                    ))}
                                </tbody>
                            </table>
                        ) : (
                            <>
                                {/* {rangeMarkers[2] === 0 && (
                                <p className="pt-2 text-center text-lg font-medium">
                                    Your results are all clear! You tested negative for the following STI's:
                                </p>
                            )} */}

                                <table className="relative left-[50%] my-7 w-full table-auto translate-x-[-50%] items-center text-center md:my-14 md:w-[60%]">
                                    <thead>
                                        <tr className="bg-[#EFEFEF]">
                                            <th className="py-2 font-medium">Test</th>
                                            <th className="py-2 font-medium">Result</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {activeResults.map((result, index) => (
                                            <tr
                                                className={
                                                    index % 2 === 0
                                                        ? index === activeGraph
                                                            ? 'bg-[#F3F3F3]'
                                                            : 'bg-[#F9F9F9]'
                                                        : index === activeGraph
                                                        ? 'bg-[#F3F3F3]'
                                                        : ''
                                                }
                                                key={result.Description}
                                            >
                                                <td className="py-2">{result.Description}</td>
                                                <td className="py-2 capitalize">
                                                    <FontAwesomeIcon
                                                        icon={faCircle}
                                                        style={{ color: result['refMarker'] }}
                                                        className="relative top-[2px] mr-2 h-[20px]"
                                                    />
                                                    {result.Result === 'not detected' ? 'Negative' : 'Positive'}
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </>
                        )}
                    </>
                )}
                {hasLoaded && activeData === null && (
                    <div className="flex min-h-[80vh] text-center">
                        <div className="my-auto">
                            <h1 className="mt-10 mb-4 text-3xl text-nw-offBlack lg:mt-20 lg:text-5xl">
                                &#128075; Hi {authUser?.user.firstName},
                            </h1>
                            <p className="mt-10 text-nw-darkGrey md:mx-20 xl:mx-10 2xl:mx-0 2xl:pr-[400px] 2xl:pl-[400px]">
                                Welcome to your Neuwell dashboard. This is where you'll be able to view your latest test
                                results after they've been processed by our lab team!<br></br>
                                <br></br> We'll send you an email when your first set of results are ready to view.
                            </p>
                            <p className="mt-20 text-2xl text-nw-lightGrey">No results...</p>
                        </div>
                    </div>
                )}
            </div>
        </>
    );
}
