import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Button } from '_components';
import { authActions } from '_store';
import { fetchWrapper } from '_helpers';
import visa from '../../../_assets/cards/visa.png';
import mastercard from '../../../_assets/cards/mastercard.png';
import americanExpress from '../../../_assets/cards/american-express.png';

export { ManagePayment };

function ManagePayment() {
    const [loading, setLoading] = useState(false);
    const [paymentMethod, setPaymentMethod] = useState(null);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const cardImages = {
        visa: visa,
        mastercard: mastercard,
        amex: americanExpress
    };

    let navigate = useNavigate();
    const dispatch = useDispatch();
    const { user: authUser } = useSelector((x) => x.auth);

    function createSetupIntent() {
        setIsSubmitting(true);

        fetchWrapper
            .post(`${process.env.REACT_APP_API_URL}/checkout/create-stripe-setup-intent`, {
                customer_id: authUser.user.stripeCustomerId
            })
            .then((client_secret) => {
                dispatch(
                    authActions.storeStripeClientSecret({
                        clientSecret: client_secret
                    })
                );

                let path = `/my-account/payment/add?id=${client_secret}`;
                navigate(path);
            })
            .catch((error) => {
                setIsSubmitting(false);
            });
    }

    useEffect(() => {
        if (authUser.user.stripePaymentMethod !== undefined && authUser.user.stripePaymentMethod !== '') {
            setLoading(true);

            fetchWrapper
                .post(`${process.env.REACT_APP_API_URL}/users/get-payment-method`, {
                    paymentMethod: authUser.user.stripePaymentMethod
                })
                .then((method) => {
                    if (method.type !== undefined) {
                        setPaymentMethod(method);
                    }

                    setLoading(false);
                });
        }
    }, [authUser.user.stripePaymentMethod]);

    return (
        <div className="min-h-[70vh]">
            {loading && (
                <div className={'loading-screen top-0 left-0 z-20 mt-16 mb-8 bg-white'}>
                    <div className="flex justify-center">
                        <div className="lds-grid my-auto">
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                        </div>
                    </div>
                </div>
            )}
            {paymentMethod && (
                <>
                    <p className="mt-8 text-center text-2xl text-nw-offBlack">Payment method</p>
                    <div className="mt-8"></div>
                    <div className="">
                        {paymentMethod.type === 'card' ? (
                            <div className="grid w-full grid-cols-3 content-around">
                                {cardImages[paymentMethod.card.brand] !== undefined && (
                                    <div className="mx-auto">
                                        <p className="font-medium text-nw-offBlack">Type</p>
                                        <img
                                            className="relative mt-2 h-[30px] w-[50px]"
                                            src={cardImages[paymentMethod.card.brand]}
                                            alt="Card type"
                                        />
                                    </div>
                                )}
                                <div className="mx-auto">
                                    <p className="font-medium text-nw-offBlack">Card number</p>
                                    <p className="mt-2 text-nw-offBlack md:text-lg">
                                        **** **** **** {paymentMethod.card.last4}
                                    </p>
                                </div>
                                <div className="mx-auto">
                                    <p className="font-medium text-nw-offBlack">Expiry date</p>
                                    <p className="mt-2 text-nw-offBlack md:text-lg">{`${
                                        paymentMethod.card.exp_month
                                    }/${paymentMethod.card.exp_year.toString().slice(-2)}`}</p>
                                </div>
                            </div>
                        ) : (
                            <div className="text-center">
                                <span className="font-medium">Method:</span> {paymentMethod.type}
                            </div>
                        )}
                    </div>

                    <p className="mx-4 mt-6 text-center text-sm font-medium text-nw-grey md:mx-20">
                        Important: This is the payment method that will be used for active repeating subscription
                        payments.
                    </p>

                    <div className="mt-8" onClick={() => createSetupIntent()}>
                        <Button
                            text="Update Payment method"
                            shadow={true}
                            fullWidth={true}
                            icon={false}
                            disabled={isSubmitting}
                        />
                    </div>
                    <div className="mt-6 h-[1px] w-full rounded-3xl bg-nw-lightGrey"></div>
                </>
            )}
            {!loading && !paymentMethod && (
                <>
                    <p className="mt-8 text-center text-2xl text-nw-offBlack">Payment method</p>
                    <div className="mt-8"></div>
                    <p className="text-center font-medium text-nw-grey">You have no saved payment methods.</p>
                    <div className="mt-6 h-[1px] w-full rounded-3xl bg-nw-lightGrey"></div>
                </>
            )}
        </div>
    );
}
