import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Link, useSearchParams, Navigate } from 'react-router-dom';
import * as Yup from 'yup';
import { useSelector, useDispatch } from 'react-redux';

import { history } from '_helpers';
import { authActions } from '_store';

import { Button, TextInput } from '_components';

import logo from '../../../_assets/neuwell-logo.gif';

export { Login };

function Login() {
    const dispatch = useDispatch();
    const authUser = useSelector((x) => x.auth.user);
    const authError = useSelector((x) => x.auth.error);
    const [msg, setMsg] = useState(null);
    // eslint-disable-next-line no-unused-vars
    const [searchParams, setSearchParams] = useSearchParams();
    const reset = searchParams.get('reset');

    useEffect(() => {
        if (reset) {
            setMsg('Password reset successful, you may now login');
        }

        document.title = `Neuwell® - Dashboard Login`;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // form validation rules
    const validationSchema = Yup.object().shape({
        username: Yup.string().required('Email is required').max(100, 'Max 100 characters'),
        password: Yup.string().required('Password is required').max(100, 'Max 100 characters')
    });
    const formOptions = { resolver: yupResolver(validationSchema) };

    // get functions to build form with useForm() hook
    const { register, handleSubmit, formState } = useForm(formOptions);

    const { errors, isSubmitting } = formState;

    function onSubmit({ username, password }) {
        return dispatch(authActions.login({ username, password }));
    }

    if (authUser) {
        // already logged in, redirect to dashboard
        return <Navigate to="/" />;
    }

    return (
        <div className="flex h-screen">
            <div className="card relative m-auto inline-block rounded-xl bg-nw-blue pt-10 pb-10 pl-4 pr-4 drop-shadow-md md:pt-[75px] md:pb-[75px] md:pl-[175px] md:pr-[175px]">
                <img className="mx-auto mb-1 w-[200px]" src={logo} alt="neuwell logo" />
                <div className="card-body max-w-[280px]">
                    <p className="text-md mb-6 text-center font-light text-nw-grey">DASHBOARD LOGIN</p>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="mb-4">
                            <TextInput name="username" label="Email" props={register('username')} width="280px" />
                            <div className="ml-5 mt-1 text-sm text-red-700">{errors.username?.message}</div>
                        </div>
                        <div className="mb-5">
                            <TextInput
                                name="password"
                                type="password"
                                label="Password"
                                props={register('password')}
                                width="280px"
                            />
                            <div className="ml-5 mt-1 text-sm text-red-700">{errors.password?.message}</div>
                        </div>
                        <div className="mt-5">
                            <Button
                                text="Login"
                                disabled={isSubmitting}
                                icon={true}
                                formHeight={true}
                                fullWidth={true}
                            />
                        </div>
                    </form>
                    <Link to="forgot-password">
                        <span className="mt-2 block text-center text-xs text-nw-grey underline">
                            Forgot your password?
                        </span>
                    </Link>
                    {(authError || msg) && (
                        <div className="mt-4 mb-0 max-w-[280px] rounded-2xl bg-white p-2 text-center text-sm text-nw-grey">
                            {msg ? msg : authError.message}
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}
