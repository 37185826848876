import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { cartActions } from '_store';
import { PriceCard, Select, Popup } from '_components';
import moment from 'moment';
import Customise from '../../_assets/Customise.svg';
import { checkNested, getItemPriceBreakdown, products } from '_helpers';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faLocationDot,
    faCalendarDays,
    faClock,
    faVial,
    faChevronRight,
    faPenToSquare
} from '@fortawesome/free-solid-svg-icons';
import { faTrashAlt } from '@fortawesome/free-regular-svg-icons';
import {} from '@fortawesome/free-solid-svg-icons';
import paperCopyIcon from '../../_assets/paper-copy-of-results.svg';
import weightLossIcon from '../../_assets/personalise/add-on-weight-loss.svg';
import heartIcon from '../../_assets/personalise/add-on-heart-health.svg';
import bioniqIcon from '../../_assets/personalise/add-on-bioniq-supplements.png';

export { BookingProduct };

function BookingProduct({ item, additionalBiomarkers }) {
    const [popupContent, setPopupContent] = useState(null);
    const dispatch = useDispatch();
    const [totalPrice, setTotalPrice] = useState(0);
    const [frequency, setFrequency] = useState(item.frequency !== undefined ? parseInt(item.frequency) : 0);
    const freqMap = {
        1: '3 months',
        2: '6 months',
        3: 'year'
    };

    function ExtrasCard({ eIcon, eTitle, eBody, ePrice, eItem, eIconDisplay = 'square' }) {
        return (
            <PriceCard
                icon={eIcon}
                title={eTitle}
                body={eBody}
                price={ePrice}
                iconDisplay={eIconDisplay}
                toggleCallback={(val) => {
                    dispatch(
                        cartActions.setProductExtra({
                            UID: eItem.UID,
                            extra: eTitle,
                            price: ePrice,
                            value: val
                        })
                    );
                }}
                selected={checkNested(eItem, 'extras', eTitle) ? eItem['extras'][eTitle].value : false}
            />
        );
    }

    useEffect(() => {
        const breakdown = getItemPriceBreakdown(item);
        setTotalPrice(breakdown.total);
    }, [item]);

    return (
        <>
            {popupContent && (
                <Popup
                    children={popupContent}
                    onClose={() => {
                        setPopupContent(null);
                    }}
                />
            )}
            <div className="mb-8 rounded-[27px] bg-[#E2F0F4] p-1">
                <div className="relative rounded-3xl bg-nw-blue py-6 px-6">
                    <p className="text-2xl text-nw-offBlack">{item.title}</p>
                    <div className="my-2 xl:flex">
                        <div className="w-full xl:mr-[2%] xl:w-[62%]">
                            <div className="grid grid-flow-row gap-2 pt-1.5 lg:grid-flow-col">
                                <div className="text-nw-grey">
                                    <FontAwesomeIcon
                                        icon={faLocationDot}
                                        style={{ color: '#7A7A7A' }}
                                        className="relative top-1 mr-1 h-6 w-6 text-nw-grey"
                                    />
                                    {item.booking.location} Clinic
                                </div>
                                <div className="text-nw-grey">
                                    <FontAwesomeIcon
                                        icon={faCalendarDays}
                                        style={{ color: '#7A7A7A' }}
                                        className="relative top-1 mr-2 h-6 w-6 text-nw-grey"
                                    />
                                    {moment(item.booking.date).format('Do MMM YYYY')}
                                </div>
                                <div className="text-nw-grey">
                                    <FontAwesomeIcon
                                        icon={faClock}
                                        style={{ color: '#7A7A7A' }}
                                        className="relative top-1 mr-2 h-6 w-6 text-nw-grey"
                                    />
                                    {moment(item.booking.time, ['h:mm']).format('h:mma')}
                                </div>
                                <div className="text-nw-grey">
                                    <Link to={'/booking?uid=' + item.UID}>
                                        <div className="flex w-full rounded-2xl border bg-nw-grey py-1 px-5 transition-colors hover:bg-nw-darkGrey">
                                            <div className="relative m-auto flex">
                                                <FontAwesomeIcon
                                                    icon={faPenToSquare}
                                                    style={{ color: '#ffffff' }}
                                                    className="relative top-0.5 h-4 w-4 text-nw-grey"
                                                />
                                                <div className="ml-1 text-white">Edit</div>
                                            </div>
                                        </div>
                                    </Link>
                                </div>
                            </div>
                            <Link to={'/checkout/personalise-test?uid=' + item.UID}>
                                <div className="relative mt-4 flex w-full justify-center rounded-2xl bg-black py-5 pl-2 pr-3 text-white hover:cursor-pointer sm:text-center">
                                    <img
                                        className="relative my-auto mr-3 h-[36px] w-[60px] sm:block"
                                        src={Customise}
                                        alt="Personalise test"
                                    />
                                    <div>
                                        <div className="relative font-semibold md:px-0">
                                            Personalise Your Health Check
                                            {additionalBiomarkers > 0 && (
                                                <div className="absolute top-[1px] right-[30px] h-5 w-5 rounded-[50%] bg-white pt-0.5 text-center text-xs text-black md:right-[-28px]">
                                                    {additionalBiomarkers}
                                                </div>
                                            )}
                                        </div>
                                        <p className="mt-0.5 text-sm">Add extra tests to your health MOT</p>
                                    </div>
                                </div>
                            </Link>
                            <div
                                className="relative mt-4 flex hover:cursor-pointer"
                                onClick={() =>
                                    setPopupContent(
                                        <>
                                            <p className="text-center text-lg text-nw-offBlack">{item.name}</p>
                                            <p className="text-center text-2xl text-nw-offBlack">Markers Tested</p>
                                            {products[item.productID].markersTested}
                                        </>
                                    )
                                }
                            >
                                <FontAwesomeIcon
                                    icon={faVial}
                                    style={{ color: '#7A7A7A' }}
                                    className="relative h-7 w-7 text-nw-grey"
                                />
                                <p className="ml-2 text-lg text-nw-darkGrey">Markers Tested</p>
                                <FontAwesomeIcon
                                    icon={faChevronRight}
                                    style={{ color: '#7A7A7A' }}
                                    className="absolute top-1 right-0 h-5 w-5 text-nw-grey"
                                />
                            </div>
                            <div className="mt-2 h-[1px] w-full rounded-3xl bg-nw-lightGrey"></div>
                        </div>
                        <div className="w-full xl:relative xl:top-[-22px] xl:w-[36%]">
                            <div className="mt-5 flex xl:mt-0">
                                <div className="mr-8 w-[150px]">
                                    <p className="mb-1 ml-2 text-nw-offBlack">Frequency</p>
                                    <Select
                                        name="frequency"
                                        options={['One-time', '3 Months', '6 Months', '1 Year']}
                                        label="Frequency"
                                        width="100%"
                                        selected={item.frequency !== undefined ? item.frequency : 0}
                                        callback={(e) => {
                                            setFrequency(parseInt(e.target.value));
                                            dispatch(
                                                cartActions.setProductFrequency({
                                                    UID: item.UID,
                                                    frequency: parseInt(e.target.value)
                                                })
                                            );
                                        }}
                                    />
                                </div>
                                <div>
                                    <p className="mb-1 ml-2 text-nw-offBlack">Quantity</p>
                                    <div
                                        className="my-1 flex rounded-2xl border border-nw-lightGrey bg-white font-light drop-shadow-md"
                                        onClick={() => dispatch(cartActions.removeItem(item.UID))}
                                    >
                                        <div className="rounded-2xl border-r border-nw-lightGrey px-3 py-1.5 transition-colors hover:cursor-pointer hover:bg-[#f9f9f9]">
                                            <FontAwesomeIcon
                                                icon={faTrashAlt}
                                                style={{ color: '#7A7A7A' }}
                                                className="relative top-0.5 h-5 w-5 text-nw-grey"
                                            />
                                        </div>
                                        <div className="ml-5 mr-6 mt-2 text-nw-offBlack">1</div>
                                    </div>
                                </div>
                            </div>
                            {frequency === 0 && (
                                <div className="mt-4 text-sm font-light text-nw-grey">
                                    Your {item.title} will be charged one-time at{' '}
                                    <span className="font-medium">£{totalPrice}</span>. You can view this order at any
                                    time using your dashboard.
                                </div>
                            )}
                            {frequency > 0 && (
                                <div className="mt-4 text-sm font-light text-nw-grey">
                                    Your {item.title} will be charged at{' '}
                                    <span className="font-medium">£{totalPrice}</span> then{' '}
                                    <span className="font-medium">£{totalPrice}</span> every{' '}
                                    <span className="font-medium">{freqMap[frequency]}</span>. You can cancel or change
                                    the frequency of your test at any time using your dashboard.
                                </div>
                            )}
                        </div>
                    </div>
                    <div className="mt-4 rounded-3xl pt-2">
                        <div className="text-center text-xl font-light text-nw-offBlack">
                            <div className="rounded-2xl px-8 py-1">Extras:</div>
                        </div>
                        <ExtrasCard
                            eIcon={paperCopyIcon}
                            eTitle={'Get a Personalised Results Brochure'}
                            eBody={'Get a result brochure sent to your address along with your doctors report.'}
                            ePrice={10}
                            eItem={item}
                        />
                        <ExtrasCard
                            eIcon={weightLossIcon}
                            eTitle={'Add Prescription Weight Loss Consultation'}
                            eBody={
                                'Add an initial consultation on our prescription weight loss programme during your health check.'
                            }
                            ePrice={0}
                            eItem={item}
                        />
                        <ExtrasCard
                            eIcon={heartIcon}
                            eTitle={'Cardiologist ECG Report'}
                            eBody={
                                'Have your ECG reported by a specialist cardiologist for a more comprehensive, in depth analysis of your heart tracing.'
                            }
                            ePrice={39}
                            eItem={item}
                        />
                        <ExtrasCard
                            eIcon={bioniqIcon}
                            eTitle={'Add Initial Bioniq Blood Test + 3 Months Personalised Supplement Supply'}
                            eBody={
                                <>
                                    Bioniq Blood Test and 3 Months Personalised Supplement Supply.{' '}
                                    <a href={'http://neuwell.co.uk/bioniq'} target="_blank" rel="noreferrer">
                                        <span className="font-medium underline hover:cursor-pointer">Learn more</span>
                                    </a>
                                </>
                            }
                            eIconDisplay={'rectangle'}
                            ePrice={600}
                            eItem={item}
                        />
                    </div>
                </div>
            </div>
        </>
    );
}
