import { useEffect, useState } from 'react';
import { Button } from '_components';
import { Link } from 'react-router-dom';

export { Account };

function Account({ page, children }) {
    const [fadeIn, setFadeIn] = useState(false);

    useEffect(() => {
        setFadeIn(true);
    }, []);

    return (
        <div className={'fade-element' + (fadeIn ? ' fadeIn' : '')}>
            <div className="flex py-8 md:pt-20">
                <div className="relative m-auto inline-block w-full md:w-auto">
                    <div className="flex flex-col sm:flex-row">
                        <div className="w-full py-2 px-3 md:w-[200px]">
                            <Link to={'/my-account/profile'}>
                                <Button
                                    text="Profile"
                                    shadow={false}
                                    active={page === 'profile' ? true : false}
                                    fullWidth={true}
                                />
                            </Link>
                        </div>
                        <div className="w-full py-2 px-3 md:w-[200px]">
                            <Link to={'/my-account/shipping'}>
                                <Button
                                    text="Shipping details"
                                    shadow={false}
                                    active={page === 'shipping' ? true : false}
                                    fullWidth={true}
                                />
                            </Link>
                        </div>
                        <div className="w-full py-2 px-3 md:w-[200px]">
                            <Link to={'/my-account/payment'}>
                                <Button
                                    text="Payment details"
                                    shadow={false}
                                    active={page === 'payment' ? true : false}
                                    fullWidth={true}
                                />
                            </Link>
                        </div>
                    </div>
                </div>
            </div>

            <div className="relative mb-10 md:left-[50%] md:mb-20 md:w-[600px] md:translate-x-[-50%]">
                <div className="h-0.5 w-full bg-nw-lightGrey"></div>
                {children}
            </div>
        </div>
    );
}
