export function checkNested(obj, level,  ...rest) {
  if (obj === undefined) return false
  if (rest.length === 0 && obj.hasOwnProperty(level)) return true
  return checkNested(obj[level], ...rest)
}

export const googleAPILibraries = ['places'];

export async function fetchFromAPI(endpoint, opts) {
  const {method, body} = {method: 'POST', body: null, ...opts};

  const res = await fetch(`${process.env.REACT_APP_API_URL}/${endpoint}`, {
    method,
    ...(body && {body: JSON.stringify(body)}),
    headers: {
      'Content-Type': 'application/json'
    },
  });

  return res.json();
}

export function getItemPriceBreakdown(item){
  let breakdown = {
    total: 0,
    biomarkerTotal: 0,
    extrasTotal: 0,
    testTypeTotal: 0,
  }

  let subtotal = item.basePrice;

  if (item.additionalBiomarkers !== undefined) {
      let total = 0;
      Object.keys(item.additionalBiomarkers).map((key) =>
          Object.keys(item.additionalBiomarkers[key]).map(
              (nkey) =>
                  (total += item.additionalBiomarkers[key][nkey].value
                      ? item.additionalBiomarkers[key][nkey].price
                      : 0)
          )
      );
      breakdown.biomarkerTotal = total;
      subtotal += total;
  }

  if (item.extras !== undefined) {
      let total = 0;
      Object.keys(item.extras).map(
          (key, eindex) => (total += item.extras[key].value ? item.extras[key].price : 0)
      );
      breakdown.extrasTotal = total;
      subtotal += total;
  }

  if (item.testType?.title !== undefined) {
    let total = parseFloat(item.testType.price);
    breakdown.testTypeTotal = total;
    subtotal += total;
  }

  breakdown.total = subtotal;

  return breakdown;
}