import { useState } from 'react';

export { TextSelect };

function TextSelect({
    name,
    options,
    type = 'text',
    label,
    props,
    width = 'auto',
    value = '',
    callback = (e) => null
}) {
    const [inputValue, setValue] = useState(value);

    function handleChange(e) {
        setValue(e.target.value);
        callback(e);
    }

    return (
        <div className="input-container relative flex flex-col" style={{ width: width }}>
            <select
                className={'rounded-3xl border border-nw-lightGrey font-light drop-shadow-md focus:border-nw-grey'}
                name={name}
                type={type}
                {...props}
                value={inputValue}
                onChange={handleChange}
            >
                <option value="" disabled></option>
                {options.map((option, index) => (
                    <option key={option + '-' + index} value={option}>
                        {option}
                    </option>
                ))}
            </select>
            <label className={'absolute font-light text-nw-grey' + (inputValue && ' filled')}>{label}</label>
        </div>
    );
}
