export const products =
    process.env.REACT_APP_CONTENTFUL_ENV === 'master'
        ? {
              // ----- In-Clinic Tests ----- //
              prod_P32GxIYMNNgBau: {
                  title: 'Active Check',
                  type: 'BOOKING',
                  testCodes: ['HSC5'],
                  personalise: 'RANDOX',
                  display: 'INCLINIC',
                  markersTested: (
                      <>
                          <p className="mt-8 px-10 text-center text-xl font-medium text-nw-offBlack">
                              Full Blood Count
                          </p>
                          <ul className="mx-auto mt-6 grid list-disc px-10 text-nw-offBlack sm:place-items-center lg:grid-cols-3">
                              <li>Haemoglobin</li>
                              <li>Haematocrit</li>
                              <li>Mean Cell Haemoglobin</li>
                              <li>Mean Cell Haemoglobin</li>
                              <li>Concentration (MCHC)</li>
                              <li>Mean Cell Volume</li>
                              <li>Red Blood Cell Count</li>
                              <li>Basophil Count</li>
                              <li>Lymphocyte Count</li>
                              <li>Eosinophil Count</li>
                              <li>Monocyte Count</li>
                              <li>Neutrophil Count</li>
                              <li>White Blood Cell Count</li>
                              <li>Platelet Count</li>
                          </ul>
                          <p className="mt-8 px-10 text-center text-xl font-medium text-nw-offBlack">Kidney Health</p>
                          <ul className="mx-auto mt-6 grid list-disc px-10 text-nw-offBlack sm:place-items-center lg:grid-cols-3">
                              <li>Creatinine</li>
                              <li>eGFR</li>
                              <li>Chloride</li>
                              <li>Potassium*</li>
                              <li>Phosphate</li>
                              <li>Sodium</li>
                              <li>Urea</li>
                          </ul>
                          <p className="mt-8 px-10 text-center text-xl font-medium text-nw-offBlack">Heart Health</p>
                          <ul className="mx-auto mt-6 grid list-disc px-10 text-nw-offBlack sm:place-items-center lg:grid-cols-3">
                              <li>Total Cholesterol</li>
                              <li>LDL Cholesterol</li>
                              <li>HDL Cholesterol</li>
                              <li>Total Cholesterol/HDL</li>
                              <li>Cholesterol Ratio</li>
                              <li>Triglycerides</li>
                              <li>High Sensitivity</li>
                              <li>C-Reactive Protein</li>
                          </ul>
                          <p className="mt-8 px-10 text-center text-xl font-medium text-nw-offBlack">Liver Health</p>
                          <ul className="mx-auto mt-6 grid list-disc px-10 text-nw-offBlack sm:place-items-center lg:grid-cols-3">
                              <li>Alkaline Phosphatase</li>
                              <li>Alanine Aminotransferase</li>
                              <li>Aspartate Transferase</li>
                              <li>Gamma-glutamyl Transferase</li>
                              <li>Total Bilirubin</li>
                              <li>Albumin</li>
                          </ul>
                          <p className="mt-8 px-10 text-center text-xl font-medium text-nw-offBlack">Diabetes Health</p>
                          <ul className="mx-auto mt-6 grid list-disc px-10 text-nw-offBlack sm:place-items-center">
                              <li>Glucose</li>
                          </ul>
                      </>
                  )
              },
              prod_P32GbcyC3r0QzQ: {
                  title: 'Core Check',
                  type: 'BOOKING',
                  testCodes: ['HSC6'],
                  personalise: 'RANDOX',
                  display: 'INCLINIC',
                  markersTested: (
                      <>
                          <p className="mt-8 px-10 text-center text-xl font-medium text-nw-offBlack">
                              Full Blood Count
                          </p>
                          <ul className="mx-auto mt-6 grid list-disc px-10 text-nw-offBlack sm:place-items-center lg:grid-cols-3">
                              <li>Haemoglobin</li>
                              <li>Haematocrit</li>
                              <li>Mean Cell Haemoglobin</li>
                              <li>Mean Cell Haemoglobin</li>
                              <li>Concentration (MCHC)</li>
                              <li>Mean Cell Volume</li>
                              <li>Red Blood Cell Count</li>
                              <li>Basophil Count</li>
                              <li>Lymphocyte Count</li>
                              <li>Eosinophil Count</li>
                              <li>Monocyte Count</li>
                              <li>Neutrophil Count</li>
                              <li>White Blood Cell Count</li>
                              <li>Platelet Count</li>
                          </ul>
                          <p className="mt-8 px-10 text-center text-xl font-medium text-nw-offBlack">Kidney Health</p>
                          <ul className="mx-auto mt-6 grid list-disc px-10 text-nw-offBlack sm:place-items-center lg:grid-cols-3">
                              <li>Creatinine</li>
                              <li>eGFR</li>
                              <li>Chloride</li>
                              <li>Potassium*</li>
                              <li>Phosphate</li>
                              <li>Sodium</li>
                              <li>Urea</li>
                          </ul>
                          <p className="mt-8 px-10 text-center text-xl font-medium text-nw-offBlack">Heart Health</p>
                          <ul className="mx-auto mt-6 grid list-disc px-10 text-nw-offBlack sm:place-items-center lg:grid-cols-3">
                              <li>Total Cholesterol</li>
                              <li>LDL Cholesterol</li>
                              <li>HDL Cholesterol</li>
                              <li>Total Cholesterol/HDL</li>
                              <li>Cholesterol Ratio</li>
                              <li>Triglycerides</li>
                              <li>High Sensitivity</li>
                              <li>C-Reactive Protein</li>
                          </ul>
                          <p className="mt-8 px-10 text-center text-xl font-medium text-nw-offBlack">Liver Health</p>
                          <ul className="mx-auto mt-6 grid list-disc px-10 text-nw-offBlack sm:place-items-center lg:grid-cols-3">
                              <li>Alkaline Phosphatase</li>
                              <li>Alanine Aminotransferase</li>
                              <li>Aspartate Transferase</li>
                              <li>Gamma-glutamyl Transferase</li>
                              <li>Total Bilirubin</li>
                              <li>Albumin</li>
                          </ul>
                          <p className="mt-8 px-10 text-center text-xl font-medium text-nw-offBlack">Diabetes Health</p>
                          <ul className="mx-auto mt-6 grid list-disc px-10 text-nw-offBlack sm:place-items-center">
                              <li>Glucose</li>
                              <li>HBA1C</li>
                          </ul>
                          <p className="mt-8 px-10 text-center text-xl font-medium text-nw-offBlack">Urinalysis</p>
                          <ul className="mx-auto mt-6 grid list-disc px-10 text-nw-offBlack sm:place-items-center lg:grid-cols-3">
                              <li>Bilirubin</li>
                              <li>Glucose</li>
                              <li>Ketones</li>
                              <li>Nitrite</li>
                              <li>PH</li>
                              <li>Protein</li>
                              <li>Red Blood Cells</li>
                              <li>Urobilinogen</li>
                              <li>White Blood Cells</li>
                          </ul>
                          <p className="mt-8 px-10 text-center text-xl font-medium text-nw-offBlack">Bone Health</p>
                          <ul className="mx-auto mt-6 grid list-disc px-10 text-nw-offBlack sm:place-items-center">
                              <li>Alkaline Phosphatase</li>
                              <li>Calcium (Adjusted)</li>
                              <li>Phosphate</li>
                              <li>Vitamin D</li>
                          </ul>
                          <p className="mt-8 px-10 text-center text-xl font-medium text-nw-offBlack">Iron Status</p>
                          <ul className="mx-auto mt-6 grid list-disc px-10 text-nw-offBlack sm:place-items-center">
                              <li>Ferritin</li>
                          </ul>
                      </>
                  )
              },
              prod_P32FMWJ0CIay5x: {
                  title: 'Complete Check Male',
                  type: 'BOOKING',
                  testCodes: ['HSC7M'],
                  personalise: 'RANDOX',
                  display: 'INCLINIC',
                  markersTested: (
                      <>
                          <p className="mt-8 px-10 text-center text-xl font-medium text-nw-offBlack">
                              Full Blood Count
                          </p>
                          <ul className="mx-auto mt-6 grid list-disc px-10 text-nw-offBlack sm:place-items-center lg:grid-cols-3">
                              <li>Haemoglobin</li>
                              <li>Haematocrit</li>
                              <li>Mean Cell Haemoglobin</li>
                              <li>Mean Cell Haemoglobin</li>
                              <li>Concentration (MCHC)</li>
                              <li>Mean Cell Volume</li>
                              <li>Red Blood Cell Count</li>
                              <li>Basophil Count</li>
                              <li>Lymphocyte Count</li>
                              <li>Eosinophil Count</li>
                              <li>Monocyte Count</li>
                              <li>Neutrophil Count</li>
                              <li>White Blood Cell Count</li>
                              <li>Platelet Count</li>
                          </ul>
                          <p className="mt-8 px-10 text-center text-xl font-medium text-nw-offBlack">Kidney Health</p>
                          <ul className="mx-auto mt-6 grid list-disc px-10 text-nw-offBlack sm:place-items-center lg:grid-cols-3">
                              <li>Creatinine</li>
                              <li>eGFR</li>
                              <li>Chloride</li>
                              <li>Potassium*</li>
                              <li>Phosphate</li>
                              <li>Sodium</li>
                              <li>Urea</li>
                          </ul>
                          <p className="mt-8 px-10 text-center text-xl font-medium text-nw-offBlack">Heart Health</p>
                          <ul className="mx-auto mt-6 grid list-disc px-10 text-nw-offBlack sm:place-items-center lg:grid-cols-3">
                              <li>Total Cholesterol</li>
                              <li>LDL Cholesterol</li>
                              <li>HDL Cholesterol</li>
                              <li>Total Cholesterol/HDL</li>
                              <li>Cholesterol Ratio</li>
                              <li>Triglycerides</li>
                              <li>High Sensitivity</li>
                              <li>C-Reactive Protein</li>
                          </ul>
                          <p className="mt-8 px-10 text-center text-xl font-medium text-nw-offBlack">Liver Health</p>
                          <ul className="mx-auto mt-6 grid list-disc px-10 text-nw-offBlack sm:place-items-center lg:grid-cols-3">
                              <li>Alkaline Phosphatase</li>
                              <li>Alanine Aminotransferase</li>
                              <li>Aspartate Transferase</li>
                              <li>Gamma-glutamyl Transferase</li>
                              <li>Total Bilirubin</li>
                              <li>Albumin</li>
                          </ul>
                          <p className="mt-8 px-10 text-center text-xl font-medium text-nw-offBlack">Diabetes Health</p>
                          <ul className="mx-auto mt-6 grid list-disc px-10 text-nw-offBlack sm:place-items-center">
                              <li>Glucose</li>
                              <li>HBA1C</li>
                          </ul>
                          <p className="mt-8 px-10 text-center text-xl font-medium text-nw-offBlack">Urinalysis</p>
                          <ul className="mx-auto mt-6 grid list-disc px-10 text-nw-offBlack sm:place-items-center lg:grid-cols-3">
                              <li>Bilirubin</li>
                              <li>Glucose</li>
                              <li>Ketones</li>
                              <li>Nitrite</li>
                              <li>PH</li>
                              <li>Protein</li>
                              <li>Red Blood Cells</li>
                              <li>Urobilinogen</li>
                              <li>White Blood Cells</li>
                          </ul>
                          <p className="mt-8 px-10 text-center text-xl font-medium text-nw-offBlack">Bone Health</p>
                          <ul className="mx-auto mt-6 grid list-disc px-10 text-nw-offBlack sm:place-items-center">
                              <li>Alkaline Phosphatase</li>
                              <li>Calcium (Adjusted)</li>
                              <li>Phosphate</li>
                              <li>Vitamin D</li>
                          </ul>
                          <p className="mt-8 px-10 text-center text-xl font-medium text-nw-offBlack">Iron Status</p>
                          <ul className="mx-auto mt-6 grid list-disc px-10 text-nw-offBlack sm:place-items-center">
                              <li>Ferritin</li>
                          </ul>
                          <p className="mt-8 px-10 text-center text-xl font-medium text-nw-offBlack">Thyroid Health</p>
                          <ul className="mx-auto mt-6 grid list-disc px-10 text-nw-offBlack sm:place-items-center lg:grid-cols-3">
                              <li>Thyroid Stimulating</li>
                              <li>Hormone</li>
                              <li>Free Tri-iodothyronine</li>
                              <li>Free Thyroxine</li>
                              <li>Anti-Thyroglobulin Antibody</li>
                              <li>Anti-Thyroid Peroxidase</li>
                              <li>Antibody</li>
                          </ul>
                          <p className="mt-8 px-10 text-center text-xl font-medium text-nw-offBlack">Tumour Markers</p>
                          <ul className="mx-auto mt-6 grid list-disc px-10 text-nw-offBlack sm:place-items-center">
                              <li>Total Prostate Specific Antigen</li>
                          </ul>
                      </>
                  )
              },
              prod_P32ViWmaOqhUMw: {
                  title: 'Complete Check Female',
                  type: 'BOOKING',
                  testCodes: ['HSC7F'],
                  personalise: 'RANDOX',
                  display: 'INCLINIC',
                  markersTested: (
                      <>
                          <p className="mt-8 px-10 text-center text-xl font-medium text-nw-offBlack">
                              Full Blood Count
                          </p>
                          <ul className="mx-auto mt-6 grid list-disc px-10 text-nw-offBlack sm:place-items-center lg:grid-cols-3">
                              <li>Haemoglobin</li>
                              <li>Haematocrit</li>
                              <li>Mean Cell Haemoglobin</li>
                              <li>Mean Cell Haemoglobin</li>
                              <li>Concentration (MCHC)</li>
                              <li>Mean Cell Volume</li>
                              <li>Red Blood Cell Count</li>
                              <li>Basophil Count</li>
                              <li>Lymphocyte Count</li>
                              <li>Eosinophil Count</li>
                              <li>Monocyte Count</li>
                              <li>Neutrophil Count</li>
                              <li>White Blood Cell Count</li>
                              <li>Platelet Count</li>
                          </ul>
                          <p className="mt-8 px-10 text-center text-xl font-medium text-nw-offBlack">Kidney Health</p>
                          <ul className="mx-auto mt-6 grid list-disc px-10 text-nw-offBlack sm:place-items-center lg:grid-cols-3">
                              <li>Creatinine</li>
                              <li>eGFR</li>
                              <li>Chloride</li>
                              <li>Potassium*</li>
                              <li>Phosphate</li>
                              <li>Sodium</li>
                              <li>Urea</li>
                          </ul>
                          <p className="mt-8 px-10 text-center text-xl font-medium text-nw-offBlack">Heart Health</p>
                          <ul className="mx-auto mt-6 grid list-disc px-10 text-nw-offBlack sm:place-items-center lg:grid-cols-3">
                              <li>Total Cholesterol</li>
                              <li>LDL Cholesterol</li>
                              <li>HDL Cholesterol</li>
                              <li>Total Cholesterol/HDL</li>
                              <li>Cholesterol Ratio</li>
                              <li>Triglycerides</li>
                              <li>High Sensitivity</li>
                              <li>C-Reactive Protein</li>
                          </ul>
                          <p className="mt-8 px-10 text-center text-xl font-medium text-nw-offBlack">Liver Health</p>
                          <ul className="mx-auto mt-6 grid list-disc px-10 text-nw-offBlack sm:place-items-center lg:grid-cols-3">
                              <li>Alkaline Phosphatase</li>
                              <li>Alanine Aminotransferase</li>
                              <li>Aspartate Transferase</li>
                              <li>Gamma-glutamyl Transferase</li>
                              <li>Total Bilirubin</li>
                              <li>Albumin</li>
                          </ul>
                          <p className="mt-8 px-10 text-center text-xl font-medium text-nw-offBlack">Diabetes Health</p>
                          <ul className="mx-auto mt-6 grid list-disc px-10 text-nw-offBlack sm:place-items-center">
                              <li>Glucose</li>
                              <li>HBA1C</li>
                          </ul>
                          <p className="mt-8 px-10 text-center text-xl font-medium text-nw-offBlack">Urinalysis</p>
                          <ul className="mx-auto mt-6 grid list-disc px-10 text-nw-offBlack sm:place-items-center lg:grid-cols-3">
                              <li>Bilirubin</li>
                              <li>Glucose</li>
                              <li>Ketones</li>
                              <li>Nitrite</li>
                              <li>PH</li>
                              <li>Protein</li>
                              <li>Red Blood Cells</li>
                              <li>Urobilinogen</li>
                              <li>White Blood Cells</li>
                          </ul>
                          <p className="mt-8 px-10 text-center text-xl font-medium text-nw-offBlack">Bone Health</p>
                          <ul className="mx-auto mt-6 grid list-disc px-10 text-nw-offBlack sm:place-items-center">
                              <li>Alkaline Phosphatase</li>
                              <li>Calcium (Adjusted)</li>
                              <li>Phosphate</li>
                              <li>Vitamin D</li>
                          </ul>
                          <p className="mt-8 px-10 text-center text-xl font-medium text-nw-offBlack">Iron Status</p>
                          <ul className="mx-auto mt-6 grid list-disc px-10 text-nw-offBlack sm:place-items-center">
                              <li>Ferritin</li>
                          </ul>
                          <p className="mt-8 px-10 text-center text-xl font-medium text-nw-offBlack">Thyroid Health</p>
                          <ul className="mx-auto mt-6 grid list-disc px-10 text-nw-offBlack sm:place-items-center lg:grid-cols-3">
                              <li>Thyroid Stimulating</li>
                              <li>Hormone</li>
                              <li>Free Tri-iodothyronine</li>
                              <li>Free Thyroxine</li>
                              <li>Anti-Thyroglobulin Antibody</li>
                              <li>Anti-Thyroid Peroxidase</li>
                              <li>Antibody</li>
                          </ul>
                          <p className="mt-8 px-10 text-center text-xl font-medium text-nw-offBlack">Tumour Markers</p>
                          <ul className="mx-auto mt-6 grid list-disc px-10 text-nw-offBlack sm:place-items-center">
                              <li>CA-125</li>
                          </ul>
                      </>
                  )
              },
              prod_P32Fbm0VNXeoYD: {
                  title: 'Signature Wellman',
                  type: 'BOOKING',
                  testCodes: ['HSC9M'],
                  personalise: 'RANDOX',
                  display: 'INCLINIC',
                  markersTested: (
                      <>
                          <p className="mt-8 px-10 text-center text-xl font-medium text-nw-offBlack">
                              Full Blood Count
                          </p>
                          <ul className="mx-auto mt-6 grid list-disc px-10 text-nw-offBlack sm:place-items-center lg:grid-cols-3">
                              <li>Haemoglobin</li>
                              <li>Haematocrit</li>
                              <li>Mean Cell Haemoglobin</li>
                              <li>Mean Cell Haemoglobin</li>
                              <li>Concentration (MCHC)</li>
                              <li>Mean Cell Volume</li>
                              <li>Red Blood Cell Count</li>
                              <li>Basophil Count</li>
                              <li>Lymphocyte Count</li>
                              <li>Eosinophil Count</li>
                              <li>Monocyte Count</li>
                              <li>Neutrophil Count</li>
                              <li>White Blood Cell Count</li>
                              <li>Platelet Count</li>
                          </ul>
                          <p className="mt-8 px-10 text-center text-xl font-medium text-nw-offBlack">
                              Infection & Inflammation
                          </p>
                          <ul className="mx-auto mt-6 grid list-disc px-10 text-nw-offBlack sm:place-items-center lg:grid-cols-3">
                              <li>C-Reactive Protein</li>
                              <li>Albumin</li>
                              <li>Complement Component 3</li>
                              <li>Complement Component 4</li>
                              <li>Ferritin</li>
                              <li>Immunoglobulin A</li>
                              <li>Immunoglobulin G</li>
                              <li>Immunoglobulin M</li>
                              <li>Anti-streptolysin O</li>
                          </ul>
                          <p className="mt-8 px-10 text-center text-xl font-medium text-nw-offBlack">Kidney Health</p>
                          <ul className="mx-auto mt-6 grid list-disc px-10 text-nw-offBlack sm:place-items-center lg:grid-cols-3">
                              <li>Creatinine</li>
                              <li>eGFR</li>
                              <li>Chloride</li>
                              <li>Potassium*</li>
                              <li>Phosphate</li>
                              <li>Sodium</li>
                              <li>Urea</li>
                              <li>Cystatin C</li>
                              <li>Magnesium</li>
                              <li>Uric Acid</li>
                          </ul>
                          <p className="mt-8 px-10 text-center text-xl font-medium text-nw-offBlack">Heart Health</p>
                          <ul className="mx-auto mt-6 grid list-disc px-10 text-nw-offBlack sm:place-items-center lg:grid-cols-3">
                              <li>Total Cholesterol</li>
                              <li>LDL Cholesterol</li>
                              <li>HDL Cholesterol</li>
                              <li>Total Cholesterol/HDL</li>
                              <li>Cholesterol Ratio</li>
                              <li>Triglycerides</li>
                              <li>High Sensitivity</li>
                              <li>C-Reactive Protein</li>
                              <li>Apolipoprotein A-I</li>
                              <li>Apolipoprotein B</li>
                              <li>Apolipoprotein B / A-I Ratio</li>
                              <li>Apolipoprotein CII</li>
                              <li>Apolipoprotein CIII</li>
                              <li>Apolipoprotein E</li>
                              <li>Cardiovascular Risk Score</li>
                              <li>High Sensitivity</li>
                              <li>C-Reactive Protein</li>
                              <li>Lipoprotein (a)</li>
                              <li>Small LDL Cholesterol</li>
                          </ul>
                          <p className="mt-8 px-10 text-center text-xl font-medium text-nw-offBlack">Liver Health</p>
                          <ul className="mx-auto mt-6 grid list-disc px-10 text-nw-offBlack sm:place-items-center lg:grid-cols-3">
                              <li>Alkaline Phosphatase</li>
                              <li>Alanine Aminotransferase</li>
                              <li>Aspartate Transferase</li>
                              <li>Gamma-glutamyl Transferase</li>
                              <li>Total Bilirubin</li>
                              <li>Albumin</li>
                          </ul>
                          <p className="mt-8 px-10 text-center text-xl font-medium text-nw-offBlack">Diabetes Health</p>
                          <ul className="mx-auto mt-6 grid list-disc px-10 text-nw-offBlack sm:place-items-center">
                              <li>Glucose</li>
                              <li>HBA1C</li>
                              <li>Insulin</li>
                              <li>C-Peptide</li>
                          </ul>
                          <p className="mt-8 px-10 text-center text-xl font-medium text-nw-offBlack">Urinalysis</p>
                          <ul className="mx-auto mt-6 grid list-disc px-10 text-nw-offBlack sm:place-items-center lg:grid-cols-3">
                              <li>Bilirubin</li>
                              <li>Glucose</li>
                              <li>Ketones</li>
                              <li>Nitrite</li>
                              <li>PH</li>
                              <li>Protein</li>
                              <li>Red Blood Cells</li>
                              <li>Urobilinogen</li>
                              <li>White Blood Cells</li>
                          </ul>
                          <p className="mt-8 px-10 text-center text-xl font-medium text-nw-offBlack">Bone Health</p>
                          <ul className="mx-auto mt-6 grid list-disc px-10 text-nw-offBlack sm:place-items-center">
                              <li>Alkaline Phosphatase</li>
                              <li>Calcium (Adjusted)</li>
                              <li>Phosphate</li>
                              <li>Vitamin D</li>
                          </ul>
                          <p className="mt-8 px-10 text-center text-xl font-medium text-nw-offBlack">Iron Status</p>
                          <ul className="mx-auto mt-6 grid list-disc px-10 text-nw-offBlack sm:place-items-center">
                              <li>Ferritin</li>
                              <li>Iron</li>
                              <li>TIBC</li>
                              <li>Transferrin</li>
                              <li>Transferrin Saturation</li>
                          </ul>
                          <p className="mt-8 px-10 text-center text-xl font-medium text-nw-offBlack">Thyroid Health</p>
                          <ul className="mx-auto mt-6 grid list-disc px-10 text-nw-offBlack sm:place-items-center lg:grid-cols-3">
                              <li>Thyroid Stimulating</li>
                              <li>Hormone</li>
                              <li>Free Tri-iodothyronine</li>
                              <li>Free Thyroxine</li>
                              <li>Anti-Thyroglobulin Antibody</li>
                              <li>Anti-Thyroid Peroxidase</li>
                              <li>Antibody</li>
                          </ul>
                          <p className="mt-8 px-10 text-center text-xl font-medium text-nw-offBlack">
                              Metabolic Syndrome
                          </p>
                          <ul className="mx-auto mt-6 grid list-disc px-10 text-nw-offBlack sm:place-items-center lg:grid-cols-3">
                              <li>Leptin</li>
                              <li>Adiponectin</li>
                              <li>Resistin</li>
                          </ul>
                          <p className="mt-8 px-10 text-center text-xl font-medium text-nw-offBlack">
                              Male Hormonal Health
                          </p>
                          <ul className="mx-auto mt-6 grid list-disc px-10 text-nw-offBlack sm:place-items-center lg:grid-cols-3">
                              <li>Testosterone</li>
                              <li>Sex Hormone Binding Globulin</li>
                              <li>Free Androgen Index</li>
                          </ul>
                          <p className="mt-8 px-10 text-center text-xl font-medium text-nw-offBlack">
                              Nutritional Health
                          </p>
                          <ul className="mx-auto mt-6 grid list-disc px-10 text-nw-offBlack sm:place-items-center lg:grid-cols-3">
                              <li>Calcium (Adjusted)</li>
                              <li>Albumin</li>
                              <li>Iron</li>
                              <li>Vitamin D</li>
                              <li>Magnesium</li>
                              <li>Folic Acid</li>
                              <li>Vitamin B12</li>
                              <li>Total Antioxidant Status</li>
                          </ul>
                          <p className="mt-8 px-10 text-center text-xl font-medium text-nw-offBlack">
                              Muscle & Joint Health
                          </p>
                          <ul className="mx-auto mt-6 grid list-disc px-10 text-nw-offBlack sm:place-items-center lg:grid-cols-3">
                              <li>Uric Acid</li>
                              <li>Creatine Kinase</li>
                              <li>Uric Acid</li>
                              <li>Rheumatoid Factor</li>
                          </ul>
                          <p className="mt-8 px-10 text-center text-xl font-medium text-nw-offBlack">
                              Pancreatic Health
                          </p>
                          <ul className="mx-auto mt-6 grid list-disc px-10 text-nw-offBlack sm:place-items-center">
                              <li>Pancreatic Amylase</li>
                              <li>Lipase</li>
                          </ul>
                          <p className="mt-8 px-10 text-center text-xl font-medium text-nw-offBlack">
                              Allergy Evaluation
                          </p>
                          <ul className="mx-auto mt-6 grid list-disc px-10 text-nw-offBlack sm:place-items-center">
                              <li>Immunoglobulin E</li>
                          </ul>
                          <p className="mt-8 px-10 text-center text-xl font-medium text-nw-offBlack">
                              Digestive Health
                          </p>
                          <ul className="mx-auto mt-6 grid list-disc px-10 text-nw-offBlack sm:place-items-center lg:grid-cols-3">
                              <li>H. Pylori</li>
                              <li>Anti-Tissue</li>
                              <li>Transglutaminase</li>
                              <li>Antibodies</li>
                          </ul>
                          <p className="mt-8 px-10 text-center text-xl font-medium text-nw-offBlack">Tumour Markers</p>
                          <ul className="mx-auto mt-6 grid list-disc px-10 text-nw-offBlack sm:place-items-center">
                              <li>PSA</li>
                          </ul>
                      </>
                  )
              },
              prod_P32FcIgYnmAovX: {
                  title: 'Signature Wellwoman',
                  type: 'BOOKING',
                  testCodes: ['HSC9F'],
                  personalise: 'RANDOX',
                  display: 'INCLINIC',
                  markersTested: (
                      <>
                          <p className="mt-8 px-10 text-center text-xl font-medium text-nw-offBlack">
                              Full Blood Count
                          </p>
                          <ul className="mx-auto mt-6 grid list-disc px-10 text-nw-offBlack sm:place-items-center lg:grid-cols-3">
                              <li>Haemoglobin</li>
                              <li>Haematocrit</li>
                              <li>Mean Cell Haemoglobin</li>
                              <li>Mean Cell Haemoglobin</li>
                              <li>Concentration (MCHC)</li>
                              <li>Mean Cell Volume</li>
                              <li>Red Blood Cell Count</li>
                              <li>Basophil Count</li>
                              <li>Lymphocyte Count</li>
                              <li>Eosinophil Count</li>
                              <li>Monocyte Count</li>
                              <li>Neutrophil Count</li>
                              <li>White Blood Cell Count</li>
                              <li>Platelet Count</li>
                          </ul>
                          <p className="mt-8 px-10 text-center text-xl font-medium text-nw-offBlack">
                              Infection & Inflammation
                          </p>
                          <ul className="mx-auto mt-6 grid list-disc px-10 text-nw-offBlack sm:place-items-center lg:grid-cols-3">
                              <li>C-Reactive Protein</li>
                              <li>Albumin</li>
                              <li>Complement Component 3</li>
                              <li>Complement Component 4</li>
                              <li>Ferritin</li>
                              <li>Immunoglobulin A</li>
                              <li>Immunoglobulin G</li>
                              <li>Immunoglobulin M</li>
                              <li>Anti-streptolysin O</li>
                          </ul>
                          <p className="mt-8 px-10 text-center text-xl font-medium text-nw-offBlack">Kidney Health</p>
                          <ul className="mx-auto mt-6 grid list-disc px-10 text-nw-offBlack sm:place-items-center lg:grid-cols-3">
                              <li>Creatinine</li>
                              <li>eGFR</li>
                              <li>Chloride</li>
                              <li>Potassium*</li>
                              <li>Phosphate</li>
                              <li>Sodium</li>
                              <li>Urea</li>
                              <li>Cystatin C</li>
                              <li>Magnesium</li>
                              <li>Uric Acid</li>
                          </ul>
                          <p className="mt-8 px-10 text-center text-xl font-medium text-nw-offBlack">Heart Health</p>
                          <ul className="mx-auto mt-6 grid list-disc px-10 text-nw-offBlack sm:place-items-center lg:grid-cols-3">
                              <li>Total Cholesterol</li>
                              <li>LDL Cholesterol</li>
                              <li>HDL Cholesterol</li>
                              <li>Total Cholesterol/HDL</li>
                              <li>Cholesterol Ratio</li>
                              <li>Triglycerides</li>
                              <li>High Sensitivity</li>
                              <li>C-Reactive Protein</li>
                              <li>Apolipoprotein A-I</li>
                              <li>Apolipoprotein B</li>
                              <li>Apolipoprotein B / A-I Ratio</li>
                              <li>Apolipoprotein CII</li>
                              <li>Apolipoprotein CIII</li>
                              <li>Apolipoprotein E</li>
                              <li>Cardiovascular Risk Score</li>
                              <li>High Sensitivity</li>
                              <li>C-Reactive Protein</li>
                              <li>Lipoprotein (a)</li>
                              <li>Small LDL Cholesterol</li>
                          </ul>
                          <p className="mt-8 px-10 text-center text-xl font-medium text-nw-offBlack">Liver Health</p>
                          <ul className="mx-auto mt-6 grid list-disc px-10 text-nw-offBlack sm:place-items-center lg:grid-cols-3">
                              <li>Alkaline Phosphatase</li>
                              <li>Alanine Aminotransferase</li>
                              <li>Aspartate Transferase</li>
                              <li>Gamma-glutamyl Transferase</li>
                              <li>Total Bilirubin</li>
                              <li>Albumin</li>
                          </ul>
                          <p className="mt-8 px-10 text-center text-xl font-medium text-nw-offBlack">Diabetes Health</p>
                          <ul className="mx-auto mt-6 grid list-disc px-10 text-nw-offBlack sm:place-items-center">
                              <li>Glucose</li>
                              <li>HBA1C</li>
                              <li>Insulin</li>
                              <li>C-Peptide</li>
                          </ul>
                          <p className="mt-8 px-10 text-center text-xl font-medium text-nw-offBlack">Urinalysis</p>
                          <ul className="mx-auto mt-6 grid list-disc px-10 text-nw-offBlack sm:place-items-center lg:grid-cols-3">
                              <li>Bilirubin</li>
                              <li>Glucose</li>
                              <li>Ketones</li>
                              <li>Nitrite</li>
                              <li>PH</li>
                              <li>Protein</li>
                              <li>Red Blood Cells</li>
                              <li>Urobilinogen</li>
                              <li>White Blood Cells</li>
                          </ul>
                          <p className="mt-8 px-10 text-center text-xl font-medium text-nw-offBlack">Bone Health</p>
                          <ul className="mx-auto mt-6 grid list-disc px-10 text-nw-offBlack sm:place-items-center">
                              <li>Alkaline Phosphatase</li>
                              <li>Calcium (Adjusted)</li>
                              <li>Phosphate</li>
                              <li>Vitamin D</li>
                          </ul>
                          <p className="mt-8 px-10 text-center text-xl font-medium text-nw-offBlack">Iron Status</p>
                          <ul className="mx-auto mt-6 grid list-disc px-10 text-nw-offBlack sm:place-items-center">
                              <li>Ferritin</li>
                              <li>Iron</li>
                              <li>TIBC</li>
                              <li>Transferrin</li>
                              <li>Transferrin Saturation</li>
                          </ul>
                          <p className="mt-8 px-10 text-center text-xl font-medium text-nw-offBlack">Thyroid Health</p>
                          <ul className="mx-auto mt-6 grid list-disc px-10 text-nw-offBlack sm:place-items-center lg:grid-cols-3">
                              <li>Thyroid Stimulating</li>
                              <li>Hormone</li>
                              <li>Free Tri-iodothyronine</li>
                              <li>Free Thyroxine</li>
                              <li>Anti-Thyroglobulin Antibody</li>
                              <li>Anti-Thyroid Peroxidase</li>
                              <li>Antibody</li>
                          </ul>
                          <p className="mt-8 px-10 text-center text-xl font-medium text-nw-offBlack">
                              Metabolic Syndrome
                          </p>
                          <ul className="mx-auto mt-6 grid list-disc px-10 text-nw-offBlack sm:place-items-center lg:grid-cols-3">
                              <li>Leptin</li>
                              <li>Adiponectin</li>
                              <li>Resistin</li>
                          </ul>
                          <p className="mt-8 px-10 text-center text-xl font-medium text-nw-offBlack">
                              Female Hormonal Health
                          </p>
                          <ul className="mx-auto mt-6 grid list-disc px-10 text-nw-offBlack sm:place-items-center lg:grid-cols-3">
                              <li>Oestradiol</li>
                              <li>Follicle Stimulating Hormone</li>
                              <li>Luteinising Hormone</li>
                              <li>Progesterone</li>
                              <li>Prolactin</li>
                              <li>Testosterone</li>
                              <li>Sex Hormone Binding Globulin</li>
                              <li>Free Androgen Index</li>
                          </ul>
                          <p className="mt-8 px-10 text-center text-xl font-medium text-nw-offBlack">
                              Nutritional Health
                          </p>
                          <ul className="mx-auto mt-6 grid list-disc px-10 text-nw-offBlack sm:place-items-center lg:grid-cols-3">
                              <li>Calcium (Adjusted)</li>
                              <li>Albumin</li>
                              <li>Iron</li>
                              <li>Vitamin D</li>
                              <li>Magnesium</li>
                              <li>Folic Acid</li>
                              <li>Vitamin B12</li>
                              <li>Total Antioxidant Status</li>
                          </ul>
                          <p className="mt-8 px-10 text-center text-xl font-medium text-nw-offBlack">
                              Muscle & Joint Health
                          </p>
                          <ul className="mx-auto mt-6 grid list-disc px-10 text-nw-offBlack sm:place-items-center lg:grid-cols-3">
                              <li>Uric Acid</li>
                              <li>Creatine Kinase</li>
                              <li>Uric Acid</li>
                              <li>Rheumatoid Factor</li>
                          </ul>
                          <p className="mt-8 px-10 text-center text-xl font-medium text-nw-offBlack">
                              Pancreatic Health
                          </p>
                          <ul className="mx-auto mt-6 grid list-disc px-10 text-nw-offBlack sm:place-items-center">
                              <li>Pancreatic Amylase</li>
                              <li>Lipase</li>
                          </ul>
                          <p className="mt-8 px-10 text-center text-xl font-medium text-nw-offBlack">
                              Allergy Evaluation
                          </p>
                          <ul className="mx-auto mt-6 grid list-disc px-10 text-nw-offBlack sm:place-items-center">
                              <li>Immunoglobulin E</li>
                          </ul>
                          <p className="mt-8 px-10 text-center text-xl font-medium text-nw-offBlack">
                              Digestive Health
                          </p>
                          <ul className="mx-auto mt-6 grid list-disc px-10 text-nw-offBlack sm:place-items-center lg:grid-cols-3">
                              <li>H. Pylori</li>
                              <li>Anti-Tissue</li>
                              <li>Transglutaminase</li>
                              <li>Antibodies</li>
                          </ul>
                          <p className="mt-8 px-10 text-center text-xl font-medium text-nw-offBlack">Tumour Markers</p>
                          <ul className="mx-auto mt-6 grid list-disc px-10 text-nw-offBlack sm:place-items-center">
                              <li>CA 19-9</li>
                          </ul>
                      </>
                  )
              },
              prod_P32FbiGOGW8t4A: {
                  title: 'Female Fertility Screen',
                  type: 'BOOKING',
                  testCodes: ['HSC14CTNG'],
                  personalise: 'RANDOX',
                  display: 'INCLINIC',
                  markersTested: (
                      <>
                          <p className="mt-8 px-10 text-center text-xl font-medium text-nw-offBlack">Thyroid Health</p>
                          <ul className="mx-auto mt-6 grid list-disc px-10 text-nw-offBlack sm:place-items-center lg:grid-cols-3">
                              <li>Thyroid Stimulating</li>
                              <li>Hormone</li>
                              <li>Free Tri-iodothyronine</li>
                              <li>Free Thyroxine</li>
                              <li>Anti-Thyroglobulin Antibody</li>
                              <li>Anti-Thyroid Peroxidase</li>
                              <li>Antibody</li>
                          </ul>
                          <p className="mt-8 px-10 text-center text-xl font-medium text-nw-offBlack">Hormonal Health</p>
                          <ul className="mx-auto mt-6 grid list-disc px-10 text-nw-offBlack sm:place-items-center lg:grid-cols-3">
                              <li>Oestradiol</li>
                              <li>Follicle Stimulating Hormone</li>
                              <li>Luteinising Hormone</li>
                              <li>Progesterone</li>
                              <li>Prolactin</li>
                              <li>Testosterone</li>
                              <li>Sex Hormone Binding Globulin</li>
                              <li>Free Androgen Index</li>
                          </ul>
                          <p className="mt-8 px-10 text-center text-xl font-medium text-nw-offBlack">
                              Nutritional Health
                          </p>
                          <ul className="mx-auto mt-6 grid list-disc px-10 text-nw-offBlack sm:place-items-center">
                              <li>Vitamin D</li>
                          </ul>
                          <p className="mt-8 px-10 text-center text-xl font-medium text-nw-offBlack">
                              Full Blood Count
                          </p>
                          <ul className="mx-auto mt-6 grid list-disc px-10 text-nw-offBlack sm:place-items-center lg:grid-cols-3">
                              <li>Haemoglobin</li>
                              <li>Haematocrit</li>
                              <li>Mean Cell Haemoglobin</li>
                              <li>Mean Cell Haemoglobin</li>
                              <li>Concentration (MCHC)</li>
                              <li>Mean Cell Volume</li>
                              <li>Red Blood Cell Count</li>
                              <li>Basophil Count</li>
                              <li>Lymphocyte Count</li>
                              <li>Eosinophil Count</li>
                              <li>Monocyte Count</li>
                              <li>Neutrophil Count</li>
                              <li>White Blood Cell Count</li>
                              <li>Platelet Count</li>
                          </ul>
                          <p className="mt-8 px-10 text-center text-xl font-medium text-nw-offBlack">Iron Status</p>
                          <ul className="mx-auto mt-6 grid list-disc px-10 text-nw-offBlack sm:place-items-center">
                              <li>Ferritin</li>
                              <li>Iron</li>
                              <li>TIBC</li>
                              <li>Transferrin</li>
                              <li>Transferrin Saturation</li>
                          </ul>
                          <p className="mt-8 px-10 text-center text-xl font-medium text-nw-offBlack">Liver Health</p>
                          <ul className="mx-auto mt-6 grid list-disc px-10 text-nw-offBlack sm:place-items-center lg:grid-cols-3">
                              <li>Alkaline Phosphatase</li>
                              <li>Alanine Aminotransferase</li>
                              <li>Aspartate Transferase</li>
                              <li>Gamma-glutamyl Transferase</li>
                              <li>Total Bilirubin</li>
                              <li>Albumin</li>
                          </ul>
                          <p className="mt-8 px-10 text-center text-xl font-medium text-nw-offBlack">Diabetes Health</p>
                          <ul className="mx-auto mt-6 grid list-disc px-10 text-nw-offBlack sm:place-items-center">
                              <li>Glucose</li>
                              <li>HBA1C</li>
                              <li>Insulin</li>
                          </ul>
                          <p className="mt-8 px-10 text-center text-xl font-medium text-nw-offBlack">Stress</p>
                          <ul className="mx-auto mt-6 grid list-disc px-10 text-nw-offBlack sm:place-items-center">
                              <li>Cortisol</li>
                              <li>DHEA-S</li>
                          </ul>
                          <p className="mt-8 px-10 text-center text-xl font-medium text-nw-offBlack">Heart Health</p>
                          <ul className="mx-auto mt-6 grid list-disc px-10 text-nw-offBlack sm:place-items-center lg:grid-cols-3">
                              <li>Total Cholesterol</li>
                              <li>LDL Cholesterol</li>
                              <li>HDL Cholesterol</li>
                              <li>Total Cholesterol/HDL</li>
                              <li>Cholesterol Ratio</li>
                              <li>Triglycerides</li>
                          </ul>
                          <p className="mt-8 px-10 text-center text-xl font-medium text-nw-offBlack">Kidney Health</p>
                          <ul className="mx-auto mt-6 grid list-disc px-10 text-nw-offBlack sm:place-items-center lg:grid-cols-3">
                              <li>Chloride</li>
                              <li>Potassium*</li>
                              <li>Phosphate</li>
                              <li>Sodium</li>
                              <li>Urea</li>
                          </ul>
                          <p className="mt-8 px-10 text-center text-xl font-medium text-nw-offBlack">
                              Infection & Inflammation
                          </p>
                          <ul className="mx-auto mt-6 grid list-disc px-10 text-nw-offBlack sm:place-items-center">
                              <li>C-Reactive Protein</li>
                          </ul>
                      </>
                  )
              },
              prod_P32F8wcLY82rCc: {
                  title: 'Male Fertility Screen',
                  type: 'BOOKING',
                  testCodes: ['HSC14CTNG'],
                  personalise: 'RANDOX',
                  display: 'INCLINIC',
                  markersTested: (
                      <>
                          <p className="mt-8 px-10 text-center text-xl font-medium text-nw-offBlack">Thyroid Health</p>
                          <ul className="mx-auto mt-6 grid list-disc px-10 text-nw-offBlack sm:place-items-center lg:grid-cols-3">
                              <li>Thyroid Stimulating</li>
                              <li>Hormone</li>
                              <li>Free Tri-iodothyronine</li>
                              <li>Free Thyroxine</li>
                              <li>Anti-Thyroglobulin Antibody</li>
                              <li>Anti-Thyroid Peroxidase</li>
                              <li>Antibody</li>
                          </ul>
                          <p className="mt-8 px-10 text-center text-xl font-medium text-nw-offBlack">Hormonal Health</p>
                          <ul className="mx-auto mt-6 grid list-disc px-10 text-nw-offBlack sm:place-items-center lg:grid-cols-3">
                              <li>Oestradiol</li>
                              <li>Follicle Stimulating Hormone</li>
                              <li>Luteinising Hormone</li>
                              <li>Progesterone</li>
                              <li>Prolactin</li>
                              <li>Testosterone</li>
                              <li>Sex Hormone Binding Globulin</li>
                              <li>Free Androgen Index</li>
                          </ul>
                          <p className="mt-8 px-10 text-center text-xl font-medium text-nw-offBlack">
                              Nutritional Health
                          </p>
                          <ul className="mx-auto mt-6 grid list-disc px-10 text-nw-offBlack sm:place-items-center">
                              <li>Vitamin D</li>
                          </ul>
                          <p className="mt-8 px-10 text-center text-xl font-medium text-nw-offBlack">
                              Full Blood Count
                          </p>
                          <ul className="mx-auto mt-6 grid list-disc px-10 text-nw-offBlack sm:place-items-center lg:grid-cols-3">
                              <li>Haemoglobin</li>
                              <li>Haematocrit</li>
                              <li>Mean Cell Haemoglobin</li>
                              <li>Mean Cell Haemoglobin</li>
                              <li>Concentration (MCHC)</li>
                              <li>Mean Cell Volume</li>
                              <li>Red Blood Cell Count</li>
                              <li>Basophil Count</li>
                              <li>Lymphocyte Count</li>
                              <li>Eosinophil Count</li>
                              <li>Monocyte Count</li>
                              <li>Neutrophil Count</li>
                              <li>White Blood Cell Count</li>
                              <li>Platelet Count</li>
                          </ul>
                          <p className="mt-8 px-10 text-center text-xl font-medium text-nw-offBlack">Iron Status</p>
                          <ul className="mx-auto mt-6 grid list-disc px-10 text-nw-offBlack sm:place-items-center">
                              <li>Ferritin</li>
                              <li>Iron</li>
                              <li>TIBC</li>
                              <li>Transferrin</li>
                              <li>Transferrin Saturation</li>
                          </ul>
                          <p className="mt-8 px-10 text-center text-xl font-medium text-nw-offBlack">Liver Health</p>
                          <ul className="mx-auto mt-6 grid list-disc px-10 text-nw-offBlack sm:place-items-center lg:grid-cols-3">
                              <li>Alkaline Phosphatase</li>
                              <li>Alanine Aminotransferase</li>
                              <li>Aspartate Transferase</li>
                              <li>Gamma-glutamyl Transferase</li>
                              <li>Total Bilirubin</li>
                              <li>Albumin</li>
                          </ul>
                          <p className="mt-8 px-10 text-center text-xl font-medium text-nw-offBlack">Diabetes Health</p>
                          <ul className="mx-auto mt-6 grid list-disc px-10 text-nw-offBlack sm:place-items-center">
                              <li>Glucose</li>
                              <li>HBA1C</li>
                              <li>Insulin</li>
                          </ul>
                          <p className="mt-8 px-10 text-center text-xl font-medium text-nw-offBlack">Stress</p>
                          <ul className="mx-auto mt-6 grid list-disc px-10 text-nw-offBlack sm:place-items-center">
                              <li>Cortisol</li>
                              <li>DHEA-S</li>
                          </ul>
                          <p className="mt-8 px-10 text-center text-xl font-medium text-nw-offBlack">Heart Health</p>
                          <ul className="mx-auto mt-6 grid list-disc px-10 text-nw-offBlack sm:place-items-center lg:grid-cols-3">
                              <li>Total Cholesterol</li>
                              <li>LDL Cholesterol</li>
                              <li>HDL Cholesterol</li>
                              <li>Total Cholesterol/HDL</li>
                              <li>Cholesterol Ratio</li>
                              <li>Triglycerides</li>
                          </ul>
                          <p className="mt-8 px-10 text-center text-xl font-medium text-nw-offBlack">Kidney Health</p>
                          <ul className="mx-auto mt-6 grid list-disc px-10 text-nw-offBlack sm:place-items-center lg:grid-cols-3">
                              <li>Chloride</li>
                              <li>Potassium*</li>
                              <li>Phosphate</li>
                              <li>Sodium</li>
                              <li>Urea</li>
                          </ul>
                          <p className="mt-8 px-10 text-center text-xl font-medium text-nw-offBlack">
                              Infection & Inflammation
                          </p>
                          <ul className="mx-auto mt-6 grid list-disc px-10 text-nw-offBlack sm:place-items-center lg:grid-cols-3">
                              <li>C-Reactive Protein</li>
                          </ul>
                      </>
                  )
              },
              prod_P32F4J4AePEzcg: {
                  title: 'Signature Athlete Performance',
                  type: 'BOOKING',
                  testCodes: ['HSC12'],
                  personalise: 'RANDOX',
                  display: 'INCLINIC',
                  markersTested: (
                      <>
                          <p className="mt-8 px-10 text-center text-xl font-medium text-nw-offBlack">
                              Full Blood Count
                          </p>
                          <ul className="mx-auto mt-6 grid list-disc px-10 text-nw-offBlack sm:place-items-center lg:grid-cols-3">
                              <li>Haemoglobin</li>
                              <li>Haematocrit</li>
                              <li>Mean Cell Haemoglobin</li>
                              <li>Mean Cell Haemoglobin</li>
                              <li>Concentration (MCHC)</li>
                              <li>Mean Cell Volume</li>
                              <li>Red Blood Cell Count</li>
                              <li>Basophil Count</li>
                              <li>Lymphocyte Count</li>
                              <li>Eosinophil Count</li>
                              <li>Monocyte Count</li>
                              <li>Neutrophil Count</li>
                              <li>White Blood Cell Count</li>
                              <li>Platelet Count</li>
                          </ul>
                          <p className="mt-8 px-10 text-center text-xl font-medium text-nw-offBlack">
                              Nutritional Health
                          </p>
                          <ul className="mx-auto mt-6 grid list-disc px-10 text-nw-offBlack sm:place-items-center lg:grid-cols-3">
                              <li>Total Antioxidant Status</li>
                              <li>Folic acid</li>
                              <li>Vitamin B12</li>
                              <li>Vitamin D</li>
                          </ul>
                          <p className="mt-8 px-10 text-center text-xl font-medium text-nw-offBlack">Thyroid Health</p>
                          <ul className="mx-auto mt-6 grid list-disc px-10 text-nw-offBlack sm:place-items-center lg:grid-cols-3">
                              <li>Thyroid Stimulating Hormone</li>
                              <li>Free Tri-iodothyronine FT3</li>
                              <li>Free Thyroxine FT4</li>
                          </ul>
                          <p className="mt-8 px-10 text-center text-xl font-medium text-nw-offBlack">Stress</p>
                          <ul className="mx-auto mt-6 grid list-disc px-10 text-nw-offBlack sm:place-items-center">
                              <li>Cortisol</li>
                              <li>DHEA-S</li>
                          </ul>
                          <p className="mt-8 px-10 text-center text-xl font-medium text-nw-offBlack">Heart Health</p>
                          <ul className="mx-auto mt-6 grid list-disc px-10 text-nw-offBlack sm:place-items-center lg:grid-cols-3">
                              <li>Total Cholesterol</li>
                              <li>LDL Cholesterol</li>
                              <li>HDL Cholesterol</li>
                              <li>Total Cholesterol / HDL</li>
                              <li>Cholesterol Ratio</li>
                              <li>Triglycerides</li>
                              <li>Apolipoprotein A-I</li>
                              <li>Apolipoprotein B</li>
                              <li>Apolipoprotein B / A-I Ratio</li>
                              <li>High Sensitivity</li>
                              <li>C-Reactive Protein</li>
                              <li>Cardiovascular Risk Score</li>
                          </ul>
                          <p className="mt-8 px-10 text-center text-xl font-medium text-nw-offBlack">Liver Health</p>
                          <ul className="mx-auto mt-6 grid list-disc px-10 text-nw-offBlack sm:place-items-center lg:grid-cols-3">
                              <li>Alkaline Phosphatase</li>
                              <li>Alanine Aminotransferase</li>
                              <li>Aspartate Transferase</li>
                              <li>Gamma-glutamyl Transferase</li>
                              <li>Total Bilirubin</li>
                              <li>Albumin</li>
                              <li>Ferritin</li>
                          </ul>
                          <p className="mt-8 px-10 text-center text-xl font-medium text-nw-offBlack">
                              Male Hormonal Health
                          </p>
                          <ul className="mx-auto mt-6 grid list-disc px-10 text-nw-offBlack sm:place-items-center lg:grid-cols-3">
                              <li>Testosterone</li>
                              <li>Sex Hormone Binding Globulin</li>
                              <li>Free Androgen Index</li>
                          </ul>
                          <p className="mt-8 px-10 text-center text-xl font-medium text-nw-offBlack">Diabetes Health</p>
                          <ul className="mx-auto mt-6 grid list-disc px-10 text-nw-offBlack sm:place-items-center">
                              <li>Glucose</li>
                              <li>HBA1C</li>
                              <li>Insulin</li>
                          </ul>
                          <p className="mt-8 px-10 text-center text-xl font-medium text-nw-offBlack">
                              Urinalysis Kidney Health
                          </p>
                          <ul className="mx-auto mt-6 grid list-disc px-10 text-nw-offBlack sm:place-items-center lg:grid-cols-3">
                              <li>Bilirubin</li>
                              <li>Glucose</li>
                              <li>Ketones</li>
                              <li>Nitrite</li>
                              <li>pH</li>
                              <li>Protein</li>
                              <li>Red Blood Cells</li>
                              <li>Urobilinogen</li>
                              <li>White Blood Cells</li>
                          </ul>
                          <p className="mt-8 px-10 text-center text-xl font-medium text-nw-offBlack">Iron Status</p>
                          <ul className="mx-auto mt-6 grid list-disc px-10 text-nw-offBlack sm:place-items-center">
                              <li>Ferritin</li>
                              <li>Iron</li>
                              <li>TIBC</li>
                              <li>Transferrin</li>
                              <li>Transferrin Saturation</li>
                          </ul>
                          <p className="mt-8 px-10 text-center text-xl font-medium text-nw-offBlack">
                              Female Hormonal Health
                          </p>
                          <ul className="mx-auto mt-6 grid list-disc px-10 text-nw-offBlack sm:place-items-center lg:grid-cols-3">
                              <li>Luteinising Hormone</li>
                              <li>Testosterone</li>
                              <li>Sex Hormone Binding Globulin</li>
                              <li>Free Androgen Index</li>
                          </ul>
                          <p className="mt-8 px-10 text-center text-xl font-medium text-nw-offBlack">
                              Infection & Inflammation
                          </p>
                          <ul className="mx-auto mt-6 grid list-disc px-10 text-nw-offBlack sm:place-items-center">
                              <li>C-Reactive Protein</li>
                          </ul>
                          <p className="mt-8 px-10 text-center text-xl font-medium text-nw-offBlack">Kidney Health</p>
                          <ul className="mx-auto mt-6 grid list-disc px-10 text-nw-offBlack sm:place-items-center lg:grid-cols-3">
                              <li>Creatinine</li>
                              <li>eGFR</li>
                              <li>Calcium (Adjusted)</li>
                              <li>Chloride</li>
                              <li>Magnesium</li>
                              <li>Phosphate</li>
                              <li>Potassium*</li>
                              <li>Sodium</li>
                              <li>Urea</li>
                              <li>Uric Acid</li>
                          </ul>
                          <p className="mt-8 px-10 text-center text-xl font-medium text-nw-offBlack">
                              Muscle & Joint Health
                          </p>
                          <ul className="mx-auto mt-6 grid list-disc px-10 text-nw-offBlack sm:place-items-center lg:grid-cols-3">
                              <li>Creatine Kinase</li>
                              <li>CK-MB</li>
                              <li>Myoglobin</li>
                          </ul>
                          <p className="mt-8 px-10 text-center text-xl font-medium text-nw-offBlack">Bone Health</p>
                          <ul className="mx-auto mt-6 grid list-disc px-10 text-nw-offBlack sm:place-items-center lg:grid-cols-3">
                              <li>Alkaline Phosphatase</li>
                              <li>Calcium (Adjusted)</li>
                              <li>Phosphate</li>
                              <li>Vitamin D</li>
                          </ul>
                      </>
                  )
              },
              prod_P32FE9hNQWavk5: {
                  title: 'Tired All The Time',
                  type: 'BOOKING',
                  testCodes: ['HSC10'],
                  personalise: 'RANDOX',
                  display: 'INCLINIC',
                  markersTested: (
                      <>
                          <p className="mt-8 px-10 text-center text-xl font-medium text-nw-offBlack">
                              Full Blood Count
                          </p>
                          <ul className="mx-auto mt-6 grid list-disc px-10 text-nw-offBlack sm:place-items-center lg:grid-cols-3">
                              <li>Haemoglobin</li>
                              <li>Haematocrit</li>
                              <li>Mean Cell Haemoglobin</li>
                              <li>Mean Cell Haemoglobin</li>
                              <li>Concentration (MCHC)</li>
                              <li>Mean Cell Volume</li>
                              <li>Red Blood Cell Count</li>
                              <li>Basophil Count</li>
                              <li>Lymphocyte Count</li>
                              <li>Eosinophil Count</li>
                              <li>Monocyte Count</li>
                              <li>Neutrophil Count</li>
                              <li>White Blood Cell Count</li>
                              <li>Platelet Count</li>
                          </ul>
                          <p className="mt-8 px-10 text-center text-xl font-medium text-nw-offBlack">Kidney Health</p>
                          <ul className="mx-auto mt-6 grid list-disc px-10 text-nw-offBlack sm:place-items-center lg:grid-cols-3">
                              <li>Creatinine</li>
                              <li>eGFR</li>
                              <li>Calcium (Adjusted)</li>
                              <li>Chloride</li>
                              <li>Magnesium</li>
                              <li>Phosphate</li>
                              <li>Potassium*</li>
                              <li>Sodium</li>
                              <li>Urea</li>
                              <li>Uric Acid</li>
                          </ul>
                          <p className="mt-8 px-10 text-center text-xl font-medium text-nw-offBlack">Bone Health</p>
                          <ul className="mx-auto mt-6 grid list-disc px-10 text-nw-offBlack sm:place-items-center">
                              <li>Alkaline Phosphatase</li>
                          </ul>
                          <p className="mt-8 px-10 text-center text-xl font-medium text-nw-offBlack">
                              Infection & Inflammation
                          </p>
                          <ul className="mx-auto mt-6 grid list-disc px-10 text-nw-offBlack sm:place-items-center">
                              <li>C-Reactive Protein</li>
                          </ul>
                          <p className="mt-8 px-10 text-center text-xl font-medium text-nw-offBlack">
                              Nutritional Health
                          </p>
                          <ul className="mx-auto mt-6 grid list-disc px-10 text-nw-offBlack sm:place-items-center lg:grid-cols-3">
                              <li>Calcium (Adjusted)</li>
                              <li>Albumin</li>
                              <li>Iron</li>
                              <li>Folic Acid</li>
                              <li>Vitamin B12</li>
                              <li>Vitamin D</li>
                          </ul>
                          <p className="mt-8 px-10 text-center text-xl font-medium text-nw-offBlack">Iron Status</p>
                          <ul className="mx-auto mt-6 grid list-disc px-10 text-nw-offBlack sm:place-items-center">
                              <li>Ferritin</li>
                              <li>Iron</li>
                              <li>TIBC</li>
                              <li>Transferrin</li>
                              <li>Transferrin Saturation</li>
                          </ul>
                          <p className="mt-8 px-10 text-center text-xl font-medium text-nw-offBlack">Thyroid Health</p>
                          <ul className="mx-auto mt-6 grid list-disc px-10 text-nw-offBlack sm:place-items-center lg:grid-cols-3">
                              <li>Thyroid Stimulating Hormone</li>
                              <li>Free Tri-iodothyronine</li>
                              <li>Free Thyroxine</li>
                              <li>Anti-Thyroglobulin Antibody</li>
                              <li>Anti-Thyroid Peroxidase Antibody</li>
                          </ul>
                          <p className="mt-8 px-10 text-center text-xl font-medium text-nw-offBlack">Diabetes Health</p>
                          <ul className="mx-auto mt-6 grid list-disc px-10 text-nw-offBlack sm:place-items-center">
                              <li>Glucose</li>
                              <li>HBA1C</li>
                              <li>Insulin</li>
                              <li>C-Peptide</li>
                          </ul>
                      </>
                  )
              },
              prod_P32FirwuAjiXbo: {
                  title: 'Male Cancer Risk Check',
                  type: 'BOOKING',
                  testCodes: ['HSC7M', 'HPYL', 'FOB_FIT_STOOL'],
                  personalise: 'RANDOX',
                  display: 'INCLINIC',
                  markersTested: (
                      <>
                          <p className="mt-8 px-10 text-center text-xl font-medium text-nw-offBlack">
                              Full Blood Count
                          </p>
                          <ul className="mx-auto mt-6 grid list-disc px-10 text-nw-offBlack sm:place-items-center lg:grid-cols-3">
                              <li>Haemoglobin</li>
                              <li>Haematocrit</li>
                              <li>Mean Cell Haemoglobin</li>
                              <li>Mean Cell Haemoglobin</li>
                              <li>Concentration (MCHC)</li>
                              <li>Mean Cell Volume</li>
                              <li>Red Blood Cell Count</li>
                              <li>Basophil Count</li>
                              <li>Lymphocyte Count</li>
                              <li>Eosinophil Count</li>
                              <li>Monocyte Count</li>
                              <li>Neutrophil Count</li>
                              <li>White Blood Cell Count</li>
                              <li>Platelet Count</li>
                          </ul>
                          <p className="mt-8 px-10 text-center text-xl font-medium text-nw-offBlack">Kidney Health</p>
                          <ul className="mx-auto mt-6 grid list-disc px-10 text-nw-offBlack sm:place-items-center lg:grid-cols-3">
                              <li>Creatinine</li>
                              <li>eGFR</li>
                              <li>Chloride</li>
                              <li>Potassium*</li>
                              <li>Phosphate</li>
                              <li>Sodium</li>
                              <li>Urea</li>
                          </ul>
                          <p className="mt-8 px-10 text-center text-xl font-medium text-nw-offBlack">Heart Health</p>
                          <ul className="mx-auto mt-6 grid list-disc px-10 text-nw-offBlack sm:place-items-center lg:grid-cols-3">
                              <li>Total Cholesterol</li>
                              <li>LDL Cholesterol</li>
                              <li>HDL Cholesterol</li>
                              <li>Total Cholesterol/HDL</li>
                              <li>Cholesterol Ratio</li>
                              <li>Triglycerides</li>
                              <li>High Sensitivity</li>
                              <li>C-Reactive Protein</li>
                          </ul>
                          <p className="mt-8 px-10 text-center text-xl font-medium text-nw-offBlack">Liver Health</p>
                          <ul className="mx-auto mt-6 grid list-disc px-10 text-nw-offBlack sm:place-items-center lg:grid-cols-3">
                              <li>Alkaline Phosphatase</li>
                              <li>Alanine Aminotransferase</li>
                              <li>Aspartate Transferase</li>
                              <li>Gamma-glutamyl Transferase</li>
                              <li>Total Bilirubin</li>
                              <li>Albumin</li>
                          </ul>
                          <p className="mt-8 px-10 text-center text-xl font-medium text-nw-offBlack">Diabetes Health</p>
                          <ul className="mx-auto mt-6 grid list-disc px-10 text-nw-offBlack sm:place-items-center">
                              <li>Glucose</li>
                              <li>HBA1C</li>
                          </ul>
                          <p className="mt-8 px-10 text-center text-xl font-medium text-nw-offBlack">Urinalysis</p>
                          <ul className="mx-auto mt-6 grid list-disc px-10 text-nw-offBlack sm:place-items-center lg:grid-cols-3">
                              <li>Bilirubin</li>
                              <li>Glucose</li>
                              <li>Ketones</li>
                              <li>Nitrite</li>
                              <li>PH</li>
                              <li>Protein</li>
                              <li>Red Blood Cells</li>
                              <li>Urobilinogen</li>
                              <li>White Blood Cells</li>
                          </ul>
                          <p className="mt-8 px-10 text-center text-xl font-medium text-nw-offBlack">Bone Health</p>
                          <ul className="mx-auto mt-6 grid list-disc px-10 text-nw-offBlack sm:place-items-center">
                              <li>Alkaline Phosphatase</li>
                              <li>Calcium (Adjusted)</li>
                              <li>Phosphate</li>
                              <li>Vitamin D</li>
                          </ul>
                          <p className="mt-8 px-10 text-center text-xl font-medium text-nw-offBlack">Iron Status</p>
                          <ul className="mx-auto mt-6 grid list-disc px-10 text-nw-offBlack sm:place-items-center">
                              <li>Ferritin</li>
                          </ul>
                          <p className="mt-8 px-10 text-center text-xl font-medium text-nw-offBlack">Thyroid Health</p>
                          <ul className="mx-auto mt-6 grid list-disc px-10 text-nw-offBlack sm:place-items-center lg:grid-cols-3">
                              <li>Thyroid Stimulating</li>
                              <li>Hormone</li>
                              <li>Free Tri-iodothyronine</li>
                              <li>Free Thyroxine</li>
                              <li>Anti-Thyroglobulin Antibody</li>
                              <li>Anti-Thyroid Peroxidase</li>
                              <li>Antibody</li>
                          </ul>
                          <p className="mt-8 px-10 text-center text-xl font-medium text-nw-offBlack">Tumour Markers</p>
                          <ul className="mx-auto mt-6 grid list-disc px-10 text-nw-offBlack sm:place-items-center">
                              <li>Total Prostate Specific Antigen (prostate cancer)</li>
                              <li>Quantitative Faecal Immunochemical Test (bowel cancer)</li>
                              <li>H.Pylori Antibodies (stomach cancer)</li>
                          </ul>
                      </>
                  )
              },
              prod_P32F1KxUJAFaa3: {
                  title: 'Female Cancer Risk Check',
                  type: 'BOOKING',
                  testCodes: ['HSC7F', 'HPYL', 'FOB_FIT_STOOL'],
                  personalise: 'RANDOX',
                  display: 'INCLINIC',
                  markersTested: (
                      <>
                          <p className="mt-8 px-10 text-center text-xl font-medium text-nw-offBlack">
                              Full Blood Count
                          </p>
                          <ul className="mx-auto mt-6 grid list-disc px-10 text-nw-offBlack sm:place-items-center lg:grid-cols-3">
                              <li>Haemoglobin</li>
                              <li>Haematocrit</li>
                              <li>Mean Cell Haemoglobin</li>
                              <li>Mean Cell Haemoglobin</li>
                              <li>Concentration (MCHC)</li>
                              <li>Mean Cell Volume</li>
                              <li>Red Blood Cell Count</li>
                              <li>Basophil Count</li>
                              <li>Lymphocyte Count</li>
                              <li>Eosinophil Count</li>
                              <li>Monocyte Count</li>
                              <li>Neutrophil Count</li>
                              <li>White Blood Cell Count</li>
                              <li>Platelet Count</li>
                          </ul>
                          <p className="mt-8 px-10 text-center text-xl font-medium text-nw-offBlack">Kidney Health</p>
                          <ul className="mx-auto mt-6 grid list-disc px-10 text-nw-offBlack sm:place-items-center lg:grid-cols-3">
                              <li>Creatinine</li>
                              <li>eGFR</li>
                              <li>Chloride</li>
                              <li>Potassium*</li>
                              <li>Phosphate</li>
                              <li>Sodium</li>
                              <li>Urea</li>
                          </ul>
                          <p className="mt-8 px-10 text-center text-xl font-medium text-nw-offBlack">Heart Health</p>
                          <ul className="mx-auto mt-6 grid list-disc px-10 text-nw-offBlack sm:place-items-center lg:grid-cols-3">
                              <li>Total Cholesterol</li>
                              <li>LDL Cholesterol</li>
                              <li>HDL Cholesterol</li>
                              <li>Total Cholesterol/HDL</li>
                              <li>Cholesterol Ratio</li>
                              <li>Triglycerides</li>
                              <li>High Sensitivity</li>
                              <li>C-Reactive Protein</li>
                          </ul>
                          <p className="mt-8 px-10 text-center text-xl font-medium text-nw-offBlack">Liver Health</p>
                          <ul className="mx-auto mt-6 grid list-disc px-10 text-nw-offBlack sm:place-items-center lg:grid-cols-3">
                              <li>Alkaline Phosphatase</li>
                              <li>Alanine Aminotransferase</li>
                              <li>Aspartate Transferase</li>
                              <li>Gamma-glutamyl Transferase</li>
                              <li>Total Bilirubin</li>
                              <li>Albumin</li>
                          </ul>
                          <p className="mt-8 px-10 text-center text-xl font-medium text-nw-offBlack">Diabetes Health</p>
                          <ul className="mx-auto mt-6 grid list-disc px-10 text-nw-offBlack sm:place-items-center">
                              <li>Glucose</li>
                              <li>HBA1C</li>
                          </ul>
                          <p className="mt-8 px-10 text-center text-xl font-medium text-nw-offBlack">Urinalysis</p>
                          <ul className="mx-auto mt-6 grid list-disc px-10 text-nw-offBlack sm:place-items-center lg:grid-cols-3">
                              <li>Bilirubin</li>
                              <li>Glucose</li>
                              <li>Ketones</li>
                              <li>Nitrite</li>
                              <li>PH</li>
                              <li>Protein</li>
                              <li>Red Blood Cells</li>
                              <li>Urobilinogen</li>
                              <li>White Blood Cells</li>
                          </ul>
                          <p className="mt-8 px-10 text-center text-xl font-medium text-nw-offBlack">Bone Health</p>
                          <ul className="mx-auto mt-6 grid list-disc px-10 text-nw-offBlack sm:place-items-center">
                              <li>Alkaline Phosphatase</li>
                              <li>Calcium (Adjusted)</li>
                              <li>Phosphate</li>
                              <li>Vitamin D</li>
                          </ul>
                          <p className="mt-8 px-10 text-center text-xl font-medium text-nw-offBlack">Iron Status</p>
                          <ul className="mx-auto mt-6 grid list-disc px-10 text-nw-offBlack sm:place-items-center">
                              <li>Ferritin</li>
                          </ul>
                          <p className="mt-8 px-10 text-center text-xl font-medium text-nw-offBlack">Thyroid Health</p>
                          <ul className="mx-auto mt-6 grid list-disc px-10 text-nw-offBlack sm:place-items-center lg:grid-cols-3">
                              <li>Thyroid Stimulating</li>
                              <li>Hormone</li>
                              <li>Free Tri-iodothyronine</li>
                              <li>Free Thyroxine</li>
                              <li>Anti-Thyroglobulin Antibody</li>
                              <li>Anti-Thyroid Peroxidase</li>
                              <li>Antibody</li>
                          </ul>
                          <p className="mt-8 px-10 text-center text-xl font-medium text-nw-offBlack">Tumour Markers</p>
                          <ul className="mx-auto mt-6 grid list-disc px-10 text-nw-offBlack sm:place-items-center">
                              <li>Total Prostate Specific Antigen</li>
                              <li>CA 125 (ovarian cancer)</li>
                              <li>H.Pylori Antibodies (stomach cancer) </li>
                              <li>CA 15-3 (breast cancer)</li>
                          </ul>
                      </>
                  )
              },
              prod_P32Fnof4bRKhnK: {
                  title: 'Build Your Own Health Check',
                  type: 'BOOKING',
                  testCodes: [],
                  personalise: 'RANDOX',
                  display: 'INCLINIC',
                  markersTested: (
                      <ul className="mx-auto mt-8 grid list-disc px-10 text-nw-offBlack sm:place-items-center">
                          <li>Add your own biomarkers by personalising this test in the checkout.</li>
                      </ul>
                  )
              },

              // ----- Oncontext Tests ----- //
              prod_P32Evv7ZUsecZx: {
                  title: 'Onconext Risk® BRCA',
                  type: 'TEST',
                  testCodes: [],
                  personalise: null,
                  display: 'ONCONTEXT',
                  markersTested: (
                      <p className="mt-8 px-10 text-center text-nw-offBlack">
                          Onconext testing analyses multiple genes for the detection of possible predisposition to
                          hereditary cancers.
                      </p>
                  )
              },
              prod_P32EV6UYCzNDJI: {
                  title: 'Onconext Risk® Breast',
                  type: 'TEST',
                  testCodes: [],
                  personalise: null,
                  display: 'ONCONTEXT',
                  markersTested: (
                      <p className="mt-8 px-10 text-center text-nw-offBlack">
                          Onconext testing analyses multiple genes for the detection of possible predisposition to
                          hereditary cancers.
                      </p>
                  )
              },
              prod_P32EEa8jM825cR: {
                  title: 'Onconext Risk® Ovarian',
                  type: 'TEST',
                  testCodes: [],
                  personalise: null,
                  display: 'ONCONTEXT',
                  markersTested: (
                      <p className="mt-8 px-10 text-center text-nw-offBlack">
                          Onconext testing analyses multiple genes for the detection of possible predisposition to
                          hereditary cancers.
                      </p>
                  )
              },
              prod_P32EcRjUnvcMhW: {
                  title: 'Onconext Risk® Colon',
                  type: 'TEST',
                  testCodes: [],
                  personalise: null,
                  display: 'ONCONTEXT',
                  markersTested: (
                      <p className="mt-8 px-10 text-center text-nw-offBlack">
                          Onconext testing analyses multiple genes for the detection of possible predisposition to
                          hereditary cancers.
                      </p>
                  )
              },
              prod_P32EFq9Vd0Gj8X: {
                  title: 'Onconext Risk® Cerebral',
                  type: 'TEST',
                  testCodes: [],
                  personalise: null,
                  display: 'ONCONTEXT',
                  markersTested: (
                      <p className="mt-8 px-10 text-center text-nw-offBlack">
                          Onconext testing analyses multiple genes for the detection of possible predisposition to
                          hereditary cancers.
                      </p>
                  )
              },
              prod_P32EOHrU1Kd9cC: {
                  title: 'Onconext Risk® Feocromocitoma/paraganglioma',
                  type: 'TEST',
                  testCodes: [],
                  personalise: null,
                  display: 'ONCONTEXT',
                  markersTested: (
                      <p className="mt-8 px-10 text-center text-nw-offBlack">
                          Onconext testing analyses multiple genes for the detection of possible predisposition to
                          hereditary cancers.
                      </p>
                  )
              },
              prod_P32Eb8oQASlhqb: {
                  title: 'Onconext Risk® Prostate',
                  type: 'TEST',
                  testCodes: [],
                  personalise: null,
                  display: 'ONCONTEXT',
                  markersTested: (
                      <p className="mt-8 px-10 text-center text-nw-offBlack">
                          Onconext testing analyses multiple genes for the detection of possible predisposition to
                          hereditary cancers.
                      </p>
                  )
              },
              prod_P32Eo8sXnHTR6o: {
                  title: 'Onconext Risk® Renal',
                  type: 'TEST',
                  testCodes: [],
                  personalise: null,
                  display: 'ONCONTEXT',
                  markersTested: (
                      <p className="mt-8 px-10 text-center text-nw-offBlack">
                          Onconext testing analyses multiple genes for the detection of possible predisposition to
                          hereditary cancers.
                      </p>
                  )
              },
              prod_P32Euy1nfKyEqJ: {
                  title: 'Onconext Risk® Melanoma',
                  type: 'TEST',
                  testCodes: [],
                  personalise: null,
                  display: 'ONCONTEXT',
                  markersTested: (
                      <p className="mt-8 px-10 text-center text-nw-offBlack">
                          Onconext testing analyses multiple genes for the detection of possible predisposition to
                          hereditary cancers.
                      </p>
                  )
              },
              prod_P32DacNsN5uHAT: {
                  title: 'Onconext Risk® Gastric',
                  type: 'TEST',
                  testCodes: [],
                  personalise: null,
                  display: 'ONCONTEXT',
                  markersTested: (
                      <p className="mt-8 px-10 text-center text-nw-offBlack">
                          Onconext testing analyses multiple genes for the detection of possible predisposition to
                          hereditary cancers.
                      </p>
                  )
              },
              prod_P32DP6TRPNRlMN: {
                  title: 'Onconext Risk® Pancreas',
                  type: 'TEST',
                  testCodes: [],
                  personalise: null,
                  display: 'ONCONTEXT',
                  markersTested: (
                      <p className="mt-8 px-10 text-center text-nw-offBlack">
                          Onconext testing analyses multiple genes for the detection of possible predisposition to
                          hereditary cancers.
                      </p>
                  )
              },
              prod_P32D9oweSiTXgh: {
                  title: 'Onconext Risk® Oncoscreening Complete',
                  type: 'TEST',
                  testCodes: [],
                  personalise: null,
                  display: 'ONCONTEXT',
                  markersTested: (
                      <p className="mt-8 px-10 text-center text-nw-offBlack">
                          Onconext testing analyses multiple genes for the detection of possible predisposition to
                          hereditary cancers.
                      </p>
                  )
              },

              // ----- TruCheck Tests ----- //
              //Test commit
              prod_P32DNbWu1b7sB3: {
                  title: 'TruCheck Bowel Cancer Screening',
                  type: 'TEST',
                  testCodes: [],
                  personalise: null,
                  display: 'TRUCHECK',
                  markersTested: (
                      <p className="mt-8 px-10 text-center text-nw-offBlack">
                          A blood test that can detect early-stage bowel cancer within the asymptomatic population.
                      </p>
                  )
              },
              prod_P32DF9Z9z3wIZ2: {
                  title: 'TruCheck Prostate Cancer Screening',
                  type: 'TEST',
                  testCodes: [],
                  personalise: null,
                  display: 'TRUCHECK',
                  markersTested: (
                      <p className="mt-8 px-10 text-center text-nw-offBlack">
                          A blood test that can detect early-stage prostate cancer within the asymptomatic population.
                      </p>
                  )
              },
              prod_P32DvQWjVZBew5: {
                  title: 'TruCheck Breast Cancer Screening',
                  type: 'TEST',
                  testCodes: [],
                  personalise: null,
                  display: 'TRUCHECK',
                  markersTested: (
                      <p className="mt-8 px-10 text-center text-nw-offBlack">
                          A blood test that can detect early-stage breast cancer within the asymptomatic population.
                      </p>
                  )
              },
              prod_P32DBCmLqrGK3s: {
                  title: 'TruCheck Intelli Cancer Screening',
                  type: 'TEST',
                  testCodes: [],
                  personalise: null,
                  display: 'TRUCHECK',
                  markersTested: (
                      <p className="mt-8 px-10 text-center text-nw-offBlack">
                          A blood test that can detect more than 70 early stage cancers in the asymptomatic population.
                      </p>
                  )
              },
              prod_P5fQRM7jqNhsP0: {
                  title: 'TruCheck Female Cancer Screening',
                  type: 'TEST',
                  testCodes: [],
                  personalise: null,
                  display: 'TRUCHECK',
                  markersTested: (
                      <p className="mt-8 px-10 text-center text-nw-offBlack">
                          A blood test that can distinguish several types of solid cancer tumours which commonly affect
                          females.
                      </p>
                  )
              },

              // ----- Prenatal Safe Tests ----- //
              prod_P5flZCgmq28Aip: {
                  title: 'Gender Reveal Test',
                  type: 'TEST',
                  testCodes: [],
                  personalise: null,
                  display: 'PRENATALSAFE',
                  markersTested: (
                      <>
                          <p className="mt-8 px-10 text-center text-nw-offBlack">
                              Prenatal gender determination with non invasive blood test
                          </p>
                          <ul className="mx-auto mt-4 grid list-disc grid-cols-1 px-10 text-nw-offBlack sm:place-items-center">
                              <li>Suitable from 10 week gestation</li>
                              <li>In clinic medical consultation & doctors report</li>
                          </ul>
                      </>
                  )
              },
              prod_P32Adbs1uKE4H7: {
                  title: 'Prenatal Safe 3 +/- Gender',
                  type: 'TEST',
                  testCodes: [],
                  personalise: null,
                  display: 'PRENATALSAFE',
                  markersTested: (
                      <>
                          <p className="mt-8 px-10 text-center text-nw-offBlack">
                              Prenantal SAFE 3 is a non invasive test that enables the analysis of Fetal genetic
                              material from a routine blood sample. This test includes:
                          </p>
                          <ul className="mx-auto mt-4 grid list-disc grid-cols-1 px-10 text-nw-offBlack sm:place-items-center">
                              <li>Fetal sex</li>
                              <li>Trisomy 21 (Downs Syndrome)</li>
                              <li>Trisomy 18 (Edwards Syndrome)</li>
                              <li>Trisomy 13 (Patau Syndrome)</li>
                          </ul>
                      </>
                  )
              },
              prod_P32AKR5nTqN7lT: {
                  title: 'Prenatal Safe 5 +/- Gender',
                  type: 'TEST',
                  testCodes: [],
                  personalise: null,
                  display: 'PRENATALSAFE',
                  markersTested: (
                      <>
                          <p className="mt-8 px-10 text-center text-nw-offBlack">
                              Prenantal SAFE 5 is a non invasive test that enables the analysis of Fetal genetic
                              material from a routine blood sample. This test includes:
                          </p>
                          <ul className="mx-auto mt-4 grid list-disc grid-cols-1 px-10 text-nw-offBlack sm:place-items-center">
                              <li>Fetal sex</li>
                              <li>Trisomy 21 (Downs Syndrome)</li>
                              <li>Trisomy 18 (Edwards Syndrome)</li>
                              <li>Trisomy 13 (Patau Syndrome)</li>
                              <li>Sex Chromosome Anueploidies</li>
                          </ul>
                      </>
                  )
              },
              prod_P329y8AXLltNIa: {
                  title: 'Prenatal Safe Karyo',
                  type: 'TEST',
                  testCodes: [],
                  personalise: null,
                  display: 'PRENATALSAFE',
                  markersTested: (
                      <>
                          <p className="mt-8 px-10 text-center text-nw-offBlack">
                              Prenantal SAFE Karyo is a non invasive test that enables the analysis of Fetal genetic
                              material from a routine blood sample. This test includes:
                          </p>
                          <ul className="mx-auto mt-4 grid list-disc grid-cols-1 px-10 text-nw-offBlack sm:place-items-center">
                              <li>Fetal sex</li>
                              <li>Trisomy 21 (Downs Syndrome)</li>
                              <li>Trisomy 18 (Edwards Syndrome)</li>
                              <li>Trisomy 13 (Patau Syndrome)</li>
                              <li>Sex Chromosome Anueploidies</li>
                              <li>Rare Autosomal Aneuploidies</li>
                              <li>Deletions & Duplications</li>
                          </ul>
                      </>
                  )
              },
              prod_P3292HUQi4Vsuw: {
                  title: 'PrenatalSafe Complete Plus',
                  type: 'TEST',
                  testCodes: [],
                  personalise: null,
                  display: 'PRENATALSAFE',
                  markersTested: (
                      <>
                          <p className="mt-8 px-10 text-center text-nw-offBlack">
                              Prenantal SAFE complete plus is a non invasive test that enables the analysis of Fetal
                              genetic material from a routine blood sample. This test includes:
                          </p>
                          <ul className="mx-auto mt-4 grid list-disc grid-cols-1 px-10 text-nw-offBlack sm:place-items-center">
                              <li>Fetal sex</li>
                              <li>Trisomy 21 (Downs Syndrome)</li>
                              <li>Trisomy 18 (Edwards Syndrome)</li>
                              <li>Trisomy 13 (Patau Syndrome)</li>
                              <li>Sex Chromosome Anueploidies</li>
                              <li>Rare Autosomal Aneuploidies</li>
                              <li>Deletions & Duplications</li>
                              <li>Microdeletions</li>
                              <li>Inherited Genetic Diseases</li>
                              <li>De novo Genetic Diseases</li>
                          </ul>
                      </>
                  )
              },

              // ----- Finger Prick Tests ----- //
              prod_P32DJRKsQSXVhO: {
                  title: 'Female Fertility Test',
                  type: 'TEST',
                  testCodes: ['NEU015'],
                  personalise: null,
                  display: 'FINGERPRICK',
                  markersTested: (
                      <ul className="mx-auto mt-8 grid list-disc px-10 text-nw-offBlack sm:place-items-center lg:grid-cols-3">
                          <li>AMH</li>
                          <li>FSH</li>
                          <li>LH</li>
                          <li>Oestrogen</li>
                          <li>Prolactin</li>
                          <li>Basic Thyroid ( TSH, FT4, FT3)</li>
                      </ul>
                  )
              },
              prod_P330ofB2zdkrux: {
                  title: 'Progesterone Test',
                  type: 'TEST',
                  testCodes: ['PROG'],
                  personalise: null,
                  display: 'FINGERPRICK',
                  markersTested: (
                      <ul className="mx-auto mt-8 grid list-disc px-10 text-nw-offBlack sm:place-items-center">
                          <li>Progesterone</li>
                      </ul>
                  )
              },
              prod_P32D4sILsMKOTn: {
                  title: 'Oestrogen Test',
                  type: 'TEST',
                  testCodes: ['OEST'],
                  personalise: null,
                  display: 'FINGERPRICK',
                  markersTested: (
                      <ul className="mx-auto mt-8 grid list-disc px-10 text-nw-offBlack sm:place-items-center">
                          <li>Oestradiol</li>
                      </ul>
                  )
              },
              prod_P32DKiBmriazfl: {
                  title: 'Vitamin D Test',
                  type: 'TEST',
                  testCodes: ['VITD'],
                  personalise: null,
                  display: 'FINGERPRICK',
                  markersTested: (
                      <ul className="mx-auto mt-8 grid list-disc px-10 text-nw-offBlack sm:place-items-center">
                          <li>Vitamin D</li>
                      </ul>
                  )
              },
              prod_P32DZr3EDCw7ik: {
                  title: 'Anaemia Test',
                  type: 'TEST',
                  testCodes: ['NEU016'],
                  personalise: null,
                  display: 'FINGERPRICK',
                  markersTested: (
                      <ul className="mx-auto mt-8 grid list-disc px-10 text-nw-offBlack sm:place-items-center lg:grid-cols-3">
                          <li>Active B12</li>
                          <li>Ferritin</li>
                          <li>Folate</li>
                          <li>Magnesium</li>
                          <li>FBC</li>
                          <li>Vitamin D</li>
                      </ul>
                  )
              },
              prod_P32CVfo9uXBXMK: {
                  title: 'Magnesium Test',
                  type: 'TEST',
                  testCodes: ['MG'],
                  personalise: null,
                  display: 'FINGERPRICK',
                  markersTested: (
                      <ul className="mx-auto mt-8 grid list-disc px-10 text-nw-offBlack sm:place-items-center">
                          <li>Magnesium</li>
                      </ul>
                  )
              },
              prod_P32CmPIUAm3Ad0: {
                  title: 'Cholesterol Test',
                  type: 'TEST',
                  testCodes: ['NEU002'],
                  personalise: null,
                  display: 'FINGERPRICK',
                  markersTested: (
                      <ul className="mx-auto mt-8 grid list-disc px-10 text-nw-offBlack sm:place-items-center">
                          <li>Total Cholesterol</li>
                          <li>HDL</li>
                          <li>Chol:HDL</li>
                          <li>LDL</li>
                          <li>Triglycerides</li>
                      </ul>
                  )
              },
              prod_P32CfIYb3V7WfZ: {
                  title: 'Pre Diabetes Test',
                  type: 'TEST',
                  testCodes: ['GHBI'],
                  personalise: null,
                  display: 'FINGERPRICK',
                  markersTested: (
                      <ul className="mx-auto mt-8 grid list-disc px-10 text-nw-offBlack sm:place-items-center">
                          <li>HbA1c</li>
                      </ul>
                  )
              },
              prod_P32CNtGl2TsHKt: {
                  title: 'Liver Function Test',
                  type: 'TEST',
                  testCodes: ['NEU002'],
                  personalise: null,
                  display: 'FINGERPRICK',
                  markersTested: (
                      <ul className="mx-auto mt-8 grid list-disc px-10 text-nw-offBlack sm:place-items-center">
                          <li>ALT</li>
                          <li>Albumin</li>
                          <li>ALP</li>
                          <li>GGT</li>
                          <li>Globulin</li>
                          <li>Total Protein</li>
                      </ul>
                  )
              },
              prod_P32CSz0dAdHcJc: {
                  title: 'Immune Health Test',
                  type: 'TEST',
                  testCodes: ['NEU003'],
                  personalise: null,
                  display: 'FINGERPRICK',
                  markersTested: (
                      <ul className="mx-auto mt-8 grid list-disc px-10 text-nw-offBlack sm:place-items-center">
                          <li>Active B12</li>
                          <li>Vitamin D</li>
                          <li>Ferritin</li>
                          <li>Albumin</li>
                          <li>FBC</li>
                          <li>HS-CRP</li>
                      </ul>
                  )
              },
              prod_P32CEbqBYrnBPW: {
                  title: 'CRP Test',
                  type: 'TEST',
                  testCodes: ['CRP'],
                  personalise: null,
                  display: 'FINGERPRICK',
                  markersTested: (
                      <ul className="mx-auto mt-8 grid list-disc px-10 text-nw-offBlack sm:place-items-center">
                          <li>HS-CRP</li>
                      </ul>
                  )
              },
              prod_P32CQ8qOlTj4r1: {
                  title: 'Vitamin B12 Test',
                  type: 'TEST',
                  testCodes: ['TB13'],
                  personalise: null,
                  display: 'FINGERPRICK',
                  markersTested: (
                      <ul className="mx-auto mt-8 grid list-disc px-10 text-nw-offBlack sm:place-items-center">
                          <li>B12</li>
                      </ul>
                  )
              },
              prod_P32ColAfecYjDP: {
                  title: 'Cortisol Test',
                  type: 'TEST',
                  testCodes: ['CORT'],
                  personalise: null,
                  display: 'FINGERPRICK',
                  markersTested: (
                      <ul className="mx-auto mt-8 grid list-disc px-10 text-nw-offBlack sm:place-items-center">
                          <li>Cortisol</li>
                      </ul>
                  )
              },
              prod_P32CxWwOUMv55f: {
                  title: 'Iron Test',
                  type: 'TEST',
                  testCodes: ['FERR'],
                  personalise: null,
                  display: 'FINGERPRICK',
                  markersTested: (
                      <ul className="mx-auto mt-8 grid list-disc px-10 text-nw-offBlack sm:place-items-center">
                          <li>Ferritin</li>
                      </ul>
                  )
              },
              prod_P32BJUXMQ4XFCj: {
                  title: 'Female Fitness Test',
                  type: 'TEST',
                  testCodes: ['NEU004'],
                  personalise: null,
                  display: 'FINGERPRICK',
                  markersTested: (
                      <ul className="mx-auto mt-8 grid list-disc px-10 text-nw-offBlack sm:place-items-center">
                          <li>Active B12</li>
                          <li>Cortisol</li>
                          <li>Ferritin</li>
                          <li>FBC</li>
                          <li>LH</li>
                          <li>Oestradiol</li>
                          <li>Prolactin</li>
                          <li>Testosterone</li>
                          <li>TSH</li>
                          <li>Free T3</li>
                          <li>Vitamin D</li>
                      </ul>
                  )
              },
              prod_P32BehTEBLw7ma: {
                  title: 'Male Fitness Test',
                  type: 'TEST',
                  testCodes: ['NEU005'],
                  personalise: null,
                  display: 'FINGERPRICK',
                  markersTested: (
                      <ul className="mx-auto mt-8 grid list-disc px-10 text-nw-offBlack sm:place-items-center">
                          <li>Active B12</li>
                          <li>Cortisol (9am)</li>
                          <li>Ferritin</li>
                          <li>Vitamin D</li>
                          <li>Testosterone</li>
                          <li>FSH</li>
                          <li>LH</li>
                          <li>Prolactin</li>
                          <li>FBC</li>
                          <li>TSH</li>
                          <li>Free T4</li>
                          <li>Free T3</li>
                      </ul>
                  )
              },
              prod_P32BHMpTRY47Zl: {
                  title: 'AFP Test',
                  type: 'TEST',
                  testCodes: ['AFP'],
                  personalise: null,
                  display: 'FINGERPRICK',
                  markersTested: (
                      <ul className="mx-auto mt-8 grid list-disc px-10 text-nw-offBlack sm:place-items-center">
                          <li>AFP</li>
                      </ul>
                  )
              },
              prod_P32BLmafOXO8sa: {
                  title: 'HCG-Beta Test',
                  type: 'TEST',
                  testCodes: ['QHCG'],
                  personalise: null,
                  display: 'FINGERPRICK',
                  markersTested: (
                      <ul className="mx-auto mt-8 grid list-disc px-10 text-nw-offBlack sm:place-items-center">
                          <li>HCG-Beta</li>
                      </ul>
                  )
              },
              prod_P32BVNQLCFHvWu: {
                  title: 'CA-19-10 Test',
                  type: 'TEST',
                  testCodes: ['CA199'],
                  personalise: null,
                  display: 'FINGERPRICK',
                  markersTested: (
                      <ul className="mx-auto mt-8 grid list-disc px-10 text-nw-offBlack sm:place-items-center">
                          <li>CA-19-10</li>
                      </ul>
                  )
              },
              prod_P32BhtidPhwp8b: {
                  title: 'CA-126 Test',
                  type: 'TEST',
                  testCodes: ['CA126'],
                  personalise: null,
                  display: 'FINGERPRICK',
                  markersTested: (
                      <ul className="mx-auto mt-8 grid list-disc px-10 text-nw-offBlack sm:place-items-center">
                          <li>CA-126</li>
                      </ul>
                  )
              },
              prod_P32B8CpqdmzW6F: {
                  title: 'CA 15-3 antigen Test',
                  type: 'TEST',
                  testCodes: ['C154'],
                  personalise: null,
                  display: 'FINGERPRICK',
                  markersTested: (
                      <ul className="mx-auto mt-8 grid list-disc px-10 text-nw-offBlack sm:place-items-center">
                          <li>CA 15-3 antigen</li>
                      </ul>
                  )
              },
              prod_P32B5VR2UN1gER: {
                  title: 'CEA Test',
                  type: 'TEST',
                  testCodes: ['CEA'],
                  personalise: null,
                  display: 'FINGERPRICK',
                  markersTested: (
                      <ul className="mx-auto mt-8 grid list-disc px-10 text-nw-offBlack sm:place-items-center">
                          <li>CEA</li>
                      </ul>
                  )
              },
              prod_P32ByHDwO3AuiV: {
                  title: 'PSA Test',
                  type: 'TEST',
                  testCodes: ['PSPA'],
                  personalise: null,
                  display: 'FINGERPRICK',
                  markersTested: (
                      <ul className="mx-auto mt-8 grid list-disc px-10 text-nw-offBlack sm:place-items-center">
                          <li>PSA</li>
                      </ul>
                  )
              },
              prod_P32BjOHEFwh4Jl: {
                  title: 'Menopause Health Test',
                  type: 'TEST',
                  testCodes: ['NEU006'],
                  personalise: null,
                  display: 'FINGERPRICK',
                  markersTested: (
                      <ul className="mx-auto mt-8 grid list-disc px-10 text-nw-offBlack sm:place-items-center">
                          <li>Albumin</li>
                          <li>Corrected Calcium</li>
                          <li>Cortisol (9am)</li>
                          <li>Ferritin</li>
                          <li>FSH</li>
                          <li>Lipids (Cholesterol, HDL, Chol:HDL, Non-HDL, LDL, Triglycerides)</li>
                          <li>LH</li>
                          <li>Magnesium</li>
                          <li>Oestradiol</li>
                          <li>Testosterone</li>
                          <li>TSH</li>
                          <li>Free T4</li>
                          <li>Vitamin D</li>
                      </ul>
                  )
              },
              prod_P32BmPZJ6E5Qhm: {
                  title: 'Female Hormone Test',
                  type: 'TEST',
                  testCodes: ['NEU007'],
                  personalise: null,
                  display: 'FINGERPRICK',
                  markersTested: (
                      <ul className="mx-auto mt-8 grid list-disc px-10 text-nw-offBlack sm:place-items-center">
                          <li>Free Androgen Index</li>
                          <li>Testosterone</li>
                          <li>FSH</li>
                          <li>LH</li>
                          <li>Oestradiol</li>
                          <li>Prolactin</li>
                          <li>SHBG</li>
                          <li>TSH</li>
                          <li>FT5</li>
                      </ul>
                  )
              },
              prod_P32AlLH50XEytN: {
                  title: 'Male Hormone Test',
                  type: 'TEST',
                  testCodes: ['NEU008'],
                  personalise: null,
                  display: 'FINGERPRICK',
                  markersTested: (
                      <ul className="mx-auto mt-8 grid list-disc px-10 text-nw-offBlack sm:place-items-center">
                          <li>Albumin, Cortisol (9am)</li>
                          <li>FSH</li>
                          <li>Free Testosterone Calculated</li>
                          <li>Testosterone</li>
                          <li>LH</li>
                          <li>Oestradiol</li>
                          <li>Prolactin</li>
                          <li>SHBG</li>
                          <li>TSH</li>
                          <li>FT5</li>
                      </ul>
                  )
              },
              prod_P32AHpecsq3v51: {
                  title: 'Tiredness & Fatigue Test',
                  type: 'TEST',
                  testCodes: ['NEU009'],
                  personalise: null,
                  display: 'FINGERPRICK',
                  markersTested: (
                      <ul className="mx-auto mt-8 grid list-disc px-10 text-nw-offBlack sm:place-items-center">
                          <li>TSH</li>
                          <li>Active B12</li>
                          <li>Ferritin</li>
                          <li>Vitamin D</li>
                      </ul>
                  )
              },
              prod_P32Akf6pimMhOs: {
                  title: 'Baseline Health Check',
                  type: 'TEST',
                  testCodes: ['NEU010'],
                  personalise: null,
                  display: 'FINGERPRICK',
                  markersTested: (
                      <ul className="mx-auto mt-8 grid list-disc px-10 text-nw-offBlack sm:place-items-center">
                          <li>Active B12</li>
                          <li>ALT</li>
                          <li>Albumin</li>
                          <li>ALP</li>
                          <li>Calcium</li>
                          <li>Corrected Calcium</li>
                          <li>Cortisol (9am)</li>
                          <li>Ferritin, GGT</li>
                          <li>Globulin</li>
                          <li>HbA1c</li>
                          <li>Lipids (Cholesterol, HDL, Chol:HDL, Non-HDL, LDL, Triglycerides)</li>
                          <li>HS-CRP</li>
                          <li>TSH</li>
                          <li>Total Protein</li>
                          <li>Vitamin D</li>
                      </ul>
                  )
              },
              prod_P32AcCGojXueqt: {
                  title: 'Baseline Plus Health Check',
                  type: 'TEST',
                  testCodes: ['NEU011'],
                  personalise: null,
                  display: 'FINGERPRICK',
                  markersTested: (
                      <ul className="mx-auto mt-8 grid list-disc px-10 text-nw-offBlack sm:place-items-center">
                          <li>Active B12</li>
                          <li>ALT</li>
                          <li>Albumin</li>
                          <li>ALP</li>
                          <li>Corrected Calcium</li>
                          <li>Cortisol (9am)</li>
                          <li>CK</li>
                          <li>Creatinine</li>
                          <li>eGFR</li>
                          <li>Ferritin</li>
                          <li>GGT</li>
                          <li>Globulin</li>
                          <li>FBC</li>
                          <li>HbA1c</li>
                          <li>Lipids (Cholesterol, HDL, Chol:HDL, Non-HDL, LDL, Triglycerides)</li>
                          <li>HS-CRP</li>
                          <li>Magnesium</li>
                          <li>Sodium</li>
                          <li>Total Testosterone</li>
                          <li>TSH</li>
                          <li>FT4</li>
                          <li>Total Protein</li>
                          <li>FT3</li>
                          <li>Urea</li>
                          <li>Vitamin D</li>
                      </ul>
                  )
              },
              prod_P32AobhlIXzHfp: {
                  title: 'Ultimate Health Check',
                  type: 'TEST',
                  testCodes: ['NEU012'],
                  personalise: null,
                  display: 'FINGERPRICK',
                  markersTested: (
                      <ul className="mx-auto mt-8 grid list-disc px-10 text-nw-offBlack sm:place-items-center">
                          <li>Active B12</li>
                          <li>ALT</li>
                          <li>Albumin</li>
                          <li>ALP</li>
                          <li>Calcium</li>
                          <li>Corrected Calcium</li>
                          <li>Cortisol (9am)</li>
                          <li>CK</li>
                          <li>Creatinine</li>
                          <li>eGFR</li>
                          <li>Ferritin</li>
                          <li>GGT</li>
                          <li>Globulin</li>
                          <li>FBC</li>
                          <li>HbA1c</li>
                          <li>Lipids (Cholesterol, HDL, Chol:HDL, Non-HDL, LDL, Triglycerides)</li>
                          <li>HS-CRP</li>
                          <li>Magnesium</li>
                          <li>Omega 6:3 ratio</li>
                          <li>SHBG,</li>
                          <li>Sodium</li>
                          <li>Testosterone</li>
                          <li>TSH</li>
                          <li>FT4</li>
                          <li>Total Protein</li>
                          <li>FT3</li>
                          <li>Urea</li>
                          <li>Uric Acid</li>
                          <li>Vitamin A</li>
                          <li>Vitamin D</li>
                          <li>Vitamin E</li>
                      </ul>
                  )
              },
              prod_P32A1NOqtYndcB: {
                  title: 'PCOS Test',
                  type: 'TEST',
                  testCodes: ['NEU013'],
                  personalise: null,
                  display: 'FINGERPRICK',
                  markersTested: (
                      <ul className="mx-auto mt-8 grid list-disc px-10 text-nw-offBlack sm:place-items-center">
                          <li>Cortisol (9am)</li>
                          <li>FSH</li>
                          <li>HbA1c</li>
                          <li>Lipids (Cholesterol, HDL, Chol:HDL, Non-HDL, LDL, Triglycerides)</li>
                          <li>LH</li>
                          <li>Oestradiol</li>
                          <li>Prolactin</li>
                          <li>Testosterone</li>
                          <li>TSH</li>
                          <li>FT4</li>
                          <li>FT4</li>
                      </ul>
                  )
              },
              prod_P32Asw5rp0W07G: {
                  title: 'Testosterone Test',
                  type: 'TEST',
                  testCodes: ['TEST'],
                  personalise: null,
                  display: 'FINGERPRICK',
                  markersTested: (
                      <ul className="mx-auto mt-8 grid list-disc px-10 text-nw-offBlack sm:place-items-center">
                          <li>Testosterone</li>
                      </ul>
                  )
              },
              prod_P32Aor99bqEMfJ: {
                  title: 'Thyroid Test',
                  type: 'TEST',
                  testCodes: ['TF3'],
                  personalise: null,
                  display: 'FINGERPRICK',
                  markersTested: (
                      <ul className="mx-auto mt-8 grid list-disc px-10 text-nw-offBlack sm:place-items-center">
                          <li>TSH</li>
                          <li>FT4</li>
                          <li>FT4</li>
                      </ul>
                  )
              },
              prod_P32AUx5JBdhZLJ: {
                  title: 'Thyroid Plus Test',
                  type: 'TEST',
                  testCodes: ['TF6'],
                  personalise: null,
                  display: 'FINGERPRICK',
                  markersTested: (
                      <ul className="mx-auto mt-8 grid list-disc px-10 text-nw-offBlack sm:place-items-center">
                          <li>Anti-TPO</li>
                          <li>Anti-TG</li>
                          <li>TSH</li>
                          <li>FT4</li>
                          <li>FT4</li>
                      </ul>
                  )
              },
              prod_P32AGQ8ZnJz7Y2: {
                  title: 'Vitamin Deficiency Test',
                  type: 'TEST',
                  testCodes: ['NEU014'],
                  personalise: null,
                  display: 'FINGERPRICK',
                  markersTested: (
                      <ul className="mx-auto mt-8 grid list-disc px-10 text-nw-offBlack sm:place-items-center">
                          <li>Active B12</li>
                          <li>Albumin</li>
                          <li>Calcium</li>
                          <li>Corrected Calcium</li>
                          <li>Ferritin</li>
                          <li>Lipids (Cholesterol, HDL, Chol:HDL, LDL, Triglycerides)</li>
                          <li>HS-CRP</li>
                          <li>Magnesium</li>
                          <li>Vitamin D</li>
                      </ul>
                  )
              }
          }
        : {
              //----------------STAGING ENVIRONMENT PRODUCT TESTING----------------//

              // ----- InClinic Example ----- //
              prod_P2ZqrhUzLaEwp4: {
                  title: 'Core Check',
                  type: 'BOOKING',
                  testCodes: ['HSC6'],
                  personalise: 'RANDOX',
                  display: 'INCLINIC',
                  markersTested: (
                      <>
                          <p className="mt-8 px-10 text-center text-xl font-medium text-nw-offBlack">Thyroid Health</p>
                          <ul className="mx-auto mt-6 grid list-disc px-10 text-nw-offBlack sm:place-items-center lg:grid-cols-3">
                              <li>Thyroid Stimulating</li>
                              <li>Hormone</li>
                              <li>Free Tri-iodothyronine</li>
                              <li>Free Thyroxine</li>
                              <li>Anti-Thyroglobulin Antibody</li>
                              <li>Anti-Thyroid Peroxidase</li>
                              <li>Antibody</li>
                          </ul>
                          <p className="mt-8 px-10 text-center text-xl font-medium text-nw-offBlack">Hormonal Health</p>
                          <ul className="mx-auto mt-6 grid list-disc px-10 text-nw-offBlack sm:place-items-center lg:grid-cols-3">
                              <li>Oestradiol</li>
                              <li>Follicle Stimulating Hormone</li>
                              <li>Luteinising Hormone</li>
                              <li>Progesterone</li>
                              <li>Prolactin</li>
                              <li>Testosterone</li>
                              <li>Sex Hormone Binding Globulin</li>
                              <li>Free Androgen Index</li>
                          </ul>
                          <p className="mt-8 px-10 text-center text-xl font-medium text-nw-offBlack">
                              Nutritional Health
                          </p>
                          <ul className="mx-auto mt-6 grid list-disc px-10 text-nw-offBlack sm:place-items-center">
                              <li>Vitamin D</li>
                          </ul>
                          <p className="mt-8 px-10 text-center text-xl font-medium text-nw-offBlack">
                              Full Blood Count
                          </p>
                          <ul className="mx-auto mt-6 grid list-disc px-10 text-nw-offBlack sm:place-items-center lg:grid-cols-3">
                              <li>Haemoglobin</li>
                              <li>Haematocrit</li>
                              <li>Mean Cell Haemoglobin</li>
                              <li>Mean Cell Haemoglobin</li>
                              <li>Concentration (MCHC)</li>
                              <li>Mean Cell Volume</li>
                              <li>Red Blood Cell Count</li>
                              <li>Basophil Count</li>
                              <li>Lymphocyte Count</li>
                              <li>Eosinophil Count</li>
                              <li>Monocyte Count</li>
                              <li>Neutrophil Count</li>
                              <li>White Blood Cell Count</li>
                              <li>Platelet Count</li>
                          </ul>
                          <p className="mt-8 px-10 text-center text-xl font-medium text-nw-offBlack">Iron Status</p>
                          <ul className="mx-auto mt-6 grid list-disc px-10 text-nw-offBlack sm:place-items-center">
                              <li>Ferritin</li>
                              <li>Iron</li>
                              <li>TIBC</li>
                              <li>Transferrin</li>
                              <li>Transferrin Saturation</li>
                          </ul>
                          <p className="mt-8 px-10 text-center text-xl font-medium text-nw-offBlack">Liver Health</p>
                          <ul className="mx-auto mt-6 grid list-disc px-10 text-nw-offBlack sm:place-items-center lg:grid-cols-3">
                              <li>Alkaline Phosphatase</li>
                              <li>Alanine Aminotransferase</li>
                              <li>Aspartate Transferase</li>
                              <li>Gamma-glutamyl Transferase</li>
                              <li>Total Bilirubin</li>
                              <li>Albumin</li>
                          </ul>
                          <p className="mt-8 px-10 text-center text-xl font-medium text-nw-offBlack">Diabetes Health</p>
                          <ul className="mx-auto mt-6 grid list-disc px-10 text-nw-offBlack sm:place-items-center">
                              <li>Glucose</li>
                              <li>HBA1C</li>
                              <li>Insulin</li>
                          </ul>
                          <p className="mt-8 px-10 text-center text-xl font-medium text-nw-offBlack">Stress</p>
                          <ul className="mx-auto mt-6 grid list-disc px-10 text-nw-offBlack sm:place-items-center">
                              <li>Cortisol</li>
                              <li>DHEA-S</li>
                          </ul>
                          <p className="mt-8 px-10 text-center text-xl font-medium text-nw-offBlack">Heart Health</p>
                          <ul className="mx-auto mt-6 grid list-disc px-10 text-nw-offBlack sm:place-items-center lg:grid-cols-3">
                              <li>Total Cholesterol</li>
                              <li>LDL Cholesterol</li>
                              <li>HDL Cholesterol</li>
                              <li>Total Cholesterol/HDL</li>
                              <li>Cholesterol Ratio</li>
                              <li>Triglycerides</li>
                          </ul>
                          <p className="mt-8 px-10 text-center text-xl font-medium text-nw-offBlack">Kidney Health</p>
                          <ul className="mx-auto mt-6 grid list-disc px-10 text-nw-offBlack sm:place-items-center lg:grid-cols-3">
                              <li>Chloride</li>
                              <li>Potassium*</li>
                              <li>Phosphate</li>
                              <li>Sodium</li>
                              <li>Urea</li>
                          </ul>
                          <p className="mt-8 px-10 text-center text-xl font-medium text-nw-offBlack">
                              Infection & Inflammation
                          </p>
                          <ul className="mx-auto mt-6 grid list-disc px-10 text-nw-offBlack sm:place-items-center">
                              <li>C-Reactive Protein</li>
                          </ul>
                      </>
                  )
              },

              // ----- Oncontext Example ----- //
              prod_P2ZvhS7Rj6lN7x: {
                  title: 'Onconext Risk® BRCA',
                  type: 'TEST',
                  testCodes: [],
                  personalise: null,
                  display: 'ONCONTEXT',
                  markersTested: (
                      <p className="mt-8 px-10 text-center text-nw-offBlack">
                          Onconext testing analyses multiple genes for the detection of possible predisposition to
                          hereditary cancers.
                      </p>
                  )
              },

              // ----- TruCheck Example ----- //
              prod_P2Zz6kSswQpUoO: {
                  title: 'TruCheck Bowel Cancer Screening',
                  type: 'TEST',
                  testCodes: [],
                  personalise: null,
                  display: 'TRUCHECK',
                  markersTested: (
                      <p className="mt-8 px-10 text-center text-nw-offBlack">
                          A blood test that can detect early-stage bowel cancer within the asymptomatic population.
                      </p>
                  )
              },

              // ----- Prenatal Example ----- //
              prod_P328t79nUFcdSB: {
                  title: 'Prenatal Safe 3 +/- Gender',
                  type: 'TEST',
                  testCodes: [],
                  personalise: null,
                  display: 'PRENATALSAFE',
                  markersTested: (
                      <>
                          <p className="mt-8 px-10 text-center text-nw-offBlack">
                              Prenantal safe 3 is a non invasive test that enables the analysis of Fetal genetic
                              material from a routine blood sample. This test includes:
                          </p>
                          <ul className="mx-auto mt-4 grid list-disc grid-cols-1 px-10 text-nw-offBlack sm:place-items-center">
                              <li>Fetal sex</li>
                              <li>Trisomy 21 (Downs Syndrome)</li>
                              <li>Trisomy 18 (Edwards Syndrome)</li>
                              <li>Trisomy 13 (Patau Syndrome)</li>
                          </ul>
                      </>
                  )
              },

              // ----- Finger Prick STI Tests ----- //
              // prod_OEtLltpQFMNWDe: {
              //     title: 'NEUCheck STI',
              //     type: 'STI',
              //     testCodes: ['TEST'],
              //     personalise: null,
              //     display: 'FINGERPRICK-STI',
              //     markersTested: (
              //         <ul className="mx-auto mt-8 grid list-disc lg:grid-cols-3 sm:place-items-center px-10 text-nw-offBlack">
              //             <li>Lorem ipsum dolor sit amet.</li>
              //         </ul>
              //     )
              // },

              // ----- Finger Prick Example ----- //
              prod_P2eR3tO4aCMrlS: {
                  title: 'Baseline Health Check',
                  type: 'TEST',
                  testCodes: ['TEST'], //NEU010
                  personalise: null,
                  display: 'FINGERPRICK',
                  markersTested: (
                      <ul className="mx-auto mt-8 grid list-disc px-10 text-nw-offBlack sm:place-items-center">
                          <li>Active B12</li>
                          <li>ALT</li>
                          <li>Albumin</li>
                          <li>ALP</li>
                          <li>Calcium</li>
                          <li>Corrected Calcium</li>
                          <li>Cortisol (9am)</li>
                          <li>Ferritin, GGT</li>
                          <li>Globulin</li>
                          <li>HbA1c</li>
                          <li>Lipids (Cholesterol, HDL, Chol:HDL, Non-HDL, LDL, Triglycerides)</li>
                          <li>HS-CRP</li>
                          <li>TSH</li>
                          <li>Total Protein</li>
                          <li>Vitamin D</li>
                      </ul>
                  )
              }
          };
