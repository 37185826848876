import { NavLink, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useState } from 'react';

import { authActions } from '_store';

import logo from '../_assets/neuwell-logo.gif';

export { Nav };

function Nav() {
    const authUser = useSelector((x) => x.auth.user);
    const dispatch = useDispatch();
    const logout = () => {
        toggleMenu();
        dispatch(authActions.logout());
    };
    const [menu, setMenu] = useState(false);
    const location = useLocation();
    const { pathname } = location;
    const splitLocation = pathname.split('/');

    function toggleMenu() {
        if (menu === true) {
            setMenu(false);
        } else {
            setMenu(true);
        }
    }

    // only show nav when logged in
    if (!authUser) return null;

    return (
        <nav className="relative z-40 py-2 shadow-md">
            <div className="container mx-auto flex flex-wrap items-center justify-between px-4">
                <a href="https://neuwell.co.uk/">
                    <img
                        className="relative left-[-9px] w-[175px] lg:mb-1 lg:w-[200px]"
                        src={logo}
                        alt="Neuwell logo"
                    />
                </a>
                <button
                    type="button"
                    onClick={toggleMenu}
                    className="ml-3 inline-flex items-center p-2 text-sm text-gray-500 lg:hidden"
                >
                    <span className="sr-only">Open main menu</span>
                    {menu === false && (
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" className="h-6 w-6">
                            <path d="M0 96C0 78.3 14.3 64 32 64H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H32C14.3 128 0 113.7 0 96zM0 256c0-17.7 14.3-32 32-32H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H32c-17.7 0-32-14.3-32-32zM448 416c0 17.7-14.3 32-32 32H32c-17.7 0-32-14.3-32-32s14.3-32 32-32H416c17.7 0 32 14.3 32 32z" />
                        </svg>
                    )}
                    {menu === true && (
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" className="h-7 w-7">
                            <path d="M310.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L160 210.7 54.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L114.7 256 9.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L160 301.3 265.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L205.3 256 310.6 150.6z" />
                        </svg>
                    )}
                </button>
                <div className={'hidden w-full lg:block lg:w-auto' + (menu === true ? ' active-menu' : '')}>
                    <ul className="mt-4 flex flex-col lg:mt-0 lg:flex-row lg:space-x-8">
                        <li className="block pl-2 pr-2 lg:py-0 xl:pl-4 xl:pr-4">
                            <NavLink
                                to="/"
                                onClick={toggleMenu}
                                className="menu-item inline-block w-full py-4 text-nw-darkGrey hover:text-black"
                                activeclassname="active"
                            >
                                Results
                            </NavLink>
                        </li>
                        <li className="block pl-2 pr-2 lg:py-0 xl:pl-4 xl:pr-4">
                            <NavLink
                                to="/orders"
                                onClick={toggleMenu}
                                className="menu-item inline-block w-full py-4 text-nw-darkGrey hover:text-black"
                                activeclassname="active"
                            >
                                Orders
                            </NavLink>
                        </li>
                        <li className="block pl-2 pr-2 lg:py-0 xl:pl-4 xl:pr-4">
                            <NavLink
                                to="/my-account/profile"
                                onClick={toggleMenu}
                                className={
                                    'menu-item inline-block w-full py-4 text-nw-darkGrey hover:text-black' +
                                    (splitLocation[1] === 'my-account' ? ' active' : '')
                                }
                            >
                                My Account
                            </NavLink>
                        </li>
                        <li className="block pl-2 pr-2 lg:py-0 xl:pl-4 xl:pr-4">
                            <a
                                href="https://neuwell.co.uk/health-hub"
                                className="menu-item inline-block w-full py-4 text-nw-darkGrey hover:text-black"
                                target="_blank"
                                rel="noreferrer"
                            >
                                Health Hub
                            </a>
                        </li>
                        <li className="block pl-2 pr-2 lg:py-0 xl:pl-4 xl:pr-4">
                            <a
                                href="https://neuwell.co.uk/help-centre"
                                className="menu-item inline-block w-full py-4 text-nw-darkGrey hover:text-black"
                                target="_blank"
                                rel="noreferrer"
                            >
                                Help Centre
                            </a>
                        </li>
                        <li className="mb-4 block pl-2 pr-2 lg:hidden lg:py-0 xl:pl-4 xl:pr-4">
                            <button
                                onClick={logout}
                                className="btn menu-item inline-block py-4 text-nw-darkGrey hover:text-black"
                            >
                                Log out
                            </button>
                        </li>
                    </ul>
                </div>
                <div className="hidden w-auto py-4 text-right lg:block xl:w-[200px]">
                    <button onClick={logout} className="btn text-nw-darkGrey hover:text-black">
                        Log out
                    </button>
                </div>
            </div>
        </nav>
    );
}
