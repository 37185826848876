import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';

export { Button };

function Button({
    text,
    disabled,
    icon = false,
    shadow = true,
    active = true,
    fullWidth = false,
    formHeight = true,
    grey = false,
    red = false
}) {
    return (
        <button
            disabled={disabled}
            className={
                'mx-auto block rounded-3xl border-2 py-1.5 font-semibold transition-colors' +
                (grey ? ' border-nw-grey' : red ? ' border-[#EF6464] hover:border-[#d45858]' : ' border-black') +
                (shadow ? ' drop-shadow-2xl' : '') +
                (active
                    ? grey
                        ? ' bg-nw-grey text-white'
                        : red
                        ? ' bg-[#EF6464] text-white hover:bg-[#d45858]'
                        : ' bg-black text-white hover:bg-[#2a2a2a]'
                    : ' hover:bg-black hover:text-white') +
                (fullWidth ? ' w-full' : ' px-14') +
                (formHeight ? ' h-[50px]' : '')
            }
        >
            {disabled ? <div className="mini-loader light mt-[4px]"></div> : text}
            {icon === true && !disabled && (
                <FontAwesomeIcon icon={faArrowRight} size="1x" className="absolute ml-7 mt-1" />
            )}
        </button>
    );
}
