import { Navigate } from 'react-router-dom';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Nav } from './Nav';

import { history } from '_helpers';

export { PrivateRoute };

function PrivateRoute({ title = '', children }) {
    const { user: authUser } = useSelector((x) => x.auth);

    useEffect(() => {
        document.title = `Neuwell® - ${title}`;
    }, [title]);

    if (!authUser) {
        // not logged in so redirect to login page with the return url
        return <Navigate to="/login" state={{ from: history.location }} />;
    }

    // authorized so return child components
    return (
        <>
            <Nav />
            <div className="container px-4">{children}</div>
        </>
    );
}
