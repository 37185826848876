import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Link, Navigate } from 'react-router-dom';
import * as Yup from 'yup';
import { useSelector } from 'react-redux';
import { history, fetchFromAPI } from '_helpers';
import { Button, TextInput } from '_components';

import logo from '../../../_assets/neuwell-logo.gif';

export { ForgotPassword };

function ForgotPassword() {
    const authUser = useSelector((x) => x.auth.user);
    const [msg, setMsg] = useState(null);

    const emailRegex =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    useEffect(() => {
        document.title = `Neuwell® - Forgot my password`;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [authUser]);

    // form validation rules
    const validationSchema = Yup.object().shape({
        username: Yup.string()
            .required('Email is required')
            .matches(emailRegex, 'Invalid email format')
            .max(100, 'Max 100 characters')
    });
    const formOptions = { resolver: yupResolver(validationSchema) };

    // get functions to build form with useForm() hook
    const { register, handleSubmit, formState } = useForm(formOptions);
    const { errors, isSubmitting } = formState;

    function onSubmit({ username }) {
        username = username.toLowerCase();
        setMsg(null);
        //Create password reset hash and store in contentful.
        //Send hash to klaviyo password reset.

        return fetchFromAPI('users/create-reset-token', {
            body: {
                username: username
            }
        })
            .then((res) => {
                setMsg('Password reset link sent to your email');
            })
            .catch((error) => {
                setMsg('Failed to send password reset link, please try again later');
            });
    }

    if (authUser) {
        // already logged in, redirect to dashboard
        return <Navigate to="/" />;
    }

    return (
        <div className="flex h-screen">
            <div className="card relative m-auto inline-block rounded-xl bg-nw-blue pt-10 pb-10 pl-4 pr-4 drop-shadow-md md:pt-[75px] md:pb-[75px] md:pl-[175px] md:pr-[175px]">
                <img className="mx-auto mb-6 w-[200px]" src={logo} alt="neuwell logo" />
                <p className="mb-4 text-center text-sm text-nw-grey">
                    Forgot your password?<br></br> We'll send a reset link to your email:
                </p>
                <div className="card-body max-w-[280px]">
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="mb-4">
                            <TextInput name="username" label="Email" props={register('username')} width="280px" />
                            <div className="ml-5 mt-1 text-sm text-red-700">{errors.username?.message}</div>
                        </div>
                        <div className="mt-5">
                            <Button text="Reset password" disabled={isSubmitting} formHeight={true} fullWidth={true} />
                        </div>
                    </form>
                    <Link to="../login">
                        <span className="mt-2 block text-center text-xs text-nw-grey underline">Back to login</span>
                    </Link>
                    {msg && (
                        <div className="mt-4 mb-0 max-w-[280px] rounded-2xl bg-white p-2 text-center text-sm text-nw-grey">
                            {msg}
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}
