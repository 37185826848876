import React, { useState, useEffect } from 'react';
import { Link, Navigate, useSearchParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { history, fetchWrapper, getItemPriceBreakdown } from '_helpers';
import { Button, Loading } from '_components';
import { authActions, cartActions } from '_store';

export { CartPayment };

function CartPayment() {
    const authUser = useSelector((x) => x.auth.user);
    const cart = useSelector((state) => state.cart);
    const dispatch = useDispatch();
    // eslint-disable-next-line no-unused-vars
    const [searchParams, setSearchParams] = useSearchParams();
    const orderID = searchParams.get('id');
    const stripe = useStripe();
    const elements = useElements();
    const [loading, setLoading] = useState(true);
    const [stripeError, setStripeError] = useState('');
    const [stripeProcessing, setStripeProcessing] = useState(false);
    const [hasSubscription, setHasSubscription] = useState(true);

    function postPayment(paymentIntent) {
        dispatch(
            authActions.updatePaymentMethod({
                entryID: authUser?.user.sys.id,
                paymentMethodID: paymentIntent.payment_method
            })
        ).then(() => {
            const orderIDTrim = orderID.replace('/success', '');

            let cartTotal = 0;

            const basketData = cart.cart.map((item) => {
                const total = Math.round(getItemPriceBreakdown(item).total);
                cartTotal += total;

                return {
                    id: item.productID,
                    name: item.title,
                    category: item.productID,
                    quantity: 1,
                    price: total
                };
            });

            window.dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.

            window.dataLayer.push({
                event: 'purchase',
                ecommerce: {
                    currency: 'GBP',
                    transaction_id: orderIDTrim,
                    value: cartTotal,
                    items: basketData
                }
            });

            // ReactGA.event('purchase', {
            //     currency: 'GBP',
            //     transaction_id: orderIDTrim,
            //     value: cartTotal,
            //     items: basketData
            // });

            history.navigate(`/checkout/payment/success?id=${orderIDTrim}`);
        });
    }

    // TODO: This function will need to be updated in the case that more than one booking is allowed per order.
    async function checkBookingAvailability() {
        const bookingItem = cart.cart.find((item) => item.booking !== undefined);

        if (bookingItem) {
            const canBook = await fetchWrapper.post(
                `${process.env.REACT_APP_API_URL}/orders/check-appointment-availability`,
                {
                    locationID: bookingItem.booking.locationID,
                    date: bookingItem.booking.date,
                    time: bookingItem.booking.time
                }
            );

            return canBook;
        } else {
            return true;
        }
    }

    useEffect(() => {
        if (!stripe) {
            return;
        }

        const clientSecret = authUser?.user?.stripeClientSecret;

        if (!clientSecret) {
            return;
        }

        stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
            if (paymentIntent.status === 'succeeded') {
                // Alternative payment method e.g. Paypal/GooglePay successful
                postPayment(paymentIntent);
            } else {
                setLoading(false);

                if (paymentIntent.last_payment_error !== null) {
                    if (paymentIntent.last_payment_error.code === 'payment_intent_authentication_failure') {
                        setStripeError('Payment gateway failed, please try again.');
                    } else if (paymentIntent.last_payment_error.code === 'payment_intent_payment_attempt_failed') {
                        setStripeError('Payment attempt failed, please try again.');
                    }
                }
            }
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [stripe]);

    useEffect(() => {
        setHasSubscription(cart.cart.find((item) => item.frequency > 0) !== undefined ? true : false);
    }, [cart]);

    const handleSubmit = async (event) => {
        event.preventDefault();
        setStripeError('');

        if (!stripe || !elements) {
            // Stripe.js hasn't yet loaded.
            // Make sure to disable form submission until Stripe.js has loaded.
            return;
        }

        let cartTotal = 0;

        const basketData = cart.cart.map((item) => {
            const total = Math.round(getItemPriceBreakdown(item).total);
            cartTotal += total;

            return {
                id: item.productID,
                name: item.title,
                category: item.productID,
                quantity: 1,
                price: total
            };
        });

        window.dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.

        window.dataLayer.push({
            event: 'add_payment_info',
            ecommerce: {
                currency: 'GBP',
                value: cartTotal,
                items: basketData
            }
        });

        setStripeProcessing(true);

        // If an booking exists in order, check if appointment is still available before processing payment.
        const canProceed = await checkBookingAvailability();

        if (!canProceed) {
            dispatch(
                cartActions.setCartError(
                    "Appointment is no longer available, please select a new date and time by clicking the 'Edit' button."
                )
            );

            history.navigate('/checkout');
        } else {
            const { error, paymentIntent } = await stripe.confirmPayment({
                //`Elements` instance that was used to create the Payment Element
                elements,
                confirmParams: {
                    return_url: window.location.href + '/success'
                },
                redirect: 'if_required'
            });

            if (error) {
                setStripeProcessing(false);

                if (error.code === 'amount_too_large') {
                    setStripeError(
                        'Payment amount is too large. Please note the maximum for Clearpay orders is £1200.'
                    );
                } else {
                    setStripeError(error.message);
                }

                if (error.type === 'invalid_request_error') {
                    if (error.code !== 'payment_intent_authentication_failure') {
                        // Issue with stripe payment intent timing out
                        history.navigate('/checkout/shipping');
                    }
                }
            } else if (paymentIntent && paymentIntent.status === 'succeeded') {
                // Save new payment method to contentful then redirect to success page
                postPayment(paymentIntent);
            }
        }
    };

    if (!cart?.cart?.length > 0) {
        return <Navigate to="/checkout" />;
    }

    if (!authUser) {
        return <Navigate to="/checkout/account" />;
    }

    if (!authUser.user.shippingAddress1 || !authUser.user.stripeCustomerId) {
        return <Navigate to="/checkout/shipping" />;
    }

    if (!orderID) {
        return <Navigate to="/checkout/shipping" />;
    }

    return (
        <div className="right-10 mb-8 lg:absolute lg:w-[70%] 2xl:w-[55%]">
            <div className="flow-root text-sm text-nw-offBlack">
                <p className="float-left text-nw-offBlack">
                    <Link to={'/checkout/account'}>
                        <span className="mr-2">Account</span>
                    </Link>{' '}
                    {'>'}{' '}
                    <Link to={'/checkout/shipping'}>
                        <span className="ml-2 mr-2">Shipping</span>
                    </Link>{' '}
                    {'>'} <span className="ml-2 font-medium">Payment</span>
                </p>
                <Link to={'/checkout'}>
                    <p className="float-right mb-4 text-nw-grey underline hover:cursor-pointer">{'<'} Back to basket</p>
                </Link>
            </div>
            <p className="mt-4 text-2xl text-nw-offBlack">Payment Information</p>
            <p className="mt-2 text-sm font-light text-nw-offBlack">Please enter your payment details below:</p>
            {!loading ? (
                <div className="relative mt-8 rounded-3xl bg-nw-blue py-6 px-6 lg:mb-20">
                    <form onSubmit={handleSubmit}>
                        <PaymentElement />
                        {hasSubscription && (
                            <div className="my-3 text-xs text-nw-grey">
                                *Please note that buy now, pay later payment methods, such as Clearpay, can only be used
                                with one-time products.
                            </div>
                        )}
                        <div className="mt-6">
                            <Button
                                text="Pay"
                                disabled={!stripe || stripeProcessing}
                                shadow={true}
                                fullWidth={true}
                                icon={true}
                            />
                        </div>
                    </form>
                    {stripeError && (
                        <div className="mt-6 mb-0 rounded-2xl bg-white p-2 py-3 text-center text-sm text-nw-grey">
                            {stripeError}
                        </div>
                    )}
                </div>
            ) : (
                <div className="relative mt-8 py-6 px-6 lg:mb-20">
                    <Loading loading={true} />
                </div>
            )}
        </div>
    );
}
