import React, { useState, useEffect } from 'react';
import { Link, Navigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import moment from 'moment';
import { Button, TextInput, TextSelect } from '_components';
import { authActions, cartActions } from '_store';
import { history, getItemPriceBreakdown } from '_helpers';

export { CartAccount };

function CartAccount() {
    const [emailCheck, setEmailCheck] = useState('');
    const [userMatch, setUserMatch] = useState(-1);
    const [isChecking, setIsChecking] = useState(false);
    const { user: authUser } = useSelector((x) => x.auth);
    const cart = useSelector((state) => state.cart);
    const authError = useSelector((x) => x.auth.error);
    const dispatch = useDispatch();

    const dobYears = Array.from({ length: 110 }, (_, i) => moment().year() - i);
    const phoneRegExp = /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/;

    // form validation rules
    let validationSchema;

    if (userMatch === 0) {
        validationSchema = Yup.object().shape({
            username: Yup.string().email('A valid email is required').max(100, 'Max 100 characters'),
            title: Yup.string().required('Title is required').max(100, 'Max 100 characters'),
            firstName: Yup.string().required('First name is required').max(100, 'Max 100 characters'),
            lastName: Yup.string().required('Last name is required').max(100, 'Max 100 characters'),
            phoneNumber: Yup.string()
                .min(4, 'Phone number is required')
                .matches(phoneRegExp, 'Phone number is not valid'),
            gender: Yup.string().required('Gender is required').max(100, 'Max 100 characters'),
            ethnicity: Yup.string().required('Ethnicity is required').max(100, 'Max 100 characters'),
            dobDay: Yup.string().required('Day is required').max(100, 'Max 100 characters'),
            dobMonth: Yup.string().required('Month is required').max(100, 'Max 100 characters'),
            dobYear: Yup.string().required('Year is required').max(100, 'Max 100 characters'),
            password: Yup.string()
                .required('Password is required')
                .min(8, 'Password must be a minimum of 8 characters')
                .max(100, 'Max 100 characters'),
            confirmPassword: Yup.string().oneOf([Yup.ref('password'), null], 'Passwords must match')
        });
    } else {
        validationSchema = Yup.object().shape({
            username: Yup.string().email('A valid email is required').max(100, 'Max 100 characters'),
            password: Yup.string().required('Password is required').max(100, 'Max 100 characters')
        });
    }
    const formOptions = { resolver: yupResolver(validationSchema) };

    // get functions to build form with useForm() hook
    const { register, handleSubmit, reset, formState } = useForm(formOptions);

    const { errors, isSubmitting } = formState;

    function login({ username, password }) {
        return dispatch(authActions.loginNoRedirect({ username, password }));
    }

    function capitalizeFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
    }

    function createAccount({
        username,
        title,
        firstName,
        lastName,
        phoneNumber,
        gender,
        ethnicity,
        dobDay,
        dobMonth,
        dobYear,
        password
    }) {
        const dateOfBirth = moment(dobDay + ' ' + dobMonth + ' ' + dobYear, 'DD MMMM YYYY').format();
        username = username.toLowerCase();
        firstName = capitalizeFirstLetter(firstName);
        lastName = capitalizeFirstLetter(lastName);

        return dispatch(
            authActions.createAccount({
                username,
                title,
                firstName,
                lastName,
                phoneNumber,
                gender,
                ethnicity,
                dateOfBirth,
                password
            })
        );
    }

    function checkEmail(e) {
        setUserMatch(-1);
        reset({
            title: '',
            firstName: '',
            lastName: '',
            phoneNumber: '',
            gender: '',
            ethnicity: '',
            dobDay: '',
            dobMonth: '',
            dobYear: '',
            password: '',
            confirmPassword: ''
        });
        const val = e.target.value.toLowerCase();
        const isEmail = val.match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );

        if (isEmail) {
            if (emailCheck === val) {
                setEmailCheck('');
                setTimeout(() => {
                    setEmailCheck(val);
                }, 100);
            } else {
                setEmailCheck(val);
            }
        } else {
        }
    }

    useEffect(() => {
        dispatch(cartActions.setCartError(null));
    }, [dispatch]);

    useEffect(() => {
        if (emailCheck !== '') {
            setIsChecking(true);
            const delayDebounceFn = setTimeout(() => {
                const query =
                    `
                query {
                    patientCollection(where: { email: "` +
                    emailCheck +
                    `"}, limit: 1){
                        items{
                        email
                        }
                    }
                }
                `;

                //Search for email
                window
                    .fetch(
                        `https://graphql.contentful.com/content/v1/spaces/${process.env.REACT_APP_CONTENTFUL_SPACE}/environments/${process.env.REACT_APP_CONTENTFUL_ENV}/?access_token=${process.env.REACT_APP_CONTENTFUL_GRAPHQL_TOKEN}`,
                        {
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json'
                            },
                            body: JSON.stringify({ query })
                        }
                    )
                    .then((response) => response.json())
                    .then((json) => {
                        setIsChecking(false);
                        if (json.data.patientCollection.items.length > 0) {
                            setUserMatch(1);
                        } else {
                            setUserMatch(0);
                        }
                    });
            }, 500);

            return () => clearTimeout(delayDebounceFn);
        }
    }, [emailCheck]);

    useEffect(() => {
        if (cart.cart.length > 0) {
            let cartTotal = 0;

            const basketData = cart.cart.map((item) => {
                const total = Math.round(getItemPriceBreakdown(item).total);
                cartTotal += total;

                return {
                    id: item.productID,
                    name: item.title,
                    category: item.productID,
                    quantity: 1,
                    price: total
                };
            });

            window.dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.

            window.dataLayer.push({
                event: 'begin_checkout',
                ecommerce: {
                    currency: 'GBP',
                    value: cartTotal,
                    items: basketData
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (!cart?.cart?.length > 0) {
        return <Navigate to="/checkout" state={{ from: history.location }} />;
    }

    return (
        <div className="right-10 mb-8 lg:absolute lg:w-[70%] 2xl:w-[55%]">
            <div className="flow-root text-sm text-nw-offBlack">
                <p className="float-left text-nw-offBlack">
                    <span className="mr-2 font-medium">Account</span> {'>'} <span className="ml-2 mr-2">Shipping</span>{' '}
                    {'>'} <span className="ml-2">Payment</span>
                </p>
                <Link to={'/checkout'}>
                    <p className="float-right mb-4 text-nw-grey underline hover:cursor-pointer">{'<'} Back to basket</p>
                </Link>
            </div>
            <p className="mt-4 text-2xl text-nw-offBlack">Your Account Details</p>
            <p className="mt-4 font-light text-nw-offBlack">
                You need an account with us in order to view your secure dashboard and get accurate results tailored to
                you.
            </p>
            <div className="relative mt-8 rounded-3xl bg-nw-blue py-6 px-6 lg:mb-20">
                {!authUser && (
                    <>
                        <form onSubmit={userMatch === 1 ? handleSubmit(login) : handleSubmit(createAccount)}>
                            <div className="">
                                <TextInput
                                    name="email"
                                    label="Email address"
                                    width="100%"
                                    props={register('username')}
                                    callback={checkEmail}
                                />
                            </div>
                            {/* CASE FOR EXISTING USER */}
                            {userMatch === 1 && (
                                <>
                                    <div className="mt-6">
                                        <TextInput
                                            name="password"
                                            label="Password"
                                            type="password"
                                            props={register('password')}
                                            width="100%"
                                        />
                                    </div>
                                    <div className="mt-6">
                                        <Button
                                            text="Sign in"
                                            disabled={isSubmitting}
                                            shadow={true}
                                            fullWidth={true}
                                            icon={true}
                                        />
                                    </div>
                                    {authError && (
                                        <div className="mt-4 mb-0 rounded-2xl bg-white p-2 text-center text-sm text-nw-grey">
                                            {authError.message}
                                        </div>
                                    )}
                                </>
                            )}
                            {/* CASE FOR NEW USER */}
                            {userMatch === 0 && (
                                <>
                                    <p className="mt-1 ml-4 text-sm font-light text-nw-grey">
                                        We'll send a confirmation of your order to this email
                                    </p>
                                    <div className="grid grid-flow-row gap-4 pt-4 sm:grid-flow-col">
                                        <div className="">
                                            <TextSelect
                                                name="title"
                                                options={['Mr', 'Mrs', 'Miss', 'Ms']}
                                                label="Title"
                                                width="100%"
                                                props={register('title')}
                                            />
                                            <div className="ml-5 mt-1 text-sm text-red-700">
                                                {errors.title?.message}
                                            </div>
                                        </div>
                                        <div className="">
                                            <TextInput
                                                name="firstName"
                                                label="First name"
                                                width="100%"
                                                props={register('firstName')}
                                            />
                                            <div className="ml-5 mt-1 text-sm text-red-700">
                                                {errors.firstName?.message}
                                            </div>
                                        </div>
                                        <div className="">
                                            <TextInput
                                                name="lastName"
                                                label="Last name"
                                                width="100%"
                                                props={register('lastName')}
                                            />
                                            <div className="ml-5 mt-1 text-sm text-red-700">
                                                {errors.lastName?.message}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="pt-6">
                                        <TextInput
                                            name="phoneNumber"
                                            label="Phone number"
                                            width="100%"
                                            props={register('phoneNumber')}
                                        />
                                        <div className="ml-5 mt-1 text-sm text-red-700">
                                            {errors.phoneNumber?.message}
                                        </div>
                                        <p className="mt-1 ml-4 text-sm font-light text-nw-grey">
                                            In case we need to contact you in an emergency
                                        </p>
                                    </div>
                                    <div className="mt-4 grid grid-flow-row gap-4 sm:grid-flow-col sm:grid-cols-2">
                                        <div className="">
                                            <TextSelect
                                                name="gender"
                                                options={['Male', 'Female']}
                                                label="Gender at birth"
                                                width="100%"
                                                props={register('gender')}
                                            />
                                            <div className="ml-5 mt-1 text-sm text-red-700">
                                                {errors.gender?.message}
                                            </div>
                                        </div>
                                        <div className="">
                                            <TextSelect
                                                name="ethnicity"
                                                options={[
                                                    'White English / Welsh / Scottish / Northern Irish / British',
                                                    'White Irish',
                                                    'White Gypsy or Irish Traveller',
                                                    'White and Black Caribbean',
                                                    'White and Black African',
                                                    'White and Asian',
                                                    'Indian',
                                                    'Pakistani',
                                                    'Bangladeshi',
                                                    'Chinese',
                                                    'African',
                                                    'Caribbean',
                                                    'Arab',
                                                    'Other'
                                                ]}
                                                label="Ethnicity"
                                                width="100%"
                                                props={register('ethnicity')}
                                            />
                                            <div className="ml-5 mt-1 text-sm text-red-700">
                                                {errors.ethnicity?.message}
                                            </div>
                                        </div>
                                    </div>
                                    <p className="ml-4 mt-4 text-sm text-nw-grey">Date of birth:</p>
                                    <div className="mt-2 grid grid-flow-row gap-4 sm:grid-flow-col sm:grid-cols-3">
                                        <div className="">
                                            <TextSelect
                                                name="dobDay"
                                                options={[
                                                    1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19,
                                                    20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31
                                                ]}
                                                label="Day"
                                                width="100%"
                                                props={register('dobDay')}
                                            />
                                            <div className="ml-5 mt-1 text-sm text-red-700">
                                                {errors.dobDay?.message}
                                            </div>
                                        </div>
                                        <div className="">
                                            <TextSelect
                                                name="dobMonth"
                                                options={[
                                                    'January',
                                                    'February',
                                                    'March',
                                                    'April',
                                                    'May',
                                                    'June',
                                                    'July',
                                                    'August',
                                                    'September',
                                                    'October',
                                                    'November',
                                                    'December'
                                                ]}
                                                label="Month"
                                                width="100%"
                                                props={register('dobMonth')}
                                            />
                                            <div className="ml-5 mt-1 text-sm text-red-700">
                                                {errors.dobMonth?.message}
                                            </div>
                                        </div>
                                        <div className="">
                                            <TextSelect
                                                name="dobYear"
                                                options={dobYears}
                                                label="Year"
                                                width="100%"
                                                props={register('dobYear')}
                                            />
                                            <div className="ml-5 mt-1 text-sm text-red-700">
                                                {errors.dobYear?.message}
                                            </div>
                                        </div>
                                    </div>
                                    <p className="ml-4 mt-6 text-sm text-nw-grey">Choose your secure password:</p>
                                    <div className="mt-2">
                                        <TextInput
                                            name="password"
                                            label="Password"
                                            type="password"
                                            width="100%"
                                            props={register('password')}
                                        />
                                        <div className="ml-5 mt-1 text-sm text-red-700">{errors.password?.message}</div>
                                    </div>
                                    <div className="mt-4">
                                        <TextInput
                                            name="confirmPassword"
                                            label="Confirm password"
                                            type="password"
                                            width="100%"
                                            props={register('confirmPassword')}
                                        />
                                        <div className="ml-5 mt-1 text-sm text-red-700">
                                            {errors.confirmPassword?.message}
                                        </div>
                                    </div>
                                    <div className="mt-6">
                                        <Button
                                            text="Create account"
                                            disabled={isSubmitting}
                                            shadow={true}
                                            fullWidth={true}
                                            icon={true}
                                        />
                                    </div>
                                    <div className="mt-4 h-0.5 w-full rounded-3xl bg-nw-lightGrey"></div>
                                    <p className="mt-3 ml-4 text-center text-sm font-light text-nw-grey">
                                        By clicking "Create account" you acknowledge the{' '}
                                        <a className="underline" href="https://#">
                                            Privacy Statement
                                        </a>
                                        , and agree to be bound by the{' '}
                                        <a className="underline" href="https://#">
                                            Conditions of Sales & Services
                                        </a>
                                    </p>
                                </>
                            )}
                            <div className="flex justify-center">
                                {isChecking && <div className="mini-loader mt-[10px] mr-2"></div>}
                                <p className="mt-3 text-center text-sm text-nw-grey">
                                    {userMatch === -1
                                        ? "Let's check if you already have an account with us"
                                        : userMatch === 1
                                        ? 'Looks like you already have an account. Sign in to continue'
                                        : ''}
                                </p>
                            </div>
                        </form>
                    </>
                )}

                {authUser && (
                    <>
                        <p className="text-center font-light text-nw-grey">
                            Currently logged in as: <span className="font-medium">{authUser.user.email}</span>
                        </p>
                        <p
                            className="mt-2 text-center text-sm font-medium text-nw-offBlack underline hover:cursor-pointer"
                            onClick={() => {
                                reset();
                                dispatch(authActions.noRedirectlogout());
                            }}
                        >
                            Log out
                        </p>
                        <div className="mt-6">
                            <Link to={'/checkout/shipping'}>
                                <Button text="Continue" shadow={true} fullWidth={true} icon={true} />
                            </Link>
                        </div>
                    </>
                )}
            </div>
        </div>
    );
}
