import React from 'react';
import { Button } from '_components';
import { cartActions } from '_store';
import { useSelector, useDispatch } from 'react-redux';
import { getItemPriceBreakdown } from '_helpers';
import payments from '../../../_assets/payments.png';
import { Link } from 'react-router-dom';
import { products } from '_helpers';

export { Checkout };

function Checkout({ children, hideButton = false }) {
    const dispatch = useDispatch();
    const cart = useSelector((state) => state.cart);

    const biomarkerTotal = {};
    const testTypesTotal = {};
    const itemsTotal = {};
    let subtotal = 0;

    if ((cart !== undefined) & (cart.cart.length > 0)) {
        cart.cart.forEach((item) => {
            if (products[item.productID] !== undefined) {
                const breakdown = getItemPriceBreakdown(item);

                if (item.additionalBiomarkers !== undefined) {
                    biomarkerTotal[item.UID] = breakdown.biomarkerTotal;
                }

                if (item.testType !== undefined) {
                    testTypesTotal[item.UID] = breakdown.testTypeTotal;
                }

                itemsTotal[item.UID] = breakdown.total;

                subtotal += breakdown.total;
            } else {
                dispatch(cartActions.removeItem(item.UID));
            }
        });
    }

    return (
        <>
            <div className="my-10 min-h-[80vh] lg:my-20 lg:flex">
                <div className="relative lg:mr-[1%] lg:w-[74%]">
                    {/* <Elements stripe={stripePromise} options={options}> */}
                    {children}
                    {/* </Elements> */}
                </div>
                <div className="lg:w-[25%]">
                    {cart !== undefined && cart.cart.length > 0 && (
                        <div className="mt-4 rounded-3xl border-2 py-3 px-4 shadow-2xl lg:mt-0">
                            <p className="mb-4 text-center text-lg font-medium text-nw-offBlack">Order Summary</p>
                            {cart.cart
                                .slice(0)
                                .reverse()
                                .map((item, index) => (
                                    <React.Fragment key={item.title + '-' + index}>
                                        <div className="mt-2 flow-root text-sm font-medium text-nw-offBlack">
                                            <p className="float-left max-w-[80%]">{item.title}</p>
                                            <p className="float-right">£{item.basePrice.toFixed(2)}</p>
                                        </div>
                                        {item.additionalBiomarkers !== undefined && biomarkerTotal[item.UID] > 0 && (
                                            <div className="mt-1 flow-root text-sm text-nw-offBlack">
                                                <p className="float-left">+ Additional biomarkers</p>
                                                <p className="float-right">£{biomarkerTotal[item.UID].toFixed(2)}</p>
                                            </div>
                                        )}
                                        {item.testType !== undefined && item.testType.price > 0 && (
                                            <div className="mt-1 flow-root text-sm text-nw-offBlack">
                                                <p className="float-left max-w-[80%]">+ {item.testType.title}</p>
                                                <p className="float-right">£{testTypesTotal[item.UID].toFixed(2)}</p>
                                            </div>
                                        )}
                                        {item.extras !== undefined &&
                                            Object.keys(item.extras).map((key, eindex) =>
                                                item.extras[key].value ? (
                                                    <div
                                                        className="mt-1 flow-root text-sm text-nw-offBlack"
                                                        key={item.extras[key].title + '-' + eindex}
                                                    >
                                                        <p className="float-left max-w-[80%]">
                                                            + {item.extras[key].title}
                                                        </p>
                                                        <p className="float-right">
                                                            £{item.extras[key].price.toFixed(2)}
                                                        </p>
                                                    </div>
                                                ) : (
                                                    ''
                                                )
                                            )}
                                        {products[item.productID].personalise !== null && (
                                            <Link to={'/checkout/personalise-test?uid=' + item.UID}>
                                                <p className="mt-1 text-sm font-semibold text-nw-offBlack underline hover:cursor-pointer">
                                                    Personalise this test
                                                </p>
                                            </Link>
                                        )}
                                        {index < cart.cart.length - 1 && (
                                            <div className="mt-2 h-[1px] w-full rounded-3xl bg-nw-lightGrey"></div>
                                        )}
                                    </React.Fragment>
                                ))}
                            <div className="mt-2 h-0.5 w-full rounded-3xl bg-nw-lightGrey"></div>
                            <div className="mt-4 flow-root text-sm text-nw-offBlack">
                                <p className="float-left">Subtotal</p>
                                <p className="float-right">£{subtotal.toFixed(2)}</p>
                            </div>
                            <div className="mt-4 flow-root text-sm text-nw-offBlack">
                                <p className="float-left">Delivery</p>
                                <p className="float-right text-[#329B00]">Free</p>
                            </div>
                            {/* <div className="mt-4 flow-root text-sm text-nw-offBlack">
                                <p className="float-left">Promo code:</p>
                                <p className="float-right">QD9CMA</p>
                            </div> */}
                            <div className="mt-4 h-0.5 w-full rounded-3xl bg-nw-lightGrey"></div>
                            <div className="mt-4 mb-2 flow-root text-sm font-semibold text-nw-offBlack">
                                <p className="float-left">Total</p>
                                <p className="float-right">£{subtotal.toFixed(2)}</p>
                            </div>
                            {!hideButton && (
                                <>
                                    <div className="flex w-full justify-center">
                                        <img
                                            className="relative mt-2 mb-2"
                                            src={payments}
                                            alt="Accepted payment types"
                                        />
                                    </div>
                                    <Link to={'/checkout/account'}>
                                        <Button
                                            text="Checkout"
                                            icon={true}
                                            fullWidth={true}
                                            shadow={false}
                                            formHeight={false}
                                        />
                                    </Link>
                                </>
                            )}
                        </div>
                    )}
                </div>
            </div>
        </>
    );
}
