import { Button } from './Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleXmark } from '@fortawesome/free-regular-svg-icons';

export { PopupChoice };

function PopupChoice({
    title,
    body,
    yesText = 'Yes',
    noText = 'No',
    onYes = null,
    onNo = null,
    onClose = null,
    submitting = false
}) {
    return (
        <div className="fixed top-0 left-0 z-50 h-full w-full bg-[rgba(0,0,0,0.4)]">
            <div className="flex h-screen justify-center">
                <div className="relative my-auto w-[90%] rounded-2xl bg-white px-8 py-10 md:w-[80%] md:px-16 lg:w-[70%]">
                    <FontAwesomeIcon
                        icon={faCircleXmark}
                        onClick={onClose}
                        className="absolute top-8 right-9 mr-1 h-10 w-10 text-nw-grey transition-colors hover:cursor-pointer hover:text-nw-darkGrey"
                    />
                    <div className="py-4 pt-10 text-center md:pt-4">
                        <p className="text-2xl text-nw-offBlack">{title}</p>
                        {body}
                    </div>

                    <div className="mb-6 flex flex-col justify-center gap-4 md:flex-row md:gap-8">
                        <div className="md:mt-4 md:w-[200px]" onClick={onYes}>
                            <Button
                                text={yesText}
                                shadow={true}
                                fullWidth={true}
                                formHeight={true}
                                disabled={submitting}
                            />
                        </div>

                        <div className="md:mt-4 md:w-[200px]" onClick={onNo}>
                            <Button text={noText} shadow={true} fullWidth={true} formHeight={true} red={true} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
