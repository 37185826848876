import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { cartActions } from '_store';
import { PriceCard, Select, Popup } from '_components';
import Customise from '../../_assets/Customise.svg';
import parterClinicIcon from '../../_assets/Visit-Partner-Clinic.svg';
import paperCopyIcon from '../../_assets/paper-copy-of-results.svg';

import { checkNested, getItemPriceBreakdown, products } from '_helpers';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt } from '@fortawesome/free-regular-svg-icons';
import { faVial, faChevronRight } from '@fortawesome/free-solid-svg-icons';

export { ONCONTEXTProduct };

function ONCONTEXTProduct({ item, personalise, additionalBiomarkers, productImage, partnerClinicCallback = null }) {
    const [popupContent, setPopupContent] = useState(null);
    const dispatch = useDispatch();
    const [totalPrice, setTotalPrice] = useState(0);
    const [frequency, setFrequency] = useState(item.frequency !== undefined ? parseInt(item.frequency) : 0);
    const freqMap = {
        1: '3 months',
        2: '6 months',
        3: 'year'
    };

    function TestTypeCard({ eIcon, eTitle, eBody, ePrice, eItem }) {
        return (
            <PriceCard
                icon={eIcon}
                title={eTitle}
                body={eBody}
                price={ePrice}
                toggleCallback={(val) => {
                    dispatch(
                        cartActions.setProductTestType({
                            UID: eItem.UID,
                            testType: eTitle,
                            price: ePrice
                        })
                    );
                }}
                selected={checkNested(eItem, 'testType') ? eItem.testType.title === eTitle : false}
                ignoreToggle={true}
            />
        );
    }

    function ExtrasCard({ eIcon, eTitle, eBody, ePrice, eItem }) {
        return (
            <PriceCard
                icon={eIcon}
                title={eTitle}
                body={eBody}
                price={ePrice}
                toggleCallback={(val) => {
                    dispatch(
                        cartActions.setProductExtra({
                            UID: eItem.UID,
                            extra: eTitle,
                            price: ePrice,
                            value: val
                        })
                    );
                }}
                selected={checkNested(eItem, 'extras', eTitle) ? eItem['extras'][eTitle].value : false}
            />
        );
    }

    useEffect(() => {
        const breakdown = getItemPriceBreakdown(item);
        setTotalPrice(breakdown.total);
    }, [item]);

    return (
        <>
            {popupContent && (
                <Popup
                    children={popupContent}
                    onClose={() => {
                        setPopupContent(null);
                    }}
                />
            )}
            <div className="mb-8 rounded-[27px] bg-[#E2F0F4] p-1">
                <div className="relative rounded-3xl bg-nw-blue py-6 px-6">
                    <div className="my-2 flex-col md:flex xl:flex-row">
                        <div className="hidden xl:mr-[2%] xl:block xl:w-[28%]">
                            <img
                                className="relative mt-[-35px] hidden w-full sm:block"
                                src={productImage}
                                alt="Product"
                            />
                        </div>
                        <div className="w-full xl:mr-[2%] xl:w-[50%]">
                            <div className="md:flex xl:block">
                                <img
                                    className="mt-[-20px] hidden w-[200px] md:block lg:mt-[-35px] xl:hidden"
                                    src={productImage}
                                    alt="Product"
                                />

                                <div className="w-full md:mt-2 md:pl-4 xl:mt-0 xl:pl-0">
                                    <p
                                        className={
                                            'text-2xl text-nw-offBlack' +
                                            (personalise ? ' md:mt-2' : ' md:mt-14 lg:mt-10')
                                        }
                                    >
                                        {item.title}
                                    </p>
                                    {personalise && (
                                        <Link to={'/checkout/personalise-test?uid=' + item.UID}>
                                            <div className="relative mt-2 flex w-full justify-center rounded-2xl bg-black py-5 text-center text-white hover:cursor-pointer">
                                                <img
                                                    className="relative mr-3 mt-2 hidden h-[36px] w-[60px] sm:block"
                                                    src={Customise}
                                                    alt="Personalise test"
                                                />
                                                <div className="px-4 text-left md:px-0">
                                                    <div className="relative mr-0 font-semibold md:px-0">
                                                        Personalise Your Test
                                                        {additionalBiomarkers > 0 && (
                                                            <div className="absolute top-[1px] right-[50px] h-5 w-5 rounded-[50%] bg-white pt-0.5 text-center text-xs text-black md:right-[58px]">
                                                                {additionalBiomarkers}
                                                            </div>
                                                        )}
                                                    </div>
                                                    <p className="mt-0.5 text-sm">
                                                        Add extra markers from as little as £5
                                                    </p>
                                                </div>
                                            </div>
                                        </Link>
                                    )}
                                </div>
                            </div>
                            <div
                                className="relative mt-4 flex hover:cursor-pointer"
                                onClick={() =>
                                    setPopupContent(
                                        <>
                                            <p className="text-center text-lg text-nw-offBlack">{item.name}</p>
                                            <p className="text-center text-2xl text-nw-offBlack">Markers Tested</p>
                                            {products[item.productID].markersTested}
                                        </>
                                    )
                                }
                            >
                                <FontAwesomeIcon
                                    icon={faVial}
                                    style={{ color: '#7A7A7A' }}
                                    className="relative h-7 w-7 text-nw-grey"
                                />
                                <p className="ml-2 text-lg text-nw-darkGrey">Markers Tested</p>
                                <FontAwesomeIcon
                                    icon={faChevronRight}
                                    style={{ color: '#7A7A7A' }}
                                    className="absolute top-1 right-0 h-5 w-5 text-nw-grey"
                                />
                            </div>
                            <div className="mt-2 h-[1px] w-full rounded-3xl bg-nw-lightGrey"></div>
                        </div>
                        <div className="w-full xl:w-[41%]">
                            <div className="mt-5 flex">
                                <div className="mr-8 w-[150px]">
                                    <p className="mb-1 ml-2 text-nw-offBlack">Frequency</p>
                                    <Select
                                        name="frequency"
                                        options={['One-time', '3 Months', '6 Months', '1 Year']}
                                        label="Frequency"
                                        width="100%"
                                        selected={item.frequency !== undefined ? item.frequency : 0}
                                        callback={(e) => {
                                            setFrequency(parseInt(e.target.value));
                                            dispatch(
                                                cartActions.setProductFrequency({
                                                    UID: item.UID,
                                                    frequency: parseInt(e.target.value)
                                                })
                                            );
                                        }}
                                    />
                                </div>
                                <div>
                                    <p className="mb-1 ml-2 text-nw-offBlack">Quantity</p>
                                    <div
                                        className="my-1 flex rounded-2xl border border-nw-lightGrey bg-white font-light drop-shadow-md"
                                        onClick={() => dispatch(cartActions.removeItem(item.UID))}
                                    >
                                        <div className="rounded-2xl border-r border-nw-lightGrey px-3 py-1.5 transition-colors hover:cursor-pointer hover:bg-[#f9f9f9]">
                                            <FontAwesomeIcon
                                                icon={faTrashAlt}
                                                style={{ color: '#7A7A7A' }}
                                                className="relative top-0.5 h-5 w-5 text-nw-grey"
                                            />
                                        </div>
                                        <div className="ml-5 mr-6 mt-2 text-nw-offBlack">1</div>
                                    </div>
                                </div>
                            </div>
                            {frequency === 0 && (
                                <div className="mt-4 text-sm font-light text-nw-grey">
                                    Your {item.title} will be charged one-time at{' '}
                                    <span className="font-medium">£{totalPrice}</span>. You can view this order at any
                                    time using your dashboard.
                                </div>
                            )}
                            {frequency > 0 && (
                                <div className="mt-4 text-sm font-light text-nw-grey">
                                    Your {item.title} will be charged at{' '}
                                    <span className="font-medium">£{totalPrice}</span> then{' '}
                                    <span className="font-medium">£{totalPrice}</span> every{' '}
                                    <span className="font-medium">{freqMap[frequency]}</span>. You can cancel or change
                                    the frequency of your test at any time using your dashboard.
                                </div>
                            )}
                        </div>
                    </div>

                    <div className="mt-6 rounded-3xl pt-0 md:mt-4 xl:mt-4">
                        <div className="text-center text-xl font-light text-nw-offBlack">
                            <div className="rounded-2xl px-8 py-1">Select the type of test:</div>
                        </div>
                        <TestTypeCard
                            eIcon={parterClinicIcon}
                            eTitle={'In Clinic Appointment'}
                            eBody={
                                <>
                                    Book an appointment at one of our clinics or have your sample taken at one of our
                                    nationwide partner clinics by a trained phlebotomist.{' '}
                                    <span
                                        className="font-medium underline hover:cursor-pointer"
                                        onClick={() => partnerClinicCallback()}
                                    >
                                        Find your nearest clinic
                                    </span>
                                </>
                            }
                            ePrice={0}
                            eItem={item}
                        />
                        <div className="mt-8 mb-6 h-0.5 w-full rounded-3xl bg-nw-lightGrey"></div>
                        <div className="text-center text-xl font-light text-nw-offBlack">
                            <div className="rounded-2xl px-8 py-1">Extras:</div>
                        </div>
                        <ExtrasCard
                            eIcon={paperCopyIcon}
                            eTitle={'Get a Personalised Results Brochure'}
                            eBody={'Get a result brochure sent to your address along with your doctors report.'}
                            ePrice={10}
                            eItem={item}
                        />
                    </div>
                </div>
            </div>
        </>
    );
}
