import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { IntercomProvider } from 'react-use-intercom';
import { Maintenance } from '_views/maintenance/Maintenance';
import { persistor, store } from './_store';
import { PersistGate } from 'redux-persist/integration/react';
import { App } from './App';
import './index.css';

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
    <React.StrictMode>
        <Provider store={store}>
            <PersistGate loading={<div>Loading</div>} persistor={persistor}>
                <BrowserRouter>
                    <IntercomProvider appId={'pq5ij879'}>
                        {process.env.REACT_APP_MAINTENANCE_MODE === '1' ? <Maintenance /> : <App />}
                    </IntercomProvider>
                </BrowserRouter>
            </PersistGate>
        </Provider>
    </React.StrictMode>
);
