import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Button, TextInput, Loading } from '_components';
import moment from 'moment';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight, faLocationDot, faCalendarDays } from '@fortawesome/free-solid-svg-icons';
import { GoogleMap, MarkerF, useJsApiLoader } from '@react-google-maps/api';
import { googleAPILibraries, history } from '_helpers';
import markerIcon from '../../../_assets/location-marker.png';
import { useDispatch } from 'react-redux';
import { cartActions } from '_store';

export { Booking };

const containerStyle = {
    width: '100%',
    height: '100%',
    borderRadius: '1.5rem'
};

const center = {
    lat: 54.15,
    lng: -3.5
};

function Booking() {
    const [loading, setLoading] = useState(false);
    const [postcodeInput, setPostcode] = useState('');
    const [clinics, setClinics] = useState(null);
    const [displayClinics, setDisplayClinics] = useState(null);
    const [clinicsAvailabilty, setClinicsAvailabilty] = useState(null);
    const [stage, setStage] = useState(0);
    const [location, setLocation] = useState('');
    const [locationID, setLocationID] = useState('');
    const [locationAddress, setLocationAddress] = useState('');
    const [date, setDate] = useState('');
    const curDate = moment().format();
    const [availableTimes, setAvailableTimes] = useState(null);
    const [requestComplete, setRequestComplete] = useState(false);
    const [isSubmittingPostcode, setSubmittingPostcode] = useState(false);
    // eslint-disable-next-line no-unused-vars
    const [searchParams, setSearchParams] = useSearchParams();
    const UID = parseInt(searchParams.get('uid'));
    const ID = searchParams.get('id');
    const clamp = (num, min, max) => Math.min(Math.max(num, min), max);
    const frequency = clamp(parseInt(searchParams.get('frequency')), 0, 3);
    const dispatch = useDispatch();

    const query = `
    query {
        clinicCollection(order:featuredOrder_ASC) {
            items {
                name
                displayAddress
                location {
                    lon
                    lat
                }
                sys {
                    id
                }
            }
        }
      
        healthCheckAvailabilityCollection(order:date_ASC, where: {date_gt: "${curDate}", times_exists: true}) {
            items {
                clinic {
                    name
                    displayAddress
                }
                date
                times
            }
        }
    }
    `;

    function LocationCard({ name, displayName, address, entryID }) {
        return (
            <div
                className="relative mb-6 rounded-3xl border border-nw-lightGrey p-6 transition-colors hover:cursor-pointer hover:bg-gray-50"
                onClick={() => {
                    setLocation(name);
                    setLocationID(entryID);
                    setLocationAddress(address);
                    setStage(1);
                }}
            >
                <p className="mr-8 text-xl font-medium text-nw-offBlack">{displayName}</p>
                <p className="mt-4 font-medium text-nw-offBlack">Address:</p>
                <p className="mr-8 text-nw-offBlack">{address}</p>
                <FontAwesomeIcon
                    icon={faArrowRight}
                    style={{ color: '#7A7A7A' }}
                    className="absolute top-[25px] right-[25px] h-6 w-6"
                />
            </div>
        );
    }

    function DateCard({ date }) {
        const day = moment(date).format('ddd');
        const num = moment(date).format('Do');
        const month = moment(date).format('MMM');

        return (
            <div
                className="rounded-3xl bg-[#E5F3F8] transition-colors hover:cursor-pointer hover:bg-[#DDECF0]"
                onClick={() => {
                    setDate(date);

                    let index = clinicsAvailabilty[location].findIndex(function (item, i) {
                        return item.date === date;
                    });

                    let times = clinicsAvailabilty[location][index].times;
                    times.sort(function (a, b) {
                        if (a < b) return -1;
                        if (a > b) return 1;
                        return 0;
                    });

                    setAvailableTimes(times);
                    setStage(2);
                }}
            >
                <div className="flex items-center justify-center">
                    <p className="py-4 text-lg font-light text-[#7A7A7A]">{day}</p>
                    <p className="ml-2 text-xl font-light text-nw-offBlack">{num}</p>
                    <div className="ml-3 border-l-2 border-[#F9F9FA] py-4 pl-3">
                        <p className="ml-1 text-lg font-normal leading-8 text-[#7A7A7A]">{month}</p>
                    </div>
                </div>
            </div>
        );
    }

    function TimeCard({ time }) {
        return (
            <div
                className="rounded-3xl bg-[#E5F3F8] transition-colors hover:cursor-pointer hover:bg-[#DDECF0]"
                onClick={() => {
                    if (isNaN(UID)) {
                        //Replace with navigate to AddToCart component to get proper pricing
                        const bookingJSON = JSON.stringify({
                            location: location,
                            locationID: locationID,
                            locationAddress: locationAddress,
                            date: date,
                            time: time
                        });

                        const freq = isNaN(frequency) ? 0 : frequency;

                        history.navigate(`/add-to-basket?id=${ID}&frequency=${freq}&booking=${bookingJSON}`);
                    } else {
                        dispatch(
                            cartActions.updateBooking({
                                UID: UID,
                                booking: {
                                    location: location,
                                    locationID: locationID,
                                    locationAddress: locationAddress,
                                    date: date,
                                    time: time
                                }
                            })
                        );

                        history.navigate('/checkout');
                    }
                }}
            >
                <div className="flex items-center justify-center">
                    <p className="py-4 text-xl font-light text-nw-offBlack">{moment(time, ['h:mm']).format('h:mma')}</p>
                </div>
            </div>
        );
    }

    function filterAvailability(data) {
        let clinicAvailability = {};

        for (let i = 0; i < data.length; i++) {
            if (data[i].clinic !== null) {
                if (clinicAvailability[data[i].clinic.name] === undefined) {
                    clinicAvailability[data[i].clinic.name] = [];
                }

                //Only include dates passed todays current date
                if (moment(data[i].date).isAfter(moment().format())) {
                    clinicAvailability[data[i].clinic.name].push({
                        date: data[i].date,
                        times: data[i].times
                    });
                }
            }
        }

        return clinicAvailability;
    }

    function findClosestClinics(originLat, originLng) {
        let results = [];

        for (let i = 0; i < clinics.length; i++) {
            let destLat = clinics[i].location.lat;
            let destLng = clinics[i].location.lon;

            results.push({
                clinicIndex: i,
                distance: getHaversineDist(originLat, originLng, destLat, destLng)
            });
        }

        results = results
            // eslint-disable-next-line array-callback-return
            .sort((a, b) => {
                if (a.distance < b.distance) {
                    return -1;
                }
            })
            .slice(0, 3); // return 3 closest clinics

        let closestClinics = [];

        for (let i = 0; i < results.length; i++) {
            let clinic = clinics[results[i].clinicIndex];
            clinic['distance'] = results[i].distance;
            closestClinics.push(clinics[results[i].clinicIndex]);
        }

        setDisplayClinics(closestClinics);
    }

    function getHaversineDist(originLat, originLon, destLat, destLon) {
        const R = 6371e3; // metres
        const φ1 = (originLat * Math.PI) / 180; // φ, λ in radians
        const φ2 = (destLat * Math.PI) / 180;
        const Δφ = ((destLat - originLat) * Math.PI) / 180;
        const Δλ = ((destLon - originLon) * Math.PI) / 180;

        const a =
            Math.sin(Δφ / 2) * Math.sin(Δφ / 2) + Math.cos(φ1) * Math.cos(φ2) * Math.sin(Δλ / 2) * Math.sin(Δλ / 2);
        const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

        const d = R * c; // in metres

        return Math.round(d * 0.000621); // return in miles
    }

    function getCoords(postcode) {
        window
            .fetch(
                `https://maps.googleapis.com/maps/api/geocode/json?address=${postcode}&key=${process.env.REACT_APP_GOOGLE_API_KEY}`,
                {
                    method: 'POST'
                }
            )
            .then((response) => response.json())
            .then((json) => {
                //ZERO_RESULTS appears if no results.
                if (json.status === 'OK') {
                    findClosestClinics(json.results[0].geometry.location.lat, json.results[0].geometry.location.lng);
                }
                setSubmittingPostcode(false);
            });
    }

    // form validation rules
    const validationSchema = Yup.object().shape({
        postcode: Yup.string()
            .required('Postcode is required')
            .matches(/^[a-z]{1,2}\d[a-z\d]?\s*\d[a-z]{2}$/i, 'Please enter a valid UK postcode')
    });
    const formOptions = { resolver: yupResolver(validationSchema) };

    // get functions to build form with useForm() hook
    const {
        register,
        handleSubmit,
        formState: { errors }
    } = useForm(formOptions);

    const validationSchema2 = Yup.object().shape({
        email: Yup.string()
            .required('Email is required')
            .email('A valid email is required')
            .max(100, 'Max 100 characters'),
        phone: Yup.string()
            .min(4, 'Phone number is required')
            .matches(
                /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/,
                'Phone number is not valid'
            ),
        postcode: Yup.string()
            .required('Postcode is required')
            .matches(
                /([Gg][Ii][Rr] 0[Aa]{2})|((([A-Za-z][0-9]{1,2})|(([A-Za-z][A-Ha-hJ-Yj-y][0-9]{1,2})|(([A-Za-z][0-9][A-Za-z])|([A-Za-z][A-Ha-hJ-Yj-y][0-9][A-Za-z]?))))\s?[0-9][A-Za-z]{2})/,
                'Postcode is not valid'
            )
    });

    const formOptions2 = { resolver: yupResolver(validationSchema2) };

    const {
        register: register2,
        formState: { errors: errors2, isSubmitting },
        handleSubmit: handleSubmit2
    } = useForm(formOptions2);

    function onSubmit({ postcode }) {
        setSubmittingPostcode(true);
        getCoords(postcode);
    }

    function handlePostcodeChange(e) {
        setPostcode(e.target.value);
    }

    function onSubmit2({ email, phone, postcode }) {
        return dispatch(
            cartActions.addClinicRequest({
                email,
                phone,
                postcode
            })
        ).then(() => {
            setRequestComplete(true);
        });
    }

    useEffect(() => {
        if (isNaN(UID) && ID === null) {
            history.navigate('/checkout');
        }
    }, [ID, UID]);

    useEffect(() => {
        window
            .fetch(
                `https://graphql.contentful.com/content/v1/spaces/${process.env.REACT_APP_CONTENTFUL_SPACE}/environments/${process.env.REACT_APP_CONTENTFUL_ENV}/?access_token=${process.env.REACT_APP_CONTENTFUL_GRAPHQL_TOKEN}`,
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({ query })
                }
            )
            .then((response) => response.json())
            .then((json) => {
                setClinics(json.data.clinicCollection.items);
                setClinicsAvailabilty(filterAvailability(json.data.healthCheckAvailabilityCollection.items));
                setLoading(false);
            });
    }, [query]);

    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY,
        libraries: googleAPILibraries
    });

    return (
        <>
            <Loading loading={true} triggerStop={!loading} delay={1000} />
            <div className="my-10 min-h-[80vh] lg:my-20 lg:flex">
                {stage === 0 && (
                    <div className="lg:mr-[3%] lg:w-[45%]">
                        <h1 className="mb-1 text-center text-2xl font-medium text-nw-offBlack lg:text-left">
                            Find your clinic
                        </h1>
                        <p className="text-center font-light lg:text-left">
                            Enter your postcode below and we'll show you our closest clinics.
                        </p>

                        <form onSubmit={handleSubmit(onSubmit)}>
                            <div className="mt-5 mb-6 flex md:px-20 lg:px-0">
                                <div className="flex w-[55%]">
                                    <TextInput
                                        name="postcode"
                                        label="Enter your postcode"
                                        width="100%"
                                        value={postcodeInput}
                                        props={register('postcode')}
                                        callback={handlePostcodeChange}
                                    />
                                </div>
                                <div className="ml-[5%] flex w-[40%]">
                                    <Button
                                        text="Find"
                                        shadow={true}
                                        disabled={isSubmittingPostcode}
                                        fullWidth={true}
                                    />
                                </div>
                            </div>
                        </form>

                        <div className="relative bottom-4 ml-5 mb-1 text-sm text-red-700">
                            {errors.postcode?.message}
                        </div>

                        <div className="max-h-[540px] overflow-y-auto pr-2">
                            {displayClinics === null &&
                                clinics !== null &&
                                clinics
                                    .slice(0, 10)
                                    .map((clinic, index) => (
                                        <LocationCard
                                            key={clinic.name}
                                            name={clinic.name}
                                            entryID={clinic.sys.id}
                                            displayName={index + 1 + '. ' + clinic.name}
                                            address={clinic.displayAddress}
                                        />
                                    ))}

                            {displayClinics !== null &&
                                displayClinics.map((clinic, index) => (
                                    <LocationCard
                                        key={clinic.name}
                                        name={clinic.name}
                                        entryID={clinic.sys.id}
                                        displayName={
                                            index + 1 + '. ' + clinic.name + ' (' + clinic.distance + ' miles)'
                                        }
                                        address={clinic.displayAddress}
                                    />
                                ))}
                        </div>

                        <div className="mt-10 md:px-0 lg:mt-12 lg:px-0">
                            <p className="text-center text-xl text-nw-offBlack">Not Near a Clinic?</p>
                            <p className="mx-6 mt-4 text-center text-nw-offBlack">
                                We will soon be opening up more clinics across the UK - enter your details below and we
                                will contact you when a clinic becomes available in your area.
                            </p>

                            {requestComplete ? (
                                <p className="mx-6 mt-4 text-center font-medium text-nw-offBlack">
                                    Thanks, we've recieved your request.
                                </p>
                            ) : (
                                <form onSubmit={handleSubmit2(onSubmit2)}>
                                    <div className="mt-6 grid grid-flow-row gap-4 sm:grid-flow-col">
                                        <div>
                                            <TextInput
                                                name="email"
                                                label="Email Address"
                                                width="100%"
                                                props={register2('email')}
                                            />
                                            <div className="relative ml-5 mt-1 text-sm text-red-700">
                                                {errors2.email?.message}
                                            </div>
                                        </div>
                                        <div>
                                            <TextInput
                                                name="phone"
                                                label="Phone Number"
                                                width="100%"
                                                props={register2('phone')}
                                            />
                                            <div className="relative ml-5 mt-1 text-sm text-red-700">
                                                {errors2.phone?.message}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="mt-4 grid grid-flow-row gap-4 sm:grid-flow-col">
                                        <div>
                                            <TextInput
                                                name="postcode"
                                                label="Postcode"
                                                width="100%"
                                                props={register2('postcode')}
                                            />
                                            <div className="relative ml-5 mt-1 text-sm text-red-700">
                                                {errors2.postcode?.message}
                                            </div>
                                        </div>
                                        <Button text="Submit" disabled={isSubmitting} shadow={true} fullWidth={true} />
                                    </div>
                                </form>
                            )}
                        </div>
                    </div>
                )}

                {stage === 1 && (
                    <div className="lg:w-[48%]">
                        <h1 className="mb-1 text-center text-2xl font-medium text-nw-offBlack lg:text-left">
                            Choose a date
                        </h1>
                        <p className="text-center font-light lg:text-left">
                            See the available dates below for your selected clinic.
                        </p>
                        <div className="mt-2 flex justify-center pt-4 lg:justify-start lg:pl-4">
                            <FontAwesomeIcon
                                icon={faLocationDot}
                                style={{ color: '#7A7A7A' }}
                                className="relative top-2 h-8 w-8"
                            />
                            <div className="ml-2">
                                <p className="text-xl text-nw-offBlack">{location}</p>
                                <p
                                    className="text-sm font-semibold text-nw-offBlack underline hover:cursor-pointer"
                                    onClick={() => setStage(0)}
                                >
                                    Change
                                </p>
                            </div>
                        </div>

                        <div className="mt-6 overflow-y-auto rounded-3xl bg-[#F9F9FA] lg:mr-[-100px] lg:h-[615px] lg:pr-[100px]">
                            {clinicsAvailabilty[location] !== undefined ? (
                                <div className="grid grid-cols-1 gap-4 p-6 md:grid-cols-2 lg:pt-6 lg:pl-6 lg:pr-6 xl:grid-cols-3">
                                    {clinicsAvailabilty[location].map((entry, index) =>
                                        entry.times !== null ? (
                                            <DateCard key={'date: ' + entry.date} date={entry.date} />
                                        ) : (
                                            ''
                                        )
                                    )}
                                </div>
                            ) : (
                                <p className="my-auto py-10 px-4 text-center text-nw-grey">
                                    No appointment dates available for this clinic...
                                </p>
                            )}
                        </div>
                    </div>
                )}

                {stage === 2 && (
                    <div className="lg:w-[48%]">
                        <h1 className="mb-1 text-center text-2xl font-medium text-nw-offBlack lg:text-left">
                            Pick a time
                        </h1>
                        <p className="text-center font-light lg:text-left">
                            Select an appointment time for the date selected at your chosen clinic.
                        </p>
                        <div className="mt-2 flex justify-center pt-4 lg:justify-start lg:pl-4">
                            <div className="flex">
                                <FontAwesomeIcon
                                    icon={faLocationDot}
                                    style={{ color: '#7A7A7A' }}
                                    className="relative top-2 h-8 w-8"
                                />
                                <div className="ml-2">
                                    <p className="text-xl text-nw-offBlack">{location}</p>
                                    <p
                                        className="text-sm font-semibold text-nw-offBlack underline hover:cursor-pointer"
                                        onClick={() => setStage(0)}
                                    >
                                        Change
                                    </p>
                                </div>
                            </div>
                            <div className="ml-6 flex lg:ml-14">
                                <FontAwesomeIcon
                                    icon={faCalendarDays}
                                    style={{ color: '#7A7A7A' }}
                                    className="relative top-1 h-6 w-8 md:top-2 md:h-8"
                                />
                                <div className="ml-1 md:ml-2">
                                    <p className="text-xl text-nw-offBlack">{moment(date).format('Do MMM YYYY')}</p>
                                    <p
                                        className="text-sm font-semibold text-nw-offBlack underline hover:cursor-pointer"
                                        onClick={() => setStage(1)}
                                    >
                                        Change
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div className="mt-6 overflow-y-auto rounded-3xl bg-[#F9F9FA] lg:mr-[-100px] lg:h-[615px] lg:pr-[100px]">
                            <div className="grid grid-cols-2 gap-4 p-6 lg:grid-cols-3 lg:pt-6 lg:pl-6 lg:pr-6">
                                {availableTimes.map((time) => (
                                    <TimeCard key={'time: ' + time} time={time} />
                                ))}
                            </div>
                        </div>
                    </div>
                )}

                {stage < 3 && (
                    <div className="mt-2 lg:mt-20 lg:w-[52%]">
                        <div className="relative mt-6 h-[500px] w-full rounded-3xl md:h-[550px] lg:top-[75px] lg:mt-0 lg:h-[615px]">
                            {isLoaded && (
                                <GoogleMap
                                    mapContainerStyle={containerStyle}
                                    center={center}
                                    zoom={6}
                                    options={{
                                        mapId: '3700b719bf2b505b',
                                        fullscreenControl: false,
                                        mapTypeControl: false,
                                        streetViewControl: false
                                    }}
                                >
                                    {clinics !== null &&
                                        clinics.map((clinic, index) => (
                                            <MarkerF
                                                key={'Marker: ' + clinic.name}
                                                name={clinic.name}
                                                position={{ lat: clinic.location.lat, lng: clinic.location.lon }}
                                                icon={markerIcon}
                                                onClick={() => {
                                                    setLocation(clinic.name);
                                                    setStage(1);
                                                }}
                                            />
                                        ))}
                                </GoogleMap>
                                // <div>Google map</div>
                            )}
                        </div>
                    </div>
                )}
            </div>
        </>
    );
}
