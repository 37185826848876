import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleXmark } from '@fortawesome/free-regular-svg-icons';

export { Popup };

function Popup({ children, onClose = null }) {
    return (
        <div className="fixed top-0 left-0 z-50 h-full w-full bg-[rgba(0,0,0,0.4)]">
            <div className="flex h-screen justify-center rounded-2xl">
                <div className="relative my-auto max-h-[90vh] w-[90%] overflow-y-auto rounded-2xl bg-white px-8 py-10 md:w-[80%] md:px-16 lg:w-[85%] xl:w-[70%]">
                    <FontAwesomeIcon
                        icon={faCircleXmark}
                        onClick={onClose}
                        className="absolute top-8 right-7 mr-1 h-10 w-10 text-nw-grey transition-colors hover:cursor-pointer hover:text-nw-darkGrey md:right-9"
                    />
                    {children}
                </div>
            </div>
        </div>
    );
}
